/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDrag, useDrop } from 'react-dnd';
import { AnyObject } from '../../models/Generic';
import { ReactNode } from 'react';
import { TableCell, TableRow } from './table';
import { ExpandableIcon, ExpandableSection, HiddenTD, HiddenTR } from './styles';
import { Checkbox, Collapse } from '@mantine/core';
import { TableColumnsProps, TableSelectionProps } from '.';
import { FindItem } from '../../utils/arrays/ArrayFunctions';
import { FocusColor } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import Icon from '../icon';

interface RowProps {
  rIndex: number;
  rows: AnyObject[];
  row: AnyObject;
  handleCollapse: (index: number) => void;
  renderColumn: (rIndex: number, data: any, column: TableColumnsProps<any>) => ReactNode;
  setHighlight: (data: any) => boolean;
  highlightColor?: string;
  checkRow: (data: any) => void;
  moveItem?: (id: string, index: number, save: boolean) => void;
  expandable?: (value: any, index: number) => ReactNode;
  selection?: TableSelectionProps<any>;
  onRowClick?: (value: any, index: number) => void;
  columns: TableColumnsProps<any>[];
  selectedKey: string;
  open: number | undefined;
  moveRow: (id: string, index: number, save: boolean) => void;
  allowMove: boolean;
  disableOrder: boolean;
  fixColumns?: number[];
}

const Row = ({ 
  rIndex,
  rows, 
  row, 
  handleCollapse, 
  renderColumn, 
  setHighlight, 
  highlightColor, 
  checkRow, 
  expandable,
  selection,
  onRowClick,
  columns,
  selectedKey,
  open,
  moveRow,
  allowMove,
  disableOrder,
  fixColumns
}: RowProps) => {
  const { t } = useTranslation();
  const { _id } = row;
  const originalIndex = FindItem(rows, _id).index;
  
  const [, drag, preview] = useDrag(
    () => ({
      type: 'block',
      item: { id: _id, originalIndex },
      end: ({ id: droppedId, originalIndex }, monitor) => {
        const didDrop = monitor.didDrop();
        if(didDrop) {
          moveRow(droppedId, originalIndex, true);
        }
      }
    }),
    [_id, originalIndex, moveRow]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'block',
      hover({ id: draggedId }: AnyObject) {
        if (draggedId !== _id) {
          const { index: overIndex } = FindItem(rows, _id);
          moveRow(draggedId, overIndex, false);
        }
      }
    }),
    [FindItem, moveRow]
  );

  return (
    <>
      <TableRow highlight={setHighlight(row)} highlightColor={highlightColor} ref={(node: any) => preview(drop(node))}>
        {expandable && (
          <TableCell
            style={{ width: '3.125rem', maxWidth: '3.125rem' }}
            onClick={e => e.stopPropagation()}
          >
            <ExpandableIcon onClick={() => handleCollapse(rIndex)}>
              {open === rIndex ? (
                <Icon icon="outlined_minusCircle" />
              ) : (
                <Icon icon="outlined_plusCircle" />
              )}
            </ExpandableIcon>
          </TableCell>
        )}
        {selection && (
          <TableCell
            style={{ width: '3.125rem', maxWidth: '3.125rem' }}
            onClick={e => e.stopPropagation()}
          >
            <ExpandableIcon>
              <Checkbox
                radius="sm"
                name={row[selectedKey]}
                checked={
                  !!selection.selected.find(
                    s => s.key === row[selectedKey as keyof AnyObject]
                  )
                }
                onChange={() => checkRow(row)}
                size="xs"
              />
            </ExpandableIcon>
          </TableCell>
        )}
        {columns.map((column: TableColumnsProps<AnyObject>, cIndex: number) => (
          <TableCell 
            key={`${rIndex}_${cIndex}`} 
            width={column.width} 
            hasClick={!column.disableRowClick && !!onRowClick}
            onClick={() => !column.disableRowClick && !!onRowClick && onRowClick(row, rIndex)}
            className={fixColumns?.includes(cIndex) ? 'sticky' : ''}
          >
            {renderColumn(rIndex, row, column)}
          </TableCell>
        ))}
        {allowMove && (
          <TableCell
            style={{ width: '3.125rem', maxWidth: '3.125rem' }}
            title={disableOrder ? t('ORDER_REMOVE_FILTERS') : ''}
          >
            <div style={{ width: '100%', pointerEvents: disableOrder ? 'none' : 'initial', opacity: disableOrder ? 0.5 : 1 }} ref={drag}>
              <Icon
                icon="outlined_switchVertical"
                color='#ffffff'
                hoverColor={FocusColor}
              />
            </div>
          </TableCell>
        )}
      </TableRow>
      {expandable && (
        <HiddenTR>
          <HiddenTD
            colSpan={
              columns.length +
              (selection && !!onRowClick
                ? 3
                : selection || !!onRowClick
                ? 2
                : 1)
            }
          >
            <Collapse in={open === rIndex}>
              <ExpandableSection>{expandable(row, rIndex)}</ExpandableSection>
            </Collapse>
          </HiddenTD>
        </HiddenTR>
      )}
    </>
  );
};

export default Row;
