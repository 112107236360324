import { DividerProps } from './index';
import styled, { css } from 'styled-components';

export const DividerComponent = styled.hr<DividerProps>`
  margin: 0;
  border: none;
  background: none;
  display: block;

  ${props => !props.vertical ? 
    props.variant == "dotted" ? 
    css`
      background-image: linear-gradient(to right, ${props.color} 20%, rgba(255, 255, 255, 0) 0%);
      background-position: top;
      background-size: 0.5rem 0.125rem;
      background-repeat: repeat-x;
      width: ${props.width};
      height: 0.125rem;
    ` : 
    props.variant == "dashed" ? 
    css`
      background-image: linear-gradient(to right, ${props.color} 60%, rgba(255, 255, 255, 0) 0%);
      background-position: top;
      background-size: 0.75rem 0.375rem;
      background-repeat: repeat-x;
      width: ${props.width};
      height: 0.063rem;
    ` : 
    css`
      border-top: 0.063rem solid ${props.color};
      width: ${props.width};
    ` :  
    props.variant == "dotted" ? 
    css`
      background-image: linear-gradient(to bottom, ${props.color} 20%, rgba(255, 255, 255, 0) 0%);
      background-position: left;
      background-size: 0.125rem 0.5rem;
      background-repeat: repeat-y;
      height: ${props.verticalHeight};
      width: 0.125rem;
    ` : 
    props.variant == "dashed" ? 
    css`
      background-image: linear-gradient(to bottom, ${props.color} 60%, rgba(255, 255, 255, 0) 0%);
      background-position: left;
      background-size: 0.375rem 0.75rem;
      background-repeat: repeat-y;
      width: 0.063rem;
      height: ${props.verticalHeight};
    ` : 
    css`
      border-left: 0.063rem solid ${props.color};
      height: ${props.verticalHeight};
    `}
`;
