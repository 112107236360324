import { Fight } from "./Fight";
import { AnyObject } from "./Generic";
import { User } from "./User";

// value < treshold (this value will not be included)
export const consistencyTreshold = 100;

export const NEEDS_REVIEW = 'NEEDS_REVIEW';
export const REVIEWED = 'REVIEWED';
export const REJECTED = 'REJECTED';

export const ReviewStatusList: AnyObject[] = [ 
  { 
    _id: NEEDS_REVIEW, 
    name: NEEDS_REVIEW, 
    icon: 'outlined_exclamationCircle', 
    iconColor: 'rgba(235, 233, 81, 0.5)', 
    bgColor: 'rgba(235, 233, 81, 0.1)', 
    fontColor: '#ffffff'
  },
  { 
    _id: REVIEWED, 
    name: REVIEWED, 
    icon: 'outlined_check', 
    iconColor: 'rgba(45, 177, 74, 0.4)', 
    bgColor: 'rgba(45, 177, 74, 0.1)', 
    fontColor: '#ffffff' 
  },
  { 
    _id: REJECTED, 
    name: REJECTED, 
    icon: 'outlined_xCircle', 
    iconColor: 'rgba(221, 64, 64, 0.4)', 
    bgColor: 'rgba(221, 64, 64, 0.1)', 
    fontColor: '#ffffff' 
  }
];

export interface ReviewRound {
  round: number;
  consistency_white_red: number;
  consistency_white_blue: number;
  consistency_white: number;
  consistency_green_red: number;
  consistency_green_blue: number;
  consistency_green: number;
  consistency_yellow_red: number;
  consistency_yellow_blue: number;
  consistency_yellow: number;
  consistency_round: number;
}

export interface Review {
  readonly _id: string;
  consistency: number;
  fight: Fight;
  rounds: ReviewRound[];
  status: typeof NEEDS_REVIEW | typeof REVIEWED | typeof REJECTED;
  last_review?: { user: User, name: string, date: Date };
}