import { FC, useContext } from 'react';
import { UserContext } from '../../Context';
import { Navigate } from 'react-router-dom';

const UnauthenticatedRoute: FC<{ children: JSX.Element, forceValidation?: boolean }> = ({ children, forceValidation }) => {
  const { user } = useContext(UserContext);
  if (user && forceValidation) {
    return <Navigate replace to="/" />;
  }
  return children;
};

export default UnauthenticatedRoute;
