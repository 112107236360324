import { FC } from 'react';
import {
  LoadingOverlay as MantineOverlay,
  MantineNumberSize
} from '@mantine/core';
import { G800, P800 } from '../../styles/Colors';

export type LoadSize = 'small' | 'medium' | 'large';

export interface LoadingOverlayProps {
  visible: boolean;
  overlayOpacity?: number;
  overlayColor?: string;
  overlayBlur?: number;
  size?: LoadSize;
  color?: string;
  radius?: MantineNumberSize;
}

const setSize = (size?: LoadSize) => {
  switch (size) {
    case 'small':
      return '30px';
    case 'medium':
      return '50px';
    case 'large':
      return '100px';
    default:
      return '50px';
  }
};

const setLoading = (size?: LoadSize, color?: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      margin: 'auto',
      background: 'none',
      display: 'block',
      shapeRendering: 'auto'
    }}
    width={setSize(size)}
    height={setSize(size)}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <path
      fill="none"
      stroke={color || P800}
      strokeWidth="8"
      strokeDasharray="218.10058898925783 38.48833923339842"
      d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
      strokeLinecap="round"
      style={{
        transform: 'scale(1)',
        transformOrigin: '50px 50px'
      }}
    >
      <animate
        attributeName="stroke-dashoffset"
        repeatCount="indefinite"
        dur="1s"
        keyTimes="0;1"
        values="0;256.58892822265625"
      ></animate>
    </path>
  </svg>
);

export const LoadingOverlay: FC<LoadingOverlayProps> = (props) => (
  <MantineOverlay
    {...props}
    overlayOpacity={0.4}
    overlayBlur={props.overlayBlur || 0.3}
    overlayColor={G800}
    loader={setLoading(props.size, props.color)}
  />
);

export default LoadingOverlay;
