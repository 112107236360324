/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  User,
  AdminList,
  AdminListType,
  SUPER_ADMIN,
  ADMIN,
  REGULATOR,
  RESPONSIBLE,
  INVITED,
  UserStatusList
} from '../../../models/User';
import { UserContext } from '../../../Context';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Block, Box } from '../../../styles/BasicStyles';
import { FocusColor, LabelColor, SubMenuColor } from '../../../styles/Colors';
import {
  showConfirm,
  showDelete,
  showSuccess
} from '../../../hooks/show-notification/show-notification';
import { useDidUpdate } from '@mantine/hooks';
import { AnyObject } from '../../../components/inputs/Generic';
import { TableSortProps } from '../../../components/table';
import { PaginationObject } from '../../../components/page/DefaultListPage';
import { Federation } from '../../../models/Federation';
import { GetUserInitialValues } from '../../../utils/modalValues';
import useFetch from 'use-http';
import Icon from '../../../components/icon';
import Typography from '../../../components/typography';
import ManageAdminModal from './ManageAdminModal';
import DropdownMenu from '../../../components/dropdownMenu';
import BaseTable from '../../../components/table';
import ConstructPaginationRequest from '../../../utils/requests/ConstructPaginationRequest';
import PageFilters from '../../../components/page/PageFilters';
import Button from '../../../components/button';
import Filters from './Filters';
import LabelDropdown from '../../../components/labelDropdown';
import UserCard from '../../../components/userCard';

const AdminsTable = BaseTable<User>();

const UsersPage = () => {
  const [modal, setModal] = useState<{ open: boolean; initialValues?: User }>({ open: false });
  const [availableAdminList, setAvailableAdminList] = useState<AdminListType[]>([]);
  const [federations, setFederations] = useState<Federation[]>([]);
  const [pagination, setPagination] = useState<PaginationObject>({
    page: 1,
    limit: 20
  });
  const [rows, setRows] = useState<User[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { get, del, put } = useFetch('/admins');
  const federationsHook = useFetch('/federations/dropdown');

  useEffect(() => {
    if(user?.type === RESPONSIBLE || user?.type === REGULATOR) {
      setAvailableAdminList(AdminList.filter(elem => elem._id === RESPONSIBLE));
    } 
    else if(user?.type === ADMIN) {
      setAvailableAdminList(AdminList.filter(elem => elem._id !== SUPER_ADMIN));
    } 
    else {
      setAvailableAdminList(AdminList);
    }
  }, [user]);

  useEffect(() => {
    const init = async () => {
      const defaultPagination: PaginationObject = {
        page: 1,
        limit: 20
      };

      const json = localStorage.getItem('USERS_FILTERS');
      const obj = JSON.parse(json || '{}');
      if (obj.page) defaultPagination.page = obj.page;
      if (obj.limit) defaultPagination.limit = obj.limit;
      if (obj.filters) defaultPagination.filters = obj.filters;
      if (obj.sort) defaultPagination.sort = obj.sort;

      setPagination(defaultPagination);

      if(user?.type !== RESPONSIBLE) {
        const federationsResult = await federationsHook.get();

        setFederations(federationsResult?.data || []);
      }

      setReady(true);
    };

    init();
  }, []);

  useDidUpdate(() => {
    const init = async () => {
      setLoading(true);

      const url = ConstructPaginationRequest(
        `/${pagination.page}/${pagination.limit}`,
        pagination.filters,
        pagination.sort,
        ['name', 'email']
      );

      const result = await get(url);

      if (pagination.page > 1 && (result?.data?.items || []).length === 0) {
        const pag = { ...pagination, page: 1 };
        handleNewPagination(pag);
      } else {
        setRows(result?.data?.items || []);
        setTotal(result?.data?.total || 0);
        setLoading(false);
      }
    };
    init();
  }, [pagination]);

  const handleNewPagination = (pag: PaginationObject) => {
    localStorage.setItem('USERS_FILTERS', JSON.stringify(pag));
    setPagination(pag);
  };

  const handleNewPage = (page: number) => {
    const pag = { ...pagination, page };
    handleNewPagination(pag);
  };

  const handleNewSort = (sort: TableSortProps) => {
    const pag = { ...pagination, sort };
    handleNewPagination(pag);
  };

  const handleNewFilters = (filters: AnyObject) => {
    const pag = { ...pagination, page: 1, filters };
    handleNewPagination(pag);
  };

  const refreshPage = (params?: PaginationObject) => {
    const pag = params ? { ...params } : { ...pagination };
    handleNewPagination(pag);
  };

  const handleCloseModal = (changed: boolean) => {
    if (changed) refreshPage();
    setModal({ open: false });
  };

  const handleDelete = async (id: string) => {
    showDelete({
      title: t('DELETE_MEMBER'),
      message: t('DELETE_MEMBER_MESSAGE'),
      onConfirm: async () => {
        await del(`/${id}`);
        refreshPage();
      }
    });
  };

  const resendInvite = async (id: string) => {
    showConfirm({
      title: t('RESEND_INVITE'),
      message: t('RESEND_INVITE_MESSAGE'),
      onConfirm: async () => {
        const { success } = await get(`/resend-invite/${id}`);

        if (success) {
          showSuccess({
            title: t('USER_INVITED_SUCCESS'),
            message: t('USER_INVITED_MESSAGE')
          });

          refreshPage();
        }
      }
    });
  };

  const onConfirmStatusToogle = async (id: string, status: string) => {
    await put(`/${id}/change-status`, { status });
    refreshPage();
  };

  const columns: any = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      sortable: true,
      render: (_: string, row: User) => (
        <UserCard user={row} showEmail noStatus />
      )
    },
    {
      title: t('TYPE'),
      dataIndex: 'type',
      sortable: true,
      render: (value: string) => (
        <Typography variant="table-header">{t(value)}</Typography>
      )
    },
    {
      title: t('STATUS'),
      dataIndex: 'status',
      sortable: true,
      render: (value: string, row: User) => (
        <LabelDropdown
          status={value}
          list={UserStatusList}
          id={row._id}
          onConfirmStatusToogle={onConfirmStatusToogle}
        />
      )
    },
    {
      title: t('ACTION'),
      key: 'actions',
      render: (row: User) => {
        const options: any[] = [
          {
            type: 'link',
            label: t('EDIT'),
            onClick: () =>
              setModal({ open: true, initialValues: GetUserInitialValues(row) })
          },
          {
            type: 'divider'
          },
          {
            type: 'link',
            label: t('DELETE'),
            onClick: () => handleDelete(row._id)
          }
        ];

        if (row.status === INVITED) {
          options.splice(
            2,
            0,
            {
              type: 'link',
              label: t('RESEND_INVITE'),
              onClick: () => resendInvite(row._id)
            },
            {
              type: 'divider'
            }
          );
        }

        return (
          <DropdownMenu
            render={<Icon icon="outlined_dotsHorizontal" color={LabelColor} />}
            menuPosition="bottom-end"
            actions={options}
          />
        );
      }
    }
  ];

  if(user?.type !== RESPONSIBLE) {
    columns.splice(1, 0, {
      title: t('FEDERATION'),
      dataIndex: 'federation',
      sortable: true,
      render: (value: Federation) => (
        <Typography variant="table-header">{value?.name}</Typography>
      )
    });
  }

  if(!ready) return null;

  return (
    <Block maxW={62.5} margin="auto" mt={3}>
      <Box fAlign="center" fJustify="space-between" mb={1.688}>
        <Box fAlign="center">
          <Typography
            variant="page-title"
            style={{
              fontWeight: 500,
              color: LabelColor,
              marginRight: '0.75rem'
            }}
          >
            {t('USERS')}
          </Typography>
          <Typography
            variant="body-small"
            style={{ color: SubMenuColor }}
          >{`${total} ${t('PEOPLE')}`}</Typography>
        </Box>
      </Box>
      <Box fAlign="center" fJustify="space-between" fWrap="wrap">
        <Box w={{ sm: '70%', xxs: '100%' }}>
          <PageFilters
            initialFilters={pagination.filters}
            onChange={handleNewFilters}
          >
            {({ filters, handleChange }) => (
              <Filters
                filters={filters}
                handleChange={handleChange}
                federations={federations}
                availableAdminList={availableAdminList}
              />
            )}
          </PageFilters>
        </Box>
        <Block
          w={{ sm: '30%', xxs: '100%' }}
          display="flex"
          fJustify={{ sm: 'flex-end', xxs: 'flex-start' }}
          mt={{ sm: '0', xxs: '0.5rem' }}
        >
          <Button
            text={t('ADD_USER')}
            icon="outlined_plus"
            variant={FocusColor}
            onClick={() => setModal({ open: true })}
          />
        </Block>
      </Box>
      <AdminsTable
        columns={columns}
        rows={rows}
        loading={loading}
        defaultSort={pagination.sort}
        sortCallback={handleNewSort}
        tableStyles={{
          compact: true
        }}
        pagination={{
          total,
          page: pagination.page,
          setPage: handleNewPage,
          limit: pagination.limit
        }}
      />
      <ManageAdminModal
        opened={modal.open}
        initialValues={modal.initialValues}
        onClose={handleCloseModal}
        availableAdminList={availableAdminList}
        federations={federations}
      />
    </Block>
  );
};

export default UsersPage;
