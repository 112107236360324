/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fight } from '../../../../../models/Fight';
import { Event } from '../../../../../models/Event';
import { useTranslation } from 'react-i18next';
import { FC, useState, useEffect } from 'react';
import { Box, Block } from '../../../../../styles/BasicStyles';
import { LabelColor } from '../../../../../styles/Colors';
import { capitalizeString } from '../../../../../utils/strings';
import { GetFightDecision } from '../../../../../utils/fights';
import { User, ATHLETE, COACH } from '../../../../../models/User';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import DefaultListPage from '../../../../../components/page/DefaultListPage';
import Icon from '../../../../../components/icon';
import Typography from '../../../../../components/typography';
import DropdownMenu from '../../../../../components/dropdownMenu';
import BaseTable from '../../../../../components/table';
import Filters from './Filters';
import dayjs from 'dayjs';
import UserCard from '../../../../../components/userCard';
import Consistency from '../../../../../components/consistency/index';

const FightsList = DefaultListPage<Fight>();
const FightsTable = BaseTable<Fight>();

interface Params {
  coach?: User;
  type: string;
}

const FightsListComponent: FC<Params> = ({ coach, type }) => {
  const [athletes, setAthletes] = useState<User[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const { t, i18n: { language } } = useTranslation();
  const { get } = useFetch(`/fights/${COACH}/${coach?._id}/${type}`);
  const athletesHooks = useFetch(`/members/${ATHLETE}/dropdown`);
  const eventsHooks = useFetch(`/events/dropdown`);
  const navigate = useNavigate();
  const title = type === 'upcoming' ? 'UPCOMING_FIGHTS' : type === 'past' ? 'PAST_FIGHTS' : '';

  useEffect(() => {
    const init = async () => {
      const athletesResult = await athletesHooks.get();
      setAthletes(athletesResult?.data || []);

      const eventsResult = await eventsHooks.get();
      setEvents(eventsResult?.data || []);
    };

    init();
  }, []);

  return (
    <Block w='100%' mt={1.5}>
      <FightsList
        translate={t}
        request={{
          get,
          reloadOnChange: [type]
        }}
        filters={({ filters, handleChange }) => 
          <Filters 
            filters={filters} 
            handleChange={handleChange}
            athletes={athletes}
            events={events}
          />
        }
        tableTitle={`${t(title).toUpperCase()}`}
        countLabel={`${capitalizeString(t('FIGHTS'))}`}
      >
        {({ rows, loading, pagination, handleNewSort }) => {
          const columns = [ 
            { 
              title: t('EVENT'), 
              dataIndex: 'event',
              sortable: true,
              width: '15%',
              render: (event: Event) => <Typography variant='table-header'>{event?.name}</Typography>
            },
            { 
              title: t('DATE'), 
              dataIndex: 'start_datetime',
              sortable: true,
              width: '15%',
              render: (value: Date) => <Typography variant='table-header'>{dayjs(value).format('DD MMM YYYY, HH:mm')}</Typography>
            },
            { 
              title: t('MY_FIGHTER'),
              dataIndex: 'red_fighter', 
              render: (_: string, fight: Fight) => (
                <Box fAlign='center'>
                  {
                    fight.red_fighter?.coach === coach?._id ?
                    <UserCard user={fight.red_fighter} fight={fight} />
                    :
                    fight.blue_fighter?.coach === coach?._id ?
                    <UserCard user={fight.blue_fighter} fight={fight} />
                    :
                    null
                  }
                </Box>
              )
            },
            { 
              title: t('OPPONENT'),
              dataIndex: 'blue_fighter', 
              render: (_: string, fight: Fight) => (
                <Box fAlign='center'>
                  {
                    fight.red_fighter?.coach !== coach?._id ?
                    <UserCard user={fight.red_fighter} fight={fight} />
                    :
                    fight.blue_fighter?.coach !== coach?._id ?
                    <UserCard user={fight.blue_fighter} fight={fight} />
                    :
                    null
                  }
                </Box>
              )
            },
            { 
              title: t('MORE'),
              dataIndex: 'actions', 
              disableRowClick: true,
              render: (_: any, fight: Fight) => {
                return (
                  <DropdownMenu 
                    render={<Icon icon='outlined_dotsHorizontal' color={LabelColor} />}
                    menuPosition='bottom-end'
                    actions={[
                      {
                        type: 'link',
                        label: t('VIEW_EVENT'),
                        onClick: () => navigate(`/events/${fight.event?._id}`)
                      },
                      {
                        type: 'divider'
                      },
                      {
                        type: 'link',
                        label: t('VIEW_FIGHT'),
                        onClick: () => navigate(`/fights/${fight._id}`)
                      }
                    ]} 
                  />
                );
              }
            }
          ];

          // If we're viewing past Fights, we need to add the Fight result and Consistency columns
          if(type === 'past') {
            columns.splice(4, 0, {
              title: t('FIGHT_ENDING'),
              dataIndex: 'result',
              render: (_: string, fight: Fight) => <Typography variant='table-header'>{GetFightDecision(t, fight.result?.decision, fight?.result?.submission?.name[language])}</Typography>
            });
            columns.splice(5, 0, {
              title: t('CONSISTENCY'),
              dataIndex: 'consistency',
              render: (_: string, fight: Fight) => <Consistency fight={fight} />
            });
          }

          return (
            <FightsTable
              columns={columns}
              rows={rows}
              loading={loading}
              defaultSort={pagination.sort}
              sortCallback={handleNewSort}
              tableStyles={{
                compact: true,
                alternateRowColor: true,
                noBorderRadius: true,
                noSideBorders: true
              }}
              onRowClick={(row) => navigate(`/fights/${row._id}`)}
            />
          );
        }}
      </FightsList>
    </Block>
  );
};

export default FightsListComponent;
