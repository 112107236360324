import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react';
import { Block, Box } from '../../styles/BasicStyles';
import { BorderColor, FocusColor, LabelColor, MenuBgColor } from '../../styles/Colors';
import { useNavigate } from 'react-router-dom';
import { AdminTypes } from '../../models/User';
import { UserContext } from '../../Context';
import { Federation } from '../../models/Federation';
import { FormatNotificationDate, NotificationButton } from '../../utils/notifications';
import { Notification } from '../../models/Notification';
import EmptyState from '../../components/empty/EmptyState';
import LoadingOverlay from '../../components/loadingOverlay';
import DefaultListPage from '../../components/page/DefaultListPage';
import useFetch from 'use-http';
import Filters from './Filters';
import Typography from '../../components/typography';
import Button from '../../components/button';

const NotificationsListPage = DefaultListPage<Notification>();

const NotificationsPage = () => {
  const [federations, setFederations] = useState<Federation[]>([]);
  const { t, i18n: { language } } = useTranslation();
  const { get } = useFetch('/notifications');
  const federationsHook = useFetch('/federations/dropdown');
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  
  useEffect(() => {
    const init = async () => {
      const federationsResult = await federationsHook.get();
      setFederations(federationsResult?.data || []);
    };

    if(user?.type && AdminTypes.includes(user.type)) init();
  }, []);

  return (
    <NotificationsListPage
      translate={t}
      request={{
        get,
        searchFields: ['text|intl']
      }}
      storage="NOTIFICATIONS_FILTERS"
      header={{
        title: t('NOTIFICATIONS')
      }}
      filters={({ filters, handleChange }) => 
        <Filters 
          filters={filters} 
          handleChange={handleChange}
          federations={federations}
        />
      }
      countLabel={`${t('NOTIFICATIONS').toLowerCase()}`}
      maxW={43.75}
    >
      {({ rows, loading }) => {
        return (
          <Block margin="1.25 auto" maxW={43.75}>
            <LoadingOverlay visible={loading} />
            {
              rows.length > 0 ?
              rows.map((notification: Notification, index: number) => {
                const { textLink, link } = NotificationButton(notification);

                return (
                  <div key={index}>
                    <Box 
                      fAlign='center'
                      fWrap='wrap'
                      bgColor={MenuBgColor} 
                      padding='0.938 1.25'
                      style={{ borderBottom: '0.063rem solid rgba(105, 115, 134, 0.9)' }}
                    >
                      <Box 
                        fDirection='column' 
                        w={{ xs: '70%', xxs: '100%' }}
                        pr={{ xs: 0.5, xxs: 0 }}
                      >
                        <Typography variant='body-small' style={{ color: LabelColor }} dangerouslySetInnerHTML={{__html: notification.text && notification.text[language]}} />
                        <Typography variant='body-small' fWeight={300} mt={0.5} style={{ color: 'rgba(105, 115, 134, 0.9)' }}>{FormatNotificationDate(notification.createdAt, t, language)}</Typography>
                      </Box>
                      <Box 
                        fJustify={{ xs: 'flex-end', xxs: 'flex-start' }} 
                        w={{ xs: '30%', xxs: '100%' }} 
                        mt={{ xs: 0, xxs: 0.75 }}
                        pl={{ xs: 0.5, xxs: 0 }}
                      >
                        {
                          !!link && !!textLink &&
                          <Button 
                            text={t(textLink)}
                            variant={FocusColor}
                            size='sm'
                            onClick={() => navigate(link)}
                          />
                        }
                      </Box>
                    </Box>
                  </div>
                );
              })
              :
              <Block bRadius={0.5} style={{ border: `0.063rem solid ${BorderColor}` }}>
                <EmptyState />
              </Block>
            }
          </Block>
        );
      }}
    </NotificationsListPage>
  );
};

export default NotificationsPage;
