import { Grid } from '@mantine/core';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ModalContent } from '../../../../../components/modal';
import { LabelColor, SubMenuColor, BgColor, FocusColor } from '../../../../../styles/Colors';
import { FieldArray } from 'react-final-form-arrays';
import { User } from '../../../../../models/User';
import { Block } from '../../../../../styles/BasicStyles';
import { IconWrapper, AddMoreWrapper, FighterWrapper, FighterColumn } from './styles';
import { requiredField, requiredArray } from '../../../../../hooks/use-validation/use-validation-schema';
import SelectInput from '../../../../../components/inputs/SelectInput';
import Typography from '../../../../../components/typography';
import Icon from '../../../../../components/icon';
import UserItem from '../../../../../components/userDropdownItem';

interface Params {
  athletes: User[];
  addFighter: () => void;
  removeFighter: (value: number) => void;
  checkAthlete: (value: string | null, oldValue: string | null, index?: number) => void;
}

const Step2: FC<Params> = ({ athletes, addFighter, removeFighter, checkAthlete }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <Typography as='div' variant='body' lHeight={1.375} fWeight={500} mb={0.5} style={{ color: LabelColor }}>{t('FIGHTERS')}</Typography> 
      <Grid gutter="xl">
        <FieldArray name="fighters" validate={requiredArray}>
          {
            ({ fields }) => 
            fields.map((name, index) => (
              <Grid.Col xs={12} sm={6} key={index}>
                <FighterWrapper>
                  <FighterColumn>
                    <Field name={`${name}`} validate={requiredField}>
                      {(props) => 
                        <SelectInput
                          {...props}
                          label={`${t('FIGHTER')} ${index + 1} *`}
                          placeholder={t('CHOOSE_FIGHTER')}
                          clearable
                          data={athletes}
                          afterChange={(value: string | null, oldValue: string | null) => checkAthlete(value, oldValue, index)}
                          itemComponent={UserItem}
                        />
                      }
                    </Field>
                  </FighterColumn>
                  <FighterColumn>
                    <Icon icon='outlined_x' hoverColor={FocusColor} onClick={() => removeFighter(index)} />
                  </FighterColumn>
                </FighterWrapper>
              </Grid.Col>
            ))
          }
        </FieldArray>
      </Grid>
      <Block display='flex' fJustify='center'mt={2.5}>
        <AddMoreWrapper display='flex' fJustify='center' fAlign='center' onClick={addFighter}>
          <IconWrapper>
            <Icon icon='outlined_plus' color={BgColor} size={0.75} />
          </IconWrapper>
          <Typography variant='body-small' pl={0.25} style={{ color: SubMenuColor }}>{t('ADD_1_FIGHTER')}</Typography>
        </AddMoreWrapper>
      </Block>
    </ModalContent>
  );
};

export default Step2;
