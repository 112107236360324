/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fight } from '../../../../models/Fight';
import { Tournament } from '../../../../models/Tournament';
import { useTranslation } from 'react-i18next';
import { FC, useState, useEffect, useContext } from 'react';
import { Box, Block } from '../../../../styles/BasicStyles';
import { LabelColor, FocusColor, PlaceholderColor } from '../../../../styles/Colors';
import { showDelete } from '../../../../hooks/show-notification/show-notification';
import { capitalizeString } from '../../../../utils/strings';
import { GetFightDate, GetFightDecision, GetNewFightNumber } from '../../../../utils/fights';
import { User, ATHLETE, SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE } from '../../../../models/User';
import { useNavigate } from 'react-router-dom';
import { AnyObject } from '../../../../models/Generic';
import { GetFightInitialValues, GetTournamentInitialValues } from '../../../../utils/modalValues';
import { canEditEvent } from '../../../../utils/events';
import { Event } from '../../../../models/Event';
import { UserContext } from '../../../../Context';
import { GetRoundNameTournament, GetWinnerOfFromTournament } from '../../../../utils/tournament/single';
import DropdownMenu, { ActionProps } from '../../../../components/dropdownMenu';
import useFetch from 'use-http';
import DefaultListPage from '../../../../components/page/DefaultListPage';
import Icon from '../../../../components/icon';
import Typography from '../../../../components/typography';
import BaseTable from '../../../../components/table';
import FightsFilters from './FightsFilters';
import ManageSingleFightModal from '../fightsModal/single';
import ManageTournamentModal from '../fightsModal/tournament';
import Label from '../../../../components/label';
import UserCard from '../../../../components/userCard';
import dayjs from 'dayjs';
import Consistency from '../../../../components/consistency';
import FightStatus from '../../../../components/fightStatus';

const FightsList = DefaultListPage<Fight>();
const FightsTable = BaseTable<Fight>();

interface Params {
  event?: Event;
  getInfo: () => void;
}

const FightsListComponent: FC<Params> = ({ getInfo, event }) => {
  const [singleFightModal, setSingleFightModal] = useState<{ open: boolean; initialValues?: Fight; index?: number; }>({ open: false });
  const [tournamentModal, setTournamentModal] = useState<{ open: boolean; initialValues?: Tournament | AnyObject; }>({ open: false });
  const [athletes, setAthletes] = useState<User[]>([]);
  const { t, i18n: { language } } = useTranslation();
  const { del, post } = useFetch('/fights');
  const { get } = useFetch(`/fights/event/${event?._id}`);
  const tournamentsHook = useFetch('/tournaments');
  const athletesHooks = useFetch(`/members/${ATHLETE}/${event?.federation?._id}/dropdown`);
  const navigate = useNavigate();
  const userCanEditEvent = canEditEvent(event);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const init = async () => {
      const { data } = await athletesHooks.get();

      setAthletes(data || []);
    };

    init();
  }, [event?._id]);

  return (
    <FightsList
      translate={t}
      request={{
        get,
        fetchAll: true,
        reloadOnChange: [event?._id]
      }}
      filters={({ filters, handleChange }) => (
        <FightsFilters
          filters={filters}
          handleChange={handleChange}
          setSingleFightModal={setSingleFightModal}
          setTournamentModal={setTournamentModal}
          athletes={athletes}
          userCanEditEvent={userCanEditEvent}
        />
      )}
      tableTitle={`${t('FIGHT_CARD').toUpperCase()}`}
      countLabel={`${capitalizeString(t('FIGHTS'))}`}
    >
      {({ rows, loading, pagination, handleNewSort, refreshPage }) => {
        const handleCloseFightModal = (changed: boolean) => {
          if (changed) {
            refreshPage();
            getInfo();
          }
          setSingleFightModal({ open: false });
        };

        const handleCloseTournamentModal = (changed: boolean) => {
          if (changed) {
            refreshPage();
            getInfo();
          }
          setTournamentModal({ open: false });
        };

        const handleDelete = async (id: string) => {
          showDelete({
            title: t('DELETE_FIGHT'),
            message: t('DELETE_FIGHT_MESSAGE'),
            onConfirm: async () => {
              await del(`/${id}`);
              refreshPage();
              getInfo();
            }
          });
        };

        const handleDeleteTournament = async (id: string | undefined) => {
          if (!!id) {
            showDelete({
              title: t('DELETE_TOURNAMENT'),
              message: t('DELETE_TOURNAMENT_MESSAGE'),
              onConfirm: async () => {
                await tournamentsHook.del(`/${id}`);
                refreshPage();
                getInfo();
              }
            });
          }
        };

        const moveFight = async (list: AnyObject[]) => {
          await post('/reorder', { event: event?._id, list });
          refreshPage();
        };

        const columns = [
          {
            title: `${capitalizeString(t('FIGHT'))} #`,
            dataIndex: 'start_datetime',
            render: (_: string, fight: Fight) => (
              <Box fDirection="column">
                <Typography
                  variant="body-small"
                  color={FocusColor}
                >{`#${fight.fight_number}`}</Typography>
                <Typography
                  variant="sidebar-group"
                  style={{ color: PlaceholderColor }}
                >
                  {GetFightDate(fight.start_datetime)}
                </Typography>
              </Box>
            )
          },
          {
            title: t('TYPE'),
            dataIndex: 'tournament',
            align: 'center',
            render: (value: string, fight: Fight) => (
              <Box fJustify='center'>
                <Label
                  bRadius='0.5rem'
                  fontColor={LabelColor}
                  text={
                    !!value ? (
                      <Block tAlign="center">
                        <div>{t('TOURNAMENT')}</div>
                        <div>{fight.tournament?.name}</div>
                        <div>{GetRoundNameTournament(t, fight, fight.tournament)}</div>
                      </Block>
                    ) : (
                      t('SINGLE_FIGHT')
                    )
                  }
                />
              </Box>
            )
          },
          {
            title: t('DURATION'),
            key: 'duration',
            align: 'center',
            render: (fight: Fight) => (
              <Box fJustify='center'>
                <Typography tAlign='center' variant="body-small" style={{ color: LabelColor }}>
                  {`${fight.rounds}x${fight.duration}`}
                </Typography>
              </Box>
            )
          },
          {
            title: t('WEIGHT_CLASS'),
            dataIndex: 'weight_class',
            align: 'center',
            render: (value: AnyObject) => (
              <Box fJustify='center'>
                <Typography tAlign='center' variant="body-small" style={{ color: LabelColor }}>
                  {value?.name && value?.name[language]}
                </Typography>
              </Box>
            )
          },
          {
            title: t('RED_FIGHTER'),
            dataIndex: 'red_fighter',
            render: (_: string, fight: Fight) => (
              <UserCard 
                user={fight.red_fighter} 
                fight={fight} 
                showFederationAlert 
                noUserText={GetWinnerOfFromTournament(t, fight._id, fight.tournament, 'red')} 
              />
            )
          },
          {
            title: t('BLUE_FIGHTER'),
            dataIndex: 'blue_fighter',
            render: (_: string, fight: Fight) => (
              <UserCard 
                user={fight.blue_fighter} 
                fight={fight} 
                showFederationAlert 
                noUserText={GetWinnerOfFromTournament(t, fight._id, fight.tournament, 'blue')} 
              />
            )
          },
          {
            title: t('FIGHT_ENDING'),
            dataIndex: 'result',
            align: 'center',
            render: (_: string, fight: Fight) => (
              <Box fJustify='center'>
                <Typography tAlign='center' variant="table-header">
                  {GetFightDecision(t, fight.result?.decision, fight?.result?.submission?.name[language])}
                </Typography>
              </Box>
            )
          },
          {
            title: t('MORE'),
            dataIndex: 'actions',
            disableRowClick: true,
            align: 'center',
            render: (_: any, fight: Fight, index: number) => {
              let options: ActionProps[] = [];

              if (!userCanEditEvent) {
                options = [
                  {
                    type: 'link',
                    label: t('VIEW_FIGHT'),
                    onClick: () => navigate(`/fights/${fight._id}`)
                  }
                ];

                if (!!fight.tournament) {
                  options.unshift(
                    {
                      type: 'link',
                      label: t('VIEW_TOURNAMENT'),
                      onClick: () =>
                        navigate(`/tournaments/${fight.tournament?._id}`)
                    },
                    {
                      type: 'divider'
                    }
                  );
                }
              } else {
                options = [
                  {
                    type: 'link',
                    label: t('VIEW_FIGHT'),
                    onClick: () => navigate(`/fights/${fight._id}`)
                  },
                  {
                    type: 'divider'
                  },
                  {
                    type: 'link',
                    label: t('EDIT_FIGHT'),
                    onClick: () =>
                      setSingleFightModal({
                        open: true,
                        initialValues: GetFightInitialValues(fight),
                        index
                      })
                  }
                ];

                if (!!fight.tournament) {
                  options.unshift(
                    {
                      type: 'link',
                      label: t('VIEW_TOURNAMENT'),
                      onClick: () =>
                        navigate(`/tournaments/${fight.tournament?._id}`)
                    },
                    {
                      type: 'divider'
                    },
                    {
                      type: 'link',
                      label: t('EDIT_TOURNAMENT'),
                      onClick: () =>
                        setTournamentModal({
                          open: true,
                          initialValues: GetTournamentInitialValues(
                            fight.tournament
                          )
                        })
                    },
                    {
                      type: 'divider'
                    },
                    {
                      type: 'link',
                      label: t('DELETE_TOURNAMENT'),
                      onClick: () =>
                        handleDeleteTournament(fight.tournament?._id)
                    },
                    {
                      type: 'divider'
                    }
                  );
                }
                // Only allow delete for single fights, fights from tournaments can't be deleted, only the tournament can
                else {
                  options.push(
                    {
                      type: 'divider'
                    },
                    {
                      type: 'link',
                      label: t('DELETE_FIGHT'),
                      onClick: () => handleDelete(fight._id)
                    }
                  );
                }
              }

              return (
                <Box fJustify='center'>
                  <DropdownMenu
                    render={
                      <Icon icon="outlined_dotsHorizontal" color={LabelColor} />
                    }
                    menuPosition="bottom-end"
                    actions={options}
                  />
                </Box>
              );
            }
          }
        ];

        // If the User is an Admin, show a column with the Fight Status
        if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR || user?.type === RESPONSIBLE) {
          columns.splice(7, 0, {
            title: t('CONSISTENCY'),
            dataIndex: 'consistency',
            align: 'center',
            render: (_: string, fight: Fight) => <Box fJustify='center'><Consistency fight={fight} /></Box>
          });

          columns.splice(8, 0, {
            title: t('STATUS'),
            dataIndex: 'status',
            align: 'center',
            render: (_: any, fight: Fight) => <Box fJustify='center'><FightStatus fight={fight} /></Box>
          });
        }

        return (
          <>
            <FightsTable
              columns={columns}
              rows={rows}
              loading={loading}
              defaultSort={pagination.sort}
              sortCallback={handleNewSort}
              tableStyles={{
                compact: true,
                alternateRowColor: true,
                noBorderRadius: true,
                noSideBorders: true
              }}
              onRowClick={(row) => navigate(`/fights/${row._id}`)}
              moveItem={moveFight}
              disableOrder={Object.keys(pagination.filters || {}).length > 0 ? true : false}
              fixColumns={[0]}
            />
            <ManageSingleFightModal
              opened={singleFightModal.open}
              initialValues={singleFightModal.initialValues}
              onClose={handleCloseFightModal}
              eventOfficials={event?.officials}
              eventID={event?._id}
              eventStart={dayjs(event?.start_datetime).startOf('day')}
              eventEnd={dayjs(event?.end_datetime).endOf('day')}
              fightNumber={GetNewFightNumber(rows)}
              eventFederation={event?.federation?._id}
              refreshPage={refreshPage}
            />
            <ManageTournamentModal
              opened={tournamentModal.open}
              initialValues={tournamentModal.initialValues}
              onClose={handleCloseTournamentModal}
              eventID={event?._id}
              eventFederation={event?.federation?._id}
              eventStart={dayjs(event?.start_datetime).startOf('day')}
              eventEnd={dayjs(event?.end_datetime).endOf('day')}
            />
          </>
        );
      }}
    </FightsList>
  );
};

export default FightsListComponent;
