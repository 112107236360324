import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { AnyObject } from '../../models/Generic';
import Platforms from '../../utils/platforms/Platforms';
import SelectInput from '../../components/inputs/SelectInput';
import TextInput from '../../components/inputs/TextInput';
import Icon from '../../components/icon';

interface FilterChildrenProps {
  filters: AnyObject;
  handleChange: (
    field: string | string[],
    value: string | string[] | null,
    time?: number
  ) => void;
  clearFilters?: () => void;
}

const TranslationFilters: React.FC<FilterChildrenProps> = ({
  filters,
  handleChange
}) => {
  const { t } = useTranslation();

  return (
    <Grid gutter="xl" style={{ justifyContent: 'flex-end' }}>
      <Grid.Col sm={4} xs={12}>
        <SelectInput
          data={Platforms}
          placeholder={t('SELECT_PLATFORM')}
          input={{
            value: filters.platform,
            onChange: (v: string | null) => handleChange('platform', v)
          }}
          clearable
        />
      </Grid.Col>
      <Grid.Col sm={4} xs={12}>
        <TextInput
          input={{
            value: filters.search,
            onChange: (v: string | null) => handleChange('search', v, 1000)
          }}
          placeholder={t('SEARCH_BY_TAG_TRANSLATION')}
          onSearch={(v: string | null) => handleChange('search', v)}
          icon={<Icon icon="outlined_search" />}
          clearable
        />
      </Grid.Col>
    </Grid>
  );
};

export default TranslationFilters;
