import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { Federation } from '../../../models/Federation';
import { BasicForm } from '../../../styles/BasicStyles';
import { InfoContext } from '../../../Context';
import { FC, useContext } from 'react';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../components/modal';
import useFetch from 'use-http';
import TextInput from '../../../components/inputs/TextInput';
import Button from '../../../components/button';
import PhoneInput from '../../../components/inputs/PhoneInput';
import SelectInput from '../../../components/inputs/SelectInput';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: Federation;
}

const { Form } = withTypes<Federation>();

const ManageFederationModal: FC<Params> = ({
  opened,
  onClose,
  initialValues
}) => {
  const { t } = useTranslation();
  const { post, put } = useFetch('/federations');
  const { info } = useContext(InfoContext);

  const onSubmit = async (values: Federation) => {
    let error = false;
    
    if (values._id) {
      const { success } = await put(`/${values._id}`, values);
      if (success) onClose(true);
      else error = true;
    } else {
      const { success } = await post(values);
      if (success) onClose(true);
      else error = true;
    }

    return !error;
  };

  return (
    <Modal
      size={36.25}
      opened={opened}
      onClose={() => onClose(false)}
      title={
        initialValues && initialValues._id
          ? t('EDIT_FEDERATION')
          : t('CREATE_FEDERATION')
      }
    >
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema(
          yup.object({
            name: yup.string().required(),
            acronym: yup
              .string()
              .required()
              .length(2)
              .matches(/^[A-Z\s]+$/, () => ({
                tag: 'FIELD_ACRONYM_ERROR'
              }))
          })
        )}
        initialValues={initialValues || {}}
      >
        {({ handleSubmit, submitting, form: { reset } }) => (
          <BasicForm onSubmit={async event => {
            const result = await handleSubmit(event);
            if(result) reset();
          }}>
            <ModalOverflowContent>
              <ModalContent>
                <Grid gutter="xl">
                  <Grid.Col xs={12} sm={6}>
                    <Field name="name">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={`${t('NAME')} *`}
                          placeholder={t('ENTER_NAME')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="acronym">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={`${t('ACRONYM')} *`}
                          placeholder={t('ENTER_ACRONYM')}
                          disabled={!!initialValues?._id}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                </Grid>
                <Grid gutter="xl">
                  <Grid.Col xs={12} sm={6}>
                    <Field name="phone">
                      {(props) => (
                        <PhoneInput
                          {...props}
                          label={t('PHONE_NUMBER')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="email">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('EMAIL')}
                          htmlType="email"
                          placeholder={t('ENTER_EMAIL')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                </Grid>
                <Grid gutter="xl">
                  <Grid.Col xs={12} sm={6}>
                    <Field name="address">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('ADDRESS')}
                          placeholder={t('ENTER_ADDRESS')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="postal_code">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('POSTAL_CODE')}
                          placeholder={t('ENTER_POSTAL_CODE')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                </Grid>
                <Grid gutter="xl">
                  <Grid.Col xs={12} sm={6}>
                    <Field name="city">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('CITY')}
                          placeholder={t('ENTER_CITY')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="country">
                      {(props) => (
                        <SelectInput
                          {...props}
                          label={t('COUNTRY')}
                          placeholder={t('CHOOSE_COUNTRY')}
                          clearable
                          data={info?.countries || []}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                </Grid>
              </ModalContent>
            </ModalOverflowContent>
            <ModalActions>
              <Button
                text={t('CANCEL')}
                variant="secondary"
                onClick={() => onClose(false)}
              />
              <Button
                text={initialValues?._id ? t('SAVE') : t('CREATE')}
                type="submit"
                loading={submitting}
              />
            </ModalActions>
          </BasicForm>
        )}
      </Form>
    </Modal>
  );
};

export default ManageFederationModal;
