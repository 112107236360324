import { RichTextEditor, RichTextEditorProps } from '@mantine/rte';
import { FieldRenderProps } from 'react-final-form';
import { GenericInput } from './Generic';
import { ErrorMessage, InputLabel, InputWrapper, QuillWrapper } from './Styles';
import { useTranslation } from 'react-i18next';
import React from 'react';

export interface RichTextInputProps extends Omit<FieldRenderProps<string>, 'input'>, Omit<RichTextEditorProps, 'onChange' | 'value'> {
  input: GenericInput<string>;
  label?: React.ReactNode;
  description?: React.ReactNode;
  saveImage?: (file: File) => Promise<string>;
}

export const RichTextInput: React.FC<RichTextInputProps> = ({
  input,
  meta,
  label,
  placeholder,
  saveImage,
  ...rest
}) => {
  const { t } = useTranslation();
  const hasError = meta?.invalid && meta?.submitFailed;

  return (
    <InputWrapper>
      {label && <InputLabel size='md'>{label}</InputLabel>}
      <QuillWrapper hasError={hasError}>
        <RichTextEditor
          {...rest}
          controls={[
            ['bold', 'italic', 'underline', 'strike', 'clean'],
            ['h1', 'h2', 'h3', 'h4'],
            ['alignLeft', 'alignCenter', 'alignRight'],
            ['unorderedList', 'orderedList'],
            ['link', 'blockquote', 'codeBlock'],
            ['sup', 'sub'],
          ]}
          placeholder={placeholder}
          value={input.value || ''}
          onChange={input.onChange}
          onImageUpload={saveImage}
        />
      </QuillWrapper>
      {
        hasError &&
        <ErrorMessage>{t('FIELD_REQUIRED')}</ErrorMessage>
      }
    </InputWrapper>
  );
};

export default RichTextInput;
