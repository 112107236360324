import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../styles/BasicStyles';
import { FC } from 'react';
import { Event } from '../../models/Event';
import { showSuccess } from '../../hooks/show-notification/show-notification';
import { LabelColor } from '../../styles/Colors';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../components/modal';
import useFetch from 'use-http';
import Button from '../../components/button';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import Typography from '../../components/typography';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  event?: Event;
}

const { Form } = withTypes<{ reason: string }>();

const RejectEventModal: FC<Params> = ({
  opened,
  onClose,
  event
}) => {
  const { t } = useTranslation();
  const { put } = useFetch('/event-drafts');

  const onSubmit = async (values: { reason: string }) => {
    let error = false;
    
    const { success } = await put(`/${event?._id}/reject`, values);
    if(success) {
      onClose(true);

      showSuccess({
        title: t('EVENT_REJECTED'),
        message: t('EVENT_REJECTED_MESSAGE'),
        autoClose: 10000
      });
    }
    else error = true;

    return !error;
  };

  return (
    <Modal
      size={31.25}
      opened={opened}
      onClose={() => onClose(false) }
      title={t('REJECT_EVENT')}
    >
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema(
          yup.object({
            reason: yup.string().required()
          })
        )}
        render={({ handleSubmit, submitting, form: { reset } }) => {
          return (
            <BasicForm onSubmit={async event => {
              const result = await handleSubmit(event);
              if(result) reset();
            }}>
              <ModalOverflowContent>
                <ModalContent>
                  <Grid gutter="xl" style={{ marginBottom: '0.5rem' }}>
                    <Grid.Col xs={12}>
                      <Typography variant='body' style={{ color: LabelColor }}>{t('EVENT')}: <span style={{ fontWeight: 500 }}>{event?.name}</span></Typography>
                    </Grid.Col>
                  </Grid>
                  <Grid gutter="xl">
                    <Grid.Col xs={12}>
                      <Field name="reason">
                        {(props) => 
                          <TextAreaInput 
                            {...props} 
                            label={`${t('REASON')} *`}
                          />
                        }
                      </Field>
                    </Grid.Col>
                  </Grid>
                </ModalContent>
              </ModalOverflowContent>
              <ModalActions>
                <Button text={t('CANCEL')} variant='secondary' onClick={() => onClose(false)} />
                <Button text={t('SAVE')} type='submit' loading={submitting} />
              </ModalActions>
            </BasicForm>
          );
        }}
      />
    </Modal>
  );
};

export default RejectEventModal;
