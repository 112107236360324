import * as yup from "yup";
import { Field, withTypes } from "react-final-form";
import { RecoverValues } from "../../../models/Info";
import { useValidationSchema } from "../../../hooks/use-validation/use-validation-schema";
import { BasicForm, Box, Block } from "../../../styles/BasicStyles";
import { useTranslation } from 'react-i18next';
import { showSuccess } from '../../../hooks/show-notification/show-notification';
import { FocusColor, LabelColor, LoginButtonColor } from '../../../styles/Colors';
import AuthLayout from "../AuthLayout";
import useFetch from "use-http";
import Button from '../../../components/button';
import TextInput from '../../../components/inputs/TextInput';
import Link from '../../../components/link';
import Typography from '../../../components/typography';

const { Form } = withTypes<RecoverValues>();

export const RecoverPage = () => {
  const { post } = useFetch("/auth");
  const { t } = useTranslation();

  const onSubmit = async (values: RecoverValues) => {
    const { success } = await post("/recover-password", values);
    
    if(success) {
      showSuccess({
        title: t('SUCCESS'),
        message: t('PASSWORD_EMAIL_SENT')
      });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          email: yup.string().email().required()
        })
      )}
    >
      {({ handleSubmit, submitting }) => (
        <AuthLayout>
          <BasicForm onSubmit={handleSubmit}>
            <Block mb={2.25} tAlign='center'>
              <Typography variant='auth-title' style={{ color: LabelColor }}>{t('RECOVER_PASSWORD')}</Typography>
            </Block>
            <Box fAlign='center' fJustify='space-between' mb={0.5} fWrap='wrap'>
              <Block w={{ xs: '50%', xxs: '100%' }}>
                <Typography variant='body-small' style={{ color: LabelColor }}>{t('EMAIL')}</Typography>
              </Block>
              <Block w={{ xs: '50%', xxs: '100%' }} tAlign='right'>
                <Link href="/login"><Typography color={FocusColor}>{t('RETURN_LOGIN')}</Typography></Link>
              </Block>
            </Box>
            <Box fDirection='column'>
              <Block w='100%' mb={2.5}>
                <Field name="email">
                  {(props) => (
                    <TextInput
                      {...props}
                      placeholder={t('ENTER_EMAIL')}
                      type="email"
                      size='lg'
                    />
                  )}
                </Field>
              </Block>
              <Button
                text={t('RECOVER')}
                type='submit'
                loading={submitting}
                w='100%'
                variant={FocusColor}
                textColor={LoginButtonColor}
              />
            </Box>
          </BasicForm>
        </AuthLayout>
      )}
    </Form>
  );
};

export default RecoverPage;
