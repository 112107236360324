/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode } from 'react';
import { ButtonProps } from '../button';
import { Box, Block } from '../../styles/BasicStyles';
import { LabelColor, BorderColor, FocusColor } from '../../styles/Colors';
import { useNavigate } from 'react-router';
import Typography from '../typography';
import Button from '../button';
import Icon from '../icon';

export interface PageHeaderProps {
  title?: string | ReactNode;
  backLink?: any;
  actions?: ButtonProps[];
}

export const PageHeader: FC<PageHeaderProps> = ({ title, backLink, actions }) => {
  const navigate = useNavigate();

  return (
    <Box fWrap='wrap' fAlign='center' fJustify='space-between' style={{ borderBottom: `0.063rem solid ${BorderColor}` }} pb={1} mb={1.875}>
      <Block display='flex' fDirection='row' fAlign='center' pb={0.5} pt={0.5} pr={0.5}>
        {
          !!backLink && 
          <Icon 
            icon='outlined_arrowLeft' 
            color='#ffffff' 
            hoverColor={FocusColor} 
            style={{ marginRight: '1.25rem' }} 
            onClick={() => navigate(backLink)}
          /> 
        }
        {
          !!title &&
          <>
            {
              typeof title === 'string' ?
              <Typography variant='page-title' style={{ color: LabelColor }}>{title}</Typography>
              :
              <>{title}</>
            }
          </>
        }
      </Block>
      {
        actions && actions.length > 0 &&
        <Box fAlign='center' fJustify='flex-end' w='initial'>
          { 
            actions.map((action: ButtonProps, index: number) =>
              <Button {...action} key={index} mr={index === (actions.length - 1) ? 0 : 0.5} />
            )
          }
        </Box>
      }
    </Box>
  );
};

export default PageHeader;
