import { Input, Checkbox, CheckboxProps } from '@mantine/core';
import { FieldRenderProps } from 'react-final-form';
import { GenericInput, InputSize } from './Generic';
import { InputWrapper, ErrorMessage } from './Styles';
import { useTranslation } from 'react-i18next';
import React from 'react';

export interface CheckboxInputProps extends Omit<FieldRenderProps<boolean>, 'input'>, CheckboxProps {
  afterChange?: (value: boolean, field: string) => void;
  input: GenericInput<boolean>;
  label?: React.ReactNode;
  description?: React.ReactNode;
  size?: InputSize;
}

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  input,
  meta = {},
  label,
  placeholder,
  afterChange,
  disabled,
  size = 'md',
  ...other
}) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.currentTarget.checked;
    input.onChange(value);
    if (afterChange && typeof afterChange === 'function') {
      afterChange(value, input.name || '');
    }
  };

  const hasError = meta?.invalid && meta?.submitFailed;

  return (
    <InputWrapper size={size} hasError={hasError}>
      <Input.Wrapper label={label}>
        <Checkbox
          label={placeholder}
          disabled={disabled}
          checked={input.value}
          size={size}
          onChange={handleChange}
          {...other}
        />
      </Input.Wrapper>
      {
        hasError &&
        <ErrorMessage>
          {t('FIELD_REQUIRED')}
        </ErrorMessage>
      }
    </InputWrapper>
  );
};

export default CheckboxInput;
