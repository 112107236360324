/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useContext } from 'react';
import { Block, Box } from '../../styles/BasicStyles';
import { useTranslation } from 'react-i18next';
import { BgColor, BorderColor, G100, MenuBgColor, PlaceholderColor } from '../../styles/Colors';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, Bar, BarChart } from 'recharts';
import { AnyObject } from '../../models/Generic';
import { DashboardInfo, GraphEvents, GraphReviews } from '../../models/Dashboard';
import { capitalizeString } from '../../utils/strings';
import { LabelColor } from '../../styles/Colors';
import { UserContext } from '../../Context';
import { PROMOTER } from '../../models/User';
import Icon from '../../components/icon';
import Typography from '../../components/typography';
import Divider from '../../components/divider';
import Link from '../../components/link';
import dayjs from 'dayjs';
require('dayjs/locale/pt');

interface Params {
  info: DashboardInfo;
}

interface GraphParams {
  title: string;
  link?: string;
  children: ReactNode;
}

interface TooltipParams {
  active?: boolean;
  payload?: AnyObject;
  type?: string;
}

const CustomTooltip: FC<TooltipParams> = ({ active, payload, type }) => {
  const { i18n: { language } } = useTranslation();

  if(active && payload && payload.length) {
    const title = type === 'month' ? capitalizeString(dayjs().month(payload[0].payload.month).locale(language).format('MMMM')) : payload[0].payload.tooltipTitle;

    return (
      <Block display='flex' fDirection='column' bgColor={BgColor} padding='0.5 1' style={{ border: `0.063rem solid ${BorderColor}` }}>
        <Typography fSize={0.875} fWeight={500} lHeight={1.25} pb={0.5}>{title}</Typography>
        <Typography fSize={0.75} fWeight={400} lHeight={1}>{payload[0].payload.tooltipValue}</Typography>
      </Block>
    );
  }

  return null;
};

const Graph: FC<GraphParams> = ({ title, link, children }) => {
  const { t } = useTranslation();

  return (
    <Block display='flex' padding={{ xs: '0 0.5', xxs: 0 }} w={{ xl: '33.333%', sm: '50%', xs: '100%', xxs: '100%' }} mb={1}>
      <Box fDirection='column' fJustify='space-between' bRadius={0.5} style={{ backgroundColor: MenuBgColor, border: `0.063rem solid ${BorderColor}` }} padding='1.25 0'>
        <Box fDirection='column'>
          <Box padding='0 1.25' mb={1.75}>
            <Typography variant='sidebar-group' style={{ color: PlaceholderColor }}>{title.toUpperCase()}</Typography>
          </Box>
          <Box pr={0.75}>
            {
              !!children ?
              children
              :
              <Box padding='0 1.25'>
                <Typography variant='body-small' tAlign='center' w='100%' style={{ color: LabelColor }}>{t('NO_DATA')}</Typography>
              </Box>
            }
          </Box>
        </Box>
        {
          !!link &&
          <Box fDirection='column'>
            <Block mt={0.875} w='100%'>
              <Divider />
            </Block>
            <Box fJustify='flex-end' padding='1.25 1.25' pb={0}>
              <Link href={link}>
                <Block display='flex' fAlign='center'>
                  <Typography variant='body-small' color={G100} pr={0.5}>{t('WEBAPP_VIEW_ALL')}</Typography>
                  <Icon icon='outlined_chevronRight' color={G100} size={0.875} />
                </Block>
              </Link>
            </Box>
          </Box>
        }
      </Box>
    </Block>
  );
};

const Graphs: FC<Params> = ({ info }) => {
  const { t, i18n: { language } } = useTranslation();
  const { user } = useContext(UserContext);

  let graphEventsMax = 0;
  if(info?.graphs?.events && info?.graphs?.events?.length > 0) {
    info.graphs.events.forEach((elem: GraphEvents) => {
      if(elem.total > graphEventsMax) graphEventsMax = elem.total;
    });
  }

  let graphReviewsMax = 0;
  if(info?.graphs?.reviews && info?.graphs?.reviews?.length > 0) {
    info.graphs.reviews.forEach((elem: GraphReviews) => {
      if(elem.reviews > graphReviewsMax) graphReviewsMax = elem.reviews;
    });

    graphReviewsMax += Math.ceil(graphReviewsMax / 2);
  }

  return (
    <>
      <Typography as='div' variant='sidebar-group' padding={{ xs: '0 0.5', xxs: 0 }} mb={0.875} style={{ color: PlaceholderColor }}>{t('STATISTICS').toUpperCase()}</Typography>
      <Block display='flex' style={{ flexFlow: 'row wrap' }} w='100%' mb={2.75}>
        {
          user?.type !== PROMOTER &&
          <Graph title={t('SCORING_CONSISTENCY')}>
            {
              info?.graphs?.consistency && info?.graphs?.consistency?.length > 0 &&
              <div style={{ width: "100%", height: 200 }}>
                <ResponsiveContainer>
                  <AreaChart data={info?.graphs?.consistency}>
                    <ReferenceLine y={50} stroke={BorderColor} />
                    <ReferenceLine y={100} stroke={BorderColor} />
                    <YAxis dataKey="value" axisLine={false} domain={[0, 100]} tickCount={3} interval='preserveStartEnd' tickFormatter={(value: any) => `${value}%`} />
                    <Tooltip content={<CustomTooltip type='month' />} />
                    <Area type="monotone" dataKey="value" stroke="#3855B3" strokeWidth={2} fill="rgba(56, 84, 179, 0.1)" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            }
          </Graph>
        }
        <Graph title={`${t('EVENTS')} (${t('LAST_6_MONTHS')})`} link='/events/past'>
          {
            info?.graphs?.events && info?.graphs?.events?.length > 0 &&
            <div style={{ width: "100%", height: 200 }}>
              <ResponsiveContainer>
                <BarChart data={info?.graphs?.events || []}>
                  {
                    graphEventsMax > 0 &&
                    <>
                      <ReferenceLine y={graphEventsMax} stroke={BorderColor} />
                      <ReferenceLine y={Math.ceil(graphEventsMax / 2)} stroke={BorderColor} />
                    </>
                  }
                  <XAxis dataKey="month" axisLine={false} tickFormatter={(tick) => capitalizeString(dayjs().month(tick).locale(language).format('MMM'))} />
                  <YAxis dataKey="total" axisLine={false} ticks={[0, Math.ceil(graphEventsMax / 2), graphEventsMax]} tickCount={3} interval='preserveStartEnd' />
                  <Tooltip cursor={false} content={<CustomTooltip type='month' />} />
                  <Bar dataKey="total" fill="rgba(0, 154, 225, 0.4)" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          }
        </Graph>
        {
          user?.type !== PROMOTER &&
          <Graph title={`${t('INCONSISTENCIES')} (${t('LAST_6_EVENTS')})`} link='/events/past'>
            {
              info?.graphs?.reviews && info?.graphs?.reviews?.length > 0 &&
              <div style={{ width: "100%", height: 200 }}>
                <ResponsiveContainer>
                  <BarChart data={info?.graphs?.reviews || []}>
                    {
                      graphReviewsMax > 0 &&
                      <>
                        <ReferenceLine y={graphReviewsMax} stroke={BorderColor} />
                        <ReferenceLine y={Math.ceil(graphReviewsMax / 2)} stroke={BorderColor} />
                      </>
                    }
                    <XAxis dataKey="event" axisLine={false} />
                    <YAxis dataKey="reviews" axisLine={false} ticks={[0, Math.ceil(graphReviewsMax / 2), graphReviewsMax]} tickCount={3} interval='preserveStartEnd' />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <Bar dataKey="reviews" fill="rgba(130, 138, 237, 0.3)" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            }
          </Graph>
        }
      </Block>
    </>
  );
};

export default Graphs;
