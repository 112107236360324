import * as yup from 'yup';

interface FieldProps {
  value: string;
  originalValue: string;
  label: string;
  path: string;
}

export const isSlug = yup.string().test(
  'isSlug',
  (props: FieldProps) => ({
    tag: 'FIELD_VALIDATION_SLUG',
    values: props
  }),
  (value: string | undefined) => {
    if (!value) return true;
    const expression = /^[a-z0-9_-]*$/gm;
    return expression.test(value);
  }
);

export const isUrl = yup.string().test(
  'isUrl',
  (props: FieldProps) => ({
    tag: 'FIELD_VALIDATION_URL',
    values: props
  }),
  (value: string | undefined) => {
    if (!value) return true;
    const expression = /^(http|https):\/\/[^ "]+$/;
    return expression.test(value);
  }
);

export const isTag = yup.string().test(
  'isTag',
  (props: FieldProps) => ({
    tag: 'FIELD_VALIDATION_TAG',
    values: props
  }),
  (value: string | undefined) => {
    if (!value) return true;
    const expression = /^[A-Z0-9_]*$/gm;
    return expression.test(value);
  }
);

export const isContact = yup.string().test(
  'isContact',
  (props: FieldProps) => ({
    tag: 'FIELD_VALIDATION_CONTACT',
    values: props
  }),
  (value: string | undefined) => {
    if (!value) return true;
    const expression =
      /^((\+\d{1,3}(-|\s)?\(?\d\)?(-|\s)?\d{1,5})|(\(?\d{2,6}\)?))(\s)?(-|\s)?(\d{3,4})(-|\s)?(\d{3,4})(-|\s)?(\d{3,4})?(( x| ext)\d{1,5}){0,1}$/i;
    return expression.test(value);
  }
);

export const isPassword = yup.string().test(
  'isPassword',
  (props: FieldProps) => ({
    tag: 'FIELD_VALIDATION_PASSWORD_RULES',
    values: props
  }),
  (value: string | undefined) => {
    if (!value) return true;
    let valid = true;
    if (value.length < 6) valid = false;
    if (!/[a-z]/.test(value)) valid = false;
    if (!/[A-Z]/.test(value)) valid = false;
    if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(value)) valid = false;
    if (!/[0-9]/.test(value)) valid = false;
    return valid;
  }
);

export const isAthleteRecord = yup.string().test(
  'isAthleteRecord',
  (props: FieldProps) => ({
    tag: 'FIELD_VALIDATION_ATHELETE_RECORD',
    values: props
  }),
  (value: string | undefined) => {
    if (!value) return true;
    const expression = /^\d{1,}-\d{1,}-\d{1,}$/;
    return expression.test(value);
  }
);
