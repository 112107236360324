import * as yup from 'yup';

export const validations = [
  yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    responsible_name: yup.string().required(),
    federation: yup.string().required()
  }),
  yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    responsible_name: yup.string().required()
  })
];
