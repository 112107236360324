/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User } from '../../../../../models/User';
import { Block, Box } from '../../../../../styles/BasicStyles';
import { capitalizeString } from '../../../../../utils/strings';
import { BorderColor, PlaceholderColor } from '../../../../../styles/Colors';
import Typography from '../../../../../components/typography';
import StatsCard from '../../../common/StatsCard';
import Icon, { IconTypes } from '../../../../../components/icon';

interface Params {
  official?: User;
}

const StatsInfo: FC<Params> = ({ official }) => {
  const { t } = useTranslation();

  const renderItem = (icon: IconTypes, iconColor: string, value: any, title: string) => 
    <Block 
      w={{ lg: '100%', sm: '25%', xs: '50%', xxs: '100%' }}
      padding={{ lg: 0, xxs: '0 0.125rem' }}
      display='flex'
    >
      <Box 
        style={{ backgroundColor: 'rgba(68, 71, 87, 0.15)', border: `0.0.63rem solid ${BorderColor}` }} 
        padding='0.75 1.5' 
        fAlign='center' 
        bRadius={0.5}
        mb={0.25} 
      >
        <Icon icon={icon} color={iconColor} size={1.5} />
        <Block pl={1.5} display='flex' fDirection='column'>
          <Typography fSize={1.25} fWeight={600} lHeight={1.25} color='#ffffff' pb={0.375}>{value}</Typography>
          <Typography variant='body-small' fWeight={500} style={{ color: PlaceholderColor }}>{title}</Typography>
        </Block>
      </Box>
    </Block>;

  return (
    <>
      <StatsCard 
        items={[
          {
            title: `${official?.stats?.totalJudged} ${capitalizeString(t('FIGHTS'))}`,
            subtitle: t('JUDGED'),
            bgColor: 'rgba(79, 194, 244, 0.15)',
            fontColor: 'rgba(79, 194, 244, 0.5)'
          },
          {
            title: `${official?.stats?.totalRefereed} ${capitalizeString(t('FIGHTS'))}`,
            subtitle: t('REFEREED'),
            bgColor: 'rgba(61, 205, 179, 0.15)',
            fontColor: 'rgba(61, 205, 179, 0.5)'
          },
          {
            title: `${official?.stats?.totalCommissioner} ${capitalizeString(t('FIGHTS'))}`,
            subtitle: t('AS_COMMISSIONER'),
            bgColor: 'rgba(237, 106, 159, 0.15)',
            fontColor: 'rgba(237, 106, 159, 0.5)'
          },
          {
            title: `${official?.stats?.totalFights} ${capitalizeString(t('FIGHTS'))}`,
            subtitle: t('TOTAL_FIGHTS'),
            bgColor: 'rgba(255, 160, 0, 0.15)',
            fontColor: 'rgba(255, 160, 0, 0.5)'
          }
        ]}
      />
      <Typography variant='body-small' color='#ffffff' pt={2} pb={1}>{t('CURRENT').toUpperCase()}</Typography>
      <Block display='flex' w='100%' fDirection={{ lg: 'column', xxs: 'row' }} margin={{ lg: 0, xxs: '0 -0.125rem' }} fWrap='wrap'>
        {renderItem('outlined_refresh', 'rgba(89, 214, 131, 0.5)', `${official?.stats?.consistency?.toFixed(2)}%`, t('SCORING_CONSISTENCY'))}
        {renderItem('outlined_exclamationCircle', 'rgba(235, 233, 81, 0.5)', official?.stats?.needReview, t('NEEDING_REVIEW'))}
        {renderItem('outlined_calendar', 'rgba(130, 138, 237, 0.6)', official?.stats?.eventsScheduled, t('EVENTS_SCHEDULED'))}
      </Block>
    </>
  );
};

export default StatsInfo;
