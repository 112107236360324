/* eslint-disable @typescript-eslint/no-explicit-any */
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../../hooks/use-validation/use-validation-schema';
import { Box, Block, BasicForm } from '../../../../../styles/BasicStyles';
import { useState, FC, useEffect } from 'react';
import { FocusColor, LabelColor, DisabledColor } from '../../../../../styles/Colors';
import { Tournament } from '../../../../../models/Tournament';
import { ProgressBar, BackWrapper } from './styles';
import { User, ATHLETE } from '../../../../../models/User';
import { AnyObject } from '../../../../../models/Generic';
import { GetValidationSchema } from './formHelpers';
import { useNavigate } from 'react-router-dom';
import { GetGroupName } from '../../../../../utils/user';
import Modal, { ModalActions, ModalOverflowContent } from '../../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../../components/button';
import toFormData from '../../../../../utils/formdata/ToFormData';
import Typography from '../../../../../components/typography';
import Icon from '../../../../../components/icon';
import MainForm from './MainForm';
import arrayMutators from 'final-form-arrays';
import SearchAthleteModal from '../searchAthlete';

// TODO: when adding the code to "ask before exit", it causes a bug in this modal. When you try to edit a tournament and then add a new, it causes some errors in the mutators code

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: Tournament | AnyObject;
  eventID?: string;
  eventFederation?: string;
  eventStart?: any;
  eventEnd?: any;
}

const { Form } = withTypes<Tournament>();

const ManageTournamentModal: FC<Params> = ({ 
  opened, 
  onClose, 
  initialValues,
  eventID, 
  eventFederation,
  eventStart,
  eventEnd
}) => {
  const { t } = useTranslation();
  const { post, put } = useFetch('/tournaments');
  const [athletes, setAthletes] = useState<User[]>([]);
  const [step, setStep] = useState<number>(1);
  const [fightersCount, setFightersCount] = useState<number>(0);
  const [searchModal, setSearchModal] = useState<{ open: boolean, index?: number }>({ open: false });
  const athletesHooks = useFetch(`/members/${ATHLETE}/${eventFederation}/dropdown`);
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      if(!!eventFederation && !initialValues?._id) {
        const { data } = await athletesHooks.get();
        
        if(data?.length > 0) {
          const athletesList: User[] = [];

          // First option is the one to search for other Federations athletes
          athletesList.push({
            _id: '1',
            name: t('SEARCH_ATHLETE'),
            group: GetGroupName(t, 'EXTERNAL', 1),
            disabled: false
          });

          data.map((athlete: User) => 
            athletesList.push({ 
              _id: athlete._id, 
              name: athlete.name, 
              status: athlete.status,
              disabled: initialValues?.fighters?.includes(athlete._id) ? true : false,
              group: GetGroupName(t, 'FEDERATION', 2)
            })
          );

          setAthletes(athletesList);
        }
      }

      setStep(1);
    };

    init();
  }, [opened]);

  const onSubmit = async (values: Tournament) => {    
    const finalStep = initialValues?._id ? 1 : 2;

    if(step === finalStep) {
      const payload = toFormData({ ...values, event: eventID });

      if(initialValues?._id) {
        const { success } = await put(`/${values._id}`, payload);
        if(success) closeModal(true);
      }
      else {
        const { data, success } = await post(payload);
        if(success) {
          closeModal(true);
          navigate(`/tournaments/${data._id}?success=true`);
        }
      }
    }
    else {
      setStep(step + 1);
    }
  };

  const closeModal = (changed: boolean) => {
    setFightersCount(0);
    onClose(changed);
  };

  return (
    <Modal
      size={36.25}
      opened={opened}
      onClose={() => closeModal(false)}
      title={initialValues?._id ? t('EDIT_TOURNAMENT') : t('CREATE_TOURNAMENT')}
      subtitle={
        <>
          {
            !initialValues?._id &&
            <Block display='flex' fAlign='center'>
              <Typography variant='body-small' lHeight={1.063} style={{ color: DisabledColor }} pr={0.625}>
                {step === 1 ? t('GENERAL_INFO') : step === 2 ? t('ADDING_FIGHTERS') : ''}
              </Typography>
              <Typography variant='body-small' fWeight={600} lHeight={1.063} color={FocusColor}>{`${t('STEP')} ${step}`}</Typography>
            </Block>
          }
        </>
      }
    >
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema(GetValidationSchema(step, initialValues?._id))}
        initialValues={initialValues || {}}
        mutators={{ 
          ...arrayMutators,
          selectFighter: (args: any, state: any, utils) => {
            utils.changeValue(state, `fighters[${args[1]}]`, () => args[0]);
          }
        }}
        render={({ 
          values,
          handleSubmit, 
          submitting,
          form: { mutators: { push, remove, selectFighter } } 
        }) => {
          const handleCloseSearchModal = () => {
            // On cancel remove "Search athlete" selected option
            selectFighter(undefined, searchModal.index);
  
            setSearchModal({ open: false });
          };
  
          const handleSaveSearchModal = async (athlete?: User) => {
            if(!!athlete) {
              const aux = [...athletes];
        
              // Add the new athlete (the first element must be the one to search athlete)
              aux.push({
                _id: athlete._id,
                name: athlete.name,
                status: athlete.status,
                group: GetGroupName(t, 'EXTERNAL', 1),
                disabled: true
              });
  
              const index = searchModal.index || 0;
              const oldValue: any = values.fighters && index >= 0 ? values.fighters[index] : '';
              selectFighter(athlete._id, index);
  
              // If there was already a selected fighter, enable it
              if(oldValue) {
                const index = aux.findIndex((athlete: User) => athlete._id === oldValue);
                if (index >= 0) aux[index].disabled = false;
              }
  
              setAthletes(aux);
              setSearchModal({ open: false });
            }
          };

          return (
            <>
              <BasicForm onSubmit={handleSubmit}>
                {!initialValues?._id && <ProgressBar progress={step === 1 ? 50 : 100} />}
                <ModalOverflowContent>
                  <MainForm 
                    eventStart={eventStart}
                    eventEnd={eventEnd}
                    eventFederation={eventFederation}
                    initialValues={initialValues}
                    setFightersCount={setFightersCount}
                    push={push}
                    remove={remove}
                    fightersCount={fightersCount}
                    athletes={athletes}
                    setAthletes={setAthletes}
                    step={step}
                    setSearchModal={setSearchModal}
                  />
                </ModalOverflowContent>
                <ModalActions>
                  <Box fAlign='center' fJustify={step > 1 ? 'space-between' : 'flex-end'}>
                    {
                      step > 1 &&
                      <BackWrapper 
                        display='flex' 
                        fDirection='row' 
                        fAlign='center' 
                        onClick={() => setStep(step - 1)}
                      >
                        <Icon icon='outlined_chevronLeft' color={LabelColor}/>
                        <Typography variant='body-small' fWeight={500} pl={0.5} style={{ color: LabelColor }}>{t('BACK')}</Typography>
                      </BackWrapper>
                    }
                    <Block display='flex' fDirection='row' fAlign='center'>
                      <Button 
                        text={t('CANCEL')} 
                        variant='secondary' 
                        onClick={() => closeModal(false)} 
                      />
                      <Button 
                        text={
                          initialValues?._id ?
                          t('SAVE')
                          :
                          step === 2 ?
                          t('ADD_TOURNAMENT')
                          :
                          t('NEXT')
                        } 
                        type='submit' 
                        loading={submitting} 
                      />
                    </Block>
                  </Box>
                </ModalActions>
              </BasicForm>
              <SearchAthleteModal 
                opened={searchModal.open}
                onClose={handleCloseSearchModal}
                onSave={handleSaveSearchModal}
                athletes={athletes}
              />
            </>
          );
        }}
      />
    </Modal>
  );
};

export default ManageTournamentModal;
