/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Block, Box } from '../../../styles/BasicStyles';
import { BorderColor, FocusColor, LabelColor, PlaceholderColor, DisabledColor } from '../../../styles/Colors';
import { showSuccess } from '../../../hooks/show-notification/show-notification';
import { useDidUpdate } from '@mantine/hooks';
import { AnyObject } from '../../../components/inputs/Generic';
import { PaginationObject } from '../../../components/page/DefaultListPage';
import { Federation } from '../../../models/Federation';
import { CustomField } from '../../../models/CustomField';
import { Grid } from '@mantine/core';
import { capitalizeString } from '../../../utils/strings';
import { Languages, LanguagesProps } from '../../../utils/languages/AvailableTranslations';
import { DocumentCategory, DocumentsDescriptions } from '../../../models/Document';
import { RESPONSIBLE } from '../../../models/User';
import { UserContext } from '../../../Context';
import useFetch from 'use-http';
import Typography from '../../../components/typography';
import BaseTable from '../../../components/table';
import ConstructPaginationRequest from '../../../utils/requests/ConstructPaginationRequest';
import Button from '../../../components/button';
import SelectInput from '../../../components/inputs/SelectInput';
import Icon from '../../../components/icon';
import TextAreaInput from '../../../components/inputs/TextAreaInput';

const DocumentsSettingsTable = BaseTable<DocumentCategory>();

const DocumentsSettingsPage = () => {
  const [federations, setFederations] = useState<Federation[]>([]);
  const [selectedFederation, setSelectedFederation] = useState<Federation>();
  const [documentsDescriptions, setDocumentsDescriptions] = useState<DocumentsDescriptions[]>([]);
  const [pagination, setPagination] = useState<PaginationObject>({
    page: 1,
    limit: 20
  });
  const [rows, setRows] = useState<DocumentCategory[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const { t, i18n: { language } } = useTranslation();
  const { get } = useFetch('/document-categories');
  const federationsHook = useFetch('/federations');
  const customFieldsHook = useFetch('/custom-fields');
  const { user } = useContext(UserContext);

  useEffect(() => {
    const init = async () => {
      const defaultPagination: PaginationObject = {
        page: 1,
        limit: 20
      };

      setPagination(defaultPagination);

      if(user?.type === RESPONSIBLE) {
        setSelectedFederation(user.federation);
      }
      else {
        const federationsResult = await federationsHook.get('/dropdown');
        
        setFederations(federationsResult?.data || []);
        setSelectedFederation(federationsResult?.data[0]);
      }

      const customFieldsResult = await customFieldsHook.get('/dropdown');
      setCustomFields(customFieldsResult?.data || []);
    };

    init();
  }, []);

  useEffect(() => {
    reloadDescriptions();
  }, [rows]);

  useDidUpdate(() => {
    const init = async () => {
      setLoading(true);

      const url = ConstructPaginationRequest(
        `/${pagination.page}/${pagination.limit}`,
        { federation: selectedFederation?._id }
      );

      const result = await get(url);

      if(pagination.page > 1 && (result?.data?.items || []).length === 0) {
        const pag = { ...pagination, page: 1 };
        handleNewPagination(pag);
      } 
      else {
        setRows(result?.data?.items || []);
        setTotal(result?.data?.total || 0);
        setLoading(false);
      }
    };
    
    if(selectedFederation?._id) init();
  }, [pagination, selectedFederation?._id]);

  const handleNewPagination = (pag: PaginationObject) => {
    setPagination(pag);
  };

  const handleNewPage = (page: number) => {
    const pag = { ...pagination, page };
    handleNewPagination(pag);
  };

  const reloadDescriptions = () => {
    if(rows?.length > 0) {
      const aux: DocumentsDescriptions[] = [];

      rows.forEach((elem: DocumentCategory) => {
        const exists = selectedFederation?.documents_descriptions?.find((desc: DocumentsDescriptions) => desc.category === elem._id);
        aux.push({ category: elem._id, description: exists?.description || {}, active: {} })
      });

      setDocumentsDescriptions(aux);
    }
  }

  const onSubmit = async (category: string, lang: string) => {
    if(selectedFederation?._id) {
      const { success } = await federationsHook.put(`/${selectedFederation._id}/documents-descriptions`, { documents_descriptions: documentsDescriptions });

      if(success) {
        showSuccess({
          title: t('SUCCESS'),
          message: t('DOCUMENTS_SETTINGS_SUCCESS')
        });

        toggleDescription(category, lang, false);

        const federationsResult = await federationsHook.get(`/${selectedFederation._id}`);
        setSelectedFederation(federationsResult?.data || {});
      }
    } 
  };

  const changeDescription = (category: string, lang: string, value: string | null) => {
    const aux = [...documentsDescriptions];
    const index = aux.findIndex(elem => elem.category === category);
    if(index >= 0) aux[index].description = { ...aux[index].description, [lang]: value };
    else aux.push({ ...aux[index], category, description: { ...aux[index].description, [lang]: value } });

    setDocumentsDescriptions([...aux]);
  };

  const toggleDescription = (category: string, lang: string, value: boolean) => {
    const aux = [...documentsDescriptions];
    const index = aux.findIndex(elem => elem.category === category);
    if(index >= 0) aux[index].active = { ...aux[index].active, [lang]: value };
    else aux.push({ category, description: {}, active: { [lang]: value } });

    setDocumentsDescriptions([...aux]);
  };

  const changeFederation = async (value: string | null) => {
    const federationsResult = await federationsHook.get(`/${value}`);
    setSelectedFederation(federationsResult?.data || {});
  };

  const getProfileTypes = (code: string) => {
    let types = '';

    if(customFields?.length > 0) {
      const aux: string[] = [];

      customFields
      .filter((elem: CustomField) => elem.documentCategoryCode === code.toUpperCase())
      .forEach((elem: CustomField) => {
        const value = capitalizeString(elem.type);
        const index = aux.findIndex(type => type === value);

        if(index === -1) aux.push(value);
      });

      if(aux.length > 0) types = aux.join('\r\n');
    }

    return types;
  };

  const columns = [
    { 
      title: t('DOCUMENT_NAME'), 
      dataIndex: 'name',
      width: '30%',
      render: (value: AnyObject) => <Typography variant='table-header'>{value[language]}</Typography>
    },
    { 
      title: t('PROFILE_TYPE'), 
      dataIndex: 'code',
      width: '30%',
      render: (value: string) => <Typography variant='table-header' style={{ color: PlaceholderColor, whiteSpace: 'pre-wrap' }}>{getProfileTypes(value)}</Typography>
    },
    { 
      title: t('DESCRIPTION'), 
      key: 'description',
      width: '40%',
      render: (_: any, category: AnyObject) => {
        const exists = documentsDescriptions?.find(elem => elem.category === category._id);
        
        return (
          <>
            <Box fDirection='column' mt={1}>
              {
                Languages.map((elem: LanguagesProps, index: number) =>
                  <Box key={index} fDirection='column' mb={1}>
                    {
                      exists?.active && exists.active[elem.code] ?
                      <Box fDirection='column'>
                        <TextAreaInput
                          label={<Typography variant='sidebar-group'>{t(elem.tag)}</Typography>}
                          input={{
                            value: (exists.description && exists.description[elem.code]) || '',
                            onChange: (v: string | null) => changeDescription(category._id, elem.code, v)
                          }}
                          placeholder={t('ENTER_DESCRIPTION')}
                          autosize
                          minRows={3}
                          hasActionButtons={true}
                        />
                        <Box 
                          fAlign='center' 
                          fJustify='flex-end' 
                          pl={0.875} 
                          pr={0.875} 
                          pb={0.563}
                          style={{ border: `0.063rem solid ${BorderColor}`, borderTop: 0 }}
                        >
                          <Button
                            text={t('CANCEL')}
                            variant='secondary'
                            onClick={() => { toggleDescription(category._id, elem.code, false); reloadDescriptions(); }}
                            size='xs'
                            mr={0.5}
                          />
                          <Button
                            text={t('SAVE')}
                            variant={FocusColor}
                            onClick={() => onSubmit(category._id, elem.code)}
                            size='xs'
                          />
                        </Box>
                      </Box>
                      :
                      <>
                        <Typography variant='sidebar-group' style={{ color: PlaceholderColor }} mb={0.5}>{t(elem.tag)}</Typography>
                        <Box fJustify='space-between'>
                          <Typography variant='sidebar-group' style={{ color: exists?.description && exists.description[elem.code] ? LabelColor : DisabledColor }}>
                            {exists?.description && exists.description[elem.code] ? exists.description[elem.code] : t('NO_DESCRIPTION')}
                          </Typography>
                          <Icon 
                            icon='outlined_pencil' 
                            color='rgba(255, 255, 255, 0.1)' 
                            hoverColor={LabelColor}
                            onClick={() => toggleDescription(category._id, elem.code, true)}
                          />
                        </Box>
                      </>
                    }
                  </Box>
                )
              }
            </Box>
          </>
        );
      }
    }
  ];
  
  return (
    <Block maxW={45.938} margin='auto' mt={3}>
      <Block w='100%' mb={1}>
        <Typography variant='page-title' style={{ fontWeight: 500, color: LabelColor }}>{t('DOCUMENTS_SETTINGS')}</Typography>
      </Block>
      {
        user?.type !== RESPONSIBLE &&
        <Block mb={1.375}>
          <Grid gutter="xl">
            <Grid.Col xs={12} sm={6}>
              <SelectInput
                input={{
                  value: selectedFederation?._id || '',
                  onChange: (v: string | null) => changeFederation(v)
                }}
                label={t('FEDERATION')}
                placeholder={t('CHOOSE_FEDERATION')}
                data={federations}
              />
            </Grid.Col>
          </Grid>
        </Block>
      }
      <DocumentsSettingsTable
        columns={columns}
        rows={rows}
        loading={loading}
        tableStyles={{ 
          compact: true
        }}
        pagination={{
          total,
          page: pagination.page,
          setPage: handleNewPage,
          limit: pagination.limit
        }}
      />
    </Block>
  );
};

export default DocumentsSettingsPage;
