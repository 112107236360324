/* eslint-disable @typescript-eslint/no-explicit-any */

export const isArrayOfStrings = (list: any) => {
  let valid = false;
  if (Array.isArray(list)) {
    valid = true;
    list.forEach((item) => {
      if (typeof item !== 'string') valid = false;
    });
  }
  return valid;
};

export const FindItem = (items: any[], id: string) => {
  const index = items.findIndex((elem: any) => elem._id === id);
  return {
    item: items[index],
    index
  };
};

export const IsDifferent = (arr1: any[], arr2: any[]) => {
  if(arr1.length !== arr2.length) return true;

  let isDiff = false;
  for(let i = 0; i < arr1.length; i++) {
    if(arr1[i]._id !== arr2[i]._id) isDiff = true;
  }

  return isDiff;
};

export const CanOrderArray = (item: any, items: any[], index: number, atIndex: number) => {
  const start = index > atIndex ? atIndex : index;
  const end = index > atIndex ? index : atIndex;

  let canMove = true;
  for(let i = start; i <= end; i++) {
    const arrayItem = items[i];

    if(item._id !== arrayItem._id && item.tournament?._id === arrayItem.tournament?._id && item.order_priority !== arrayItem.order_priority) canMove = false;
  }

  return canMove;
}
