import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { Box, Block } from '../../../../../styles/BasicStyles';
import { AnyObject } from '../../../../../models/Generic';
import { FightTypes, FightResults } from '../../../../../utils/fights';
import { User } from '../../../../../models/User';
import { Event } from '../../../../../models/Event';
import { DecisionList } from '../../../../../models/Fight';
import FiltersModal from '../../../../../components/filtersModal';
import SelectInput from '../../../../../components/inputs/SelectInput';
import DateInput from '../../../../../components/inputs/DateInput';

interface FiltersProps {
  filters?: AnyObject;
  handleChange: (field: string | string[], value: string | string[] | null, time?: number) => void;
  athletes: User[];
  events: Event[];
}

const Filters: FC<FiltersProps> = ({ filters, handleChange, athletes, events }) => {
  const [filtersModal, setFiltersModal] = useState<AnyObject>({ ...filters });
  const { t } = useTranslation();

  // When closing the modal without applying the filters, reload the filters saved in local storage
  const reloadStorageFilters = () => {
    setFiltersModal({ ...filters });
  }
  
  return (
    <Box fAlign='center' fJustify='flex-end'>
      <FiltersModal 
        handleChange={handleChange}
        filters={filtersModal}
        setFilters={setFiltersModal}
        reloadStorageFilters={reloadStorageFilters}
      >
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.event || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, event: v }})
            }}
            label={t('EVENT')}
            placeholder={t('CHOOSE_EVENT')}
            clearable
            data={events}
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.type || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, type: v }})
            }}
            label={t('TYPE')}
            placeholder={t('CHOOSE_TYPE')}
            clearable
            data={FightTypes}
            translate
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.opponent || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, opponent: v }})
            }}
            label={t('OPPONENT')}
            placeholder={t('CHOOSE_FIGHTER')}
            clearable
            data={athletes}
          />
        </Block>
        <Block mb={0.5}>
          <DateInput
            input={{
              value: filtersModal?.start_datetime || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, start_datetime: v }})
            }}
            label={t('DATE')}
            placeholder={t('CHOOSE_DATE')}
            clearable
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: (filtersModal && filtersModal['result.winner']) || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, 'result.winner': v }})
            }}
            label={t('RESULT')}
            placeholder={t('CHOOSE_RESULT')}
            clearable
            data={FightResults}
            translate
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: (filtersModal && filtersModal['result.decision']) || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, 'result.decision': v }})
            }}
            label={t('FIGHT_ENDING')}
            placeholder={t('CHOOSE_RESULT')}
            clearable
            data={DecisionList}
            translate
          />
        </Block>
      </FiltersModal>
    </Box>
  );
};

export default Filters;
