import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User } from '../../../../../models/User';
import { AnyObject } from '../../../../../models/Generic';
import Typography from '../../../../../components/typography';
import InfoCard from '../../../common/InfoCard';
import dayjs from 'dayjs';

interface Params {
  coach?: User;
  isDraftMember: boolean;
}

const InfoCards: FC<Params> = ({ coach, isDraftMember }) => {
  const { t, i18n: { language } } = useTranslation();

  let martialArts = '';
  if(coach?.martial_arts && coach.martial_arts.length > 0) {
    coach.martial_arts.forEach((martialArt: AnyObject) => martialArts = `${martialArts}${martialArt.name}, `);

    martialArts = martialArts.slice(0, -2); 
  }

  let evaluationClasses = '';
  if(coach?.class && coach.class.length > 0) {
    coach.class.forEach((elem: AnyObject) => evaluationClasses = `${evaluationClasses}${elem.name[language]}, `);

    evaluationClasses = evaluationClasses.slice(0, -2); 
  }

  let address = '';
  if(coach?.address) address = coach.address;
  if(coach?.postal_code) address = address ? `${address} ${coach.postal_code}` : coach.postal_code;
  if(coach?.city) address = address ? `${address}, ${coach.city}` : coach.city;

  const federationInfo = [
    {
      title: t('FEDERATION'),
      value: coach?.federation?.name
    }
  ];

  if(!isDraftMember) {
    federationInfo.push({
      title: t('AFFILIATION_NUMBER'),
      value: coach?.associate
    });
  }
  
  return (
    <>
      <InfoCard 
        items={[
          {
            title: t('BIRTH_DATE'),
            value: coach?.birthday ? dayjs(coach?.birthday).format('DD MMM, YYYY') : ''
          },
          {
            title: t('ADDRESS'),
            value: address
          },
          {
            title: t('COUNTRY'),
            value: coach?.country?.name
          },
          {
            title: t('GENDER'),
            value: t(coach?.gender || '')
          },
          {
            title: t('NATIONALITY'),
            value: coach?.nationality
          },
          {
            title: t('EMAIL'),
            value: coach?.email
          },
          {
            title: t('PHONE_NUMBER'),
            value: coach?.phone
          },
          {
            title: t('CLUB'),
            value: coach?.club?.name
          }
        ]}
      />
      <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('CAREER_INFO').toUpperCase()}</Typography>
      <InfoCard 
        items={[
          {
            title: t('CLASS'),
            value: evaluationClasses
          },
          {
            title: t('MARTIAL_ARTS_BACKGROUND'),
            value: martialArts
          }
        ]}
      />
      <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('FEDERATION_INFO').toUpperCase()}</Typography>
      <InfoCard 
        items={federationInfo}
      />
    </>
  );
};

export default InfoCards;
