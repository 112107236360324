import { useTranslation } from 'react-i18next';
import { Box } from '../../../styles/BasicStyles';
import { SuccessImgWrapper } from '../Styles';
import { LayoutWrapper } from '../../../components/layout/LayoutStyles';
import Typography from '../../../components/typography';
import HeaderExternal from '../../../components/header/HeaderExternal';

const ExternalFormSuccessPage = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <HeaderExternal />
      <LayoutWrapper showSidebar={false}>
        <Box fDirection='column' maxW={43.75} margin='auto' pt={3}>
          <Box fDirection='column' fAlign='center' bRadius={0.5} bgColor='#222429' padding='2 1'>
            <SuccessImgWrapper>
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> 
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
            </SuccessImgWrapper>
            <Typography variant='body' fWeight={500} color='#ffffff' pt={1} tAlign='center'>{t('MEMBER_FORM_SUBMITTED')}</Typography>
            <Typography variant='body-small' color='#ffffff' pt={0.5} maxW={25} tAlign='center'>{t('MEMBER_FORM_SUBMITTED_MESSAGE')}</Typography>
          </Box>
        </Box>   
      </LayoutWrapper>
    </>
  );
};

export default ExternalFormSuccessPage;
