import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { GenericInput } from './Generic';
import { DangerColor, DisabledColor, FocusColor, LabelColor } from '../../styles/Colors';
import { InputWrapper, ErrorMessage, InputLabel } from './Styles';
import { InputSize } from './Generic';
import { useTranslation } from 'react-i18next';
import DateTimePicker from 'react-datetime-picker';
import Icon from '../icon';

export interface DateTimeInputProps extends Omit<FieldRenderProps<Date>, 'input'> {
  afterChange?: (value: Date) => void;
  input: GenericInput<Date>;
  size?: InputSize;
  isExternal?: boolean;
}

export const DateTimeInput: FC<DateTimeInputProps> = ({
  input,
  meta = {},
  afterChange,
  clearable = false,
  size = 'md',
  isExternal = false,
  label,
  ...other
}) => {
  const { t } = useTranslation();

  const handleChange = (value: Date | null): void => {
    input.onChange(value);
    
    if(value && afterChange && typeof afterChange === 'function') {
      afterChange(value);
    }
  };

  const hasError = meta?.invalid && meta?.submitFailed;
  const value = input.value ? new Date(input.value) : null;

  return (
    <InputWrapper size={size} hasError={hasError} isExternal={isExternal}>
      {label && <InputLabel size={size}>{label}</InputLabel>}
      <DateTimePicker
        {...other}
        value={value}
        onChange={handleChange}
        disableClock={true}
        format="dd/MM/y HH:mm"
        clearIcon={clearable && value ? <Icon icon='outlined_x' /> : null}
        calendarIcon={<Icon icon='outlined_calendar' color={other.disabled ? DisabledColor : hasError ? DangerColor : LabelColor} hoverColor={!hasError && !other.disabled ? FocusColor : undefined} />}
      />
      {
        hasError &&
        <ErrorMessage>
          {t('FIELD_REQUIRED')}
        </ErrorMessage>
      }
    </InputWrapper>
  );
};

export default DateTimeInput;
