import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { FC, useState, useEffect, useContext } from 'react';
import { COACH, MemberTypes, User, SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PENDING, ACTIVE, MEMBER_AWAITING_VALIDATION } from '../../../../models/User';
import { GetFirstAndLastName } from '../../../../utils/user';
import { Tabs } from '@mantine/core';
import { TabsWrapper } from '../../../../components/tabs/styles';
import { GetCoachInitialValues } from '../../../../utils/modalValues';
import { UserContext } from '../../../../Context';
import { useLocation, useNavigate } from 'react-router-dom';
import { showConfirm, showSuccess } from '../../../../hooks/show-notification/show-notification';
import { AlertErrorBg, AlertSuccessBg } from '../../../../styles/Colors';
import useFetch from 'use-http';
import headerActions, { HeaderBaseAction } from '../../../../components/header/HeaderActions';
import LoadingOverlay from '../../../../components/loadingOverlay';
import PageHeader from '../../../../components/page/PageHeader';
import CoachModal from '../form';
import PasswordModal from '../../common/modals/Password';
import MainCard from '../../common/MainCard';
import Overview from './overview';
import Fights from './fights';
import RejectMemberModal from '../../../memberDrafts/RejectMemberModal';

interface Params {
  propsId?: string;
}

const CoachDetailPage: FC<Params> = ({ propsId }) => {
  const [pageLoading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<User>();
  const [editModal, setEditModal] = useState<{ open: boolean, initialValues?: User }>({ open: false });
  const [passwordModal, setPasswordModal] = useState<boolean>(false);
  const [rejectModal, setRejectModal] = useState<{ open: boolean, user?: User }>({ open: false });
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const isDraftMember = location.pathname.includes('/draft/');
  const { loading, get, put } = useFetch(isDraftMember ? '/member-drafts' : '/members');
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  // Coaches can only access their own page (it will be their Homepage, so the ID comes from Props and not Url params)
  const userId = propsId || id;
  const adminList = [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE];

  useEffect(() => {
    const init = async () => {
      await getInfo();
    };
    
    if((user?.type === COACH && user?._id === propsId) || user?.type !== COACH) init();
    else navigate('/');
  }, [userId]);

  const getInfo = async () => {
    if(userId) {
      const user = await get(`/${userId}`);

      if(user?.success) {
        setInitialValues(user.data);
        setEditModal({ open: false, initialValues: GetCoachInitialValues(user.data) });
      }
    }

    setLoading(false);
  };

  const handleCloseModal = async (changed: boolean) => {
    if(changed) {
      setLoading(true);
      await getInfo();
    }
    else {
      setEditModal({ ...editModal, open: false }); 
    }
  };

  const validateMember = () => {
    showConfirm({
      title: t('VALIDATE_MEMBER'),
      message: t('VALIDATE_MEMBER_MESSAGE'),
      onConfirm: async () => {
        let success = false, newId = '';

        if(isDraftMember) {
          const result = await put(`/${initialValues?._id}/validate`);
          success = result?.success;
          newId = result?.data?._id;
        }
        else {
          const result = await put(`/${initialValues?._id}/change-status`, { status: ACTIVE });
          success = result?.success;
        }

        if(success) {
          showSuccess({
            title: t('MEMBER_VALIDATED'),
            message: t('MEMBER_VALIDATED_MESSAGE'),
            autoClose: 10000
          });

          if(isDraftMember) {
            return navigate(`/members/coach/${newId}`);
          }
          else {
            setLoading(true);
            getInfo();
          }
        }
      }
    });
  };

  const openRejectModal = async () => setRejectModal({ open: true, user: initialValues });

  const handleCloseRejectModal = (changed: boolean) => {
    if(changed) {
      setLoading(true);
      getInfo();
    }
    setRejectModal({ open: false });
  };

  const actions: HeaderBaseAction[] = [{
    type: 'button',
    variant: 'primary',
    text: t('EDIT_PROFILE'),
    onClick: () => setEditModal({ ...editModal, open: true })
  }];

  // If the current User is an Athlete, Official or Coach show a specific button to edit their password
  if(user?.type === COACH) {
    actions.push(
      {
        type: 'button',
        variant: 'primary',
        text: t('CHANGE_PASSWORD'),
        onClick: () => setPasswordModal(true)
      }
    );
  }

  // If the current user is an Admin, show the "validate member" button (if the member is in status "pending")
  if(!isDraftMember && user?.type && adminList.includes(user?.type) && initialValues?.status === PENDING) {
    actions.unshift(
      {
        type: 'button',
        variant: 'gradient',
        text: t('VALIDATE_MEMBER'),
        onClick: validateMember,
        disabled: loading,
        loading
      }
    );
  }

  // If the current user is an Admin, show the "validate member" and "reject member" button
  if(isDraftMember && user?.type && adminList.includes(user?.type)) {
    actions.unshift(
      {
        type: 'button',
        variant: AlertSuccessBg,
        text: t('VALIDATE'),
        onClick: validateMember,
        disabled: loading,
        loading
      }
    );

    if(initialValues?.status === MEMBER_AWAITING_VALIDATION) {
      actions.unshift(
        {
          type: 'button',
          variant: AlertErrorBg,
          text: t('REJECT'),
          onClick: openRejectModal,
          disabled: loading
        }
      );
    }
  }

  return (
    <>
      <LoadingOverlay visible={pageLoading} />
      <PageHeader
        title={GetFirstAndLastName(initialValues?.name)}
        backLink={user?.type && MemberTypes.includes(user.type) ? undefined : isDraftMember ? '/members/draft' : '/members/coach'}
        actions={headerActions({
          hasSave: false,
          extra: actions
        })}
      />
      {
        !pageLoading &&
        <>
          <MainCard userInfo={initialValues} isDraftMember={isDraftMember} />
          <TabsWrapper>
            <Tabs keepMounted={false} radius="md" defaultValue='overview'>
              {
                !isDraftMember && user?.type !== COACH &&
                <Tabs.List>
                  <Tabs.Tab value='overview'>
                    {t('OVERVIEW')}
                  </Tabs.Tab>
                  <Tabs.Tab value='upcoming_fights'>
                    {t('UPCOMING_FIGHTS')}
                  </Tabs.Tab>
                  <Tabs.Tab value='past_fights'>
                    {t('PAST_FIGHTS')}
                  </Tabs.Tab>
                </Tabs.List>
              }
              <Tabs.Panel value='overview'>
                <Overview coach={initialValues} getInfo={getInfo} isDraftMember={isDraftMember} />
              </Tabs.Panel>
              {
                !isDraftMember && user?.type !== COACH &&
                <>
                  <Tabs.Panel value='upcoming_fights'>
                    <Fights coach={initialValues} type='upcoming' />
                  </Tabs.Panel>
                  <Tabs.Panel value='past_fights'>
                    <Fights coach={initialValues} type='past' />
                  </Tabs.Panel>
                </>
              }
            </Tabs>
          </TabsWrapper>
        </>
      }
      <CoachModal
        opened={editModal.open}
        initialValues={editModal.initialValues}
        onClose={handleCloseModal}
        isDraftMember={isDraftMember}
      />
      {
        user?.type === COACH &&
        <PasswordModal
          opened={passwordModal}
          onClose={() => setPasswordModal(false)}
        />
      }
      {
        isDraftMember &&
        <RejectMemberModal
          opened={rejectModal.open}
          user={rejectModal.user}
          onClose={handleCloseRejectModal}
        />
      }
    </>
  );
};

export default CoachDetailPage;
