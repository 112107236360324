import { FC, HTMLAttributes, useState } from 'react';
import { PaginationArrow, PaginationButton } from './styles';
import { Block } from '../../styles/BasicStyles';
import { PaginationColor } from '../../styles/Colors';
import Typography from '../typography';
import Icon from '../icon';

export interface PaginationProps extends HTMLAttributes<HTMLDivElement> {
  count: number;
  defaultPage?: number;
  boundaryCount?: number;
  siblingCount?: number;
  onChangeHandle?: (page: number) => void;
}

export const Pagination: FC<PaginationProps> = ({
  count,
  defaultPage = 1,
  boundaryCount = 1,
  siblingCount = 1,
  onChangeHandle,
  ...props
}) => {
  const [currentPage, setPage] = useState(defaultPage);

  let pi = 0;
  const pItems = [], plen = count;
  while (++pi <= plen) pItems.push(pi);

  return (
    <Block display='flex' fAlign='center' fDirection='row' {...props}>
      <PaginationArrow onClick={() => {
          if (currentPage == 1) return;
          const page = currentPage - 1;          
          setPage(page);
          if (onChangeHandle) {
            onChangeHandle(page);
          }
        }}
      >
        <Icon icon='outlined_chevronLeft' size={1} className='prevIcon' />
      </PaginationArrow>
      <Block display='flex' fAlign='center' fDirection='row'>
        {
          pItems.map((_x, index) =>
            index < boundaryCount ||
            index > count - boundaryCount - 1 ||
            index == currentPage - 1 ||
            (index >= currentPage - siblingCount - 1 && index <= currentPage + siblingCount - 1) ?
            <PaginationButton
              key={index}
              onClick={() => {
                setPage(index + 1);
                if (onChangeHandle) {
                  onChangeHandle(index + 1);
                }
              }}
              className={index + 1 == currentPage ? 'active' : ''}
            >
              <Typography variant='pagination' style={{ color: PaginationColor }}>{index + 1}</Typography>
            </PaginationButton>
            :
            (index == boundaryCount || index == count - boundaryCount - 1) && 
            <PaginationButton key={index} disabled>
              <Typography variant='pagination' style={{ color: PaginationColor }}>...</Typography>
            </PaginationButton>
          )
        }
      </Block>
      <PaginationArrow onClick={() => {
          if (currentPage == count) return;
          const page = currentPage + 1;
          setPage(page);
          if (onChangeHandle) {
            onChangeHandle(page);
          }
        }}
      >
        <Icon icon='outlined_chevronRight' size={1} className='nextIcon' />
      </PaginationArrow>
    </Block>
  );
};

export default Pagination;
