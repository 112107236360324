import { transitionsDelay } from '../../styles/BasicStyles';
import { breakpoints } from '../../styles/Breakpoints';
import styled, { css } from 'styled-components';

export const PaginationArrow = styled.div`
  cursor: pointer;
  padding: 0.625rem 0.75rem;

  & svg path[fill] {
    transition: fill ${transitionsDelay} linear;
    fill: ${({ theme }) => theme.colors.paginationArrows};
  }

  & svg path[stroke] {
    transition: stroke ${transitionsDelay} linear;
    stroke: ${({ theme }) => theme.colors.paginationArrows};
  }

  & .prevIcon {
    margin-right: 0.4rem;

    @media ${breakpoints.sm} {
      margin-right: 0rem;
      height: 1.25rem;
      align-items: center;
    }
  }

  & .nextIcon {
    margin-left: 0.4rem;

    @media ${breakpoints.sm} {
      margin-left: 0rem;
      height: 1.25rem;
      align-items: center;
    }
  }

  &:hover {
    & svg path[fill] {
      fill: ${({ theme }) => theme.colors.paginationArrowsHover};
    }

    & svg path[stroke] {
      stroke: ${({ theme }) => theme.colors.paginationArrowsHover};
    }
  }
`;

export const PaginationButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0.625rem 1rem;
  background-color: transparent;
  transition: background-color ${transitionsDelay} linear;
  margin: 0rem 0.125rem;

  ${({ disabled }) => !disabled && 
    css`
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.colors.paginationHoverColor};
      }

      &.active {
        background-color: ${({ theme }) => theme.colors.paginationActiveColor};
      }
    `
  }
`;