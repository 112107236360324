import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { PlaceholderColor } from '../../styles/Colors';
import { Review } from '../../models/Review';
import Typography from '../../components/typography';
import Accordion from '../../components/accordion';
import Title from '../reviews/components/Title';
import Content from '../reviews/components/Content';

interface Params {
  review?: Review;
  onConfirmStatusToogle: (id: string, status: string) => void;
  isFightDetailPage?: boolean;
}

const Consistency: FC<Params> = ({ review, onConfirmStatusToogle, isFightDetailPage=  false }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography as='div' mb={1.125} variant='sidebar-group' style={{ color: PlaceholderColor }}>{t('CONSISTENCY').toUpperCase()}</Typography>
      {
        !!review?._id &&
        <Accordion 
          title={<Title row={review} onConfirmStatusToogle={onConfirmStatusToogle} isFightDetailPage={isFightDetailPage} />}
          content={<Content row={review} />}
          titleClick={false}
        />
      }
    </>
  );
};

export default Consistency;
