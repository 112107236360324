import { transitionsDelay, Block } from '../../styles/BasicStyles';
import styled, { css } from 'styled-components';

export const LinkComponent = styled(Block)<{ underline:boolean, width?:string, height?:string }>`
  cursor: pointer !important;
  line-height: 0;

  ${props => props.width && css`width: ${props.width};`}
  ${props => props.height && css`height: ${props.height};`}

  ${props => props.underline ? css`
    text-decoration: underline solid Currentcolor;
    transition: text-decoration ${transitionsDelay} linear;

    &:hover {
      text-decoration: underline solid transparent;
    }
  `: css`
    text-decoration: none;
    transition: opacity ${transitionsDelay} linear;

    &:hover {
      opacity: 0.6;
    }
  `}
`;