import dayjs from 'dayjs';

export const JoinDateAndHour = (date: string | Date, time: string | Date) => {
  const day = dayjs(date).format('YYYY-MM-DD');
  const hour = dayjs(time).format('HH:mm:ss');
  return dayjs(`${day} ${hour}`);
};

export const MillisecondsToTime = (value: number, showHours = true) => {
  const seconds = Math.floor((value / 1000) % 60),
    minutes = Math.floor((value / (1000 * 60)) % 60),
    hours = Math.floor((value / (1000 * 60 * 60)) % 24);

  let res = '';
  if(showHours && hours > 0) res = `${hours.toString().padStart(2, '0')}:`;
  res = `${res}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return res;
};

export const MillisecondsToDate = (value: number, forceHour = false) => {
  if(forceHour) return dayjs.utc(value).format('YYYY-MM-DD 00:mm:ss');
  return dayjs.utc(value).format('YYYY-MM-DD HH:mm:ss');
};

export const TimeToMilliseconds = (date: Date | string) => {
  const newDate = new Date(date);
  const hours = newDate.getHours(), minutes = newDate.getMinutes(), seconds = newDate.getSeconds();
  return (((hours * 60 * 60) + (minutes * 60) + seconds) * 1000);
}
