/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { ReactNode, FC, useState } from 'react';
import { Fight, Deduction, Pause } from '../../models/Fight';
import { Box, Block } from '../../styles/BasicStyles';
import { FocusColor, MenuBgColor, PlaceholderColor, BorderColor, BlueFighter, RedFighter, DisabledColor, LabelColor, G500 } from '../../styles/Colors';
import { GetFightDecision } from '../../utils/fights';
import { MillisecondsToDate, MillisecondsToTime } from '../../utils/dates';
import { AnyObject } from '../../models/Generic';
import { GetEditFightWinnerInitialValues } from '../../utils/modalValues';
import Typography from '../../components/typography';
import Label from '../../components/label';
import Divider from '../../components/divider';
import Link from '../../components/link';
import DeductionItem from './DeductionItem';
import Icon from '../../components/icon';
import EditWinnerModal from './modals/EditWinner';
import EditDeductionsModal from './modals/EditDeductions';
import EditPausesModal from './modals/EditPauses';
import PauseItem from './PauseItem';

interface CardParams {
  fight?: Fight;
  reloadInfo: () => void;
}

interface ContentParams {
  title: string;
  children: ReactNode;
  noContentPadding?: boolean;
  editInfo?: () => void;
  hasNotes: boolean;
  hasViewMore?: boolean;
  hideEmptySpace?: boolean;
  order: any;
}

const CardContent: FC<ContentParams> = ({ 
  title, 
  children, 
  noContentPadding = false, 
  editInfo, 
  hasNotes = false, 
  hasViewMore = false, 
  hideEmptySpace = false, 
  order 
}) =>  {
  const { t } = useTranslation();
  let width = {};
  if(hasNotes) width = { xl: '25%', md: '50%', xxs: '100%' };
  else width = { xl: '33.333%', xxs: '100%' };

  return (
    <Block display='flex' fDirection='column' w={width} padding='0 0.813' mt={0.75} order={order}>
      <Block bgColor={MenuBgColor} bRadius={0.5} style={{ border: `0.063rem solid ${BorderColor}`}} w='100%' h='100%' display='flex' fDirection='column'>
        <Box fAlign='center' fJustify='space-between' padding='0.563 1.25'>
          <Typography as='div' color='#ffffff'>{title}</Typography>
          {
            !!editInfo &&
            <Icon icon='outlined_pencil' hoverColor={FocusColor} title={t('EDIT')} onClick={editInfo} style={{ paddingLeft: '0.5rem' }} size={1.25} />
          }
        </Box>
        <Divider />
        <Block padding={noContentPadding ? 0 : '0.563 1.25' }>
          {children}
        </Block>
      </Block>
      {
        hasViewMore ?
        <Box fJustify='center' padding='0 0.813' pt={0.625}>
          <Link onClick={editInfo} underline style={{ color: FocusColor }}>
            <Typography fSize={1} lHeight={1.375} fWeight={500}>
              {t('VIEW_MORE')}
            </Typography>
          </Link>
        </Box>
        :
        <Box h={2.3} display={{ xl: 'block', xxs: hideEmptySpace ? 'none' : 'block' }} />
      }
    </Block>
  );
};

const Cards: FC<CardParams> = ({ fight, reloadInfo }) => {
  const { t, i18n: { language } } = useTranslation();
  const [editWinnerModal, setEditWinnerModal] = useState(false);
  const [editDeductionsModal, setEditDeductionsModal] = useState(false);
  const [editPausesModal, setEditPausesModal] = useState(false);

  const deductionsList: Deduction[] = [];
  fight?.record?.deductions.forEach((deduction: AnyObject) => {
    if(deduction.red > 0) {
      deductionsList.push({
        fighter: 'red',
        round: deduction.round,
        time: deduction.time,
        date: MillisecondsToDate(deduction.time || 0),
        points: deduction.red,
        reason: deduction.reason?._id,
        reasonName: deduction.reason?.name && deduction.reason.name[language],
        fighterName: fight?.red_fighter?.name
      });
    }
    if(deduction.blue > 0) {
      deductionsList.push({
        fighter: 'blue',
        round: deduction.round,
        time: deduction.time,
        date: MillisecondsToDate(deduction.time || 0),
        points: deduction.blue,
        reason: deduction.reason?._id,
        reasonName: deduction.reason?.name && deduction.reason.name[language],
        fighterName: fight?.blue_fighter?.name
      });
    }
  });

  const pausesList: Pause[] = [];
  fight?.record?.pauses.forEach((pause: AnyObject) => {
    pausesList.push({
      round: pause.round,
      time: pause.time,
      start: pause.start,
      startTime: MillisecondsToDate(pause.start || 0),
      duration: MillisecondsToDate(pause.time || 0),
      reason: pause.reason?._id,
      reasonName: pause.reason?.name && pause.reason.name[language]
    });
  });
  
  return (
    <>
      <Typography as='div' variant='sidebar-group' style={{ color: PlaceholderColor }}>{t('RESULTS').toUpperCase()}</Typography>
      <Block display='flex' margin='0 -0.813rem' style={{ flexFlow: 'row wrap' }}>
        <CardContent 
          title={t('FINAL_RESULT').toUpperCase()} 
          editInfo={!!fight?.result ? () => setEditWinnerModal(true) : undefined}
          hasNotes={!!fight?.record?.notes}
          order={1}
          hideEmptySpace
        >
          <Box fDirection='column'>
            {
              !!fight?.result?.winner &&
              <>
                <Box fAlign='center'>
                  <Typography variant='body-small' style={{ color: DisabledColor }} mr={0.688}>{t('WINNING_CORNER')}:</Typography>
                  <Label 
                    text={fight?.result?.winner === 'RED' ? t('RED') : fight?.result?.winner === 'BLUE' ? t('BLUE') : t('DRAW')} 
                    bgColor={fight?.result?.winner === 'RED' ? RedFighter : fight?.result?.winner === 'BLUE' ? BlueFighter : G500} 
                    fontColor='#ffffff' 
                    bRadius='0.188rem'
                    fSize={0.875}
                    lHeight={1.25}
                  />
                </Box>
                <Box fAlign='center' mt={0.625}>
                  <Typography variant='body-small' style={{ color: DisabledColor }} mr={0.688}>{t('TIME')}:</Typography>
                  <Typography variant='body-small' color='#ffffff'>
                    {fight?.result?.time > 0 ? MillisecondsToTime(fight?.result?.time - (fight.result?.round - 1) * fight.duration * 60000, false) : ''}
                  </Typography>
                </Box>
                <Box fAlign='center' mt={0.625}>
                  <Typography variant='body-small' style={{ color: DisabledColor }} mr={0.688}>{t('ROUND')}:</Typography>
                  <Typography variant='body-small' color='#ffffff' mr={1.25}>{fight?.result?.round}</Typography>
                  <Typography variant='body-small' style={{ color: DisabledColor }} mr={0.688}>{t('BY')}</Typography>
                  <Typography variant='body-small' color='#ffffff'>{GetFightDecision(t, fight?.result?.decision, fight?.result?.submission?.name[language])}</Typography>
                </Box>
              </>
            }
          </Box>
        </CardContent>
        <CardContent 
          title={t('POINT_DEDUCTION').toUpperCase()} 
          noContentPadding 
          editInfo={!!fight?.result ? () => setEditDeductionsModal(true) : undefined}
          hasNotes={!!fight?.record?.notes}
          hasViewMore={deductionsList.length > 2}
          order={{ xl: 2, xxs: 3 }}
        >
          {
            deductionsList.length > 0 && 
            <Box fDirection='column'>
              {
                deductionsList.slice(0, 2).map((deduction: Deduction, index: number) =>
                  <Block w='100%' key={index}>
                    <DeductionItem 
                      deduction={deduction} 
                      index={index}
                      fight={fight}
                      hideCounter
                    />
                  </Block>
                )
              }
            </Box>
          }
        </CardContent>
        <CardContent 
          title={t('PAUSES').toUpperCase()} 
          noContentPadding 
          editInfo={!!fight?.result ? () => setEditPausesModal(true) : undefined}
          hasNotes={!!fight?.record?.notes}
          hasViewMore={pausesList.length > 2}
          order={{ xl: 3, xxs: 4 }}
        >
          {
            pausesList.length > 0 && 
            <Box fDirection='column'>
              {
                pausesList.slice(0, 2).map((pause: Pause, index: number) =>
                  <Block w='100%' key={index}>
                    <PauseItem 
                      pause={pause} 
                      index={index}
                      hideCounter
                    />
                  </Block>
                )
              }
            </Box>
          }
        </CardContent>
        {
          !!fight?.record?.notes &&
          <CardContent 
            title={t('NOTES').toUpperCase()} 
            hasNotes
            order={{ xl: 4, xxs: 2 }} 
            hideEmptySpace
          >
            <Typography variant='body-small' style={{ color: LabelColor }}>{fight?.record?.notes}</Typography>
          </CardContent>
        }
      </Block>
      {
        !!fight?.result &&
        <>
          <EditWinnerModal
            opened={editWinnerModal}
            onClose={() => setEditWinnerModal(false)}
            initialValues={GetEditFightWinnerInitialValues(fight?.result)}
            fightID={fight?._id}
            fightDuration={fight?.duration}
            reloadInfo={reloadInfo}
          />
          <EditDeductionsModal
            opened={editDeductionsModal}
            onClose={() => setEditDeductionsModal(false)}
            initialValues={fight?.record?.deductions}
            fight={fight}
            reloadInfo={reloadInfo}
          />
          <EditPausesModal
            opened={editPausesModal}
            onClose={() => setEditPausesModal(false)}
            initialValues={fight?.record?.pauses}
            fight={fight}
            reloadInfo={reloadInfo}
          />
        </>
      }
    </>
  );
};

export default Cards;
