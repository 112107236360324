import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0;
  background-color: ${({ theme }) => theme.colors.bgColor};
`;

export const LogoImage = styled.img`
  cursor: pointer;
  position: absolute;
  top: 44px;
  left: 32px;
  width: 200px;
`;

export const Illustration = styled.img`
  max-width: 50vw;
  max-height: 40vh;
`;