/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { FC, useState, useEffect } from 'react';
import { Pause } from '../../models/Fight';
import { Box, Block } from '../../styles/BasicStyles';
import { FocusColor, MenuBgColor, G500, PlaceholderColor } from '../../styles/Colors';
import { AnyObject } from '../../models/Generic';
import { MillisecondsToTime, TimeToMilliseconds } from '../../utils/dates';
import { Field } from 'react-final-form';
import { Rounds } from '../../utils/rounds';
import { requiredField } from '../../hooks/use-validation/use-validation-schema';
import { showConfirm } from '../../hooks/show-notification/show-notification';
import Typography from '../../components/typography';
import Icon from '../../components/icon';
import SelectInput from '../../components/inputs/SelectInput';
import TimeInput from '../../components/inputs/TimeInput';

interface PauseParams {
  pause: Pause;
  index: number;
  reasonsList?: AnyObject[];
  editItem?: number | undefined;
  setEditItem?: (value?: number) => void;
  pausesList?: Pause[];
  setPausesList?: (values: Pause[]) => void;
  setFormPristine?: (value: boolean) => void;
  hideCounter?: boolean;
}

const PauseItem: FC<PauseParams> = ({ 
  pause, 
  index, 
  reasonsList, 
  editItem, 
  setEditItem, 
  pausesList = [], 
  setPausesList,
  setFormPristine,
  hideCounter = false
}) => {
  const { t, i18n: { language } } = useTranslation();
  const [round, setRound] = useState(pause.round || '');
  const [startTime, setStartTime] = useState(pause.startTime || undefined);
  const [duration, setDuration] = useState(pause.duration || undefined);
  const [reason, setReason] = useState(pause.reason || '');

  useEffect(() => {
    saveEditItem(index);
  }, [round, startTime, duration, reason]);

  const saveEditItem = (index: number) => {
    if(!!setFormPristine && !!setPausesList && round && startTime && duration) {
      const reasonName = reasonsList?.find(elem => elem._id === reason)?.name;

      const aux: Pause[] = [...pausesList];
      aux[index].round = round;
      aux[index].duration = duration;
      aux[index].startTime = startTime;
      aux[index].time = TimeToMilliseconds(duration);
      aux[index].start = TimeToMilliseconds(startTime);
      aux[index].reason = reason;
      aux[index].reasonName = reason && reasonName ? reasonName[language] : '';

      setPausesList(aux);
      setFormPristine(false);
    }
  };

  const removeItem = (index: number) => {
    if(!!setFormPristine && !!setPausesList) {
      showConfirm({
        title: t('CONFIRM_BEFORE_SAVE'),
        message: t('DELETE_DEDUCTION_MESSAGE'),
        onConfirm: () => {
          const aux: Pause[] = [...pausesList];
          aux.splice(index, 1);
          setPausesList(aux);
          setFormPristine(false);
        }
      });
    }
  };

  return (
    <>
      {
        editItem === index && !!setEditItem ?
        <Box fWrap='wrap' bgColor={G500} padding='0.5 0.75'>
          <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
            <Field name={`pauses[${index}].round`} validate={requiredField}>
              {(props) => 
                <SelectInput 
                  {...props} 
                  label={`${t('ROUND')} *`}
                  placeholder={t('CHOOSE_ROUND')}
                  data={Rounds}
                  afterChange={(value: any) => setRound(value)}
                />
              }
            </Field>
          </Box>
          <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
            <Field name={`pauses[${index}].startTime`} validate={requiredField}>
              {(props) => 
                <TimeInput 
                  {...props} 
                  label={`${t('WA_START')} *`} 
                  withSeconds
                  afterChange={(value: any) => setStartTime(value)}
                />
              }
            </Field>
          </Box>
          <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
            <Field name={`pauses[${index}].duration`} validate={requiredField}>
              {(props) => 
                <TimeInput 
                  {...props} 
                  label={`${t('DURATION')} *`} 
                  withSeconds
                  afterChange={(value: any) => setDuration(value)}
                />
              }
            </Field>
          </Box>
          <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
            <Field name={`pauses[${index}].reason`}>
              {(props) => 
                <SelectInput 
                  {...props} 
                  label={t('REASON')}
                  placeholder={t('CHOOSE_REASON')}
                  data={reasonsList || []}
                  intl
                  afterChange={(value: any) => setReason(value)}
                  clearable
                />
              }
            </Field>
          </Box>
          <Box 
            fJustify='flex-end' 
            w={{ xs: '66.666%', xxs: '100%' }} 
            padding='0 0.5' 
            mt={{ xs: 2.375, xxs: 0.25 }} 
            mb={{ xs: 0, xxs: 1 }}
          >
            <Icon 
              icon='outlined_checkCircle' 
              hoverColor={FocusColor} 
              title={t('SAVE_CLOSE')} 
              onClick={() => setEditItem(undefined)} 
              size={1.5} 
            />
          </Box>
        </Box>
        :
        <Box 
          fAlign='center' 
          fWrap='wrap' 
          bgColor={index % 2 === 0 ? '#1F2025' : MenuBgColor} 
          padding='0.5 0.75'
        >
          <Box 
            fAlign='center' 
            w={!!setEditItem ? { xs: '90%', xxs: '100%' } : '100%'} 
            order={{ xs: 1, xxs: 2 }}
          >
            {
              !hideCounter &&
              <Box w='5%'>
                <Typography 
                  variant='body-small' 
                  fWeight={500} 
                  color='#ffffff'
                >
                  {index + 1}.
                </Typography>
              </Box>
            }
            <Box w={hideCounter ? '33.333%' : '31%'} padding='0 0.5'>
              <Typography 
                variant='body-small' 
                color='#ffffff'
              >
                {`${t('ROUND')} ${pause.round}`}
              </Typography>
            </Box>
            <Box fDirection='column' w={hideCounter ? '33.333%' : '32%'} padding='0 0.5'>    
              <Typography 
                variant='body-small' 
                color='#ffffff'
              >
                <span style={{ color: PlaceholderColor }}>{t('WA_START')}:</span> {MillisecondsToTime(pause.start)}
              </Typography>
              <Typography 
                variant='body-small' 
                color='#ffffff'
              >
                <span style={{ color: PlaceholderColor }}>{t('DURATION')}:</span> {MillisecondsToTime(pause.time)}
              </Typography>
            </Box>
            <Block w={hideCounter ? '33.333%' : '32%'} padding='0 0.5'>
              <Typography 
                variant='body-small' 
                color='#ffffff'
                wBreak='break-word'
              >
                {pause.reasonName}
              </Typography>
            </Block>
          </Box>
          {
            !!setEditItem &&
            <Box 
              w={{ xs: '10%', xxs: '100%' }} 
              padding='0 0.5' 
              fAlign='center' 
              order={{ xs: 2, xxs: 1 }}
              mb={{ xs: 0, xxs: 0.25 }}
              fJustify='flex-end'
            >
              <Icon 
                icon='outlined_pencil' 
                hoverColor={FocusColor} 
                title={t('EDIT')} 
                onClick={() => setEditItem(index)} 
                style={{ marginRight: '0.25rem' }} 
              />
              <Icon 
                icon='outlined_x' 
                hoverColor={FocusColor} 
                title={t('REMOVE')} 
                onClick={() => removeItem(index)} 
                size={1.25} 
              />
            </Box>
          }
        </Box>
      }
    </>
  );
};

export default PauseItem;
