import { Input } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { CropperRef, Cropper } from 'react-advanced-cropper';
import { FieldRenderProps } from 'react-final-form';
import { AnyObject, GenericInput, InputSize } from './Generic';
import { useState, useRef } from 'react';
import { CreatePreviewFile, ConvertBase64ToBlob } from '../../utils/files/Conversions';
import { ImageWrapper, InputImageWrapper, CropperWrapper, DropzoneWrapper, DropzoneButton } from './Styles';
import { DangerColor, FocusColor, LabelColor } from '../../styles/Colors';
import { Box, Block } from '../../styles/BasicStyles';
import { showError } from '../../hooks/show-notification/show-notification';
import { useTranslation } from 'react-i18next';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../modal';
import React from 'react';
import Icon from '../icon';
import Button from '../button';
import Typography from '../typography';

export interface ImageInputProps extends Omit<FieldRenderProps<AnyObject>, 'input'> {
  afterChange?: (value: Date) => void;
  input: GenericInput<AnyObject>;
  label?: React.ReactNode;
  description?: React.ReactNode;
  ratio?: number;
  maxSize?: number;
  confirmText?: string;
  errorTitle?: string;
  errorMessage?: string;
  placeholder?: string;
  size?: InputSize;
  showDropzoneArea?: boolean;
  borderColor?: string;
  disabled?: boolean;
}

export const ImageInput: React.FC<ImageInputProps> = ({
  input,
  meta,
  label,
  description,
  ratio,
  maxSize = 8000000,
  confirmText,
  errorTitle,
  errorMessage,
  placeholder,
  size = 'md',
  showDropzoneArea = false,
  borderColor,
  disabled = false
}) => {
  const { t } = useTranslation();
  const [image, setImage] = useState<string>();
  const cropperRef = useRef<CropperRef>(null);

  const hasError = meta?.invalid && meta?.submitFailed;

  const handleAccept = (files: File[]) => {
    if (files[0]) {
      const img = CreatePreviewFile(files[0]);
      setImage(img.preview);
    }
  };

  const handleReject = () => {
    showError({
      title: errorTitle || t('INVALID_IMAGE'),
      message: errorMessage || t('INVALID_IMAGE_MESSAGE')
    });
  };

  const onCrop = () => {
    if (cropperRef.current) {
      const base64 = cropperRef.current.getCanvas()?.toDataURL();
      if (base64) {
        const blob = ConvertBase64ToBlob(base64);
        const img = CreatePreviewFile(blob);
        setImage(undefined);
        input.onChange(img);
      }
    }
  };

  const deleteImage = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setImage(undefined);
    input.onChange(null);
  }

  const renderImage = (
    <>
      {
        input.value &&
        <Box w='auto' fAlign='center'>
          <InputImageWrapper big={showDropzoneArea} hasError={hasError}>
            <img src={input.value.preview || input.value.url} alt='image' />
          </InputImageWrapper>
          {
            !disabled &&
            <Block display='flex' fAlign='center' fWrap='wrap' mb='-0.75rem'>
              <Button variant={FocusColor} text={t('CHANGE_PICTURE')} mr={0.75} mb={0.75} />
              <Button variant='secondary' text={t('DELETE')} mb={0.75} onClick={deleteImage} />
            </Block>
          }
        </Box>
      }
    </>
  );

  const renderDropzone = (
    <>
      {
        showDropzoneArea ?
        (
          input.value ?
          <DropzoneWrapper borderColor={borderColor} hasValue={true} error={hasError}>
            {renderImage}
          </DropzoneWrapper>
          :
          <DropzoneWrapper borderColor={borderColor} error={hasError}>
            <Box fDirection='column' fAlign='center' fJustify='center' position='relative'>
              <Icon icon="bold_cloudUpload" size={2} color={hasError ? DangerColor : FocusColor} />
              <Typography variant='dropdown-menu' lHeight={1.188} style={{ color: hasError ? DangerColor : LabelColor }} mt={1.375}>{t('DROPZONE_TITLE')}</Typography>
              <Typography variant='dropdown-menu-small' style={{ color: hasError ? DangerColor : LabelColor }} mt={0.5}>{placeholder || t('DROPZONE_PLACEHOLDER')}</Typography>
              <DropzoneButton className='dropzoneButton' mt={0.5} bRadius={0.25} padding='0.375 0.75'>
                <Typography variant='dropdown-menu-small' fWeight={500} color={FocusColor}>{t('UPLOAD_PICTURE')}</Typography>
              </DropzoneButton>
            </Box>
          </DropzoneWrapper>
        )
        :
        input.value ?
        renderImage
        :
        <Box w='auto' fAlign='center'>
          <InputImageWrapper big={false} hasError={hasError}>
            <Icon icon="outlined_photograph" size={1.125} color={hasError ? DangerColor : FocusColor} />
          </InputImageWrapper>
          <Button variant={FocusColor} text={t('UPLOAD_PICTURE')} />
        </Box>
      }
    </>
  );

  return (
    <ImageWrapper size={size} showDropzoneArea={showDropzoneArea}>
      <Input.Wrapper label={label} description={description}>
        <Dropzone
          onDrop={handleAccept}
          onReject={handleReject}
          maxSize={maxSize}
          accept={[MIME_TYPES.jpeg, MIME_TYPES.png]}
          multiple={false}
          disabled={disabled}
          styles={{
            root: {
              border: 'none',
              padding: 0
            }
          }}
        >
          {renderDropzone}
        </Dropzone>
        <Modal
          opened={!!image}
          onClose={() => setImage(undefined)}
          title={t('CROP_IMAGE')}
        >
          <ModalOverflowContent>
            <ModalContent>
              <CropperWrapper>
                <Cropper
                  stencilProps={{
                    aspectRatio: 100 / (100 * (ratio || 1)),
                    movable: true,
                    resizable: true
                  }}
                  className='cropper'
                  ref={cropperRef}
                  src={image}
                />
              </CropperWrapper>
            </ModalContent>
          </ModalOverflowContent>
          <ModalActions>
            <Button text={confirmText || t('CONFIRM')} onClick={onCrop} />
          </ModalActions>
        </Modal>
      </Input.Wrapper>
    </ImageWrapper>
  );
};

export default ImageInput;
