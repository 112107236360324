import { SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER } from '../../models/User';
import LoginPage from '../../pages/auth/components/LoginPage';
import RecoverPasswordPage from '../../pages/auth/components/RecoverPasswordPage';
import ResetPasswordPage from '../../pages/auth/components/ResetPasswordPage';
import ValidateAccountPage from '../../pages/auth/components/ValidateAccount';
import RequestValidationPage from '../../pages/auth/components/RequestValidation';
import DashboardPage from '../../pages/dashboard';
import ProfilePage from '../../pages/profile';
import PromoterProfilePage from '../../pages/members/promoters/detail';

const BaseRoutes = [
  {
    path: '/',
    component: <DashboardPage />,
    isAuthenticated: true
  },
  {
    path: '/my-profile',
    component: <ProfilePage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/promoter-profile',
    component: <PromoterProfilePage />,
    isAuthenticated: true,
    roles: [PROMOTER]
  },
  {
    path: '/login',
    component: <LoginPage />,
    isAuthenticated: false,
    forceValidation: true
  },
  {
    path: '/recover-password',
    component: <RecoverPasswordPage />,
    isAuthenticated: false,
    forceValidation: true
  },
  {
    path: '/reset-password/:token',
    component: <ResetPasswordPage />,
    isAuthenticated: false,
    forceValidation: true
  },
  {
    path: '/validate-account/:id/:token',
    component: <ValidateAccountPage />,
    isAuthenticated: false
  },
  {
    path: '/validate-account',
    component: <RequestValidationPage />,
    isAuthenticated: false
  }
];

export default BaseRoutes;
