import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../hooks/use-validation/use-validation-schema';
import { User } from '../../../../models/User';
import { BasicForm, Block } from '../../../../styles/BasicStyles';
import { showSuccess, showError } from '../../../../hooks/show-notification/show-notification';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../components/button';
import toFormData from '../../../../utils/formdata/ToFormData';
import PasswordInput from '../../../../components/inputs/PasswordInput';
import PasswordVerificationInput from '../../../../components/inputs/PasswordVerificationInput';

interface Params {
  opened: boolean;
  onClose: () => void;
}

const { Form } = withTypes<User>();

const PasswordModal: React.FC<Params> = ({ opened, onClose }) => {
  const { t } = useTranslation();
  const { put } = useFetch('/users/change-password');

  const onSubmit = async (values: User) => {
    if(values.password !== values.confirm_password) {
      return showError({
        title: t('ERROR'),
        message: t('PASSWORDS_DONT_MATCH')
      });
    }

    const payload = toFormData(values);

    const { success } = await put(payload);

    if(success) {
      onClose();

      showSuccess({
        title: t('SUCCESS'),
        message: t('PROFILE_UPDATED')
      });
    }
  };

  return (
    <Modal size={27.5} opened={opened} onClose={onClose} title={t('CHANGE_PASSWORD')}>
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema(yup.object({
          current: yup.string().required().min(6),
          password: yup.string().required().min(6),
          confirm_password: yup.string().required().min(6) 
        }))}
      >
        {({ handleSubmit, submitting }) => (
          <BasicForm onSubmit={handleSubmit}>
            <ModalOverflowContent>
              <ModalContent style={{ paddingTop: '2.5rem', paddingBottom: '2.5rem' }}>
                <Grid gutter="xl">
                  <Grid.Col span={12}>
                    <Field name="current">
                      {(props) => (
                        <PasswordInput
                          {...props}
                          label={t('CURRENT_PASSWORD')}
                          placeholder={t('ENTER_CURRENT_PASSWORD')}
                        />
                      )}
                    </Field>
                    <Block w='100%' mt={1.5}>
                      <Field name="password">
                        {(props) => (
                          <PasswordVerificationInput
                            {...props}
                            label={t('PASSWORD')}
                            placeholder={t('ENTER_PASSWORD')}
                          />
                        )}
                      </Field>
                    </Block>
                    <Block w='100%' mt={1.5}>
                      <Field name="confirm_password">
                        {(props) => (
                          <PasswordInput
                            {...props}
                            label={t('CONFIRM_PASSWORD')}
                            placeholder={t('CONFIRM_PASSWORD')}
                          />
                        )}
                      </Field>
                    </Block>
                  </Grid.Col>
                </Grid>
              </ModalContent>
            </ModalOverflowContent>
            <ModalActions>
              <Button
                text={t('CANCEL')}
                variant="secondary"
                onClick={onClose}
              />
              <Button text={t('SAVE')} type="submit" loading={submitting} />
            </ModalActions>
          </BasicForm>
        )}
      </Form>
    </Modal>
  );
};

export default PasswordModal;
