/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fight } from '../../../../../models/Fight';
import { Event } from '../../../../../models/Event';
import { useTranslation } from 'react-i18next';
import { FC, useState, useEffect, useContext } from 'react';
import { Block } from '../../../../../styles/BasicStyles';
import { LabelColor } from '../../../../../styles/Colors';
import { capitalizeString } from '../../../../../utils/strings';
import { GetFightDecision } from '../../../../../utils/fights';
import { User, ATHLETE, PROMOTER } from '../../../../../models/User';
import { useNavigate } from 'react-router-dom';
import { AnyObject } from '../../../../../models/Generic';
import { UserContext } from '../../../../../Context';
import useFetch from 'use-http';
import DefaultListPage from '../../../../../components/page/DefaultListPage';
import Icon from '../../../../../components/icon';
import Typography from '../../../../../components/typography';
import DropdownMenu, { ActionProps } from '../../../../../components/dropdownMenu';
import BaseTable from '../../../../../components/table';
import Filters from './Filters';
import Label from '../../../../../components/label';
import dayjs from 'dayjs';
import UserCard from '../../../../../components/userCard';
import Consistency from '../../../../../components/consistency/index';

const FightsList = DefaultListPage<Fight>();
const FightsTable = BaseTable<Fight>();

interface Params {
  athlete?: User;
  type: string;
}

const FightsListComponent: FC<Params> = ({ athlete, type }) => {
  const [athletes, setAthletes] = useState<User[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const { t, i18n: { language } } = useTranslation();
  const { get } = useFetch(`/fights/${ATHLETE}/${athlete?._id}/${type}`);
  const athletesHooks = useFetch(`/members/${ATHLETE}/dropdown`);
  const eventsHooks = useFetch(`/events/dropdown`);
  const navigate = useNavigate();
  const title = type === 'upcoming' ? 'UPCOMING_FIGHTS' : type === 'past' ? 'PAST_FIGHTS' : '';
  const { user } = useContext(UserContext);

  useEffect(() => {
    const init = async () => {
      const { data } = await athletesHooks.get();

      // Exclude current Athlete from the list for the Filters
      let list = data || [];
      if(list.length > 0) {
        list = list.filter((elem: User) => elem._id !== athlete?._id);
      }
      setAthletes(list);


      const eventsResult = await eventsHooks.get();
      setEvents(eventsResult?.data || []);
    };

    if(athlete?._id) init();
  }, [athlete?._id]);

  const renderOpponent = (fight: Fight) => {
    let fighter: User | AnyObject = {};
    if(fight.blue_fighter?._id === athlete?._id) fighter = fight.red_fighter;
    else if(fight.red_fighter?._id === athlete?._id) fighter = fight.blue_fighter;

    return (
      <UserCard user={fighter} fight={fight} />
    );
  };

  const renderFightWinner = (fight: Fight) => {
    let result = '';

    if(
      (fight.blue_fighter?._id === athlete?._id && fight.result?.winner === 'BLUE') ||
      (fight.red_fighter?._id === athlete?._id && fight.result?.winner === 'RED')
    ) result = 'WIN';
    else if(
      (fight.blue_fighter?._id === athlete?._id && fight.result?.winner === 'RED') ||
      (fight.red_fighter?._id === athlete?._id && fight.result?.winner === 'BLUE')
    ) result = 'LOSS';
    else if(!!fight.result?.winner) result = 'DRAW';

    return <Typography variant='table-header'>{t(result)}</Typography>;
  };

  return (
    <Block w='100%' mt={1.5}>
      <FightsList
        translate={t}
        request={{
          get,
          reloadOnChange: [type]
        }}
        filters={({ filters, handleChange }) => 
          <Filters 
            filters={filters} 
            handleChange={handleChange}
            athletes={athletes}
            events={events}
          />
        }
        tableTitle={`${t(title).toUpperCase()}`}
        countLabel={`${capitalizeString(t('FIGHTS'))}`}
      >
        {({ rows, loading, pagination, handleNewSort }) => {
          const columns = [ 
            { 
              title: t('EVENT'), 
              dataIndex: 'event',
              sortable: true,
              width: '12%',
              render: (event: Event) => <Typography variant='table-header'>{event?.name}</Typography>
            },
            { 
              title: t('TYPE'),
              dataIndex: 'tournament', 
              width: '13%',
              render: (value: string, fight: Fight) => 
                <Label 
                  text={!!value ? <Block tAlign='center'><div>{t('TOURNAMENT')}</div><div>{fight.tournament?.name}</div></Block> : t('SINGLE_FIGHT')}
                  bRadius='0.5rem'
                  fontColor={LabelColor} 
                />
            },
            { 
              title: t('OPPONENT'),
              dataIndex: 'blue_fighter', 
              render: (_: string, fight: Fight) => renderOpponent(fight)
            },
            { 
              title: t('DATE'), 
              dataIndex: 'start_datetime',
              sortable: true,
              width: '15%',
              render: (value: Date) => <Typography variant='table-header'>{dayjs(value).format('DD MMM YYYY, HH:mm')}</Typography>
            },
            { 
              title: t('MORE'),
              dataIndex: 'actions', 
              width: '5%',
              disableRowClick: true,
              render: (_: any, fight: Fight) => {
                const options: ActionProps[] = [
                  {
                    type: 'link',
                    label: t('VIEW_EVENT'),
                    onClick: () => navigate(`/events/${fight.event?._id}`)
                  },
                  {
                    type: 'divider'
                  },
                  {
                    type: 'link',
                    label: t('VIEW_FIGHT'),
                    onClick: () => navigate(`/fights/${fight._id}`)
                  }
                ];

                if(!!fight.tournament) {
                  options.push(
                    {
                      type: 'divider'
                    },
                    {
                      type: 'link',
                      label: t('VIEW_TOURNAMENT'),
                      onClick: () => navigate(`/tournaments/${fight.tournament?._id}`)
                    }
                  );
                }

                return (
                  <DropdownMenu 
                    render={<Icon icon='outlined_dotsHorizontal' color={LabelColor} />}
                    menuPosition='bottom-end'
                    actions={options} 
                  />
                );
              }
            }
          ];

          // If we're viewing past Fights, we need to add the Fight result and ending columns
          if(type === 'past') {
            columns.splice(4, 0, { 
              title: t('RESULT'),
              dataIndex: 'result',
              width: '10%',
              render: (_: any, fight: Fight) => <Typography variant='table-header'>{renderFightWinner(fight)}</Typography>
            });
            columns.splice(5, 0, { 
              title: t('FIGHT_ENDING'),
              dataIndex: 'result',
              width: '12%',
              render: (_: any, fight: Fight) => <Typography variant='table-header'>{GetFightDecision(t, fight.result?.decision, fight?.result?.submission?.name[language])}</Typography>
            });

            if(user?.type !== PROMOTER) {
              columns.splice(6, 0, { 
                title: t('CONSISTENCY'),
                dataIndex: 'result',
                width: '8%',
                render: (_: any, fight: Fight) => <Consistency fight={fight} />
              });
            }
          }

          return (
            <FightsTable
              columns={columns}
              rows={rows}
              loading={loading}
              defaultSort={pagination.sort}
              sortCallback={handleNewSort}
              tableStyles={{
                compact: true,
                alternateRowColor: true,
                noBorderRadius: true,
                noSideBorders: true
              }}
              onRowClick={(row) => navigate(`/fights/${row._id}`)}
            />
          );
        }}
      </FightsList>
    </Block>
  );
};

export default FightsListComponent;
