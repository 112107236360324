import { EmailTemplate } from '../../models/EmailTemplate';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { AnyObject } from '../../models/Generic';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import DefaultListPage from '../../components/page/DefaultListPage';
import TextInput from '../../components/inputs/TextInput';
import Icon from '../../components/icon';

const baseTemplateID = '63878111dc56c4ab26682fbe';
const EmailTemplatesListPage = DefaultListPage<EmailTemplate>();

const EmailTemplatesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    { title: t('NAME'), dataIndex: 'name', sortable: true },
    { title: t('TAG'), dataIndex: 'tag', sortable: true },
    {
      title: t('SUBJECT'),
      dataIndex: 'subject',
      sortable: true,
      render: (value: AnyObject) => <ActiveTranslation value={value} />
    }
  ];

  const { get } = useFetch('/email-templates');

  return (
    <EmailTemplatesListPage
      translate={t}
      request={{
        get,
        searchFields: ['name', 'subject']
      }}
      storage="EMAIL_TEMPLATES_FILTERS"
      header={{
        title: t('EMAIL_TEMPLATES'),
        actions: [
          {
            text: t('EDIT_HEADER_FOOTER'),
            icon: 'outlined_pencil',
            onClick: () => navigate(`/email-templates/${baseTemplateID}`)
          }
        ]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24} style={{ justifyContent: 'flex-end' }}>
          <Grid.Col sm={6} xs={12}>
            <TextInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              icon={<Icon icon="outlined_search" />}
              onSearch={(v: string | null) => handleChange('search', v)}
              placeholder={t('SEARCH_NAME_SUBJECT')}
              clearable
            />
          </Grid.Col>
        </Grid>
      )}
      countLabel={t('TEMPLATES').toLowerCase()}
      table={{
        columns,
        onRowClick: (row) => navigate(`/email-templates/${row._id}`)
      }}
    />
  );
};

export default EmailTemplatesPage;
