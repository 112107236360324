export const OfficialRoles = [
  { _id: 'JUDGE', name: 'JUDGE', bgColor: 'rgba(89, 214, 131, 0.2)', labelColor: '#59D683' },
  { _id: 'RECORD_KEEPER', name: 'RECORD_KEEPER', bgColor: 'rgba(65, 71, 220, 0.2)', labelColor: '#4147DC' },
  { _id: 'REFEREE', name: 'REFEREE', bgColor: 'rgba(163, 0, 163, 0.2)', labelColor: '#A300A3' },
  { _id: 'COMMISSIONER', name: 'COMMISSIONER', bgColor: 'rgba(245, 0, 0, 0.2)', labelColor: '#F50000' }
];

export const GetOfficialRole = (value: string | undefined) => {
  const found = OfficialRoles.find(elem => elem._id === value);

  return found?.name || '';
};
