import { FC, useContext } from 'react';
import { Block, Box } from '../../styles/BasicStyles';
import { useTranslation } from 'react-i18next';
import { MenuBgColor, PlaceholderColor } from '../../styles/Colors';
import { DashboardInfo } from '../../models/Dashboard';
import { UserContext } from '../../Context';
import { PROMOTER } from '../../models/User';
import Icon, { IconTypes } from '../../components/icon';
import Typography from '../../components/typography';

interface Params {
  info: DashboardInfo;
}

interface SmallCardParams {
  title: string;
  value: string | number;
  icon: IconTypes;
  iconColor: string;
  iconSize: number | number[];
}

const SmallCard: FC<SmallCardParams> = ({ title, value, icon, iconColor, iconSize }) => {
  return (
    <Block display='flex' padding={{ xs: '0 0.5', xxs: 0 }} w={{ lg: '25%', xs: '50%', xxs: '100%' }} mb={1}>
      <Box bRadius={0.5} style={{ backgroundColor: MenuBgColor }} padding='0.75 1.563'>
        <Box fAlign='center'>
          <Icon icon={icon} size={iconSize} color={iconColor} />
          <Block display='flex' fDirection='column' pl={1.5}>
            <Typography fSize={1.25} lHeight={1.25} fWeight={600} color='#ffffff' pb={0.375}>{value}</Typography>
            <Typography fSize={0.875} lHeight={1} fWeight={500} style={{ color: PlaceholderColor }}>{title}</Typography>
          </Block>
        </Box>
      </Box>
    </Block>
  );
};

const SmallCards: FC<Params> = ({ info }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <>
      <Typography as='div' variant='sidebar-group' padding={{ xs: '0 0.5', xxs: 0 }} mb={0.875} style={{ color: PlaceholderColor }}>{t('CURRENT').toUpperCase()}</Typography>
      <Block display='flex' style={{ flexFlow: 'row wrap' }} w='100%' mb={2.75}>
        {
          user?.type !== PROMOTER &&
          <>
            <SmallCard 
              title={t('SCORING_CONSISTENCY')}
              value={`${info?.smallCards?.consistency?.toFixed(2) || 0}%`}
              icon='outlined_refresh'
              iconColor='rgba(89, 214, 131, 0.5)'
              iconSize={1.5}
            />
            <SmallCard  
              title={t('NEEDING_REVIEW')}
              value={info?.smallCards?.reviews || 0}
              icon='outlined_exclamationCircle'
              iconColor='rgba(235, 233, 81, 0.5)'
              iconSize={1.5}
            />
          </>
        }
        <SmallCard 
          title={t('EVENTS_THIS_MONTH')}
          value={info?.smallCards?.events || 0}
          icon='outlined_calendar'
          iconColor='rgba(0, 154, 225, 0.5)'
          iconSize={1.5}
        />
        {
          user?.type !== PROMOTER &&
          <SmallCard 
            title={t('TOTAL_MEMBERS_VALIDATED')}
            value={info?.smallCards?.members || 0}
            icon='outlined_users'
            iconColor='rgba(130, 138, 237, 0.6)'
            iconSize={1.5}
          />
        }
      </Block>
    </>
  );
};

export default SmallCards;
