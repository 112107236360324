import { FC, useContext } from 'react';
import { Block, Box } from '../../styles/BasicStyles';
import { useTranslation } from 'react-i18next';
import { LabelColor, SubMenuColor } from '../../styles/Colors';
import { DashboardInfo } from '../../models/Dashboard';
import { UserContext } from '../../Context';
import { PROMOTER } from '../../models/User';
import Icon, { IconTypes } from '../../components/icon';
import Typography from '../../components/typography';
import Link from '../../components/link';

interface Params {
  info: DashboardInfo;
}

interface Item {
  title: string;
  value: number;
  link: string;
}

interface CardParams {
  title: string;
  icon: IconTypes;
  iconColor: string;
  iconSize: number | number[];
  bgColor: string;
  items: Item[];
}

const Card: FC<CardParams> = ({ title, icon, iconColor, iconSize, bgColor, items }) => {
  return (
    <Block display='flex' padding={{ xs: '0 0.5', xxs: 0 }} w={{ lg: '25%', xs: '50%', xxs: '100%' }} minH={10} mb={1}>
      <Box fDirection='column' fJustify='space-between' bRadius={0.5} style={{ backgroundColor: bgColor }} padding='0.938 1.25' pt={0.25}>
        <Box fAlign='center' fJustify='space-between'>
          <Typography fSize={1.25} lHeight={1.25} fWeight={500} style={{ color: LabelColor }}>{title}</Typography>
          <Icon icon={icon} size={iconSize} color={iconColor} />
        </Box>
        {
          items.length > 0 &&
          <Box fDirection='column'>
            {
              items.map((elem: Item, index: number) =>
                <Link key={index} mt={index > 0 ? 0.25 : 0} href={elem.link}>
                  <Typography variant='dropdown-menu' lHeight={1.188} style={{ color: SubMenuColor }}>
                    {`${elem.value} ${elem.title}`}
                  </Typography>  
                </Link>
              )
            }
          </Box>
        }
      </Box>
    </Block>
  );
};

const Cards: FC<Params> = ({ info }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <Block display='flex' style={{ flexFlow: 'row wrap' }} w='100%' mb={2.75}>
      <Card
        title={t('EVENTS')}
        icon='bold_bubbleChart'
        iconColor='#ED6A9F'
        iconSize={3}
        bgColor='rgba(237, 106, 159, 0.15)'
        items={[
          { title: t('EVENT_DRAFTS'), value: info?.cards?.events?.pending || 0, link: '/events/draft' },
          { title: t('UPCOMING_EVENTS'), value: info?.cards?.events?.upcoming || 0, link: '/events/upcoming' },
          { title: t('PAST_EVENTS'), value: info?.cards?.events?.past || 0, link: '/events/past' }
        ]}
      />
      {
        user?.type === PROMOTER ?
        <Card
          title={t('ATHLETES')}
          icon='bold_users'
          iconColor='#3DCDB3'
          iconSize={3}
          bgColor='rgba(61, 205, 179, 0.15)'
          items={[
            { title: t('REGISTERED_ATHLETES'), value: info?.cards?.athletes?.registered || 0, link: '/members/athlete' }
          ]}
        />
        :
        <>
          <Card
            title={t('OFFICIALS')}
            icon='bold_users'
            iconColor='#828AED'
            iconSize={3}
            bgColor='rgba(130, 138, 237, 0.15)'
            items={[
              { title: t('DRAFT_OFFICIALS'), value: info?.cards?.officials?.draft || 0, link: '/members/draft' },
              { title: t('PENDING_OFFICIALS'), value: info?.cards?.officials?.pending || 0, link: '/members/pending' },
              { title: t('REGISTERED_OFFICIALS'), value: info?.cards?.officials?.registered || 0, link: '/members/official' }
            ]}
          />
          <Card
            title={t('COACHES')}
            icon='bold_users'
            iconColor='#4FC2F4'
            iconSize={3}
            bgColor='rgba(79, 194, 244, 0.15)'
            items={[
              { title: t('DRAFT_COACHES'), value: info?.cards?.coaches?.draft || 0, link: '/members/draft' },
              { title: t('PENDING_COACHES'), value: info?.cards?.coaches?.pending || 0, link: '/members/pending' },
              { title: t('REGISTERED_COACHES'), value: info?.cards?.coaches?.registered || 0, link: '/members/coach' }
            ]}
          />
          <Card
            title={t('ATHLETES')}
            icon='bold_users'
            iconColor='#3DCDB3'
            iconSize={3}
            bgColor='rgba(61, 205, 179, 0.15)'
            items={[
              { title: t('DRAFT_ATHLETES'), value: info?.cards?.athletes?.draft || 0, link: '/members/draft' },
              { title: t('PENDING_ATHLETES'), value: info?.cards?.athletes?.pending || 0, link: '/members/pending' },
              { title: t('REGISTERED_ATHLETES'), value: info?.cards?.athletes?.registered || 0, link: '/members/athlete' }
            ]}
          />
        </>
      }
    </Block>
  );
};

export default Cards;
