import { Event } from '../../../../models/Event';
import { Box, Block, Image } from '../../../../styles/BasicStyles';
import { LabelColor, PlaceholderColor } from '../../../../styles/Colors';
import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalizeString } from '../../../../utils/strings';
import { GetEventConsistency } from '../../../../utils/events';
import { PROMOTER } from '../../../../models/User';
import { UserContext } from '../../../../Context';
import Icon from '../../../../components/icon';
import Typography from '../../../../components/typography';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import Consistency from '../../../../components/consistency';

type TitleProps = {
  row: Event,
  renderActions: (row: Event) => void,
  onConfirmStatusToogle: (id: string, status: string) => void
};

const EventTitle: FC<TitleProps> = ({ row, renderActions }) => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const enterDetailPage = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/events/${row._id}`);
  };

  const renderDesktopTitle = () => {
    return (
      <Box fAlign='center' display={{ sm: 'flex', xxs: 'none' }}>
        <Box padding='0 0.5 0 0' w='35%' fAlign='center' onClick={(e: React.SyntheticEvent) => enterDetailPage(e)}>
          <Block bgColor='#33353E' bRadius={0.25} display='flex' fAlign='center' fJustify='center' w={3} h={3} mr={0.75}>
            {
              row.photo?.url ?
              <Image src={row.photo.url} alt='event logo' w={3} h={3} />
              :
              <Icon icon='outlined_calendar' size={1.125} color='rgba(255, 255, 255, 0.1)' />
            }
          </Block>
          <Block>
            <Typography as='div' variant='dropdown-menu' style={{ color: LabelColor }}>{row.name}</Typography>
            {!!row.promoter && <Typography as='div' variant='body-small' style={{ color: PlaceholderColor }}>{row.promoter?.name}</Typography>}
          </Block>
        </Box>
        <Block padding='0 0.5' w='20%' onClick={(e: React.SyntheticEvent) => enterDetailPage(e)}>
          <Typography as='div' variant='dropdown-menu-small' style={{ color: LabelColor }}>
            {capitalizeString(dayjs(row.start_datetime).locale(language).format('dddd, DD MMM, YYYY HH:mm'))}
          </Typography>
        </Block>
        <Block padding='0 0.5' w='20%' onClick={(e: React.SyntheticEvent) => enterDetailPage(e)}>
          <Typography variant='dropdown-menu-small' style={{ color: LabelColor }}>
            {
              row?.location && row?.country ?
              `${row.location}, ${row.country.name}`
              :
              row?.location ?
              row.location
              :
              row?.country ?
              row.country.name
              :
              ''
            }
          </Typography> 
        </Block>
        <Block padding='0 0.5' w='10%' onClick={(e: React.SyntheticEvent) => enterDetailPage(e)}>
          <Typography variant='dropdown-menu-small' style={{ color: LabelColor }}>{`${row.fights?.length || 0} ${t('FIGHTS')}`}</Typography> 
        </Block>
        <Block padding='0 0.5' w='10%' onClick={(e: React.SyntheticEvent) => enterDetailPage(e)}>
          {
            user?.type !== PROMOTER &&
            <Consistency value={GetEventConsistency(row)} />
          }
        </Block>
        <Block padding='0 0.5' w='5%' minW={1.25}>
          {renderActions(row)}
        </Block>
      </Box>
    );
  };

  const renderMobileTitle = () => {
    return (
      <Box padding='0 0.5 0 0' fAlign='center' display={{ sm: 'none', xxs: 'flex' }}>
        <Block bgColor='#33353E' bRadius={0.25} display='flex' fAlign='center' fJustify='center' w={3} h={3} mr={0.75} onClick={(e: React.SyntheticEvent) => enterDetailPage(e)}>
          {
            row.photo?.url ?
            <Image src={row.photo.url} alt='event logo' w={3} h={3} />
            :
            <Icon icon='outlined_calendar' size={1.125} color='rgba(255, 255, 255, 0.1)' />
          }
        </Block>
        <Box fDirection='column' onClick={(e: React.SyntheticEvent) => enterDetailPage(e)}>
          <Typography variant='dropdown-menu' pb={0.5} style={{ color: LabelColor }}>
            {row.name}
          </Typography>
          {
            !!row.promoter && 
            <Typography variant='dropdown-menu-small' pb={0.25} style={{ color: PlaceholderColor }}>{row.promoter?.name}</Typography>
          }
          <Typography variant='dropdown-menu-small' pb={0.25} style={{ color: LabelColor }}>
            {capitalizeString(dayjs(row.start_datetime).locale(language).format('dddd, DD MMM, YYYY HH:mm'))}
          </Typography> 
          <Typography variant='dropdown-menu-small' pb={0.25} style={{ color: LabelColor }}>
            {row.country?.name}
          </Typography> 
          <Typography variant='dropdown-menu-small' pb={0.25} style={{ color: LabelColor }}>
            {`${row.fights?.length || 0} ${t('FIGHTS')}`}
          </Typography> 
          {
            user?.type !== PROMOTER &&
            <Block>
              <Consistency value={GetEventConsistency(row)} />
            </Block>
          }
        </Box>
        <Block padding='0 0.5' w='5%' minW={1.25}>
          {renderActions(row)}
        </Block>
      </Box>
    );
  };

  return (
    <>
      {renderDesktopTitle()}
      {renderMobileTitle()}
    </>
  );
};

export default EventTitle;