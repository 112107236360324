import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Theme from './Theme';
import { BgColor } from './Colors';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';
import 'react-advanced-cropper/dist/style.css';
import 'video-react/dist/video-react.css';

const BasicStyles = createGlobalStyle`
  html, body {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }

  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Inter', sans-serif;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
    overflow-x: hidden;
    background-color: ${BgColor};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.scrollBarTrack};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.scrollBarThumb};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.scrollBarThumbHover};
  }

  & .mantine-Notification-root {
    align-items: flex-start;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.menuBgColor};

    & .mantine-Notification-icon {
      background-color: transparent;
    }

    & .mantine-Notification-title {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.188rem;
      margin-bottom: 0.5rem;
      color: ${({ theme }) => theme.colors.labelColor};
    }

    & .mantine-Notification-description {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: ${({ theme }) => theme.colors.labelColor};
    }

    & .mantine-Notification-closeButton {
      & svg path {
        fill: ${({ theme }) => theme.colors.tableHeader};
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  & .custom-error-notification {
    border: 0.063rem solid ${({ theme }) => theme.colors.alertErrorBorder};

    &.primary {
      background-color: ${({ theme }) => theme.colors.alertErrorBg};
    }
  }

  & .custom-success-notification {
    border: 0.063rem solid ${({ theme }) => theme.colors.alertSuccessBorder};

    &.primary {
      background-color: ${({ theme }) => theme.colors.alertSuccessBg};
    }
  }

  & .custom-warning-notification {
    border: 0.063rem solid ${({ theme }) => theme.colors.alertWarningBorder};

    &.primary {
      background-color: ${({ theme }) => theme.colors.alertWarningBg};
    }
  }

  & .custom-info-notification {
    border: 0.063rem solid ${({ theme }) => theme.colors.alertInfoBorder};

    &.primary {
      background-color: ${({ theme }) => theme.colors.alertInfoBg};
    }
  }

  & .mantine-Tooltip-tooltip {
    font-family: 'Inter', sans-serif;
    background: #40424E;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
  }
`;

const StyledProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <NotificationsProvider limit={5} position="top-center" zIndex={9999999}>
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider theme={Theme}>
            <BasicStyles />
            {children}
          </ThemeProvider>
        </DndProvider>
      </NotificationsProvider>
    </MantineProvider>
  );
};

export default StyledProvider;
