export const ConvertBase64ToBlob = (base64Image: string) => {
  const parts = base64Image.split(';base64,');
  const imageType = parts[0].split(':')[1];
  const decodedData = window.atob(parts[1]);
  const uInt8Array = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: imageType });
};

export const CreatePreviewFile = (blob: File | Blob) => ({
  preview: URL.createObjectURL(blob),
  size: blob.size,
  type: blob.type,
  blob: blob,
});

export const AspectRatiosList = [
  { _id: '1', name: '1:1' },
  { _id: '1.5', name: '2:3' },
  { _id: '0.667', name: '3:2' },
  { _id: '0.75', name: '4:3' },
  { _id: '1.333', name: '3:4' },
  { _id: '0.562', name: '16:9' },
  { _id: '1.778', name: '9:16' },
];

export const AspectRatios = {
  '1:1': 1,
  '2:3': 1.5,
  '3:2': 0.667,
  '4:3': 0.75,
  '3:4': 1.333,
  '16:9': 0.562,
  '9:16': 1.778,
};
