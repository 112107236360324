import { useTranslation } from 'react-i18next';
import { LabelColor } from '../../styles/Colors';
import Icon, { IconTypes} from '../icon';
import Typography from '../typography';
import styled from 'styled-components';

const EmptySection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 260px;
  flex-direction: column;
`;

const EmptyState: React.FC<{
  icon?: IconTypes;
  title?: string;
  tag?: string;
}> = ({
  icon = 'outlined_clipboard',
  title = 'TABLE_EMPTY_TITLE',
  tag = 'TABLE_EMPTY_MESSAGE'
}) => {
  const { t } = useTranslation();
  return (
    <EmptySection>
      <Icon icon={icon} size={3.125} color={LabelColor} style={{ marginBottom: '1rem' }} />
      <Typography variant="subheadline" style={{ color: LabelColor }}>
        {t(title)}
      </Typography>
      <Typography variant="body-small" style={{ color: LabelColor }}>
        {t(tag)}
      </Typography>
    </EmptySection>
  );
};

export default EmptyState;
