import { transitionsDelay } from '../../styles/BasicStyles';
import styled from 'styled-components';

export const TabsWrapper = styled.div` 
  & .mantine-Tabs-tabsList {
    border-bottom: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
  }

  & .mantine-Tabs-tab {
    padding: 1.125rem 0.875rem; 
    transition: border-color ${transitionsDelay} linear;

    & .mantine-Tabs-tabLabel {
      color: ${({ theme }) => theme.colors.labelColor};
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    &[data-active] {
      border-bottom: 0.125rem solid ${({ theme }) => theme.colors.focusColor};

      & .mantine-Tabs-tabLabel {
        color: ${({ theme }) => theme.colors.focusColor};
      }
    }

    &:hover {
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.g400};
    }
  }
`;
