const Platforms = [
  { _id: 'COMMON', name: 'Common', tag: 'COMMON' },
  { _id: 'CA', name: 'Central App', tag: 'CENTRAL_APP' },
  { _id: 'MA', name: 'Mobile App', tag: 'MOBILE_APP' },
  { _id: 'WA', name: 'Web App', tag: 'WEB_APP' },
  { _id: 'SERVER', name: 'Server', tag: 'SERVER' }
];

export const GetPlatformTag = (id: string) => {
  const found = Platforms.find((p) => p._id === id);
  return found?.tag || id;
};

export default Platforms;
