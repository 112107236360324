import { NavLink, NavLinkProps } from 'react-router-dom';
import { transitionsDelay } from '../../styles/BasicStyles';
import { breakpoints } from '../../styles/Breakpoints';
import styled, { css } from 'styled-components';
import Typography from '../typography';

/* SIDEBAR */

export const SidebarWrapper = styled.div<{ isOpen: boolean }>`
  padding: 1.875rem 1.25rem;
  position: fixed;
  top: 4.5rem;
  left: 0;
  background: ${({ theme }) => theme.colors.menuBgColor};
  height: calc(100vh - 4.5rem);
  width: 17.5rem;
  display: flex;
  flex-direction: column;
  z-index: 999999;

  @media ${breakpoints.md} {
    transition: left ${transitionsDelay} linear;
    left: ${({ isOpen }) => (isOpen ? '0rem' : '-17.5rem')};
  }
`;

/* OPTIONS */

export const OptionsWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const Option = styled(NavLink)<NavLinkProps & { isSubMenu: boolean }>`
  display: inline-block;
  width: 100%;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  transition: background-color ${transitionsDelay} linear;

  ${({ isSubMenu }) => 
    isSubMenu ?
    css`
      & span {
        transition: color ${transitionsDelay} linear, font-weight ${transitionsDelay} linear;
      }

      &.active, &:hover {
        & span {
          color: ${({ theme }) => theme.colors.labelColor};
          font-weight: 600;
        }
      }
    `
    :
    css`
      &.active {
        text-decoration: none;
        background-color: ${({ theme }) => theme.colors.menuActiveColor};
        border-radius: 0.25rem;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.menuActiveColor};
        border-radius: 0.25rem;
      }
    `
  }

  & > div:last-child {
    & svg {
      & path[stroke] {
        stroke: ${({ theme }) => theme.colors.placeholderColor};
      }

      & path[fill] {
        fill: ${({ theme }) => theme.colors.placeholderColor};
      }
    }
  }
`;

export const GroupOption = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 100%;

  & > div:first-child {
    padding: 0.75rem 1.25rem;
    transition: background-color ${transitionsDelay} linear;
    background-color: ${({ isActive, theme }) => isActive ? theme.colors.menuActiveColor : 'transparent'};
    border-radius: 0.25rem;

    &:hover {
      background-color: ${({ theme }) => theme.colors.menuActiveColor};
      border-radius: 0.25rem;
    }

    & > div:first-child {
      & svg {
        & path[stroke] {
          stroke: ${({ theme }) => theme.colors.placeholderColor};
        }

        & path[fill] {
          fill: ${({ theme }) => theme.colors.placeholderColor};
        }
      }
    }

    & > div:last-child {
      & svg {
        & path[stroke] {
          stroke: ${({ theme }) => theme.colors.labelColor};
        }

        & path[fill] {
          fill: ${({ theme }) => theme.colors.labelColor};
        }
      }
    }
  }
`;

export const OptionName = styled(Typography)<{ hasIcon: boolean }>`
  margin-left: ${({ hasIcon }) => hasIcon ? '1rem' : '0rem'};
  color: ${({ theme, isSubMenu }) => isSubMenu ? theme.colors.subMenuColor : theme.colors.labelColor};
`;


