import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { Configs } from '../../../models/Configs';
import { BasicForm } from '../../../styles/BasicStyles';
import { useContext, useEffect, useState } from 'react';
import { AnyObject } from '../../../models/Generic';
import { showSuccess } from '../../../hooks/show-notification/show-notification';
import { InfoContext } from '../../../Context';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../components/modal';
import useFetch from 'use-http';
import TextInput from '../../../components/inputs/TextInput';
import Button from '../../../components/button';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: Configs;
  type?: string;
}

const { Form } = withTypes<Configs>();

const EditConfigsModal: React.FC<Params> = ({
  opened,
  onClose,
  initialValues,
  type
}) => {
  const { t } = useTranslation();
  const { put } = useFetch('/configs');
  const { info, setInfo } = useContext(InfoContext);
  const [modalInfo, setModalInfo] = useState<{ validations?: AnyObject }>({});

  useEffect(() => {
    switch (type) {
      case 'NAME':
        setModalInfo({ validations: { feedback_url: yup.string() } });
        break;
      default:
        break;
    }
  }, [type]);

  const onSubmit = async (values: Configs) => {
    if(values._id) {
      if(!values.feedback_url) values.feedback_url = '';

      const { data, success } = await put(`/${values._id}`, values);

      if(success) {
        if(info) setInfo({ ...info, configs: { ...data }});

        onClose(true);

        showSuccess({
          title: t('SUCCESS'),
          message: t('CONFIGS_UPDATED')
        });
      }
    }
  };

  return (
    <Modal size={27.5} opened={opened} onClose={() => onClose(false)} title={t('EDIT_CONFIGS')}>
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema(yup.object(modalInfo.validations))}
        initialValues={initialValues || {}}
      >
        {({ handleSubmit, submitting }) => (
          <BasicForm onSubmit={handleSubmit}>
            <ModalOverflowContent>
              <ModalContent style={{ paddingTop: '2.5rem', paddingBottom: '2.5rem' }}>
                <Grid gutter="xl">
                  <Grid.Col span={12}>
                    {
                      type === 'FEEDBACK_URL' ?
                      <Field name="feedback_url">
                        {(props) => (
                          <TextInput 
                            {...props} 
                            label={t('FEEDBACK_URL')} 
                            placeholder={t('ENTER_LINK')} 
                          />
                        )}
                      </Field>
                      : 
                      null
                    }
                  </Grid.Col>
                </Grid>
              </ModalContent>
            </ModalOverflowContent>
            <ModalActions>
              <Button
                text={t('CANCEL')}
                variant="secondary"
                onClick={() => onClose(false)}
              />
              <Button text={t('SAVE')} type="submit" loading={submitting} />
            </ModalActions>
          </BasicForm>
        )}
      </Form>
    </Modal>
  );
};

export default EditConfigsModal;
