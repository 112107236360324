/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { Grid, Space } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Field, withTypes } from 'react-final-form';
import { EmailTemplate } from '../../models/EmailTemplate';
import { BasicForm } from '../../styles/BasicStyles';
import { useState, useEffect } from 'react';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import useFetch from 'use-http';
import HtmlInput from '../../components/inputs/HtmlInput';
import headerActions from '../../components/header/HeaderActions';
import IntlTitleInput from '../../components/inputs/IntlTitleInput';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import LanguageTabs from '../../components/languages/LanguageTabs';
import LoadingOverlay from '../../components/loadingOverlay';
import TextInput from '../../components/inputs/TextInput';
import PageHeader from '../../components/page/PageHeader';
import SwitchInput from '../../components/inputs/SwitchInput';

const { Form } = withTypes<EmailTemplate>();

const ManageEmailTemplatePage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<EmailTemplate>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const intlObject = useIntlValidation();

  const { get, put } = useFetch('/email-templates');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: EmailTemplate) => {
    if (id) {
      const { success } = await put(`/${values._id}`, values);
      if (success) navigate('/email-templates');
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues || {}}
      validate={useValidationSchema(
        yup.object({
          subject: intlObject({ required: true }),
          html: intlObject({ required: true, html: true })
        })
      )}
    >
      {({ handleSubmit, submitting, pristine }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <PageHeader
            title={t('EDIT_EMAIL_TEMPLATE')}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/email-templates'
            })}
          />
          <Grid>
            <Grid.Col xs={12}>
              <Field name="name">
                {(props) => (
                  <TextInput {...props} label={t('NAME')} disabled />
                )}
              </Field>
            </Grid.Col>
            <Grid.Col xs={12}>
              <Field name="tag">
                {(props) => (
                  <TextInput {...props} label={t('TAG')} disabled />
                )}
              </Field>
            </Grid.Col>
            {initialValues?.tag !== 'BASE_TEMPLATE' && (
              <>
                <Grid.Col xs={12}>
                  <Field name="subject">
                    {(props) => (
                      <IntlTitleInput {...props} label={t('SUBJECT')} />
                    )}
                  </Field>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Field name="includeHeaderFooter">
                    {(props) => (
                      <SwitchInput
                        {...props}
                        label={t('INCLUDE_HEADER_FOOTER')}
                      />
                    )}
                  </Field>
                </Grid.Col>
              </>
            )}
          </Grid>
          <Space h={50} />
          <Grid>
            <Grid.Col xs={12}>
              <LanguageTabs>
                {(lang) => (
                  <Field name={`html[${lang}]`}>
                    {(props) => (
                      <HtmlInput {...props} params={initialValues?.params} />
                    )}
                  </Field>
                )}
              </LanguageTabs>
            </Grid.Col>
          </Grid>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageEmailTemplatePage;
