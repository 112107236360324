/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import { useTranslation } from 'react-i18next';
import { FC, forwardRef, useContext } from 'react';
import { Fight } from '../../models/Fight';
import { Block, Box } from '../../styles/BasicStyles';
import { MenuBgColor, BorderColor, DisabledColor } from '../../styles/Colors';
import { PROMOTER, User } from '../../models/User';
import { Tooltip } from '@mantine/core';
import { UserContext } from '../../Context';
import Typography from '../../components/typography';
import UserAvatar from '../../components/userAvatar';
import Consistency from '../../components/consistency';

interface Params {
  fight?: Fight;
}

const OfficialAvatar = forwardRef<HTMLDivElement, { user: User }>((props, ref) => (
  <div ref={ref} {...props}>
    <UserAvatar user={props.user} size='sm' />
  </div>
));

const OfficiatingCard: FC<Params> = ({ fight }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const renderOfficialLabel = (name: string) => <Typography as='div' variant='sidebar-group' lHeight={0.875}>{name}</Typography>;

  const renderOfficial = (title: string, official: any) => {
    return (
      <Box fAlign='center' mb={0.25} fWrap='wrap'>
        <Typography variant='body-small' style={{ color: DisabledColor }} fWeight={500} pr={1}>{title}:</Typography>
        {
          Array.isArray(official) && official.length > 0 ?
          official.map((elem: User, index: number) => 
            <Block key={index} pr={0.25} pb={0.25}>
              <Tooltip label={renderOfficialLabel(elem.name)}>
                <OfficialAvatar user={elem} />
              </Tooltip>
            </Block>
          )
          :
          <Block pb={0.25}>
            <Tooltip label={renderOfficialLabel(official.name)}>
              <OfficialAvatar user={official} />
            </Tooltip>
          </Block>
        }
      </Box>
    );
  };

  const renderConsistency = () => {
    return (
      <Box fAlign='center' mt={0.25} mb={0.25} fWrap='wrap'>
        <Typography variant='body-small' style={{ color: DisabledColor }} fWeight={500} pr={1}>{t('CONSISTENCY')}:</Typography>
        <Consistency fight={fight} />
      </Box>
    );
  };

  return (
    <Block display='flex' w={{ lg: '50%', xxs: '100%' }} padding='0 0.563rem' pt={{ lg: 0, xxs: 1 }}>
      <Block 
        bgColor={MenuBgColor} 
        padding="1.25 1.5" 
        bRadius={0.5} 
        style={{ border: `0.063rem solid ${BorderColor}`}} 
        w='100%'
      >
        <Typography as='div' variant='body-small' color='#ffffff' tTransform='uppercase' lSpacing={0.031} mb={1}>{t('OFFICIATING_TEAM')}</Typography>
        <Box fWrap='wrap'>
          <Box fDirection='column' w={{ xs: '50%', xxs: '100%' }}>
            {!!fight?.referee && renderOfficial(t('REFEREE'), fight.referee)}
            {!!fight?.referee2 && renderOfficial(t('SECOND_REFEREE'), fight.referee2)}
            {!!fight?.record_keeper && renderOfficial(t('RECORD_KEEPER'), fight.record_keeper)}
            {!!fight?.commissioner && fight?.commissioner?.length > 0 && renderOfficial(t('COMMISSIONER'), fight.commissioner)}
            {user?.type !== PROMOTER && (!fight?.commissioner || fight?.commissioner?.length === 0) && fight?.consistency?.consistency > 0 && renderConsistency()} 
          </Box>
          <Box fDirection='column' w={{ xs: '50%', xxs: '100%' }}>
            {!!fight?.white_judge && renderOfficial(t('WHITE_JUDGE'), fight.white_judge)}
            {!!fight?.yellow_judge && renderOfficial(t('YELLOW_JUDGE'), fight.yellow_judge)}
            {!!fight?.green_judge && renderOfficial(t('GREEN_JUDGE'), fight.green_judge)}
            {user?.type !== PROMOTER &&!!fight?.commissioner && fight?.commissioner?.length > 0 && fight?.consistency?.consistency > 0 && renderConsistency()}
          </Box>
        </Box>
      </Block>
    </Block>
  );
};

export default OfficiatingCard;
