import { Grid } from '@mantine/core';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  SUPER_ADMIN,
  User,
  REGULATOR,
  ADMIN,
  RESPONSIBLE,
  PROMOTER
} from '../../../models/User';
import { AspectRatios } from '../../../utils/files/Conversions';
import { FC, useContext } from 'react';
import { InfoContext, UserContext } from '../../../Context';
import { Federation } from '../../../models/Federation';
import { showWarning } from '../../../hooks/show-notification/show-notification';
import { AnyObject } from '../../../models/Generic';
import { Event } from '../../../models/Event';
import TextInput from '../../../components/inputs/TextInput';
import ImageInput from '../../../components/inputs/ImageInput';
import SelectInput from '../../../components/inputs/SelectInput';
import TextAreaInput from '../../../components/inputs/TextAreaInput';
import dayjs from 'dayjs';
import OfficialsList from './OfficialsList';
import DateTimeInput from '../../../components/inputs/DateTimeInput';

export interface ComponentProps {
  chooseDirector: (value: string | null, oldValue: string | null) => void;
  officials: User[];
  promoters: User[];
  federations: Federation[];
  isDraftEvent: boolean;
  resetOfficials: (...args: AnyObject[]) => void;
  disableFields?: boolean;
  setOfficials: (list: User[]) => void;
  currentOfficials: User[];
  setCurrentOfficials: (list: User[]) => void;
  setFormPristine: (value: boolean) => void;
  initialValues?: Event;
}

const FormFields: FC<ComponentProps> = ({
  chooseDirector,
  officials,
  promoters,
  federations,
  isDraftEvent,
  resetOfficials,
  disableFields = false,
  setOfficials,
  currentOfficials,
  setCurrentOfficials,
  setFormPristine,
  initialValues
}) => {
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);

  // Promoters can only create Events after: today + 30 days
  const checkStartDate = (date?: Date) => {
    if (user?.type === PROMOTER) {
      const isAfter = dayjs().add(30, 'days').isAfter(date);

      if (isAfter) {
        showWarning({
          title: t('WARNING'),
          message: t('EVENT_START_DATE_WARNING')
        });
      }
    }
  };
  
  const excludeDate = () => {
    // If we're editing a event, we need to allow "past" dates that were already selected
    if(initialValues?._id) {
      return dayjs(initialValues.start_datetime).startOf('day').toDate();
    }

    // If the User is a Super Admin, he can select "today" for event, otherwise selection starts "tomorrow"
    if(user?.type === SUPER_ADMIN) return dayjs().startOf('day').toDate();

    const tomorrow = dayjs().add(1, 'day').startOf('day').toDate();
    return tomorrow;
  };

  return (
    <>
      <Grid gutter="xl">
        <Grid.Col xs={12}>
          <Field name="photo">
            {(props) => 
              <ImageInput 
                {...props} 
                ratio={AspectRatios['1:1']}
                disabled={disableFields} 
              />
            }
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={user?.type === RESPONSIBLE || user?.type === PROMOTER ? 12 : 6}>
          <Field name="name">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('EVENT_NAME')} *`}
                placeholder={t('ENTER_NAME')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        {(user?.type === SUPER_ADMIN ||
          user?.type === ADMIN ||
          user?.type === REGULATOR) && (
          <Grid.Col xs={12} sm={6}>
            <Field name="federation">
              {(props) => (
                <SelectInput
                  {...props}
                  label={`${t('FEDERATION')}  *`}
                  placeholder={t('CHOOSE_FEDERATION')}
                  clearable
                  data={federations}
                  afterChange={() => resetOfficials()}
                  disabled={disableFields}
                />
              )}
            </Field>
          </Grid.Col>
        )}
      </Grid>
      {(user?.type === SUPER_ADMIN ||
        user?.type === ADMIN ||
        user?.type === REGULATOR ||
        user?.type === RESPONSIBLE) && (
        <Grid gutter="xl">
          <Grid.Col xs={12} sm={6}>
            <Field name="promoter">
              {(props) => (
                <SelectInput
                  {...props}
                  label={`${t('PROMOTOR_NAME')} ${isDraftEvent ? '*' : ''}`}
                  placeholder={t('CHOOSE_PROMOTER')}
                  clearable
                  data={promoters}
                  disabled={disableFields}
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Field name="director">
              {(props) => (
                <SelectInput
                  {...props}
                  label={t('EVENT_DIRECTOR')}
                  placeholder={t('CHOOSE_DIRECTOR')}
                  clearable
                  data={officials}
                  afterChange={chooseDirector}
                  disabled={disableFields}
                />
              )}
            </Field>
          </Grid.Col>
        </Grid>
      )}
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="country">
            {(props) => (
              <SelectInput
                {...props}
                label={t('COUNTRY')}
                placeholder={t('CHOOSE_COUNTRY')}
                clearable
                data={info?.countries || []}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="location">
            {(props) => (
              <TextInput
                {...props}
                label={t('LOCATION')}
                placeholder={t('ENTER_LOCATION')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="start_datetime">
            {(props) => 
              <DateTimeInput 
                {...props} 
                label={`${t('START_DATE_TIME')} *`}
                afterChange={checkStartDate}
                disabled={disableFields}
                minDate={excludeDate()}
              />
            }
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="end_datetime">
            {(props) => 
              <DateTimeInput 
                {...props} 
                label={`${t('END_DATE_TIME')} *`}
                afterChange={checkStartDate}
                disabled={disableFields}
                minDate={excludeDate()}
              />
            }
          </Field>
        </Grid.Col>
      </Grid>
      {user?.type !== PROMOTER && (
        <Grid gutter="xl">
          <Grid.Col xs={12}>
            <OfficialsList
              officials={officials}
              setOfficials={setOfficials}
              currentOfficials={currentOfficials}
              setCurrentOfficials={setCurrentOfficials}
              setFormPristine={setFormPristine}
              disableFields={disableFields}
            />
          </Grid.Col>
        </Grid>
      )}
      <div style={{ marginTop: user?.type !== PROMOTER ? '0.25rem' : 0 }}>
        <Grid gutter="xl">
          <Grid.Col xs={12}>
            <Field name="observations">
              {(props) => 
                <TextAreaInput 
                  {...props} 
                  label={t('OBSERVATIONS')} 
                  disabled={disableFields}
                />
              }
            </Field>
          </Grid.Col>
        </Grid>
      </div>
    </>
  );
};

export default FormFields;
