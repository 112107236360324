import * as yup from 'yup';
import { Field, withTypes } from 'react-final-form';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { BasicForm, Box, Block } from '../../../styles/BasicStyles';
import { useTranslation } from 'react-i18next';
import { showError } from '../../../hooks/show-notification/show-notification';
import { useParams, useNavigate } from 'react-router';
import { isPassword } from '../../../hooks/use-validation/genericValidations';
import { useState } from 'react';
import {
  FocusColor,
  LabelColor,
  LoginButtonColor
} from '../../../styles/Colors';
import AuthLayout from '../AuthLayout';
import useFetch from 'use-http';
import Button from '../../../components/button';
import PasswordInput from '../../../components/inputs/PasswordInput';
import PasswordVerificationInput from '../../../components/inputs/PasswordVerificationInput';
import Typography from '../../../components/typography';

interface Values {
  password: string;
  confirm: string;
}

const { Form } = withTypes<Values>();

const ValidateTenantAccount = () => {
  const [finish, setFinish] = useState(false);
  const { put } = useFetch('/auth');
  const { t } = useTranslation();
  const { id, token } = useParams();
  const navigate = useNavigate();

  const onSubmit = async (values: Values) => {
    if (values.password !== values.confirm) {
      return showError({
        title: t('ERROR'),
        message: t('PASSWORDS_DONT_MATCH')
      });
    }

    const { success, data } = await put('/validate-account', {
      ...values,
      token,
      _id: id
    });

    if (success) {
      setFinish(true);
    } else {
      if (data.expired) {
        navigate('/validate-account');
      }
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          password: isPassword.required(),
          confirm: isPassword.required()
        })
      )}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <AuthLayout>
            <Block w="100%">
              <Block mb={2.25} tAlign="center">
                <Typography variant="auth-title" style={{ color: LabelColor }}>
                  {t('VALIDATE_ACCOUNT')}
                </Typography>
              </Block>
              {finish ? (
                <>
                  <Typography variant="body-bold" as="div">
                    {t('SUCCESS')}
                  </Typography>
                  <Typography
                    variant="body"
                    as="div"
                    style={{ marginBottom: '2.5rem' }}
                  >
                    {t('ACCOUNT_VALIDATION_SUCCESS')}
                  </Typography>
                  <Button
                    text={t('LOGIN')}
                    w="100%"
                    variant={FocusColor}
                    textColor={LoginButtonColor}
                    onClick={() => navigate('/login')}
                  />
                </>
              ) : (
                <BasicForm onSubmit={handleSubmit}>
                  <Box fDirection="column">
                    <Block w="100%" mb={1.5}>
                      <Field name="password">
                        {(props) => (
                          <PasswordVerificationInput
                            {...props}
                            label={t('CHOOSE_YOUR_PASSWORD')}
                            placeholder={t('CHOOSE_YOUR_PASSWORD')}
                            size="lg"
                          />
                        )}
                      </Field>
                    </Block>
                    <Block w="100%" mb={2.5}>
                      <Field name="confirm">
                        {(props) => (
                          <PasswordInput
                            {...props}
                            label={t('REPEAT_YOUR_PASSWORD')}
                            placeholder={t('REPEAT_YOUR_PASSWORD')}
                            size="lg"
                          />
                        )}
                      </Field>
                    </Block>
                    <Button
                      text={t('VALIDATE')}
                      type="submit"
                      loading={submitting}
                      w="100%"
                      variant={FocusColor}
                      textColor={LoginButtonColor}
                    />
                  </Box>
                </BasicForm>
              )}
            </Block>
          </AuthLayout>
        );
      }}
    </Form>
  );
};

export default ValidateTenantAccount;
