/* eslint-disable react/display-name */
import { Box, Block, Image } from '../../../../styles/BasicStyles';
import { Event } from '../../../../models/Event';
import { MenuBgColor, BorderColor, DisabledColor, SubMenuColor, PlaceholderColor } from '../../../../styles/Colors';
import { Tooltip } from '@mantine/core';
import { FC, forwardRef, useContext } from 'react';
import { PROMOTER, User } from '../../../../models/User';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../Context';
import { GetEventType, ShowEventStatus } from '../../../../utils/events';
import Typography from '../../../../components/typography';
import dayjs from 'dayjs';
import UserAvatar from '../../../../components/userAvatar';
import Icon from '../../../../components/icon';
import Consistency from '../../../../components/consistency';
import Label from '../../../../components/label';

interface Params {
  initialValues?: Event;
  officiatingTeamRoles?: { _id: string, roles: string[] }[];
}

const OfficialAvatar = forwardRef<HTMLDivElement, { user: User }>((props, ref) => (
  <div ref={ref} {...props}>
    <UserAvatar user={props.user} size='sm' />
  </div>
));

const Cards: FC<Params> = ({ initialValues, officiatingTeamRoles }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const eventStatus = GetEventType(initialValues?.status);

  const renderOfficialLabel = (name: string, roles: string[]) => {
    return (
      <>
        <Typography as='div' variant='sidebar-group' lHeight={0.875}>{name}</Typography>
        {
          roles.length > 0 && 
          <Block pt={0.3}>
            {
              roles.map((role: string, index: number) =>
                <Typography as='div' key={index} variant='sidebar-group' lHeight={0.875} style={{ color: SubMenuColor }} pt={0.2}>{t(role)}</Typography>
              )
            }
          </Block>
        }
      </>
    );
  };

  const renderOfficial = (official: User) => {
    const officialRoles = officiatingTeamRoles?.find((elem: { _id: string, roles: string[] }) => elem._id === official._id);

    return (
      <Tooltip label={renderOfficialLabel(official.name, officialRoles?.roles || [])}>
        <OfficialAvatar user={official} />
      </Tooltip>
    );
  };

  return (
    <Block display='flex' margin='0 -0.688rem' style={{ flexFlow: 'row wrap' }} mb={3.438}>
      <Block padding='0 0.688' w={{ lg: '35%', xxs: '100%' }}>
        <Box fDirection='column' padding='1.125 2' bgColor={MenuBgColor} bRadius={0.5} style={{ border: `0.063rem solid ${BorderColor}` }} h='100%'>
          <Block bgColor='#33353E' bRadius='50%' display='flex' fAlign='center' fJustify='center' w={4.375} h={4.375}>
            {
              initialValues?.photo?.url ?
              <Image src={initialValues.photo.url} alt='event logo' w={4.375} h={4.375} bRadius='50%' /> 
              :
              <Icon icon='outlined_calendar' size={1.5} color='rgba(255, 255, 255, 0.1)' />
            }
          </Block>
          <Typography variant='body' fSize={1.25} fWeight={500} pt={0.375} color='#ffffff'>{initialValues?.name}</Typography>
          <Box fAlign='center' pt={1.25}>
            <Typography variant='body-small' lHeight={1.063} fWeight={500} style={{ color: DisabledColor }} pr={0.5}>{t('START_DATE')}:</Typography>
            <Typography variant='body-small' lHeight={1.063} fWeight={500} color='#ffffff'>
              {dayjs(initialValues?.start_datetime).format('DD MMM, YYYY HH:mm')}
            </Typography>
          </Box>
          <Box fAlign='center' pt={0.5}>
            <Typography variant='body-small' lHeight={1.063} fWeight={500} style={{ color: DisabledColor }} pr={0.5}>{t('END_DATE')}:</Typography>
            <Typography variant='body-small' lHeight={1.063} fWeight={500} color='#ffffff'>
              {dayjs(initialValues?.end_datetime).format('DD MMM, YYYY HH:mm')}
            </Typography>
          </Box>
          <Box fAlign='center' pt={0.5}>
            <Typography variant='body-small' lHeight={1.063} fWeight={500} style={{ color: DisabledColor }} pr={0.5}>{t('LOCATION')}:</Typography>
            <Typography variant='body-small' lHeight={1.063} fWeight={500} color='#ffffff'>
              {
                initialValues?.location && initialValues?.country ?
                `${initialValues.location}, ${initialValues.country.name}`
                :
                initialValues?.location ?
                initialValues.location
                :
                initialValues?.country ?
                initialValues.country.name
                :
                '-'
              }
            </Typography>
          </Box>
          {
            initialValues?.promoter?.name &&
            <Box fAlign='center' pt={0.5}>
              <Typography variant='body-small' lHeight={1.063} fWeight={500} style={{ color: DisabledColor }} pr={0.5}>{t('ORGANIZED_BY')}:</Typography>
              <Typography variant='body-small' lHeight={1.063} fWeight={500} color='#ffffff'>{initialValues.promoter.name}</Typography>
            </Box>
          }
          {
            ShowEventStatus(user?.type) &&
            <Box fAlign='center' pt={0.5}>
              <Typography variant='body-small' lHeight={1.063} fWeight={500} style={{ color: DisabledColor }} pr={0.5}>{t('STATUS')}:</Typography>
              <Label 
                text={t(eventStatus.name || '')} 
                fontColor={eventStatus.fontColor}
                bgColor={eventStatus.bgColor}
              />
            </Box>
          }
        </Box>
      </Block>
      <Block padding='0 0.688' w={{ lg: '30%', sm: '50%', xxs: '100%' }} mt={{ lg: 0, xxs: 1 }}>
        <Box fDirection='column' padding='1.125 2' bgColor={MenuBgColor} bRadius={0.5} style={{ border: `0.063rem solid ${BorderColor}` }} h='100%'>
          <Typography variant='body' color='#ffffff'>{t('OFFICIATING_TEAM').toUpperCase()}</Typography>
          <Block pt={1} w='100%'>
            {
              initialValues?.officials && initialValues.officials.length > 0 ?
              <Box fAlign='center' margin='0 -0.313rem' fWrap='wrap'>
                {
                  initialValues.officials.map((official: User, index: number) => 
                    <Block key={index} padding='0.313'>
                      {renderOfficial(official)}
                    </Block>
                  )
                }
              </Box>
              :
              ''
            }
          </Block>
        </Box>
      </Block>
      <Block padding='0 0.688' w={{ lg: '35%', sm: '50%', xxs: '100%' }} mt={{ lg: 0, xxs: 1 }}>
        <Box fDirection='column' padding='1.125 2' bgColor={MenuBgColor} bRadius={0.5} style={{ border: `0.063rem solid ${BorderColor}` }} h='100%'>
          <Typography variant='body' color='#ffffff'>{t('EVENT_STATISTICS').toUpperCase()}</Typography>
          <Box fDirection='column' pt={1}>
            <Box fAlingn='center'>
              <Block w='50%' pr={0.313}>
                <Box fDirection='column' fAlign='center' padding='0.625 0.25' bRadius={0.5} bgColor='#23242A'>
                  <Typography fSize={1.5} lHeight={2.125} fWeight={400} color='#ffffff' pb={0.125}>{initialValues?.stats?.totalBreaks}</Typography>
                  <Typography variant='body-small' style={{ color: PlaceholderColor }} tAlign='center'>{t('TOTAL_BREAKS')}</Typography>
                </Box>
              </Block>
              <Block w='50%' pl={0.313}>
                <Box fDirection='column' fAlign='center' padding='0.625 0.25' bRadius={0.5} bgColor='#23242A'>
                  <Typography fSize={1.5} lHeight={2.125} fWeight={400} color='#ffffff' pb={0.125}>{initialValues?.stats?.pointsDeducted}</Typography>
                  <Typography variant='body-small' style={{ color: PlaceholderColor }} tAlign='center'>{t('POINTS_DEDUCTED')}</Typography>
                </Box>
              </Block>
            </Box>
            <Box fJustify='space-between' fAlign='center' mt={1.25} fWrap='wrap'>
              {
                user?.type !== PROMOTER && !!initialValues?.stats?.consistency && initialValues.stats.consistency >= 0 &&
                <Block display='flex' fDirection='column' fAlign='center' pr={0.5} mt={1}>
                  <Typography variant='body-small' color='#ffffff' pb={0.75}>{t('CONSISTENCY')}:</Typography>
                  <Consistency value={initialValues?.stats?.consistency} />
                </Block>
              }
              <Block display='flex' fDirection='column' fAlign='center' pr={0.5} mt={1}>
                <Typography variant='body-small' color='#ffffff' pb={0.75}>{t('DECISION')}:</Typography>
                <Typography variant='body-small' lHeight={1.563} style={{ color: PlaceholderColor }}>{initialValues?.stats?.decision}</Typography>
              </Block>
              <Block display='flex' fDirection='column' fAlign='center' pr={0.5} mt={1}>
                <Typography variant='body-small' color='#ffffff' pb={0.75}>{t('SUBMISSION')}:</Typography>
                <Typography variant='body-small' lHeight={1.563} style={{ color: PlaceholderColor }}>{initialValues?.stats?.submission}</Typography>
              </Block>
              <Block display='flex' fDirection='column' fAlign='center' mt={1}>
                <Typography variant='body-small' color='#ffffff' pb={0.75}>{t('TKO_2')}:</Typography>
                <Typography variant='body-small' lHeight={1.563} style={{ color: PlaceholderColor }}>{initialValues?.stats?.ko}</Typography>
              </Block>
            </Box>
          </Box>
        </Box>
      </Block>
    </Block>
  );
};

export default Cards;