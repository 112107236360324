import { Container, LogoImage, Illustration } from './styles';
import { Box } from '../../styles/BasicStyles';
import Codepoint from '../../assets/images/logo.svg';
import IllustrationIcon from '../../assets/images/server.svg';
import Typography from '../../components/typography';
import Button from '../../components/button';

const ServerErrorPage = () => {
  return (
    <Container>
      <LogoImage
        src={Codepoint}
        onClick={() => window.open('https://codepoint.pt', '_blank')}
      />
      <Box fDirection='column' fJustify="center" fAlign="center">
        <Illustration src={IllustrationIcon} style={{ marginBottom: '1.875rem' }} />
        <Typography variant="title" fSize={2} lHeight={2.3} mb={1.875}>Server Error</Typography>
        <Typography variant="body" tAlign="center" mb={1.875}>
          The api seems to be down.
          <br />
          If this error persists please contact the platform administrator.
        </Typography>
        <Button text='Refresh platform' onClick={() => window.open('/', '_self')} />
      </Box>
    </Container>
  );
};

export default ServerErrorPage;
