import { FC } from 'react';
import { Fight } from '../../models/Fight';
import { ConsistencyGreen, ConsistencyGreenBg, ConsistencyRed, ConsistencyRedBg, ConsistencyYellow, ConsistencyYellowBg } from '../../styles/Colors';
import Label, { LabelSize } from '../label';

interface Params {
  fight?: Fight;
  value?: number;
  size?: LabelSize;
}

const Consistency: FC<Params> = ({ fight, value, size = 'md' }) => {
  if(!fight?.consistency && !value) return null;

  const consistency = value || fight?.consistency?.consistency;
  const bgColor = consistency < 50 ? ConsistencyRedBg : consistency < 80 ? ConsistencyYellowBg : ConsistencyGreenBg;
  const fontColor = consistency < 50 ? ConsistencyRed : consistency < 80 ? ConsistencyYellow : ConsistencyGreen;

  return (
    <Label 
      text={`${consistency.toFixed(2)}%`}
      fontColor={fontColor}
      bgColor={bgColor}
      size={size}
    />
  );
};

export default Consistency;