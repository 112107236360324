import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';
import { Fight } from '../../models/Fight';
import { PlaceholderColor } from '../../styles/Colors';
import { Tabs } from '@mantine/core';
import { TabsWrapper } from '../../components/tabs/styles';
import { PROMOTER } from '../../models/User';
import { UserContext } from '../../Context';

import Typography from '../../components/typography';
import Graphs from './Graphs';
import JudgeTables from './JudgeTables';
interface Params {
  fight?: Fight;
  reloadInfo: () => void;
}

const ScoreCards: FC<Params> = ({ fight, reloadInfo }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  // Generate auxiliary arrays with length = number of rounds / duration of each round
  // If the Fight stopped before the final round, use that number as the final round 
  const finalRound = fight?.result && fight?.result?.round > 0 ? fight.result.round : fight?.rounds;
  const roundsArray = Array(finalRound || 0).fill(0);
  const roundDurationArray = Array(fight?.duration || 0).fill(0);

  const renderTabContent = (round: number) => {
    return (
      <>
        {
          user?.type !== PROMOTER &&
          <Graphs 
            round={round} 
            fight={fight} 
            roundsArray={roundsArray} 
            roundDurationArray={roundDurationArray} 
          />
        }
        <JudgeTables 
          round={round} 
          fight={fight} 
          roundsArray={roundsArray} 
          reloadInfo={reloadInfo} 
        />
      </>
    );
  }

  return (
    <>
      {
        roundsArray.length > 0 &&
        <>
          <Typography as='div' variant='sidebar-group' style={{ color: PlaceholderColor }}>{t('SCORECARDS').toUpperCase()}</Typography>
          <TabsWrapper>
            <Tabs radius="md" defaultValue='overview'>
              <Tabs.List>
                <Tabs.Tab value='overview'>
                  {t('OVERVIEW')}
                </Tabs.Tab>
                {
                  roundsArray.map((_: number, index: number) =>
                    <Tabs.Tab key={index} value={`round_${index + 1}`}>
                      {`${t('ROUND')} ${index + 1}`}
                    </Tabs.Tab>
                  )
                }
              </Tabs.List>
              <Tabs.Panel value='overview'>
                {renderTabContent(0)}
              </Tabs.Panel>
              {
                roundsArray.map((_: number, index: number) =>
                  <Tabs.Panel key={index} value={`round_${index + 1}`}>
                    {renderTabContent(index + 1)}
                  </Tabs.Panel>
                )
              }
            </Tabs>
          </TabsWrapper>
        </>
      }
    </>
  );
};

export default ScoreCards;
