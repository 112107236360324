import { AnyObject, SortObject } from "../../models/Generic";

const ConstructPaginationRequest = (
  base: string,
  filters?: AnyObject,
  sort?: SortObject,
  searchFields?: Array<string>
) => {
  let url = `${base}?`;
  if (filters && Object.keys(filters).length) {
    const params = { ...filters };
    if (params.search && params.search !== "") {
      url = `${url}&search=${JSON.stringify({
        value: params.search,
        fields: searchFields,
      })}`;
    }
    delete params.search;
    url = `${url}&filters=${JSON.stringify(params)}`;
  }
  if (sort && Object.keys(sort).length && sort.field !== "")
    url = `${url}&sort=${JSON.stringify({ [sort.field]: sort.order })}`;
  return url;
};

export default ConstructPaginationRequest;
