/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { Fight } from '../../models/Fight';
import { Block, Box } from '../../styles/BasicStyles';
import { YellowJudge, MenuBgColor, BlueFighter, RedFighter, WhiteJudge, SubMenuColor, GreenJudge, FocusColor } from '../../styles/Colors';
import { AnyObject } from '../../models/Generic';
import { GetFirstAndLastName } from '../../utils/user';
import Typography from '../../components/typography';
import Icon from '../../components/icon';
import EditJudgeScoreModal from './modals/EditJudgeScore';

type Judge = 'white' | 'green' | 'yellow';

interface Params {
  round: number;
  roundsArray: number[];
  fight?: Fight;
  reloadInfo: () => void;
}

const JudgeTables: FC<Params> = ({ round, roundsArray, fight, reloadInfo }) => {
  const { t } = useTranslation();
  const [editJudgeScoreModal, setEditJudgeScoreModal] = useState<{ open: boolean, initialValues?: any, judge?: Judge, judgeName?: string }>({ open: false });

  const calculateScore = (type: 'total' | 'deductions' | 'points', judge: Judge, round: number, color: 'red' | 'blue') => {
    let points = 0, deductions = 0;
    if(round > 0) {
      points = fight?.record?.points[judge]?.filter((elem: AnyObject) => elem.round === round).reduce((acc: number, elem: AnyObject) => acc + elem[color], 0);
      deductions = fight?.record?.deductions?.filter((elem: AnyObject) => elem.round === round).reduce((acc: number, elem: AnyObject) => acc + elem[color], 0)
    }
    else {
      roundsArray.forEach((_: number, index: number) => {
        points += fight?.record?.points[judge]?.filter((elem: AnyObject) => elem.round === (index + 1)).reduce((acc: number, elem: AnyObject) => acc + elem[color], 0);
        deductions += fight?.record?.deductions?.filter((elem: AnyObject) => elem.round === (index + 1)).reduce((acc: number, elem: AnyObject) => acc + elem[color], 0)
      });
    }

    if(type === 'points') return points;
    if(type === 'deductions') return deductions;
    return points - deductions;
  };

  const renderRoundScore = (judge: Judge, round: number, index: number) => {
    return (
      <Box key={index} fAlign='center' style={{ borderBottom: '0.063rem solid #26262D' }}>
        <Typography padding='0.625 0.25' fSize={1.125} lHeight={1.563} fWeight={500} tTransform='uppercase' tAlign='center' w='20%' color='#ffffff' style={{ borderRight: '0.063rem solid #26262D'}}>
          {calculateScore('points', judge, round, 'red')}
        </Typography>
        <Typography padding='0.625 0.25' fSize={1.125} lHeight={1.563} fWeight={500} tTransform='uppercase' tAlign='center' w='20%' color='#ffffff'>
          {calculateScore('deductions', judge, round, 'red')}
        </Typography>
        <Typography padding='0.625 0.25' fSize={1.125} lHeight={1.563} fWeight={500} tTransform='uppercase' tAlign='center' w='20%' color='#ffffff' bgColor='#242428'>
          {round}
        </Typography>
        <Typography padding='0.625 0.25' fSize={1.125} lHeight={1.563} fWeight={500} tTransform='uppercase' tAlign='center' w='20%' color='#ffffff'>
          {calculateScore('deductions', judge, round, 'blue')}
        </Typography>
        <Typography padding='0.625 0.25' fSize={1.125} lHeight={1.563} fWeight={500} tTransform='uppercase' tAlign='center' w='20%' color='#ffffff' style={{ borderLeft: '0.063rem solid #26262D'}}>
          {calculateScore('points', judge, round, 'blue')}
        </Typography>
      </Box>
    );
  }

  const renderJudgeCard = (judge: Judge, color: string, round: number) => {
    const judgeName = GetFirstAndLastName(fight && fight[`${judge}_judge`]?.name);

    return fight?.record?.points && fight.record.points[judge]?.length > 0 && (
      <Block display='flex' fDirection='column' w={{ lg: '33.333%', sm: '50%', xxs: '100%' }} padding='0 0.813' mb={0.75}>
        <Box fAlign='center' fJustify='space-between' bgColor={color} bRadius={0.25} padding='0.188 0.5' minH={1.563}>
          <Typography fSize={1} lHeight={1.188} fWeight={500} color='#131316'>
            {judgeName}
          </Typography>
          <Icon 
            icon='outlined_pencil' 
            color='#131316'
            hoverColor={FocusColor} 
            title={t('EDIT')} 
            onClick={() => setEditJudgeScoreModal({ open: true, initialValues: fight?.record?.points[judge], judge, judgeName })} 
            size={1.25}
          />
        </Box>
        <Box fDirection='column' bRadius={0.938} bgColor={MenuBgColor} mt={0.813}>
          <Box fAlign='center' style={{ borderBottom: '0.063rem solid #26262D' }}>
            <Typography padding='0.375 0.25' fSize={0.625} lHeight={0.75} fWeight={500} tTransform='uppercase' tAlign='center' w='20%' style={{ color: SubMenuColor }}>
              {t('JUDGE_SCORE')}
            </Typography>
            <Typography padding='0.375 0.25' fSize={0.625} lHeight={0.75} fWeight={500} tTransform='uppercase' tAlign='center' w='20%' style={{ color: SubMenuColor }}>
              {t('POINTS_DEDUCTED')}
            </Typography>
            <Typography padding='0.375 0.25' fSize={0.625} lHeight={0.75} fWeight={500} tTransform='uppercase' tAlign='center' w='20%' style={{ color: SubMenuColor }}>
              {t('ROUND')}
            </Typography>
            <Typography padding='0.375 0.25' fSize={0.625} lHeight={0.75} fWeight={500} tTransform='uppercase' tAlign='center' w='20%' style={{ color: SubMenuColor }}>
              {t('POINTS_DEDUCTED')}
            </Typography>
            <Typography padding='0.375 0.25' fSize={0.625} lHeight={0.75} fWeight={500} tTransform='uppercase' tAlign='center' w='20%' style={{ color: SubMenuColor }}>
              {t('JUDGE_SCORE')}
            </Typography>
          </Box>
          {
            round === 0 ? 
            (
              roundsArray.length > 0 &&
              roundsArray.map((_: number, index: number) => renderRoundScore(judge, index + 1, index))
            )
            :
            renderRoundScore(judge, round, 0)
          }
          <Box>
            <Block w='40%' h={0.563} bgColor={RedFighter}></Block>
            <Block w='20%' h={0.563} bgColor='#242428'></Block>
            <Block w='40%' h={0.563} bgColor={BlueFighter}></Block>
          </Box>
          <Box>
            <Typography padding='0.5 0.25' fSize={1.125} lHeight={1.563} fWeight={500} tTransform='uppercase' tAlign='center' color='#ffffff' w='40%'>
              {calculateScore('total', judge, round, 'red')}
            </Typography>
            <Block w='20%' bgColor='#242428'>
              <Typography as='div' padding='0.5 0.25' fSize={1} lHeight={1.563} fWeight={600} tTransform='uppercase' tAlign='center' w='100%' style={{ color: SubMenuColor }}>
                {t('TOTAL').toUpperCase()}
              </Typography>
            </Block>
            <Typography padding='0.5 0.25' fSize={1.125} lHeight={1.563} fWeight={500} tTransform='uppercase' tAlign='center' color='#ffffff' w='40%'>
              {calculateScore('total', judge, round, 'blue')}
            </Typography>
          </Box>
        </Box>
      </Block>
    );
  };

  return (
    <>
      <Block display='flex' margin='0 -0.813rem' style={{ flexFlow: 'row wrap' }} pt={2.25}>
        {renderJudgeCard('white', WhiteJudge, round)}
        {renderJudgeCard('green', GreenJudge, round)}
        {renderJudgeCard('yellow', YellowJudge, round)}
      </Block>
      <EditJudgeScoreModal
        opened={editJudgeScoreModal.open}
        onClose={() => setEditJudgeScoreModal({ open: false })}
        initialValues={editJudgeScoreModal.initialValues}
        judge={editJudgeScoreModal.judge}
        judgeName={editJudgeScoreModal.judgeName}
        fight={fight}
        reloadInfo={reloadInfo}
      />
    </>
  );
};

export default JudgeTables;
