import { Box } from '../../../styles/BasicStyles';
import { breakpoints } from '../../../styles/Breakpoints';
import styled, { css } from 'styled-components';

export const FirstColumn = styled(Box)<{ fullWidth?: boolean }>`
  flex-direction: column;

  ${({ fullWidth }) => fullWidth ?
    css`
      width: 100%;
    `
    :
    css`
      border-right: 0.063rem dashed rgba(255, 255, 255, 0.15);
      padding-right: 1.875rem;
      width: 70%;

      @media ${breakpoints.lg} {
        width: 100%;
        border-bottom: 0.063rem dashed rgba(255, 255, 255, 0.15);
        border-right: 0;
        padding-right: 0;
        padding-bottom: 1.875rem;
      }
    `
  }
`;
