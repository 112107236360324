import { setLocale } from 'yup';

interface FieldProps {
  value: string;
  originalValue: string;
  label: string;
  path: string;
}

const translation = {
  mixed: {
    default: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_DEFAULT_ERROR',
      values: props
    }),
    required: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_REQUIRED_ERROR',
      values: props
    }),
    oneOf: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_ONE_OF_ERROR',
      values: props
    }),
    notOneOf: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_NOT_ONE_OF_ERROR',
      values: props
    }),
    notType: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_NOT_TYPE_ERROR',
      values: props
    }),
    defined: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_NOT_DEFINED_ERROR',
      values: props
    })
  },
  string: {
    length: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_STRING_LENGTH_ERROR',
      values: props
    }),
    min: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_STRING_MIN_ERROR',
      values: props
    }),
    max: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_STRING_MAX_ERROR',
      values: props
    }),
    email: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_STRING_EMAIL_ERROR',
      values: props
    }),
    url: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_STRING_URL_ERROR',
      values: props
    }),
    trim: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_STRING_TRIM_ERROR',
      values: props
    }),
    lowercase: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_STRING_LOWERCASE_ERROR',
      values: props
    }),
    uppercase: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_STRING_UPPERCASE_ERROR',
      values: props
    })
  },
  number: {
    min: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_NUMBER_MIN_ERROR',
      values: props
    }),
    max: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_NUMBER_MAX_ERROR',
      values: props
    }),
    lessThan: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_NUMBER_LESS_THAN_ERROR',
      values: props
    }),
    moreThan: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_NUMBER_MORE_THAN_ERROR',
      values: props
    }),
    notEqual: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_NUMBER_NOT_EQUAL_ERROR',
      values: props
    }),
    positive: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_NUMBER_POSITIVE_ERROR',
      values: props
    }),
    negative: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_NUMBER_NEGATIVE_ERROR',
      values: props
    }),
    integer: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_NUMBER_INTEGER_ERROR',
      values: props
    })
  },
  date: {
    min: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_DATE_MIN_ERROR',
      values: props
    }),
    max: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_DATE_MAX_ERROR',
      values: props
    })
  },
  array: {
    length: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_ARRAY_LENGTH_ERROR',
      values: props
    }),
    min: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_ARRAY_MIN_ERROR',
      values: props
    }),
    max: (props: FieldProps) => ({
      tag: 'FIELD_VALIDATION_ARRAY_MAX_ERROR',
      values: props
    })
  }
};

export default () => setLocale(translation);
