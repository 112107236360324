/* eslint-disable @typescript-eslint/no-explicit-any */
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../../../../styles/BasicStyles';
import { useState, FC, useEffect, useContext } from 'react';
import { Fight } from '../../../../../models/Fight';
import { User, ATHLETE } from '../../../../../models/User';
import { showConfirm, showSuccess, showWarning } from '../../../../../hooks/show-notification/show-notification';
import { FocusColor } from '../../../../../styles/Colors';
import { UserContext } from '../../../../../Context';
import { GetValidationSchema } from './formHelpers';
import { GetGroupName, SortDisabledUsers } from '../../../../../utils/user';
import { TournamentFight } from '../../../../../models/Tournament';
import Modal, { ModalActions, ModalOverflowContent } from '../../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../../components/button';
import FormFields from './FormFields';
import dayjs from 'dayjs';
import SearchAthleteModal from '../searchAthlete';
import LoadingOverlay from '../../../../../components/loadingOverlay';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: Fight;
  eventOfficials?: User[];
  eventID?: string;
  eventStart?: any;
  eventEnd?: any;
  fightNumber?: number;
  eventFederation?: string;
  refreshPage?: () => void;
}

const { Form } = withTypes<Fight>();

const ManageSingleFightModal: FC<Params> = ({
  opened,
  onClose,
  initialValues,
  eventOfficials,
  eventID,
  fightNumber,
  eventStart,
  eventEnd,
  eventFederation,
  refreshPage
}) => {
  const { t } = useTranslation();
  const { post, put } = useFetch('/fights');
  const [loading, setLoading] = useState<boolean>(true);
  const [searchModal, setSearchModal] = useState<{ open: boolean, type?: 'red' | 'blue' }>({ open: false });
  const [athletes, setAthletes] = useState<User[]>([]);
  const [officials, setOfficials] = useState<User[]>([]);
  const [submitType, setSubmitType] = useState<number>(1);
  const athletesHook = useFetch('/members');
  const { user } = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    getInfo();
  }, [opened]);

  const getInfo = async () => {
    const { data } = await athletesHook.get(`/${ATHLETE}/all/dropdown`);

    if(data?.length > 0) {
      const federationList = data.filter((elem: User) => elem.federation === eventFederation) || [];

      let athletesList: User[] = [];
      const selectedAthletes = [
        initialValues?.blue_fighter?.toString(),
        initialValues?.red_fighter?.toString()
      ];

      // First option is the one to search for other Federations athletes
      athletesList.push({
        _id: '1',
        name: t('SEARCH_ATHLETE'),
        group: GetGroupName(t, 'EXTERNAL', 1),
        disabled: false,
        order: 1
      });

      federationList.map((athlete: User) => {
        const item: User = {
          _id: athlete._id,
          name: athlete.name,
          status: athlete.status,
          disabled: selectedAthletes.includes(athlete._id) ? true : false
        };

        // Athletes that are in the tournament belong to one group, the rest to another
        let group = GetGroupName(t, 'FEDERATION', !!initialValues?.tournament ? 3 : 2);
        const index = !!initialValues?.tournament ? initialValues.tournament_detail?.fighters.findIndex((elem: User) => elem._id === athlete._id) : -1;
        if(index >= 0) group = GetGroupName(t, 'IN_TOURNAMENT', 2);

        item.group = group;

        athletesList.push(item);
      });

      if(!!initialValues?.tournament_detail) {
        // We need to check if there are fighters in the tournament that don't exist in the Federation list, and add them
        if(initialValues.tournament_detail.fighters?.length > 0) {
          initialValues.tournament_detail.fighters.forEach((fighter: User) => {
            const index = federationList.findIndex((elem: User) => elem._id === fighter._id);

            // If this fighter doesn't exist in the Federation, add him to the list
            if(index === -1) {
              athletesList.push({ 
                _id: fighter._id, 
                name: fighter.name, 
                status: fighter.status,
                disabled: selectedAthletes.includes(fighter._id) ? true : false,
                group: GetGroupName(t, 'EXTERNAL', 1)
              });
            }
          });
        }

        // We need to check each fight of the tournament to see if there are any fighters not in the tournament and not in the federation, and add them
        if(initialValues.tournament_detail.fights?.length > 0) {
          initialValues.tournament_detail.fights.forEach((fight: TournamentFight) => {
            if(!!fight.fight?.red_fighter?._id) {
              const index = athletesList.findIndex((elem: User) => elem._id === fight.fight?.red_fighter?._id);

              if(index === -1) {
                athletesList.push({ 
                  _id: fight.fight?.red_fighter?._id, 
                  name: fight.fight?.red_fighter?.name, 
                  status: fight.fight?.red_fighter?.status,
                  disabled: selectedAthletes.includes(fight.fight?.red_fighter?._id) ? true : false,
                  group: GetGroupName(t, 'EXTERNAL', 1)
                });
              }
            }

            if(!!fight.fight?.blue_fighter?._id) {
              const index = athletesList.findIndex((elem: User) => elem._id === fight.fight?.blue_fighter?._id);

              if(index === -1) {
                athletesList.push({ 
                  _id: fight.fight?.blue_fighter?._id, 
                  name: fight.fight?.blue_fighter?.name, 
                  status: fight.fight?.blue_fighter?.status,
                  disabled: selectedAthletes.includes(fight.fight?.blue_fighter?._id) ? true : false,
                  group: GetGroupName(t, 'EXTERNAL', 1)
                });
              }
            }
          });
        }
      }
      // If it's a single fight, if the selected fighters are from another Federation, add them to the list
      else {
        athletesList = await loadExternalFighter(athletesList, selectedAthletes, 'red_fighter');
        athletesList = await loadExternalFighter(athletesList, selectedAthletes, 'blue_fighter');
      }

      // Disabled items go to the end of the list per client request
      athletesList = SortDisabledUsers(athletesList);

      setAthletes(athletesList);
    }

    if (eventOfficials && eventOfficials.length > 0) {
      let officialsList: User[] = [];
      const selectedOfficials = [
        initialValues?.referee?.toString(),
        initialValues?.referee2?.toString(),
        initialValues?.white_judge?.toString(),
        initialValues?.green_judge?.toString(),
        initialValues?.yellow_judge?.toString(),
        initialValues?.record_keeper?.toString()
      ];

      if (
        initialValues?.commissioner &&
        initialValues.commissioner.length > 0
      ) {
        initialValues.commissioner.forEach((value: User) => {
          selectedOfficials.push(value.toString());
        });
      }

      eventOfficials.map((official: User) =>
        officialsList.push({
          _id: official._id,
          name: official.name,
          roles: official.roles,
          disabled: selectedOfficials.includes(official._id) ? true : false
        })
      );

      // Disabled items go to the end of the list per client request
      officialsList = SortDisabledUsers(officialsList);

      setOfficials(officialsList);
    }

    setLoading(false);
  };

  const loadExternalFighter = async (athletesList: User[], selectedAthletes: (string | undefined)[], type: 'red_fighter' | 'blue_fighter') => {
    if(!!initialValues && !!initialValues[type] && initialValues[`${type}_detail`]?.federation !== eventFederation) {
      const fighterResult = await athletesHook.get(`/${initialValues[type]}`);
    
      if(fighterResult?.success && !! fighterResult?.data) {
        athletesList.push({
          _id: fighterResult.data._id,
          name: fighterResult.data.name,
          status: fighterResult.data.status,
          group: GetGroupName(t, 'EXTERNAL', 1),
          disabled: selectedAthletes.includes(fighterResult.data._id) ? true : false
        });
      }
    }

    return athletesList;
  };

  const onSubmit = async (values: Fight) => {
    if(values.start_datetime && values.end_datetime) {
      const start = dayjs(values.start_datetime);
      const end = dayjs(values.end_datetime);

      if (start.isAfter(end) || end.isBefore(start) || start.isSame(end)) {
        return showWarning({
          title: t('WARNING'),
          message: t('WRONG_DATES_SELECTED')
        });
      }
    }

    let error = false;

    if(values._id) {
      const { success } = await put(`/${values._id}`, { ...values, event: eventID });
      if(success) onClose(true);
      else error = true;
    } 
    else {
      const { success } = await post({ ...values, event: eventID });
      if(success) {
        if(submitType === 1) onClose(true);
        else {
          showSuccess({
            title: t('SUCCESS'),
            message: t('FIGHT_CREATED_ADD_NEW')
          });

          setLoading(true);
          getInfo();
          if(!!refreshPage) refreshPage();
        }
      }
      else error = true;
    }

    return !error;
  };

  // When selecting an Official, mark it as disabled. When removing a selected Official, mark it as enabled
  const checkOfficial = (value: string | null, oldValue: string | null) => {
    let aux = [...officials];

    if (!!oldValue) {
      const index = officials.findIndex((elem: User) => elem._id === oldValue);
      if (index >= 0) aux[index].disabled = false;
    }

    if (!!value) {
      const index = officials.findIndex((elem: User) => elem._id === value);
      if (index >= 0) aux[index].disabled = true;
    }

    // Disabled items go to the end of the list per client request
    aux = SortDisabledUsers(aux);

    setOfficials(aux);
  };

  // Allow selecting multiple Commissioners
  const checkMultipleOfficials = (
    values: Array<string>,
    oldValues: Array<string>
  ) => {
    let aux = [...officials];

    // For each selected Official, mark it as disabled
    if (values?.length > 0) {
      values.forEach((value: string) => {
        const index = officials.findIndex((elem: User) => elem._id === value);
        if (index >= 0) aux[index].disabled = true;
      });
    }

    // For each Official that is in the oldValues array, but not in the values array, it means we have to enabled it
    if (oldValues?.length > 0) {
      const filtered = oldValues.filter(
        (value: string) => !values.includes(value)
      );

      if (filtered?.length > 0) {
        filtered.forEach((value: string) => {
          const index = officials.findIndex((elem: User) => elem._id === value);
          if (index >= 0) aux[index].disabled = false;
        });
      }
    }

    // Disabled items go to the end of the list per client request
    aux = SortDisabledUsers(aux);

    setOfficials(aux);
  };

  // When selecting an Athlete, mark it as disabled. When removing a selected Athlete, mark it as enabled
  const checkAthlete = (value: string | null, oldValue: string | null, type: 'red' | 'blue') => {
    let aux = [...athletes];

    if (!!oldValue) {
      const index = athletes.findIndex(
        (athlete: User) => athlete._id === oldValue
      );
      if (index >= 0) aux[index].disabled = false;
    }

    // "Search athlete" opens a modal
    if(value === '1') {
      setSearchModal({ open: true, type });
    }
    else {
      if (!!value) {
        const index = athletes.findIndex(
          (athlete: User) => athlete._id === value
        );
        if (index >= 0) aux[index].disabled = true;
      }
    }

    // Disabled items go to the end of the list per client request
    aux = SortDisabledUsers(aux); 

    setAthletes(aux);
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(GetValidationSchema(initialValues?._id ? false : true, user?.type))}
      initialValues={initialValues || {}}
      mutators={{
        selectRedFighter: (args: any, state: any, utils) => {
          utils.changeValue(state, 'red_fighter', () => args[0]);
        },
        selectBlueFighter: (args: any, state: any, utils) => {
          utils.changeValue(state, 'blue_fighter', () => args[0]);
        }
      }}
    >
      {({ values, handleSubmit, submitting, pristine, form: { reset, mutators: { selectRedFighter, selectBlueFighter } } }) => {
        const handleClose = () => {
          if(!pristine) {
            showConfirm({
              title: t('EXIT_BEFORE_SAVE'),
              message: t('EXIT_BEFORE_SAVE_MESSAGE'),
              onConfirm: () => {
                onClose(false);
                reset();
              }
            });
          }
          else {
            onClose(false);
            reset();
          }
        };

        const handleCloseSearchModal = () => {
          // On cancel remove "Search athlete" selected option
          if(searchModal.type === 'red') selectRedFighter(undefined);
          else if(searchModal.type === 'blue') selectBlueFighter(undefined);

          setSearchModal({ open: false });
        };

        const handleSaveSearchModal = async (athlete?: User) => {
          if(!!athlete) {
            const aux = [...athletes];
      
            // Add the new athlete (the first element must be the one to search athlete)
            aux.push({
              _id: athlete._id,
              name: athlete.name,
              status: athlete.status,
              group: GetGroupName(t, 'EXTERNAL', 1),
              disabled: true
            });

            let oldValue: any = '';
            if(searchModal.type === 'red') {
              oldValue = values.red_fighter;
              selectRedFighter(athlete._id);
            }
            else if(searchModal.type === 'blue') {
              oldValue = values.blue_fighter;
              selectBlueFighter(athlete._id);
            }

            // If there was already a selected fighter, enable it
            if(oldValue) {
              const index = aux.findIndex((athlete: User) => athlete._id === oldValue);
              if (index >= 0) aux[index].disabled = false;
            }

            setAthletes(aux);
            setSearchModal({ open: false });
          }
        };

        return (
          <>
            <Modal
              size={36.25}
              opened={opened}
              onClose={handleClose}
              title={initialValues && initialValues._id ? t('EDIT_FIGHT') : t('CREATE_FIGHT')}
            >
              <LoadingOverlay visible={loading} />
              <BasicForm onSubmit={async event => {
                const result = await handleSubmit(event);
                // If it's a new Fight, we need to force a empty array in "commissioner" field, otherwise it would maintain a value selected after the reset
                if(result) reset(initialValues || { commissioner: [] });
              }}>
                <ModalOverflowContent>
                  <FormFields
                    athletes={athletes}
                    checkAthlete={checkAthlete}
                    officials={officials}
                    checkOfficial={checkOfficial}
                    checkMultipleOfficials={checkMultipleOfficials}
                    fightNumber={fightNumber}
                    eventStart={eventStart}
                    eventEnd={eventEnd}
                    isNew={initialValues?._id ? false : true}
                  />
                </ModalOverflowContent>
                <ModalActions>
                  <Button
                    text={t('CANCEL')}
                    variant="secondary"
                    onClick={handleClose}
                  />
                  {
                    !initialValues?._id &&
                    <Button
                      text={t('SAVE_ADD_NEW')}
                      variant={FocusColor}
                      type="submit"
                      loading={submitting}
                      disabled={pristine}
                      onClick={() => setSubmitType(2)}
                    />
                  }
                  <Button
                    text={initialValues?._id ? t('SAVE') : t('ADD_FIGHT')}
                    type="submit"
                    loading={submitting}
                    disabled={pristine}
                    onClick={() => setSubmitType(1)}
                  />
                </ModalActions>
              </BasicForm>
            </Modal>
            <SearchAthleteModal 
              opened={searchModal.open}
              onClose={handleCloseSearchModal}
              onSave={handleSaveSearchModal}
              athletes={athletes}
            />
          </>
        );
      }}
    </Form>
  );
};

export default ManageSingleFightModal;
