import { transitionsDelay } from '../../styles/BasicStyles';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  background-color: #222429;
  border: 0.063rem solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  width: 100%;
`;

export const FileLink = styled.div` 
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & svg path[fill] {
    transition: fill ${transitionsDelay} linear;
  }

  & svg path[stroke] {
    transition: stroke ${transitionsDelay} linear;
  }

  &:hover {
    & svg path[fill] {
      fill: ${({ theme }) => theme.colors.labelColor};
    }

    & svg path[stroke] {
      stroke: ${({ theme }) => theme.colors.labelColor};
    }
  }
`;

export const SuccessImgWrapper = styled.div`
  & .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: ${({ theme }) => theme.colors.alertSuccessBg};
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  & .checkmark {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #ffffff;
    stroke-miterlimit: 10;
    box-shadow: inset 0rem 0rem 0rem ${({ theme }) => theme.colors.alertSuccessBg};
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }

  & .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0rem 0rem 0rem 1.875rem ${({ theme }) => theme.colors.alertSuccessBg};
    }
  }



  /* .checkmark_circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 50;
    stroke-miterlimit: 10;
    stroke: green;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 40px auto;
    box-shadow: inset 0px 0px 0px red;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }

  .checkmark_check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px blue;
    }
  } */
`;
