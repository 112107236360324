/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, HTMLAttributes, ReactNode, forwardRef } from 'react';
import { AnyObject } from '../../models/Generic';
import { TableComponent, TableRowComponent, TableHeadComponent, TableBodyComponent, TableCellComponent } from './styles';

export interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  children?: ReactNode | ReactNode[];
  highlight?: boolean;
  highlightColor?: string;
  ref?: any;
}

export const TableRow = forwardRef<HTMLDivElement, TableRowProps>((props, ref) => (
  <TableRowComponent ref={ref} {...props}>{props.children}</TableRowComponent>
));

export interface TableHeadProps
  extends HTMLAttributes<HTMLTableSectionElement> {
  children?: ReactNode | ReactNode[];
}

export const TableHead: FC<TableHeadProps> = ({ children, ...props }) => {
  return <TableHeadComponent {...props}>{children}</TableHeadComponent>;
};

export interface TableBodyProps
  extends HTMLAttributes<HTMLTableSectionElement> {
  children?: ReactNode | ReactNode[];
  ref?: any;
}

export const TableBody = forwardRef<HTMLDivElement, TableBodyProps>((props, ref) => (
  <TableBodyComponent ref={ref} {...props}>{props.children}</TableBodyComponent>
));

export interface TableCellProps extends HTMLAttributes<HTMLTableCellElement> {
  children?: ReactNode | ReactNode[];
  width?: string;
  hasClick?: boolean;
  ref?: any;
  className?: string;
}

export const TableCell: FC<TableCellProps> = ({ children, ...props }) => {
  return <TableCellComponent {...props}>{children}</TableCellComponent>;
};

export interface TableProps extends HTMLAttributes<HTMLTableElement> {
  children?: ReactNode | ReactNode[];
  showTableHeaders?: boolean;
  tableStyles?: AnyObject
}

export const Table: FC<TableProps> = ({
  children,
  ...props
}) => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <TableComponent {...props}>
        {children}
      </TableComponent>
    </div>
  );
};

export default Table;
