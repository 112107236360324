import { FC } from 'react';
import { StyledDropdown, StyledItem, StyledDivider } from './styles';
import { Menu, UnstyledButton } from '@mantine/core';
import Typography from '../typography';

type FloatingPlacement = 'end' | 'start';
type FloatingSide = 'top' | 'right' | 'bottom' | 'left';
type FloatingPosition = FloatingSide | `${FloatingSide}-${FloatingPlacement}`;

export type ActionProps = {
  type: 'link' | 'divider';
  render?: React.ReactNode;
  label?: string;
  subLabel?: string;
  highlight?: boolean;
  onClick?: () => void;
};

export interface MenuProps {
  render: React.ReactNode;
  menuPosition?: FloatingPosition | undefined;
  actions: ActionProps[];
  profileDropdown?: boolean;
}

const renderAction = (action: ActionProps, index: number, profileDropdown: boolean) => {
  if(action.type === 'link') {
    return (
      <StyledItem key={index} highlight={action.highlight} onClick={action.onClick} profileDropdown={profileDropdown}>
        {!!action.render && action.render}
        {action.label && <div><Typography variant='dropdown-menu'>{action.label}</Typography></div>}
        {action.subLabel && <div><Typography variant='dropdown-menu-small' style={{ color: 'rgba(255, 255, 255, 0.5)' }}>{action.subLabel}</Typography></div>}
      </StyledItem>
    );
  }

  if(action.type === 'divider') {
    return (
      <StyledDivider key={index} profileDropdown={profileDropdown} />
    );
  }

  return null;
}

export const DropdownMenu: FC<MenuProps> = ({ render, menuPosition, actions, profileDropdown = false }) => (
  <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex', alignItems: 'center' }}>
    <Menu position={menuPosition || 'bottom'}>
      <Menu.Target>
        <UnstyledButton>
          {render}
        </UnstyledButton>
      </Menu.Target>
      {
        actions.length > 0 &&
        <StyledDropdown profileDropdown={profileDropdown}>
          {
            actions.map((action: ActionProps, index: number) => renderAction(action, index, profileDropdown))
          }
        </StyledDropdown>
      }
    </Menu>
  </div>
);

export default DropdownMenu;
