import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User } from '../../../../../models/User';
import { Box } from '../../../../../styles/BasicStyles';
import { FirstColumn } from '../../../common/styles';
import Typography from '../../../../../components/typography';
import InfoCards from './InfoCards';
import Documents from '../../../common/Documents';
import StatsInfo from './StatsInfo';
import DraftFileList from '../../../common/DraftFileList';

interface Params {
  promoter?: User;
  getInfo: () => void;
  isDraftMember: boolean;
}

const Overview: FC<Params> = ({ promoter, getInfo, isDraftMember }) => {
  const { t } = useTranslation();

  return (
    <Box mt={1.5} fDirection='column' mb={5}>
      <Typography variant='body-small' color='#ffffff' pb={0.688}>{t('GENERAL_INFO').toUpperCase()}</Typography>
      <Box fWrap='wrap'>
        <FirstColumn fullWidth={isDraftMember}>
          <InfoCards promoter={promoter} isDraftMember={isDraftMember} />
          {
            isDraftMember ?
            <DraftFileList initialValues={promoter} />
            :
            <Documents getInfo={getInfo} />
          }
        </FirstColumn>
        {
          !isDraftMember &&
          <Box fDirection='column' w={{ lg: '30%', xxs: '100%' }} pl={{ lg: 1.875, xxs: 0 }} pt={{ lg: 0, xxs: 1.875 }}>
            <StatsInfo promoter={promoter} />
          </Box>
        }
      </Box>
    </Box>
  );
};

export default Overview;
