
import { UserContext } from '../../Context';
import { useMemo, ReactNode, useContext } from 'react';
import { SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL, ATHLETE, COACH } from '../../models/User';

type UserType = typeof SUPER_ADMIN | typeof ADMIN | typeof REGULATOR | typeof RESPONSIBLE | typeof PROMOTER | typeof OFFICIAL | typeof ATHLETE | typeof COACH;

type CanBeRenderedProps = {
  children: ReactNode;
  types?: UserType[];
};

const CanBeRendered = ({ children, types }: CanBeRenderedProps) => {
  const { user } = useContext(UserContext);
  const authorized = useMemo(() => user?.type && types?.includes(user?.type), [user, types]);

  if(!types?.length) {
    return <>{children}</>;
  }

  if(!authorized) {
    return null;
  }

  return <>{children}</>;
}

export default CanBeRendered;
