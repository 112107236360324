import {
  PasswordInput as MantinePassword,
  PasswordInputProps as MantinePasswordInputProps
} from '@mantine/core';
import { FieldRenderProps } from 'react-final-form';
import { GenericInput } from './Generic';
import { InputWrapper, ErrorMessage } from './Styles';
import { useTranslation } from 'react-i18next';
import { InputSize } from './Generic';
import React from 'react';

export interface PasswordInputProps
  extends Omit<FieldRenderProps<string>, 'input'>,
    MantinePasswordInputProps {
  afterChange?: (value: string) => void;
  input: GenericInput<string>;
  size?: InputSize;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  input,
  meta = {},
  afterChange,
  size = 'md',
  ...other
}) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.currentTarget.value;
    input.onChange(value);
    if (afterChange && typeof afterChange === 'function') {
      afterChange(value);
    }
  };

  const hasError = meta?.invalid && meta?.submitFailed;

  return (
    <InputWrapper size={size} hasError={hasError}>
      <MantinePassword
        {...other}
        value={input.value}
        onChange={handleChange}
        error={hasError}
      />
      {hasError && (
        <ErrorMessage>
          {meta.error
            ? t(meta?.error?.tag, meta?.error?.values)
            : t('FIELD_REQUIRED')}
        </ErrorMessage>
      )}
    </InputWrapper>
  );
};

export default PasswordInput;
