/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { Block, Box } from '../../../styles/BasicStyles';
import { FocusColor, LabelColor, PlaceholderColor } from '../../../styles/Colors';
import { showDelete } from '../../../hooks/show-notification/show-notification';
import { useDidUpdate } from '@mantine/hooks';
import { AnyObject } from '../../../components/inputs/Generic';
import { PaginationObject } from '../../../components/page/DefaultListPage';
import { Document, REJECTED } from '../../../models/Document';
import { MemberTypes, PROMOTER, OFFICIAL, COACH, ATHLETE } from '../../../models/User';
import { DocumentStatus } from '../../../utils/user/documents';
import { UserContext } from '../../../Context';
import useFetch from 'use-http';
import Icon from '../../../components/icon';
import Typography from '../../../components/typography';
import DropdownMenu from '../../../components/dropdownMenu';
import BaseTable from '../../../components/table';
import ConstructPaginationRequest from '../../../utils/requests/ConstructPaginationRequest';
import PageFilters from '../../../components/page/PageFilters';
import Button from '../../../components/button';
import DocumentsFilters from './DocumentsFilters';
import ManageDocumentModal from './modals/ManageDocument';
import Link from '../../../components/link';
import dayjs from 'dayjs';
import LabelDropdown from '../../../components/labelDropdown';
import RejectDocumentModal from './modals/RejectDocument';

interface Params {
  getInfo: () => void;
}

const DocumentsTable = BaseTable<Document>();

const Documents: FC<Params> = ({ getInfo }) => {
  const [modal, setModal] = useState<{ open: boolean, initialValues?: Document }>({ open: false });
  const [pagination, setPagination] = useState<PaginationObject>({
    page: 1,
    limit: 10
  });
  const [rows, setRows] = useState<Document[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rejectModal, setRejectModal] = useState<{ open: boolean, document?: Document, closeDocumentModal?: boolean }>({ open: false });
  const { t, i18n: { language } } = useTranslation();
  const { get, del, put } = useFetch('/user-documents');
  const { user } = useContext(UserContext);
  // We need to get the ID from params, because if we passed it down from props and we came from member draft page, it would use the user draft ID and not the correct user ID
  const { id } = useParams();

  useEffect(() => {
    const init = async () => {
      const defaultPagination: PaginationObject = {
        page: 1,
        limit: 20
      };

      setPagination(defaultPagination);
    };

    init();
  }, []);

  useDidUpdate(() => {
    const init = async () => {
      setLoading(true);

      const url = ConstructPaginationRequest(
        `/${id}/${pagination.page}/${pagination.limit}`,
        pagination.filters,
        pagination.sort,
        ['name']
      );

      const result = await get(url);

      if(pagination.page > 1 && (result?.data?.items || []).length === 0) {
        const pag = { ...pagination, page: 1 };
        handleNewPagination(pag);
      } 
      else {
        setRows(result?.data?.items || []);
        setTotal(result?.data?.total || 0);
        setLoading(false);
      }
    };
    
    if(id) init();
  }, [pagination, id]);

  const handleNewPagination = (pag: PaginationObject) => {
    setPagination(pag);
  };

  const handleNewPage = (page: number) => {
    const pag = { ...pagination, page };
    handleNewPagination(pag);
  };

  const handleNewFilters = (filters: AnyObject) => {
    const pag = { ...pagination, page: 1, filters };
    handleNewPagination(pag);
  };

  const refreshPage = (params?: PaginationObject) => {
    const pag = params ? { ...params } : { ...pagination };
    handleNewPagination(pag);
  };
  
  const handleCloseModal = (changed: boolean) => {
    if (changed) refreshPage();
    setModal({ open: false });
  };

  const handleCloseRejectModal = (changed: boolean) => {
    if (changed) refreshPage();
    setRejectModal({ open: false });
  };

  const handleDelete = async (id: string) => {
    showDelete({
      title: t('DELETE_DOCUMENT'),
      message: t('DELETE_DOCUMENT_MESSAGE'),
      onConfirm: async () => {
        await del(`/${id}`);
        refreshPage();

        // If the current User is a Member, we need to reload their info because his status will change
        if(user?.type && MemberTypes.includes(user.type)) getInfo();
      }
    });
  };

  const onConfirmStatusToogle = async (id: string, status: string) => {
    const document = rows?.find((elem: Document) => elem._id === id);

    if(document) {
      if(status === REJECTED) setRejectModal({ open: true, document });
      else {
        const { success } = await put(`/${id}/change-status`, { ...document, status });
        if(success) refreshPage();
        
        // If the current User is a Member, we need to reload their info because his status will change
        if(user?.type && MemberTypes.includes(user.type)) getInfo();
      }
    }
  };

  const openFile = (file: AnyObject) => window.open(`${process.env.REACT_APP_FILE_URL}/${file._id}`, '_blank')

  const columns: any = [
    { 
      title: t('NAME'), 
      dataIndex: 'file',
      render: (file: AnyObject) => (
        <Box fAlign='center'>
          <Icon 
            icon='outlined_documentText' 
            color={FocusColor} 
            hoverColor={LabelColor} 
            onClick={() => openFile(file)} 
          />
          <Link onClick={() => openFile(file)} style={{ marginLeft: '0.625rem' }}>
            <Typography variant='multi-select' fWeight={400} style={{ color: LabelColor }}>
              {file.filename}
            </Typography>
          </Link>
        </Box>
      )
    },
    { 
      title: t('CATEGORY'), 
      dataIndex: 'category',
      width: '20%',
      render: (category: AnyObject) => <Typography variant='multi-select' fWeight={400} style={{ color: PlaceholderColor }}>{category.name[language]}</Typography>
    },
    { 
      title: t('EXPIRATION_DATE'), 
      dataIndex: 'expiration_date',
      width: '20%',
      render: (expiration_date: Date) => <Typography variant='multi-select' fWeight={400} style={{ color: PlaceholderColor }}>{expiration_date ? dayjs(expiration_date).format('DD MMM, YYYY') : ''}</Typography>
    },
    { 
      title: t('STATUS'), 
      dataIndex: 'status',
      width: '20%',
      render: (status: string, document: Document) => 
        <LabelDropdown 
          status={status} 
          list={DocumentStatus} 
          id={document._id} 
          onConfirmStatusToogle={onConfirmStatusToogle} 
          disabled={user?.type === ATHLETE || user?.type === OFFICIAL || user?.type === COACH || user?.type === PROMOTER} 
        />
    },
    { 
      title: t('ACTION'),
      key: 'actions', 
      width: '5%',
      render: (document: Document) => 
        <DropdownMenu 
          render={<Icon icon='outlined_dotsHorizontal' color={LabelColor} />}
          menuPosition='bottom-end'
          actions={[
            {
              type: 'link',
              label: t('EDIT'),
              onClick: () => setModal({ open: true, initialValues: { ...document, category: document.category?._id } })
            },
            {
              type: 'divider'
            },
            {
              type: 'link',
              label: t('DELETE'),
              onClick: () => handleDelete(document._id)
            }
          ]} 
        />
    }
  ];
  
  return (
    <Block mt={1.5} w='100%'>
      <Block mb={0.688}>
        <Typography variant='body-small' color='#ffffff'>{t('DOCUMENTS').toUpperCase()}</Typography>
      </Block>
      <Box fAlign='center' fJustify='space-between' fWrap='wrap'>
        <Box w={{ sm: '70%', xxs: '100%' }}>
          <PageFilters 
            initialFilters={pagination.filters}
            onChange={handleNewFilters}
          >
            {
              ({ filters, handleChange }) => (
                <DocumentsFilters 
                  filters={filters} 
                  handleChange={handleChange}
                />
              )
            }
          </PageFilters>
        </Box>
        <Block w={{ sm: '30%', xxs: '100%' }} display='flex' fJustify={{ sm: 'flex-end', xxs: 'flex-start' }} mt={{ sm: '0', xxs: '0.5rem' }}>
          <Button
            text={t('ADD_DOCUMENT')}
            icon='outlined_plus'
            variant={FocusColor}
            onClick={() => setModal({ open: true })}
            size='sm'
          />
        </Block>
      </Box>
      <DocumentsTable
        columns={columns}
        rows={rows}
        loading={loading}
        tableStyles={{ 
          compact: true
        }}
        pagination={{
          total,
          page: pagination.page,
          setPage: handleNewPage,
          limit: pagination.limit
        }}
      />
      <ManageDocumentModal
        opened={modal.open}
        initialValues={modal.initialValues}
        onClose={handleCloseModal}
        userID={id}
        openRejectModal={setRejectModal}
      />
      <RejectDocumentModal
        opened={rejectModal.open}
        document={rejectModal.document}
        onClose={handleCloseRejectModal}
        closeDocumentModal={rejectModal.closeDocumentModal}
        onCloseDocumentModal={handleCloseModal}
      />
    </Block>
  );
};

export default Documents;
