import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { Translation } from '../../models/Translation';
import { BasicForm } from '../../styles/BasicStyles';
import { isTag } from '../../hooks/use-validation/genericValidations';
import IntlTextAreaInput from '../../components/inputs/IntlTextAreaInput';
import useFetch from 'use-http';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../components/modal';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import Button from '../../components/button';
import Platforms from '../../utils/platforms/Platforms';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: Translation;
}

const { Form } = withTypes<Translation>();

const TranslationModal: React.FC<Params> = ({
  opened,
  onClose,
  initialValues
}) => {
  const { t } = useTranslation();
  const intlObject = useIntlValidation();
  const { post, put } = useFetch('/translations');

  const onSubmit = async (values: Translation) => {
    if (values._id) {
      const { success } = await put(`/${values.tag}`, values);
      if (success) onClose(true);
    } else {
      const { success } = await post(values);
      if (success) onClose(true);
    }
  };

  return (
    <Modal
      size={27.5}
      opened={opened}
      onClose={() => onClose(false)}
      title={
        initialValues && initialValues._id
          ? t('EDIT_TRANSLATION')
          : t('CREATE_TRANSLATION')
      }
    >
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema(
          yup.object({
            tag: isTag.required(),
            value: intlObject({ required: true }),
            platform: yup.string().required().oneOf(Platforms.map((p) => p._id))
          })
        )}
        initialValues={initialValues || {}}
      >
        {({ handleSubmit, submitting }) => (
          <BasicForm onSubmit={handleSubmit}>
            <ModalOverflowContent>
              <ModalContent>
                <Grid gutter="xl">
                  <Grid.Col span={12}>
                    <Field name="tag">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('TAG')}
                          placeholder={t('TRANSLATION_TAG_PLACEHOLDER')}
                          disabled={!!initialValues?._id}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Field name="platform">
                      {(props) => (
                        <SelectInput
                          {...props}
                          label={t('PLATFORM')}
                          placeholder={t('PLATFORM')}
                          data={Platforms}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Field name="value">
                      {(props) => (
                        <IntlTextAreaInput
                          {...props}
                          label={t('TRANSLATION_VALUE')}
                          placeholder="TRANSLATION_VALUE_PLACEHOLDER"
                        />
                      )}
                    </Field>
                  </Grid.Col>
                </Grid>
              </ModalContent>
            </ModalOverflowContent>
            <ModalActions>
              <Button text={t('CANCEL')} variant='secondary' onClick={() => onClose(false)} />
              <Button text={t('SAVE')} type='submit' loading={submitting} />
            </ModalActions>
          </BasicForm>
        )}
      </Form>
    </Modal>
  );
};

export default TranslationModal;
