/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

let timeout: NodeJS.Timer;

interface AnyObject {
  [key: string]: any;
}

export interface FilterChildrenProps {
  filters: AnyObject;
  handleChange: (field: string | string[], value: string | string[] | null, time?: number) => void;
  clearFilters?: () => void;
}

export interface PageFiltersProps {
  initialFilters?: AnyObject;
  onChange: (filters: AnyObject) => void;
  children: React.FC<FilterChildrenProps>;
}

export const PageFilters: React.FC<PageFiltersProps> = ({
  initialFilters,
  onChange,
  children,
}) => {
  const [filters, setFilters] = useState<AnyObject>(initialFilters || {});

  // If "field" is an array, then "value" should also be with the same number of elements
  const handleChange = (field: string | string[], value: string | string[] | null, time = 0) => {
    let newFilters: AnyObject = { ...filters };

    if(Array.isArray(field)) {
      field.forEach((key: string, index: number) => {
        newFilters = { ...newFilters, [key]: value && value[index] };
      });
    }
    else {
      newFilters = { ...newFilters, [field]: value };
    }

    setFilters(newFilters);

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      const cleanObj = { ...newFilters };
      Object.keys(cleanObj).forEach(key => {
        if (!cleanObj[key] || cleanObj[key] === '') {
          delete cleanObj[key];
        }
      });
      if (onChange) onChange(cleanObj);
    }, time);
  };

  const clearFilters = () => {
    setFilters({ search: '' });
    if (onChange) onChange({});
  };

  return children({ filters, handleChange, clearFilters });
};

export default PageFilters;
