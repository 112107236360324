/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonWrapper, ButtonText } from './styles';
import { FC, MouseEvent } from 'react';
import { LabelColor } from '../../styles/Colors';
import Icon from '../icon';
import IconType from '../../assets/icons/props';

export type ButtonVariants = 'gradient' | 'primary' | 'secondary' | undefined;

export type ButtonSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;

export type ButtonTypes = 'submit' | 'button' | 'reset' | undefined;

export interface ButtonProps {
  text?: string;
  icon?: IconType;
  size?: ButtonSizes;
  variant?: ButtonVariants | string;
  type?: ButtonTypes;
  disabled?: boolean;
  loading?: boolean;
  round?: boolean;
  onClick?: (event: MouseEvent) => void;
  textColor?: string;
  reverseIcon?: boolean;
}

const Button: FC<ButtonProps & any> = ({ text, icon, ...forwardProps }) => {
  const { size, loading, round, textColor, reverseIcon = false } = forwardProps;
  
  return (
    <ButtonWrapper {...forwardProps}>
      {!reverseIcon && !!icon && !loading && <Icon icon={icon} className='icon' color={textColor || LabelColor} />}
      {!round && <ButtonText size={size} textColor={textColor}>{text}</ButtonText>}
      {reverseIcon && !!icon && !loading && <Icon icon={icon} className='icon' color={textColor || LabelColor} />}
    </ButtonWrapper>
  )
};

Button.defaultProps = {
  type: 'button',
  size: 'md',
  variant: 'gradient',
  disabled: false,
  loading: false,
  round: false
};

export default Button;
