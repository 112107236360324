import { Block, transitionsDelay } from '../../../../../styles/BasicStyles';
import styled from 'styled-components';

export const ProgressBar = styled.div<{ progress: number }>`
  width: 100%;
  height: 0.25rem;
  background-color: rgba(255, 255, 255, 0.2);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ progress }) => progress}%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.focusColor};
    z-index: 1;
  }
`;

export const BackWrapper = styled(Block)`
  cursor: pointer;

  & svg path[fill] {
    transition: fill ${transitionsDelay} linear;
  }

  & svg path[stroke] {
    transition: stroke ${transitionsDelay} linear;
  }

  & span {
    transition: color ${transitionsDelay} linear;
  }

  &:hover {
    & svg path[fill] {
      fill: ${({ theme }) => theme.colors.focusColor};
    }

    & svg path[stroke] {
      stroke: ${({ theme }) => theme.colors.focusColor};
    }

    & span {
      color: ${({ theme }) => theme.colors.focusColor} !important;
    }
  }
`;

export const AddMoreWrapper = styled(Block)`
  cursor: pointer;

  & svg path[fill] {
    transition: fill ${transitionsDelay} linear;
  }

  & svg path[stroke] {
    transition: stroke ${transitionsDelay} linear;
  }

  & span {
    transition: color ${transitionsDelay} linear;
  }

  &:hover {
    & svg path[fill] {
      fill: ${({ theme }) => theme.colors.focusColor};
    }

    & svg path[stroke] {
      stroke: ${({ theme }) => theme.colors.focusColor};
    }

    & div {
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.focusColor};
    }

    & span {
      color: ${({ theme }) => theme.colors.focusColor} !important;
    }
  }
`;

export const IconWrapper = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.focusColor};
  border: 0.063rem solid ${({ theme }) => theme.colors.focusColor};
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  transition: background-color ${transitionsDelay} linear, border-color ${transitionsDelay} linear;
`;

export const FighterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;


export const FighterColumn = styled.div`
  &:last-child {
    padding-left: 0.5rem;
    margin-top: 1.75rem;
  }
`;
