import { Box, Block } from '../../../styles/BasicStyles';
import { LabelColor, BgColor, MenuBgColor, BorderColor, PlaceholderColor, WhiteJudge, GreenJudge, YellowJudge, BlueFighter, RedFighter } from '../../../styles/Colors';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { consistencyTreshold, NEEDS_REVIEW, Review, ReviewRound, ReviewStatusList } from '../../../models/Review';
import { AnyObject } from '../../../models/Generic';
import Typography from '../../../components/typography';
import Consistency from '../../../components/consistency';
import Label from '../../../components/label';

type Judge = 'white' | 'green' | 'yellow';

type ContentProps = {
  row: Review
};

const Content: FC<ContentProps> = ({ row }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const enterDetailPage = () => navigate(`/fights/${row.fight?._id}`);

  const renderColorBar = () => {
    return (
      <Box w='97%'>
        <Block padding='0 0.5 0 0' w='15%' display={{ sm: 'block', xxs: 'none' }} />
        <Block padding='0 0.5 0 0' w={{ sm: '15%', xs: '30%', xxs: '38%' }} />
        <Block padding='0 0.5' w='20%' display='flex' fJustify='center'>
          <Block w={3} h={0.5} bgColor={RedFighter} />
        </Block>
        <Block padding='0 0.5' w='20%' display='flex' fJustify='center'>
          <Block w={3} h={0.5} bgColor={BlueFighter} />
        </Block>
      </Box>
    );
  };

  const renderRound = (round: ReviewRound, judge: Judge, showRound = false) => {
    let color = '';
    if(judge === 'white') color = WhiteJudge;
    else if(judge === 'green') color = GreenJudge;
    else if(judge === 'yellow') color = YellowJudge;

    const pointsBlue = row.fight?.record?.points[judge]?.filter((elem: AnyObject) => elem.round === round.round).reduce((acc: number, elem: AnyObject) => acc + elem.blue, 0);
    const pointsRed = row.fight?.record?.points[judge]?.filter((elem: AnyObject) => elem.round === round.round).reduce((acc: number, elem: AnyObject) => acc + elem.red, 0);

    return (
      <Box padding='0.5 0' w='97%'>
        <Block padding='0 0.5 0 0' w='15%' display={{ sm: 'block', xxs: 'none' }}>
          {
            showRound &&
            <Typography variant='body-small' fWeight={500} style={{ color: PlaceholderColor }}>{`${t('ROUND').toUpperCase()} ${round.round}`}</Typography>
          }
        </Block>
        <Block padding={{ sm: '0 0.5', xxs: '0 0.5 0 0' }} w={{ sm: '15%', xs: '30%', xxs: '38%' }}>
          <Typography variant='body-small' fWeight={500} style={{ color }}>{row.fight && row.fight[`${judge}_judge`] && row.fight[`${judge}_judge`]?.name}</Typography>
        </Block>
        <Block padding='0 0.5' w='20%' tAlign='center'>
          <Typography variant='body-small' fWeight={500} style={{ color: LabelColor }}>{pointsRed}</Typography>
        </Block>
        <Block padding='0 0.5' w='20%' tAlign='center'>
          <Typography variant='body-small' fWeight={500} style={{ color: LabelColor }}>{pointsBlue}</Typography>
        </Block>
        <Block margin='0 0.5' w='9%'>
          <Consistency value={round[`consistency_${judge}`]} />
        </Block>
        <Block margin='0 0 0 0.5' w='18%' display={{ sm: 'flex', xxs: 'none' }} fJustify='center'>
          {
            row.status === NEEDS_REVIEW && round[`consistency_${judge}`] < consistencyTreshold &&
            <Label 
              text={t(ReviewStatusList[0].name)} 
              icon={ReviewStatusList[0].icon}
              iconColor={ReviewStatusList[0].iconColor}
              fontColor={ReviewStatusList[0].fontColor}
              bgColor={ReviewStatusList[0].bgColor}
            />
          }
        </Block>
      </Box>
    );
  };
  
  const renderContentDesktop = () => {
    return (
      <Box display={{ sm: 'flex', xxs: 'none' }} fDirection='column'>
        {
          row?.rounds?.map((round: ReviewRound, index: number) => (
            <Box 
              key={index} 
              padding='0.625 1.5'
              fDirection='column'
              bgColor={index % 2 === 0 ? BgColor : MenuBgColor} 
              style={{ borderBottom: `0.063rem solid ${BorderColor}`, cursor: 'pointer' }}
              onClick={enterDetailPage}
            >
              {renderColorBar()}
              {renderRound(round, 'white', true)}
              {renderRound(round, 'green')}
              {renderRound(round, 'yellow')}
            </Box>
          ))
        }
      </Box>
    );
  };

  const renderContentMobile = () => {
    return (
      <Box display={{ sm: 'none', xxs: 'flex' }} fDirection='column'>
        {
          row?.rounds?.map((round: ReviewRound, index: number) => (
            <Box 
              key={index} 
              padding='0.625 1.5' 
              fDirection='column'
              bgColor={index % 2 === 0 ? BgColor : MenuBgColor} 
              style={{ borderBottom: `0.063rem solid ${BorderColor}`, cursor: 'pointer' }}
              onClick={enterDetailPage}
            >
              <Box fAlign='center' pb={0.75}>
                <Typography variant='body-small' fWeight={500} mr={0.5} style={{ color: PlaceholderColor }}>{`${t('ROUND').toUpperCase()} ${round.round}`}</Typography>
                {
                  row.status === NEEDS_REVIEW && round.consistency_round < consistencyTreshold &&
                  <Label 
                    text={t(ReviewStatusList[0].name)} 
                    icon={ReviewStatusList[0].icon}
                    iconColor={ReviewStatusList[0].iconColor}
                    fontColor={ReviewStatusList[0].fontColor}
                    bgColor={ReviewStatusList[0].bgColor}
                  />
                }
              </Box>
              {renderColorBar()}
              {renderRound(round, 'white', true)}
              {renderRound(round, 'green')}
              {renderRound(round, 'yellow')}
            </Box>
          ))
        }
      </Box>
    );
  };

  return (
    <>
      {
        row.rounds && row.rounds.length > 0 ?
        <Block mb={1.125}>
          {renderContentDesktop()}
          {renderContentMobile()}
        </Block>
        :
        <Block w='100%' tAlign='center' padding={1}>
          <Typography variant='body-small' color={LabelColor}>{t('NO_ROUNDS_TEXT')}</Typography>
        </Block>
      }
    </>
  );
};

export default Content;