import { DynamicPage } from '../../models/DynamicPage';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { AnyObject } from '../../models/Generic';
import useFetch from 'use-http';
import DefaultListPage from '../../components/page/DefaultListPage';
import TextInput from '../../components/inputs/TextInput';
import Icon from '../../components/icon';
import Typography from '../../components/typography';

const DynamicPagesListPage = DefaultListPage<DynamicPage>();

const DynamicPages = () => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    { 
      title: t('TITLE'), 
      dataIndex: 'title', 
      sortable: true,
      render: (value: AnyObject) => <Typography variant='table-header'>{value && value[language]}</Typography>
    },
    { 
      title: t('TAG'), 
      dataIndex: 'tag', 
      sortable: true 
    }
  ];

  const { get } = useFetch('/dynamic-pages');

  return (
    <DynamicPagesListPage
      translate={t}
      request={{
        get,
        searchFields: ['tag', 'title|intl']
      }}
      storage="DYNAMIC_PAGES_FILTERS"
      header={{
        title: t('DYNAMIC_PAGES'),
        actions: []
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24} style={{ justifyContent: 'flex-end' }}>
          <Grid.Col sm={6} xs={12}>
            <TextInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              icon={<Icon icon="outlined_search" />}
              onSearch={(v: string | null) => handleChange('search', v)}
              placeholder={t('SEARCH_TITLE_TAG')}
              clearable
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/dynamic-pages/${row._id}`)
      }}
    />
  );
};

export default DynamicPages;
