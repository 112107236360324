/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconSvg } from './styles';
import React from 'react';
import icons from '../../assets/icons';
import IconType from '../../assets/icons/props';

export type IconTypes = IconType;

export interface IconProps {
  icon: IconType;
  size?: number | number[];
  color?: string;
  hoverColor?: string;
  className?: string;
  onClick?: () => void;
  style?: any;
  title?: string;
}

export const Icon: React.FC<IconProps> = ({ icon, ...otherProps }) => {
  const SelectedIcon = (icons as any)[icon];

  if(!SelectedIcon) {
    return null;
  }

  return <IconSvg {...otherProps}>{SelectedIcon}</IconSvg>;
};

export default Icon;
