import { Box } from '../../styles/BasicStyles';
import styled from 'styled-components';

export const ItemsWrapper = styled(Box)`
  border: 0.063rem solid ${({ theme }) => theme.colors.borderColor};

  & > div {
    border-bottom: 0.063rem solid ${({ theme }) => theme.colors.borderColor};

    &:last-child {
      border-bottom: 0;
    }
  }
`;
