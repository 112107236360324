import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { User, AdminListType, SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE } from '../../../models/User';
import { BasicForm } from '../../../styles/BasicStyles';
import { AspectRatios } from '../../../utils/files/Conversions';
import { Federation } from '../../../models/Federation';
import { useState, FC, useEffect, useContext } from 'react';
import { showSuccess } from '../../../hooks/show-notification/show-notification';
import { Languages } from '../../../utils/languages/AvailableTranslations';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../components/modal';
import useFetch from 'use-http';
import TextInput from '../../../components/inputs/TextInput';
import ImageInput from '../../../components/inputs/ImageInput';
import Button from '../../../components/button';
import toFormData from '../../../utils/formdata/ToFormData';
import SelectInput from '../../../components/inputs/SelectInput';
import { UserContext } from '../../../Context';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: User;
  availableAdminList: AdminListType[];
  federations?: Federation[];
}

const validations = [
  yup.object({
    type: yup.string().required(),
    name: yup.string().required(),
    email: yup.string().email().required(),
    language: yup.string().required()
  }),
  yup.object({
    type: yup.string().required(),
    name: yup.string().required(),
    email: yup.string().email().required(),
    language: yup.string().required(),
    federation: yup.string().required()
  })
];

const { Form } = withTypes<User>();

const ManageAdminModal: FC<Params> = ({ opened, onClose, initialValues, availableAdminList, federations }) => {
  const { t } = useTranslation();
  const { post, put } = useFetch('/admins');
  const [showFederationField, setFederationField] = useState((!!initialValues && initialValues?.type === RESPONSIBLE) ? true : false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setFederationField((!!initialValues && initialValues?.type === RESPONSIBLE) ? true : false);
  }, [opened]);
  
  const onSubmit = async (values: User) => {
    // If we're changing the type of User from Responsible or Promoter to Admin, clear the Federation field
    if(values?.type === SUPER_ADMIN || values?.type === ADMIN || values?.type === REGULATOR) values.federation = undefined;

    if(user?.type === RESPONSIBLE) values.federation = user?.federation?._id;

    let error = false;
    const payload = toFormData(values);

    if(values._id) {
      const { success } = await put(`/${values._id}`, payload);
      if(success) onClose(true);
      else error = true;
    } 
    else {
      const { success } = await post(payload);
      if(success) {
        showSuccess({
          title: t('USER_INVITED_SUCCESS'),
          message: t('USER_INVITED_MESSAGE')
        });
        
        onClose(true);
      }
      else error = true;
    }

    return !error;
  };

  const checkUserType = (value: string | null) => {
    if(value === RESPONSIBLE) setFederationField(true);
    else setFederationField(false);
  };

  return (
    <Modal
      size={38.75}
      opened={opened}
      onClose={() => onClose(false)}
      title={initialValues?._id ? t('EDIT_USER') : t('ADD_NEW_USER')}
    >
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema((showFederationField && user?.type !== RESPONSIBLE) ? validations[1] : validations[0])}
        initialValues={initialValues || {}}
      >
        {({ handleSubmit, submitting, form: { reset } }) => (
          <BasicForm onSubmit={async event => {
            const result = await handleSubmit(event);
            if(result) reset();
          }}>
            <ModalOverflowContent>
              <ModalContent>
                <Grid gutter="xl">
                  <Grid.Col xs={12}>
                    <Field name="photo">
                      {(props) =>
                        <ImageInput
                          {...props}
                          ratio={AspectRatios['1:1']}
                        />
                      }
                    </Field>
                  </Grid.Col>
                </Grid>
                <Grid gutter="xl">
                  <Grid.Col xs={12}>
                    <Field name="name">
                      {(props) => 
                        <TextInput 
                          {...props} 
                          label={`${t('FULL_NAME')} *`} 
                          placeholder={t('ENTER_NAME')}
                        />
                      }
                    </Field>
                  </Grid.Col>
                </Grid>
                <Grid gutter="xl">
                  <Grid.Col xs={12} sm={6}>
                    <Field name="email">
                      {(props) =>
                        <TextInput
                          {...props}
                          label={`${t('EMAIL')} *`}
                          htmlType="email"
                          placeholder={t('ENTER_EMAIL')}
                        />
                      }
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name='language'>
                      {props => (
                        <SelectInput
                          {...props}
                          label={`${t('LANGUAGE')} *`} 
                          placeholder={t('CHOOSE_LANGUAGE')}
                          data={Languages}
                          keyValue='code'
                        />
                      )}
                    </Field>
                  </Grid.Col>
                </Grid>
                <Grid gutter="xl">
                  <Grid.Col xs={12} sm={6}>
                    <Field name='type'>
                      {props => (
                        <SelectInput
                          {...props}
                          label={`${t('TYPE')} *`} 
                          placeholder={t('CHOOSE_TYPE')}
                          data={availableAdminList}
                          translate
                          afterChange={checkUserType}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  { 
                    showFederationField && user?.type !== RESPONSIBLE &&
                    <Grid.Col xs={12} sm={6}>
                      <Field name='federation'>
                        {props => (
                          <SelectInput
                            {...props}
                            label={`${t('FEDERATION')} *`} 
                            placeholder={t('CHOOSE_FEDERATION')}
                            data={federations || []}
                          />
                        )}
                      </Field>
                    </Grid.Col>
                  }
                </Grid>
              </ModalContent>
            </ModalOverflowContent>
            <ModalActions>
              <Button text={t('CANCEL')} variant='secondary' onClick={() => onClose(false)} disabled={submitting} />
              <Button text={initialValues?._id ? t('SAVE') : t('CREATE')} type='submit' loading={submitting} disabled={submitting} />
            </ModalActions>
          </BasicForm>
        )}
      </Form>
    </Modal>
  );
};

export default ManageAdminModal;
