import { FC, HTMLAttributes} from 'react';
import { DividerColor } from '../../styles/Colors';
import { DividerComponent } from './styles';

export interface DividerProps extends HTMLAttributes<HTMLHRElement> {
  variant?: 'dashed' | 'dotted' | 'solid';
  vertical?: boolean;
  color?: string;
  width?: string;
  verticalHeight?: string;
}

export const Divider: FC<DividerProps> = ({ variant = 'solid', vertical = false, color = DividerColor, verticalHeight='200px', width = '100%', ...props }) => 
  <DividerComponent 
    variant={variant}
    vertical={vertical}
    color={color}
    width={width}
    verticalHeight={verticalHeight}
    { ...props}
  />;

export default Divider;