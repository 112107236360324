import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User } from '../../../../../models/User';
import { AnyObject } from '../../../../../models/Generic';
import Typography from '../../../../../components/typography';
import InfoCard from '../../../common/InfoCard';
import dayjs from 'dayjs';

interface Params {
  official?: User;
  isDraftMember: boolean;
}

// TODO: check what to do with dates "Renewal" and "Official since"

const InfoCards: FC<Params> = ({ official, isDraftMember }) => {
  const { t, i18n: { language } } = useTranslation();

  let roles = '';
  if(official?.roles && official.roles.length > 0) {
    official.roles.forEach((role: string) => roles = `${roles}${t(role)}, `);

    roles = roles.slice(0, -2); 
  }

  let martialArts = '';
  if(official?.martial_arts && official.martial_arts.length > 0) {
    official.martial_arts.forEach((martialArt: AnyObject) => martialArts = `${martialArts}${martialArt.name}, `);

    martialArts = martialArts.slice(0, -2); 
  }

  let evaluationClasses = '';
  if(official?.class && official.class.length > 0) {
    official.class.forEach((elem: AnyObject) => evaluationClasses = `${evaluationClasses}${elem.name[language]}, `);

    evaluationClasses = evaluationClasses.slice(0, -2); 
  }

  let address = '';
  if(official?.address) address = official.address;
  if(official?.postal_code) address = address ? `${address} ${official.postal_code}` : official.postal_code;
  if(official?.city) address = address ? `${address}, ${official.city}` : official.city;
  
  const generalInfo = [
    {
      title: t('BIRTH_DATE'),
      value: official?.birthday ? dayjs(official?.birthday).format('DD MMM, YYYY') : ''
    },
    {
      title: t('ADDRESS'),
      value: address
    },
    {
      title: t('COUNTRY'),
      value: official?.country?.name
    },
    {
      title: t('GENDER'),
      value: t(official?.gender || '')
    },
    {
      title: t('NATIONALITY'),
      value: official?.nationality
    },
    {
      title: t('EMAIL'),
      value: official?.email
    },
    {
      title: t('PHONE_NUMBER'),
      value: official?.phone
    },
    {
      title: t('WEIGHT'),
      value: official?.weight && `${official.weight} kg`
    },
    {
      title: t('HEIGHT'),
      value: official?.height && `${official.height} cm`
    },
    {
      title: t('WEIGHT_CLASS'),
      value: official?.weight_class?.name && official.weight_class.name[language]
    },
    {
      title: t('CLUB'),
      value: official?.club?.name
    },
    {
      title: t('REGISTERED_AS'),
      value: roles
    }
  ];

  const federationInfo = [
    {
      title: t('FEDERATION'),
      value: official?.federation?.name
    }
  ];

  if(!isDraftMember) {
    generalInfo.push({
      title: t('OVERALL_CONSISTENCY_RATE'),
      value: `${official?.stats?.consistency?.toFixed(2)}%`
    });

    federationInfo.push({
      title: t('AFFILIATION_NUMBER'),
      value: official?.associate
    });
  }

  return (
    <>
      <InfoCard 
        items={generalInfo}
      />
      <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('CAREER_INFO').toUpperCase()}</Typography>
      <InfoCard 
        items={[
          {
            title: t('CLASS'),
            value: evaluationClasses
          },
          {
            title: t('MARTIAL_ARTS_BACKGROUND'),
            value: martialArts
          },
          {
            title: t('CERTIFICATIONS'),
            value: official?.certifications 
          }
        ]}
      />
      <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('FEDERATION_INFO').toUpperCase()}</Typography>
      <InfoCard 
        items={federationInfo}
      />
    </>
  );
};

export default InfoCards;
