import { AnyObject } from 'final-form';
import { Fight } from './Fight';
import { User } from './User';
import { Federation } from './Federation';

type Stats = {
  consistency?: number;
  totalBreaks?: number,
  pointsDeducted?: number,
  decision?: number,
  submission?: number,
  ko?: number
};

export interface Event {
  readonly _id: string;
  name: string;
  photo?: AnyObject;
  promoter?: User | AnyObject;
  start_datetime: Date;
  end_datetime: Date;
  country?: AnyObject;
  location?: string;
  director?: User;
  fights?: Fight[]; 
  officials?: User[];
  federation?: Federation | AnyObject;
  status?: string;
  stats?: Stats;
}

/* EVENT STATUS */
export const ACTIVE = 'ACTIVE';
export const DEACTIVATED = 'DEACTIVATED';

/* EVENT DRAFT STATUS */
export const AWAITING_VALIDATION = 'AWAITING_VALIDATION';
export const REJECTED = 'REJECTED';
