
import { FC } from 'react';
import { useState } from 'react';
import { GenericInput } from '../../models/Generic';
import { useTranslation } from 'react-i18next';
import { EmailParams } from '../../models/EmailTemplate';
import { FocusColor, MenuBgColor, DangerColor } from '../../styles/Colors';
import { Block } from '../../styles/BasicStyles';
import { Grid } from '@mantine/core';
import AceEditor from 'react-ace';
import Typography from '../typography';
import Button from '../button';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';

export interface HtmlInputProps {
  input: GenericInput<string>;
  placeholder?: string;
  params?: EmailParams[];
}

const HtmlInput: FC<HtmlInputProps> = ({ input, placeholder, params }) => {
  const [expandEditor, setExpandEditor] = useState(false);
  const { t } = useTranslation();

  return (
    <Block margin="1.25 0">
      <Grid>
        <Grid.Col xs={12}>
          <Typography variant="body-bold">
            <span style={{ color: DangerColor }}>{t('NOTE')}: </span>
            {t('EMAIL_TEMPLATE_TAG_WARNING')}
          </Typography>
          {params && params.length > 0 && (
            <Block margin="1.875 0 0.625 0">
              <Typography variant="body-bold" style={{ color: DangerColor }}>
                {t('AVAILABLE_TAGS')}:
              </Typography>
              {params.map((param) => (
                <div key={param.name}>
                  <Typography variant="body-bold">
                    {`{{params.${param.name}}}`}:
                  </Typography>
                  <Typography variant="body"> {param.label}</Typography>
                </div>
              ))}
            </Block>
          )}
        </Grid.Col>
        <Grid.Col xs={12}>
          <Block tAlign='right'>
            <Button 
              text={expandEditor ? t('SHRINK_EDITOR') : t('EXPAND_EDITOR')}
              variant={FocusColor}
              onClick={() => setExpandEditor(!expandEditor)}
            />
          </Block>
        </Grid.Col>
        <Grid.Col md={expandEditor ? 12 : 6} xs={12}>
          <AceEditor
            placeholder={placeholder}
            mode="html"
            theme="monokai"
            name="html"
            onLoad={(editorInstance) => {
              editorInstance.container.style.resize = 'both';
              document.addEventListener('mouseup', () =>
                editorInstance.resize()
              );
            }}
            onChange={input.onChange}
            fontSize={14}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            value={input.value}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2
            }}
            style={{ width: '100%', height: '37.5rem' }}
          />
        </Grid.Col>
        <Grid.Col md={expandEditor ? 12 : 6} xs={12}>
          <Block
            dangerouslySetInnerHTML={{
              __html: input.value || ''
            }}
            bgColor={MenuBgColor}
            padding={0.625}
            bRadius={0.375}
          />
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default HtmlInput;
