/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { Box } from '../../../../styles/BasicStyles';
import { FC, useState } from 'react';
import { Rounds } from '../../../../utils/rounds';
import { FocusColor, G800 } from '../../../../styles/Colors';
import { AnyObject } from '../../../../models/Generic';
import { Pause } from '../../../../models/Fight';
import { TimeToMilliseconds } from '../../../../utils/dates';
import Button from '../../../../components/button';
import SelectInput from '../../../../components/inputs/SelectInput';
import TimeInput from '../../../../components/inputs/TimeInput';

interface Params {
  reasonsList: AnyObject[];
  pausesList: Pause[];
  setPausesList: (values: Pause[]) => void;
  setFormPristine: (value: boolean) => void;
}

const NewPauseModal: FC<Params> = ({ reasonsList, pausesList, setPausesList, setFormPristine }) => {
  const { t, i18n:{ language } } = useTranslation();
  const [errors, setErrors] = useState<{ round: boolean, startTime: boolean, duration: boolean }>({ round: false, startTime: false, duration: false });
  const [round, setRound] = useState('');
  const [startTime, setStartTime] = useState(undefined);
  const [duration, setDuration] = useState(undefined);
  const [reason, setReason] = useState('');

  const onSubmit = async () => {
    if(round && startTime && duration) {
      const reasonName = reasonsList.find(elem => elem._id === reason)?.name;

      const aux: Pause[] = [...pausesList];
      aux.push({
        round,
        start: TimeToMilliseconds(startTime),
        time: TimeToMilliseconds(duration),
        startTime,
        duration,
        reason,
        reasonName: reason && reasonName ? reasonName[language] : ''
      });

      setPausesList(aux);
      setErrors({ round: false, startTime: false, duration: false });
      setRound('');
      setStartTime(undefined);
      setDuration(undefined);
      setReason('');
      setFormPristine(false);
    }
    else {
      const errors = { round: false, startTime: false, duration: false };

      if(!round) errors.round = true;
      if(!startTime) errors.startTime = true;
      if(!duration) errors.duration = true;

      setErrors(errors);
    }
  };

  return (
    <Box fWrap='wrap' bgColor={G800} padding='1 0.75'>
      <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
        <SelectInput 
          label={`${t('ROUND')} *`}
          placeholder={t('CHOOSE_ROUND')}
          data={Rounds}
          meta={{}}
          input={{ value: round, onChange: (value: any) => { setErrors({ ...errors, round: false }); setRound(value) } }}
          showError={errors.round}
        />
      </Box>
      <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
        <TimeInput 
          label={`${t('WA_START')} *`} 
          withSeconds
          meta={{}}
          input={{ value: startTime, onChange: (value: any) => { setErrors({ ...errors, startTime: false }); setStartTime(value); } }}
          showError={errors.startTime}
        />
      </Box>
      <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
        <TimeInput 
          label={`${t('DURATION')} *`} 
          withSeconds
          meta={{}}
          input={{ value: duration, onChange: (value: any) => { setErrors({ ...errors, duration: false }); setDuration(value); } }}
          showError={errors.duration}
        />
      </Box>
      <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
        <SelectInput 
          label={t('REASON')}
          placeholder={t('CHOOSE_REASON')}
          data={reasonsList}
          intl
          meta={{}}
          input={{ value: reason, onChange: (value: any) => setReason(value) }}
          clearable
        />
      </Box>
      <Box fJustify='flex-end' w={{ xs: '66.666%', xxs: '100%' }} padding='0 0.5' mt={{ xs: 1.875, xxs: 0.25 }}>
        <Button
          text={t('ADD')}
          variant={FocusColor}
          onClick={onSubmit}
        />
      </Box>
    </Box>
  );
};

export default NewPauseModal;
