/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mantine/core';
import { Block } from '../../styles/BasicStyles';
import Icon from '../icon';

type UserProps = {
  userFederation?: any;
  eventFederation?: any;
  size?: 'xs' | 'md';
};

const UserFederation: FC<UserProps> = ({ userFederation, eventFederation, size = 'md' }) => {
  const { t } = useTranslation();

  return (
    <>
      {
        userFederation && eventFederation && userFederation !== eventFederation &&
        <Tooltip label={t('NOT_IN_FEDERATION')}>
          <Block>
            <Icon icon='outlined_globeAlt' size={size === 'xs' ? 1 : 1.25} color='#009fdc' style={{ marginLeft: '0.5rem' }} />
          </Block>
        </Tooltip>
      }
    </>
  );
};

export default UserFederation;