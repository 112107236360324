import { Collapse } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { GroupOption, OptionName } from '../styles';
import { SidebarOptionProps } from '../options';
import { Box } from '../../../styles/BasicStyles';
import Icon from '../../icon';

interface DropdownProps {
  option: SidebarOptionProps;
  renderMenuOptions: React.FC<SidebarOptionProps>;
  dropdownIsExpanded: number | undefined;
  setDropdownIsExpanded: (value?: number) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ option, renderMenuOptions, dropdownIsExpanded, setDropdownIsExpanded }) => {
  const { t } = useTranslation();

  return (
    <GroupOption isActive={dropdownIsExpanded === option.number}>
      <Box fAlign='center' fJustify='space-between' onClick={() => setDropdownIsExpanded(dropdownIsExpanded === option.number ? 0 : option.number)}>
        <Box fAlign='center'>
          {option.icon}
          <OptionName
            variant="body-small-bold"
            hasIcon={!!option.icon}
          >
            {t(option.name || '')}
          </OptionName>
        </Box>
        <Icon icon={dropdownIsExpanded === option.number ? 'bold_chevronUp' : 'bold_chevronDown'} />
      </Box>
      {
        option.options && option.options.length > 0 &&
        <Collapse in={dropdownIsExpanded === option.number}>
          {
            option.options.map((option: SidebarOptionProps) => renderMenuOptions(option, true))
          }
        </Collapse>
      }
    </GroupOption>
  );
};

export default Dropdown;
