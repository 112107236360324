import { AnyObject } from "../../models/Generic";

export const DocumentStatus = [
  { _id: 'AWAITING_REVIEW', name: 'AWAITING_REVIEW', bgColor: 'rgba(79, 194, 244, 0.15)', fontColor: '#4fc2f4' },
  { _id: 'EXPIRED', name: 'EXPIRED', bgColor: 'rgba(255, 160, 0, 0.09)', fontColor: '#FFA000' },
  { _id: 'VALIDATED', name: 'VALIDATED', bgColor: 'rgba(76, 175, 80, 0.12)', fontColor: '#4CAF50' },
  { _id: 'REJECTED', name: 'REJECTED', bgColor: 'rgba(217, 99, 99, 0.1)', fontColor: '#D96363' }
];

export const GetDocumentStatus = (value: string | undefined) => {
  const found: AnyObject = DocumentStatus.find(elem => elem._id === value) || {};

  return found;
};