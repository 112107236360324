/* eslint-disable react/display-name */
import { forwardRef, ComponentPropsWithoutRef } from 'react';
import { Box } from '../../styles/BasicStyles';
import { GetFirstAndLastName } from '../../utils/user';
import Typography from '../typography';
import UserStatus from '../userStatus';

interface ItemProps extends ComponentPropsWithoutRef<'div'> {
  status: string;
  label: string;
}

const UserItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, status, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Box fAlign='center'>
        <Typography variant='body-small'>{GetFirstAndLastName(label)}</Typography>
        <UserStatus status={status} />
      </Box>
    </div>
  )
);

export default UserItem;
