import { FC, useEffect, useState } from 'react';
import { Box } from '../../styles/BasicStyles';
import { NotificationsWrapper, NotificationsTitle, NotificationsContent, NotificationsOverflowContent } from './styles';
import { useTranslation } from 'react-i18next';
import { FiltersBgColor, FocusColor, SubMenuColor } from '../../styles/Colors';
import { useNavigate } from 'react-router-dom';
import { FormatNotificationDate, NotificationButton } from '../../utils/notifications';
import { Notification } from '../../models/Notification';
import useFetch from 'use-http';
import Typography from '../typography';
import Button from '../button';
import Link from '../link';
import LoadingOverlay from '../loadingOverlay';

interface Params {
  open: boolean;
  closeNotificationsModal: () => void;
}

const NotificationsModal: FC<Params> = ({ open, closeNotificationsModal }) => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { loading, get } = useFetch('/notifications');

  useEffect(() => {
    const init = async () => {
      const { data } = await get('/last');
      setNotifications(data || []);
    };

    if(open) init();
  }, [open]);

  const renderNotification = (notification: Notification, index: number) => {
    const { textLink, link } = NotificationButton(notification);

    return (
      <Box key={index} padding='0.75 0' style={{ borderBottom: index === (notifications.length - 1) ? 0 : '0.063rem solid #697386' }}>
        <Box fDirection='column' padding='0 1'>
          <Typography variant='body-small' style={{ color: SubMenuColor }} dangerouslySetInnerHTML={{__html: notification.text && notification.text[language]}} />
          {
            !!textLink && !!link &&
            <Button 
              text={t(textLink)}
              variant={FocusColor}
              size='xs'
              onClick={() => navigation(link)}
              mt={0.5}
            />
          }
          <Typography variant='body-small' fWeight={500} color='#697386' mt={0.625}>{FormatNotificationDate(notification.createdAt, t, language)}</Typography>
        </Box>
      </Box>
    );
  };

  const navigation = (url: string) => {
    closeNotificationsModal();
    navigate(url);
  }

  return (
    <>
      {
        open && 
        <NotificationsWrapper>
          <NotificationsTitle fJustify='space-between' bgColor='#2E2F34' padding='0.75 1'>
            <Typography variant='body-small' fWeight={500} style={{ color: SubMenuColor }}>{t('NOTIFICATIONS')}</Typography>
            <Link onClick={() => navigation('/notifications')}>
              <Typography variant='body-small' style={{ color: SubMenuColor }}>{t('VIEW_ALL')}</Typography>
            </Link>
          </NotificationsTitle>
          {
            loading ?
            <NotificationsContent bgColor={FiltersBgColor} h={6} position='relative'>
              <LoadingOverlay visible={loading} />
            </NotificationsContent>
            :
            notifications.length > 0 &&
            <NotificationsContent bgColor={FiltersBgColor}>
              <NotificationsOverflowContent fDirection='column' maxH={16}>
                {
                  notifications.map((notification: Notification, index: number) => renderNotification(notification, index))
                }
              </NotificationsOverflowContent>
            </NotificationsContent>
          }
        </NotificationsWrapper>
      }
    </>
  );
};

export default NotificationsModal;
