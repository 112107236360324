import { useState, FC, ReactNode, useRef } from 'react';
import { FocusColor } from '../../styles/Colors';
import { Block } from '../../styles/BasicStyles';
import { useTranslation } from 'react-i18next';
import { FiltersWrapper, FiltersHeader, FiltersContent, FiltersActions, ClearButton } from './styles';
// import { useClickOutside } from '../../hooks/outside-click';
import { AnyObject } from '../inputs/Generic';
import Icon from '../icon';
import Button, { ButtonSizes } from '../button';
import Typography from '../typography';

export type FloatingPlacement = 'top' | 'bottom' | undefined;

export interface FiltersModalProps {
  children: ReactNode;
  handleChange: (field: string | string[], value: string | string[] | null, time?: number) => void;
  filters: AnyObject;
  setFilters: (values: AnyObject) => void;
  reloadStorageFilters: () => void;
  menuPosition?: FloatingPlacement;
  buttonSize?: ButtonSizes;
}

const FiltersModal: FC<FiltersModalProps> = ({ filters, setFilters, children, handleChange, reloadStorageFilters, menuPosition = 'bottom', buttonSize = 'md' }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const applyFilters = () => {
    const keys: string[] = [], values: string[] = [];

    Object.keys(filters).forEach(key => {
      keys.push(key);
      values.push(filters[key]);
    });

    handleChange(keys, values);

    setModalOpen(false);
  }

  const clearFilters = () => {
    const keys: string[] = [], values: string[] = [];

    Object.keys(filters).forEach(key => {
      keys.push(key);
      values.push('');
    });

    setFilters({});
    handleChange(keys, values);

    setModalOpen(false);
  }

  const checkActiveFilters = () => {
    let res = 0;
    Object.keys(filters).forEach(key => {
      if(key !== 'search' && !!filters[key]) res++;
    });

    return res;
  }

  const totalFilters = checkActiveFilters();

  // TODO: this causes issues for Date pickers for example, it closes the modal without selecting the date, try to fix this

  // const closeModal = () => {
  //   if(modalOpen) {
  //     reloadStorageFilters();
  //     setModalOpen(false);
  //   }
  // }

  // useClickOutside(containerRef?.current, closeModal);

  return (
    <Block position='relative' ref={containerRef}>
      <Button 
        text={`${t('FILTER_BY')}${totalFilters > 0 ? ` (${totalFilters})` : ''}`}
        icon='outlined_adjustments'
        variant={totalFilters > 0 ? FocusColor : 'primary'}
        reverseIcon
        onClick={() => { reloadStorageFilters(); setModalOpen(!modalOpen); }}
        size={buttonSize}
      />
      <FiltersWrapper open={modalOpen} menuPosition={menuPosition}>
        <FiltersHeader>
          <Typography 
            variant='dropdown-menu' 
            lHeight={1.25} 
            color='#ffffff'
          >
            {`${t('FILTERS')}${totalFilters > 0 ? ` (${totalFilters})` : ''}`}
          </Typography>
          <Icon 
            icon='outlined_x' 
            color='#ffffff' 
            size={1.1} 
            className='icon' 
            onClick={() => { reloadStorageFilters(); setModalOpen(false); }} 
          />
        </FiltersHeader>
        <FiltersContent>
          {children}
        </FiltersContent>
        <FiltersActions>
          <ClearButton onClick={clearFilters}>
            <Typography variant='sidebar-group' lHeight={1.25}>{t('CLEAR_ALL')}</Typography>
          </ClearButton>
          <Button 
            text={t('APPLY')}
            variant={FocusColor}
            size='xs'
            onClick={applyFilters}
          />
        </FiltersActions>
      </FiltersWrapper>
    </Block>
  )
};

export default FiltersModal;
