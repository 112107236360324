import * as yup from 'yup';
import { Field, withTypes } from 'react-final-form';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { BasicForm, Box, Block } from '../../../styles/BasicStyles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FocusColor, LabelColor, LoginButtonColor } from '../../../styles/Colors';
import AuthLayout from '../AuthLayout';
import useFetch from 'use-http';
import Button from '../../../components/button';
import TextInput from '../../../components/inputs/TextInput';
import Typography from '../../../components/typography';

interface Values {
  email: string;
}

const { Form } = withTypes<Values>();

export const RequestTenantValidation = () => {
  const [finish, setFinish] = useState(false);
  const { post } = useFetch('/auth');
  const { t } = useTranslation();

  const onSubmit = async (values: Values) => {
    const { success } = await post('/validate-account', values);
    if (success) setFinish(true);
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          email: yup.string().email().required()
        })
      )}
    >
      {({ handleSubmit, submitting }) => (
        <AuthLayout>
          <Block w='100%'>
            <Block mb={2.25} tAlign='center'>
              <Typography variant='auth-title' style={{ color: LabelColor }}>{t('REQUEST_NEW_ACCOUNT_VALIDATION')}</Typography>
            </Block>
            {finish ? (
              <>
                <Typography variant="body-bold" as='div'>{t('SUCCESS')}</Typography>
                <Typography variant="body" as='div'>
                  {t('NEW_ACCOUNT_VALIDATION_SUCCESS')}
                </Typography>
              </>
            ) : (
              <BasicForm onSubmit={handleSubmit}>
                <Box fDirection='column'>
                  <Block w='100%' mb={2.5}>
                    <Field name="email">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('EMAIL')}
                          placeholder={t('EMAIL_ACCOUNT_PLACEHOLDER')}
                          type="email"
                          size='lg'
                        />
                      )}
                    </Field>
                  </Block>
                  <Button
                    text={t('SEND_EMAIL')}
                    type='submit'
                    loading={submitting}
                    w='100%'
                    variant={FocusColor}
                    textColor={LoginButtonColor}
                  />
                </Box>
              </BasicForm>
            )}
          </Block>
        </AuthLayout>
      )}
    </Form>
  );
};

export default RequestTenantValidation;
