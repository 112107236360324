import { useTranslation } from 'react-i18next';
import { FC, useState, useContext } from 'react';
import { Box, Block } from '../../../styles/BasicStyles';
import { AnyObject } from '../../../models/Generic';
import { Federation } from '../../../models/Federation';
import { InfoContext, UserContext } from '../../../Context';
import { OfficialRoles } from '../../../utils/officialRoles';
import {
  SUPER_ADMIN,
  ADMIN,
  REGULATOR,
  UserStatusList,
  PENDING
} from '../../../models/User';
import Icon from '../../../components/icon';
import TextInput from '../../../components/inputs/TextInput';
import FiltersModal from '../../../components/filtersModal';
import SelectInput from '../../../components/inputs/SelectInput';

interface FiltersProps {
  filters?: AnyObject;
  handleChange: (
    field: string | string[],
    value: string | string[] | null,
    time?: number
  ) => void;
  federations: Federation[];
}

const Filters: FC<FiltersProps> = ({ filters, handleChange, federations }) => {
  const [filtersModal, setFiltersModal] = useState<AnyObject>({ ...filters });
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);

  // When closing the modal without applying the filters, reload the filters saved in local storage
  const reloadStorageFilters = () => {
    setFiltersModal({ ...filters });
  };

  return (
    <Box fAlign="center" fJustify="flex-end">
      <Block pr={0.875}>
        <TextInput
          input={{
            value: filters?.search,
            onChange: (v: string | null) => handleChange('search', v, 1000)
          }}
          placeholder={t('SEARCH')}
          onSearch={(v: string | null) => handleChange('search', v)}
          icon={<Icon icon="outlined_search" />}
          clearable
        />
      </Block>
      <FiltersModal
        handleChange={handleChange}
        filters={filtersModal}
        setFilters={setFiltersModal}
        reloadStorageFilters={reloadStorageFilters}
      >
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.status || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, status: v };
                })
            }}
            label={t('STATUS')}
            placeholder={t('CHOOSE_STATUS')}
            clearable
            data={UserStatusList.filter(elem => elem._id !== PENDING)}
            translate
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.country || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, country: v };
                })
            }}
            label={t('COUNTRY')}
            placeholder={t('CHOOSE_COUNTRY')}
            clearable
            data={info?.countries || []}
          />
        </Block>
        {(user?.type === SUPER_ADMIN ||
          user?.type === ADMIN ||
          user?.type === REGULATOR) && (
          <Block mb={0.5}>
            <SelectInput
              input={{
                value: filtersModal?.federation || '',
                onChange: (v: string | null) =>
                  setFiltersModal((old) => {
                    return { ...old, federation: v };
                  })
              }}
              label={t('FEDERATION')}
              placeholder={t('CHOOSE_FEDERATION')}
              clearable
              data={federations}
            />
          </Block>
        )}
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.class || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, class: v };
                })
            }}
            label={t('RATING_CLASS')}
            placeholder={t('CHOOSE_CLASS')}
            clearable
            data={info?.evaluationClasses || []}
            intl
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.role || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, role: v };
                })
            }}
            label={t('ROLES')}
            placeholder={t('CHOOSE_ROLE')}
            clearable
            data={OfficialRoles}
            translate
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.gender || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, gender: v };
                })
            }}
            label={t('GENDER')}
            placeholder={t('CHOOSE_GENDER')}
            clearable
            data={info?.genders || []}
            translate
          />
        </Block>
      </FiltersModal>
    </Box>
  );
};

export default Filters;
