import { useTranslation } from 'react-i18next';
import { Block } from '../../../styles/BasicStyles';
import { SidebarGroupProps, SidebarOptionProps } from '../options';
import { PlaceholderColor } from '../../../styles/Colors';
import { UserContext } from '../../../Context';
import { useContext } from 'react';
import Typography from '../../typography';
import Item from './item';
import Dropdown from './dropdown';

interface GroupProps {
  group: SidebarGroupProps;
  dropdownIsExpanded: number | undefined;
  setDropdownIsExpanded: (value?: number) => void;
}

const Group: React.FC<GroupProps> = ({ group, dropdownIsExpanded, setDropdownIsExpanded }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const renderMenuOptions = (option: SidebarOptionProps, isSubMenu: boolean) => {
    if(option.type === 'link' && (!option.roles || option.roles.includes(user?.type || ''))) {
      return <Item key={option.name} option={option} isSubMenu={isSubMenu} />;
    }

    if(option.type === 'dropdown' && (!option.roles || option.roles.includes(user?.type || ''))) {
      return (
        <Dropdown
          key={option.name}
          option={option}
          renderMenuOptions={renderMenuOptions}
          dropdownIsExpanded={dropdownIsExpanded}
          setDropdownIsExpanded={setDropdownIsExpanded}
        />
      );
    }

    return null;
  };

  // Check if a Group of options will be visible for the current User or not  
  const options = group.options.filter((option) => option.superuser ? user?.superuser === true : option.roles?.includes(user?.type || '') ? true : false);

  return (
    <>
      {
        options.length > 0 &&
        <Block mb={2.375} w='100%'>
          {
            group.name &&
            <Typography variant='sidebar-group' mb={0.75} tTransform='uppercase' lSpacing={0.031} style={{ color: PlaceholderColor }} display='block'>
              {t(group.name)}
            </Typography>
          }
          {
            options
            .filter((option) => option.superuser ? user?.superuser === true : option.roles?.includes(user?.type || '') ? true : false)
            .map((option) => renderMenuOptions(option, false))
          }
        </Block>
      }
    </>
  );
};

export default Group;
