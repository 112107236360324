import { FC, ReactNode } from 'react';
import { MultiSelect, MultiSelectProps, MultiSelectValueProps } from '@mantine/core';
import { FieldRenderProps } from 'react-final-form';
import { AnyObject, GenericInput } from './Generic';
import { useTranslation } from 'react-i18next';
import { InputWrapper, ErrorMessage } from './Styles';
import { DangerColor, LabelColor, G400 } from '../../styles/Colors';
import { Box } from '../../styles/BasicStyles';
import { InputSize } from './Generic';
import Icon from '../icon';
import Typography from '../typography';

export interface MultiSelectInputProps extends Omit<FieldRenderProps<string>, 'input'>, Omit<MultiSelectProps, 'data' | 'translate'> {
  input: GenericInput<Array<string>>;
  afterChange?: (value: Array<string>, oldValue: Array<string>) => void;
  keyValue?: string;
  keyLabel?: string;
  label?: ReactNode;
  data: Array<AnyObject>;
  translate?: boolean;
  size?: InputSize;
  isExternal?: boolean;
  intl?: boolean;
}

function Value({
  label,
  onRemove,
  bgColor,
  labelColor,
  ...others
}: MultiSelectValueProps & { bgColor: string, labelColor: string }) {
  return (
    <Box w='auto' fAlign='center' padding='0.188 0.75' bRadius={6.25} style={{ backgroundColor: bgColor }} {...others}>
      <Typography variant='multi-select' style={{ color: LabelColor }} pr={0.5}>{label}</Typography>
      <Icon icon='outlined_x' color={labelColor} onClick={onRemove} style={{ cursor: 'pointer' }} />
    </Box>
  );
}

export const MultiSelectInput: FC<MultiSelectInputProps> = ({
  input,
  meta = {},
  label,
  afterChange,
  data = [],
  keyValue = '_id',
  keyLabel = 'name',
  searchable = true,
  clearable = false,
  translate = false,
  intl = false,
  nothingFound,
  size = 'md',
  isExternal = false,
  ...other
}) => {
  const { t, i18n: { language } } = useTranslation();

  const handleChange = (value: Array<string>): void => {
    const oldValue = input.value || [];

    input.onChange(value);
    if (afterChange) {
      afterChange(value, oldValue);
    }
  };

  const hasError = meta?.invalid && meta?.submitFailed && (!input.value || input.value?.length === 0);
  const rightSectionError = hasError ? <Icon icon='bold_xCircle' color={DangerColor} size={1.25} /> : '';

  return (
    <InputWrapper size={size} hasError={hasError} isExternal={isExternal}>
      <MultiSelect
        {...other}
        label={label}
        value={input.value}
        valueComponent={Value}
        onChange={handleChange}
        error={hasError}
        searchable={searchable}
        clearable={clearable}
        nothingFound={nothingFound || t('NO_OPTIONS')}
        data={data.map((d: AnyObject) => ({
          value: d[keyValue],
          label: translate ? t(d[keyLabel]) : d[keyLabel] ? intl ? d[keyLabel][language] : d[keyLabel] : '',
          bgColor: d['bgColor'] || G400,
          labelColor: d['labelColor'] || LabelColor,
          group: d.group,
          disabled: input.value?.includes(d[keyValue]) ? false : d.disabled
        }))}
        rightSection={rightSectionError || (!input.value && <Icon icon='bold_caretDown' color={LabelColor} size={0.6} />)}
        styles={{ rightSection: { pointerEvents: 'none' } }}
        dropdownPosition='flip'
      />
      {
        hasError &&
        <ErrorMessage>
          {t('FIELD_REQUIRED')}
        </ErrorMessage>
      }
    </InputWrapper>
  );
};

export default MultiSelectInput;
