import * as yup from 'yup';
import { ADMIN, REGULATOR, SUPER_ADMIN } from '../../../models/User';

const validations = [
  yup.object({
    name: yup.string().required(),
    start_datetime: yup.date().required(),
    end_datetime: yup.date().required(),
    federation: yup.string().required(),
    promoter: yup.string().required()
  }),
  yup.object({
    name: yup.string().required(),
    start_datetime: yup.date().required(),
    end_datetime: yup.date().required(),
    federation: yup.string().required()
  }),
  yup.object({
    name: yup.string().required(),
    start_datetime: yup.date().required(),
    end_datetime: yup.date().required(),
  })
];

export const GetValidationSchema = (isDraftEvent: boolean, type?: string) => {
  if(type === SUPER_ADMIN || type === ADMIN || type === REGULATOR) {
    if(isDraftEvent) return validations[0];
    return validations[1];
  }

  return validations[2];
};
