import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../../../styles/BasicStyles';
import { FC } from 'react';
import { Event } from '../../../../models/Event';
import { showConfirm } from '../../../../hooks/show-notification/show-notification';
import { AnyObject } from '../../../../models/Generic';
import { AspectRatios } from '../../../../utils/files/Conversions';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../components/button';
import TextInput from '../../../../components/inputs/TextInput';
import ImageInput from '../../../../components/inputs/ImageInput';
import toFormData from '../../../../utils/formdata/ToFormData';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: Event;
}

const { Form } = withTypes<AnyObject>();

const PromoterEditEventModal: FC<Params> = ({ opened, onClose, initialValues }) => {
  const { t } = useTranslation();
  const { put } = useFetch('/events/promoter');

  const onSubmit = async (values: AnyObject) => {
    let error = false;
    const payload = toFormData(values);

    const { success } = await put(`/${initialValues?._id}`, payload);
    if(success) onClose(true);
    else error = true;

    return !error;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          name: yup.string().required()
        })
      )}
      initialValues={initialValues || {}}
      render={({ handleSubmit, pristine, submitting, form: { reset } }) => {
        const handleClose = () => {
          if(!pristine) {
            showConfirm({
              title: t('EXIT_BEFORE_SAVE'),
              message: t('EXIT_BEFORE_SAVE_MESSAGE'),
              onConfirm: () => {
                onClose(false);
                reset();
              }
            });
          }
          else {
            onClose(false);
            reset();
          }
        };

        return (
          <Modal
            size={36.25}
            opened={opened}
            onClose={handleClose}
            title={t('EDIT_EVENT')}
          >
            <BasicForm onSubmit={async event => {
              const result = await handleSubmit(event);
              if(result) reset();
            }}>
              <ModalOverflowContent>
                <ModalContent>
                  <Grid gutter="xl">
                    <Grid.Col xs={12}>
                      <Field name="photo">
                        {(props) => 
                          <ImageInput 
                            {...props} 
                            ratio={AspectRatios['1:1']}
                          />
                        }
                      </Field>
                    </Grid.Col>
                  </Grid>
                  <Grid gutter="xl">
                    <Grid.Col xs={12}>
                      <Field name="name">
                        {(props) => (
                          <TextInput
                            {...props}
                            label={`${t('EVENT_NAME')} *`}
                            placeholder={t('ENTER_NAME')}
                          />
                        )}
                      </Field>
                    </Grid.Col>
                  </Grid>
                </ModalContent>
              </ModalOverflowContent>
              <ModalActions>
                <Button
                  text={t('CANCEL')}
                  variant="secondary"
                  onClick={handleClose}
                />
                <Button
                  text={t('SAVE')}
                  type="submit"
                  loading={submitting}
                  disabled={pristine}
                />
              </ModalActions>
            </BasicForm>
          </Modal>
        );
      }}
    />
  );
};

export default PromoterEditEventModal;
