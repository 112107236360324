import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../models/User';
import { Grid } from '@mantine/core';
import { Box, Block } from '../../../styles/BasicStyles';
import { OfficialLabel } from './styles';
import { PlaceholderColor, LabelColor } from '../../../styles/Colors';
import { SortDisabledUsers, GetFirstAndLastName } from '../../../utils/user';
import ComposedInput from '../../../components/inputs/ComposedInput';
import SelectInput from '../../../components/inputs/SelectInput';
import Typography from '../../../components/typography';
import Icon from '../../../components/icon';
import UserAvatar from '../../../components/userAvatar';

export interface ComponentProps {
  officials: User[];
  setOfficials: (list: User[]) => void;
  currentOfficials: User[];
  setCurrentOfficials: (list: User[]) => void;
  setFormPristine: (value: boolean) => void;
  disableFields?: boolean;
}

/* NOTES
* officials: list of the available officials in the platform (mutates when adding/removing a official)
* currentOfficials: list of the current selected officials (mutates when adding/removing a official)
*/

const OfficialsList: FC<ComponentProps> = ({ officials, setOfficials, currentOfficials, setCurrentOfficials, setFormPristine, disableFields = false }) => {
  const { t } = useTranslation();

  const addOfficial = (value: string | null) => {
    if(value) {
      const officialIndex = officials.findIndex((official: User) => official._id === value);

      // Add selected official to the selected officials list
      let aux: User[] = [...currentOfficials];
      aux.push(officials[officialIndex]);
      setCurrentOfficials(aux);

      // Mark the Official as disabled
      aux = [...officials];
      aux[officialIndex].disabled = true;

      // Disabled items go to the end of the list per client request
      aux = SortDisabledUsers(aux);

      setOfficials(aux);
      
      setFormPristine(false);
    }
  }

  const removeOfficial = (removedOfficial: User, removedOfficialIndex: number) => {
    const index = officials.findIndex((official: User) => official._id === removedOfficial._id);

    // Mark the removed Official as enabled
    let aux: User[] = [...officials];
    aux[index].disabled = false;
    setOfficials(aux);

    // Remove official from the selected officials list
    aux = [...currentOfficials];
    aux.splice(removedOfficialIndex, 1);
    setCurrentOfficials(aux);

    setFormPristine(false);
  }

  return (
    <>
      <ComposedInput 
        label={t('OFFICIALS_LIST')}
        secondInput={
          <SelectInput
            input={{
              value: '',
              onChange: (value: string | null) => addOfficial(value)
            }}
            placeholder={t('CHOOSE_OFFICIAL')}
            data={officials}
            hideErrors
            disabled={disableFields}
          />
        }
      />
      {
        currentOfficials.length > 0 &&
        <Block mt={1.25}>
          <Grid gutter="xl" style={{ margin: '-0.625rem' }}>
            {
              currentOfficials.map((official: User, index: number) =>
                <Grid.Col xs={12} sm={6} key={index} style={{ padding: '0.25rem 0.625rem' }}>
                  <Box fAlign='center'>
                    <OfficialLabel>
                      <UserAvatar user={official} size='xxs' firstInitial />
                      <Typography variant='sidebar-group' style={{ color: LabelColor }} pl={0.25} pr={0.375}>{GetFirstAndLastName(official?.name)}</Typography>
                      {!disableFields && <Icon icon='bold_xCircle' size={1} color={PlaceholderColor} className='icon' onClick={() => removeOfficial(official, index)}/> }
                    </OfficialLabel>
                  </Box>
                </Grid.Col>
              )
            }
          </Grid>
        </Block>
      }
    </>
  );
}

export default OfficialsList;