import { LabelSize } from './index';
import styled, { css } from 'styled-components';

export const LabelWrapper = styled.div` 
  display: inline-block;
`;

export const LabelContent = styled.div<{ size: LabelSize, bgColor: string, bRadius: string }>` 
  display: flex;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor };
  border-radius: ${({ bRadius }) => bRadius };

  ${({ size }) =>
    size === 'xs' ?
    css` 
      padding: 0.125rem 0.313rem;
    `
    :
    css`
      padding: 0.25rem 0.688rem;
    `
  }
`;
