import { transitionsDelay } from '../../../styles/BasicStyles';
import styled from 'styled-components';

export const OfficialLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.filtersBgColor};
  border-radius: 6.25rem;
  padding: 0.25rem 0.5rem;

  & .icon {
    cursor: pointer;
    transition: opacity ${transitionsDelay} linear;

    &:hover {
      opacity: 0.6;
    }
  }
`;
