import { Translation } from 'react-i18next';
import { ScrollArea } from '@mantine/core';
import { showNotification, hideNotification } from '@mantine/notifications';
import { AlertSuccessBg, AlertErrorBg, AlertWarningBg, AlertInfoBg } from '../../styles/Colors';
import { Box, Block } from '../../styles/BasicStyles';
import Button from '../../components/button';
import Icon from '../../components/icon';
import IconType from '../../assets/icons/props';

type AlertTypes = 'primary' | 'secondary' | undefined;

interface ErrorProps {
  title: string | React.ReactElement;
  message: string | React.ReactElement;
  id?: string;
  autoClose?: number | false;
  color?: string;
  icon?: IconType;
  type?: AlertTypes;
}

type ErrorFunction = (props: ErrorProps) => void;

const ErrorContent = (message: string | React.ReactElement) => (
  <ScrollArea.Autosize maxHeight={120} scrollbarSize={5} type="auto">
    {message}
  </ScrollArea.Autosize>
);

export const showError: ErrorFunction = ({
  title,
  message,
  color = 'red',
  autoClose = 5000,
  icon = 'bold_exclamationCircle',
  type = 'primary'
}) =>
  showNotification({
    autoClose,
    title,
    message: ErrorContent(message),
    color,
    icon: <Icon icon={icon} size={1.3} color={type === 'primary' ? 'white' : AlertErrorBg} />,
    classNames: { root: `custom-error-notification ${type}` }
  });

export const showSuccess: ErrorFunction = ({
  title,
  message,
  color = 'green',
  autoClose = 5000,
  icon = 'bold_checkCircle',
  type = 'primary'
}) =>
  showNotification({
    autoClose,
    title,
    message: ErrorContent(message),
    color,
    icon: <Icon icon={icon} size={1.3} color={type === 'primary' ? 'white' : AlertSuccessBg} />,
    classNames: { root: `custom-success-notification ${type}` }
  });

export const showWarning: ErrorFunction = ({
    title,
    message,
    color = 'yellow',
    autoClose = 5000,
    icon = 'bold_exclamation',
    type = 'primary'
  }) =>
    showNotification({
      autoClose,
      title,
      message: ErrorContent(message),
      color,
      icon: <Icon icon={icon} size={1.3} color={type === 'primary' ? 'white' : AlertWarningBg} />,
      classNames: { root: `custom-warning-notification ${type}` }
    });

export const showInfo: ErrorFunction = ({
  title,
  message,
  color = 'blue',
  autoClose = 5000,
  icon = 'bold_informationCircle',
  type = 'primary'
}) =>
  showNotification({
    autoClose,
    title,
    message: ErrorContent(message),
    color,
    icon: <Icon icon={icon} size={1.3} color={type === 'primary' ? 'white' : AlertInfoBg} />,
    classNames: { root: `custom-info-notification ${type}` }
  });

interface ConfirmProps extends ErrorProps {
  onConfirm: () => void;
  confirmTag?: string;
}

type ConfirmFunction = (props: ConfirmProps) => void;

const ConfirmContent = (
  message: string | React.ReactElement,
  id: string,
  onConfirm: () => void,
  confirmTag: string,
  color?: string
) => (
  <ScrollArea.Autosize maxHeight={120} scrollbarSize={5} type="auto">
    <Box fDirection='column'>
      <Block style={{ paddingBottom: '0.5rem' }}>{message}</Block>
      <Box fAlign='center' fJustify='flex-end'>
        <Button 
          variant='primary'
          size='xs'
          text={<Translation>{(t) => <span>{t('CANCEL')}</span>}</Translation>} 
          onClick={() => hideNotification(id)} 
          style={{ marginRight: '1rem' }}
        />
        <Button 
          variant={color}
          size='xs'
          text={<Translation>{(t) => <span>{t(confirmTag)}</span>}</Translation>} 
          onClick={() => { hideNotification(id); onConfirm(); }} 
        />
      </Box>
    </Box>
  </ScrollArea.Autosize>
);

export const showDelete: ConfirmFunction = ({
  id = 'delete_popup',
  title,
  message,
  autoClose = 10000,
  color = 'red',
  icon = 'bold_trash',
  onConfirm,
  confirmTag = 'DELETE'
}) =>
  showNotification({
    id,
    autoClose,
    disallowClose: true,
    title,
    message: ConfirmContent(message, id, onConfirm, confirmTag, AlertErrorBg),
    icon: <Icon icon={icon} size={1.3} color={AlertErrorBg} />,
    classNames: { root: `custom-error-notification` },
    color,
  });

export const showConfirm: ConfirmFunction = ({
  id = 'confirm_popup',
  title,
  message,
  autoClose = 10000,
  color = 'primary',
  icon = 'bold_informationCircle',
  onConfirm,
  confirmTag = 'CONFIRM'
}) =>
  showNotification({
    id,
    autoClose,
    disallowClose: true,
    title,
    message: ConfirmContent(message, id, onConfirm, confirmTag, AlertInfoBg),
    icon: <Icon icon={icon} size={1.3} color={AlertInfoBg} />,
    color
  });
