import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelColor } from '../../styles/Colors';
import { Log } from '../../models/Log';
import { User } from '../../models/User';
import { showDelete } from '../../hooks/show-notification/show-notification';
import dayjs from 'dayjs';
import useFetch from 'use-http';
import BaseTable from '../../components/table';
import LogsFilters from './LogsFilters';
import DropdownMenu from '../../components/dropdownMenu';
import Icon from '../../components/icon';
import DefaultListPage from '../../components/page/DefaultListPage';
import Typography from '../../components/typography/index';
import ActiveTranslation from '../../components/languages/ActiveTranslation';

const LogsListPage = DefaultListPage<Log>();
const LogsTable = BaseTable<Log>();

const LogsPage = () => {
  const [users, setUsers] = useState<User[]>([]);
  const { t } = useTranslation();
  const { get, del } = useFetch('/logs');
  const usersHook = useFetch('/users');

  useEffect(() => {
    const init = async () => {
      const { data, success } = await usersHook.get('/list');
      if (success) setUsers(data);
    };

    init();
  }, []);

  return (
    <LogsListPage
      translate={t}
      request={{
        get,
        searchFields: ['curr_name', 'curr_email', 'entity', 'entity_monitor']
      }}
      storage="LOGS_FILTERS"
      header={{
        title: t('LOGS')
      }}
      filters={({ filters, handleChange }) => (
        <LogsFilters
          filters={filters}
          handleChange={handleChange}
          users={users}
        />
      )}
      countLabel={`${t('LOGS').toLowerCase()}`}
    >
      {({ rows, loading, pagination, handleNewSort, refreshPage }) => {
        const handleDelete = async (id: string) => {
          showDelete({
            title: t('DELETE_LOG'),
            message: t('DELETE_LOG_MESSAGE'),
            onConfirm: async () => {
              await del(`/${id}`);
              refreshPage();
            }
          });
        };

        const columns = [
          {
            title: t('USER'),
            dataIndex: 'curr_email',
            sortable: true
          },
          {
            title: t('ACTION'),
            dataIndex: 'action',
            sortable: true
          },
          {
            title: t('ENTITY'),
            dataIndex: 'entity',
            sortable: true
          },
          {
            title: t('VALUE'),
            dataIndex: 'entity_monitor',
            render: (value: string) => (
              <Typography variant="table-header">
                <ActiveTranslation value={value} />
              </Typography>
            )
          },
          {
            title: t('DATE'),
            dataIndex: 'createdAt',
            sortable: true,
            render: (value: string) => (
              <Typography variant="table-header">
                {value
                  ? dayjs(value).format('DD/MM/YYYY HH:mm:ss')
                  : t('NO_RECORD')}
              </Typography>
            )
          },
          {
            title: t('MORE'),
            key: 'actions',
            render: (row: Log) => (
              <DropdownMenu
                render={
                  <Icon icon="outlined_dotsHorizontal" color={LabelColor} />
                }
                menuPosition="bottom-end"
                actions={[
                  {
                    type: 'link',
                    label: t('DELETE'),
                    onClick: () => handleDelete(row._id)
                  }
                ]}
              />
            )
          }
        ];

        return (
          <LogsTable
            columns={columns}
            rows={rows}
            loading={loading}
            defaultSort={pagination.sort}
            sortCallback={handleNewSort}
          />
        );
      }}
    </LogsListPage>
  );
};

export default LogsPage;
