import { FieldRenderProps } from 'react-final-form';
import { GenericInput, AnyObject } from '../../models/Generic';
import { useContext } from 'react';
import { LanguageContext } from '../../Context';
import { useTranslation, TFunction } from 'react-i18next';
import { Language } from '../../models/Language';
import { InputLabel } from './Styles';
import { Box } from '../../styles/BasicStyles';
import TextAreaInput from './TextAreaInput';
import RichTextInput from './RichTextInput';

const Label: React.FC<{
  lang: Language;
  t: TFunction<'translation', undefined>;
}> = ({ lang, t }) => (
  <Box mt={0.625} fAlign='center'>
    <img src={lang.icon} style={{ width: '1.25rem', marginRight: '0.313rem' }} /> {t(lang.tag)}
  </Box>
);

interface ITextAreaInput extends Omit<FieldRenderProps<AnyObject>, 'input'> {
  afterChange?: (value: AnyObject) => void;
  input: GenericInput<AnyObject>;
  label?: string;
  placeholder?: string;
  richText?: boolean;
  maxChar?: number;
}

const IntlTextAreaInput: React.FC<ITextAreaInput> = ({
  input,
  meta,
  label,
  placeholder,
  afterChange,
  richText,
  maxChar
}) => {
  const { t } = useTranslation();
  const languages = useContext(LanguageContext);

  const value = languages.reduce((result: AnyObject, lang) => {
    if (input.value && input.value[lang.code]) {
      result[lang.code] = input.value[lang.code];
    } else result[lang.code] = '';
    return result;
  }, {});

  const showError = meta?.invalid && meta?.submitFailed;

  const handleInputChange = (
    ev: React.ChangeEvent<HTMLTextAreaElement> | string | null,
    code: string
  ) => {
    const text = typeof ev === 'string' ? ev : ev?.currentTarget?.value;
    const newValue = { ...value };
    newValue[code] = text;
    input.onChange(newValue);
    if (afterChange) afterChange(newValue);
  };

  return (
    <>
      <InputLabel>{label}</InputLabel>
      {languages.map((lang) => {
        const error =
          showError &&
          meta.error &&
          (typeof meta.error === 'string' || !!meta.error[lang.code]);

        if (richText)
          return (
            <RichTextInput
              label={<Label t={t} lang={lang} />}
              key={lang.code}
              input={{
                value: value[lang.code],
                onChange: (html: string | null) =>
                  handleInputChange(html, lang.code)
              }}
              placeholder={t(placeholder || lang.tag, { lang: t(lang.tag) })}
              meta={{ ...meta, invalid: error }}
            />
          );

        return (
          <TextAreaInput
            key={lang.code}
            label={<Label t={t} lang={lang} />}
            input={{
              value: value[lang.code],
              onChange: (html: string | null) =>
                handleInputChange(html, lang.code)
            }}
            placeholder={t(placeholder || lang.tag, { lang: t(lang.tag) })}
            meta={{ ...meta, invalid: error }}
            autosize
            minRows={3}
            maxChar={maxChar}
          />
        );
      })}
    </>
  );
};

export default IntlTextAreaInput;
