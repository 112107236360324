import { FC } from 'react';
import { ModalWrapper, TitleWrapper, ContentWrapper, ActionsWrapper, OverflowWrapper } from './styles';
import { LabelColor, P600 } from '../../styles/Colors';
import Typography from '../typography';
import Icon from '../icon';

export const ModalOverflowContent = OverflowWrapper;
export const ModalContent = ContentWrapper;
export const ModalActions = ActionsWrapper;

export interface ModalProps {
  opened: boolean;
  onClose: () => void;
  title?: string;
  subtitle?: React.ReactNode;
  size?: string | number;
  fullScreen?: boolean;
  children: React.ReactNode;
}

export const ModalComponent: FC<ModalProps> = ({ opened, onClose, title, subtitle, children, ...props}) => (
  <ModalWrapper
    opened={opened}
    onClose={onClose}
    withCloseButton={false}
    trapFocus={false}
    closeOnClickOutside={false}
    closeOnEscape={false}
    centered
    {...props}
  >
    {
      (!!title || !!onClose) &&
      <TitleWrapper hasSubtitle={!!subtitle}>
        {!!title && <Typography variant='body-bold' style={{ color: LabelColor }} className='title'>{title}</Typography>}
        {subtitle ? <div className='subtitle'>{subtitle}</div> : <Icon icon='outlined_x' color={LabelColor} hoverColor={P600} onClick={onClose} />}
      </TitleWrapper>
    }
    {children}
  </ModalWrapper>
);

export default ModalComponent;
