/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../../../styles/BasicStyles';
import { FC, useState, useEffect } from 'react';
import { Fight } from '../../../../models/Fight';
import { showConfirm, showSuccess } from '../../../../hooks/show-notification/show-notification';
import { AnyObject } from '../../../../models/Generic';
import { requiredField } from '../../../../hooks/use-validation/use-validation-schema';
import { BlueFighter, LabelColor, RedFighter } from '../../../../styles/Colors';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../components/button';
import Divider from '../../../../components/divider';
import Typography from '../../../../components/typography';
import TextInput from '../../../../components/inputs/TextInput';

interface Params {
  opened: boolean;
  onClose: () => void;
  initialValues?: any;
  fight?: Fight;
  reloadInfo: () => void;
  judge?: string;
  judgeName?: string;
}

const { Form } = withTypes<any>();

const EditJudgeScoreModal: FC<Params> = ({ opened, onClose, initialValues, fight, judge, judgeName, reloadInfo }) => {
  const { t } = useTranslation();
  const { put } = useFetch('/fights');
  const [formInitialValues, setFormInitialValues] = useState({});

  // Auxiliary array
  // If the Fight stopped before the final round, use that number as the final round 
  const finalRound = fight?.result && fight?.result?.round > 0 ? fight.result.round : fight?.rounds;
  const roundsArray = Array(finalRound || 0).fill(0);

  useEffect(() => {
    setFormInitialValues(getInitialValues());
  }, [opened, initialValues]);

  const onSubmit = async (values: any) => {
    const { success } = await put(`/${fight?._id}/scores`, { ...values, judge });

    if(success) {
      showSuccess({
        title: t('SUCCESS'),
        message: t('FIGHT_CHANGE_SUCCESS')
      });

      onClose();
      reloadInfo();
    }
  };

  const getInitialValues = () => {
    const redScores: number[] = [], blueScores: number[] = [];

    if(initialValues?.length > 0) {
      initialValues.forEach((elem: AnyObject) => {
        if(elem.red >= 0) {
          if(!!redScores[elem.round - 1]) redScores[elem.round - 1] += elem.red;
          else redScores[elem.round - 1] = elem.red;
        }
        if(elem.blue >= 0) {
          if(!!blueScores[elem.round - 1]) blueScores[elem.round - 1] += elem.blue;
          else blueScores[elem.round - 1] = elem.blue;
        }
      });
    }

    return {
      redScores,
      blueScores
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({ 
          redScores: yup.array().of(yup.string().required()),
          blueScores: yup.array().of(yup.string().required()) 
        })
      )}
      initialValues={formInitialValues || {}}
      render={({ handleSubmit, pristine, submitting, form: { reset, submit } }) => {
        const handleClose = () => {
          if(!pristine) {
            showConfirm({
              title: t('EXIT_BEFORE_SAVE'),
              message: t('EXIT_BEFORE_SAVE_MESSAGE'),
              onConfirm: () => {
                onClose();
                reset();
              }
            });
          }
          else {
            onClose();
            reset();
          }
        };

        const handleSave = () => {
          showConfirm({
            title: t('CONFIRM_BEFORE_SAVE'),
            message: t('CONFIRM_BEFORE_SAVE_FIGHT'),
            onConfirm: () => submit()
          });
        }

        return (
          <Modal
            size={30}
            opened={opened}
            onClose={handleClose}
            title={t('EDIT_JUDGE_SCORE')}
          >
            <BasicForm onSubmit={handleSubmit}>
              <ModalOverflowContent>
                <ModalContent>
                  <Typography as='div' variant='page-title' mb={1.25} style={{ color: LabelColor }}>{t('JUDGE')}: <span style={{ fontWeight: 500 }}>{judgeName}</span></Typography>
                  <Typography as='div' variant='body' fWeight={500} mb={1} style={{ color: RedFighter }}>RED</Typography> 
                  {
                    roundsArray.length > 0 &&
                    roundsArray.map((_: any, index: number) =>
                      <Grid key={index} gutter="xl">
                        <Grid.Col xs={12}>
                          <Field name={`redScores[${index}]`} validate={requiredField}>
                            {(props) =>
                              <TextInput
                                {...props}
                                label={`${t('ROUND')} ${index + 1} *`}
                                placeholder={t('ENTER_SCORE')}
                                htmlType='number'
                                min={0}
                                max={10}
                                onlyIntegers
                              />
                            }
                          </Field>
                        </Grid.Col>
                      </Grid>
                    )
                  }
                </ModalContent>
                <Divider />
                <ModalContent>
                  <Typography as='div' variant='body' fWeight={500} mb={1} style={{ color: BlueFighter }}>BLUE</Typography> 
                  {
                    roundsArray.length > 0 &&
                    roundsArray.map((_: any, index: number) =>
                      <Grid key={index} gutter="xl">
                        <Grid.Col xs={12}>
                          <Field name={`blueScores[${index}]`} validate={requiredField}>
                            {(props) =>
                              <TextInput
                                {...props}
                                label={`${t('ROUND')} ${index + 1} *`}
                                placeholder={t('ENTER_SCORE')}
                                htmlType='number'
                                min={0}
                                max={10}
                                onlyIntegers
                              />
                            }
                          </Field>
                        </Grid.Col>
                      </Grid>
                    )
                  }
                </ModalContent>
              </ModalOverflowContent>
              <ModalActions>
                <Button
                  text={t('CANCEL')}
                  variant="secondary"
                  onClick={handleClose}
                />
                <Button
                  text={t('SAVE')}
                  loading={submitting}
                  disabled={pristine}
                  onClick={handleSave}
                />
              </ModalActions>
            </BasicForm>
          </Modal>
        );
      }}
    />
  );
};

export default EditJudgeScoreModal;
