import {
  SUPER_ADMIN,
  ADMIN,
  REGULATOR,
  RESPONSIBLE,
  PROMOTER,
  OFFICIAL
} from '../../models/User';
import OfficialsPage from '../../pages/members/officials/OfficialsPage';
import AthletesPage from '../../pages/members/athletes/AthletesPage';
import TranslationsPage from '../../pages/translations/TranslationsPage';
import EmailTemplatesPage from '../../pages/emailTemplates/EmailTemplatesPage';
import ManageEmailTemplatePage from '../../pages/emailTemplates/ManageEmailTemplatePage';
import EventsPage from '../../pages/events/list/EventsPage';
import EventDetailPage from '../../pages/events/detail';
import EventDraftsPage from '../../pages/eventDrafts/EventDraftsPage';
import FightDetailPage from '../../pages/fights';
import TournamentDetailPage from '../../pages/tournaments';
import AthleteDetailPage from '../../pages/members/athletes/detail';
import OfficialDetailPage from '../../pages/members/officials/detail';
import CoachDetailPage from '../../pages/members/coaches/detail';
import CoachesPage from '../../pages/members/coaches/CoachesPage';
import ExternalFormPage from '../../pages/external/form';
import ExternalFormSuccessPage from '../../pages/external/success';
import MemberDraftsPage from '../../pages/memberDrafts/MemberDraftsPage';
import LogsPage from '../../pages/logs/LogsPage';
import DynamicPages from '../../pages/dynamicPages/DynamicPages';
import ManageDynamicPage from '../../pages/dynamicPages/ManageDynamicPage';
import ReviewsPage from '../../pages/reviews';
import NotificationsPage from '../../pages/notifications';
import GenerateTournamentPage from '../../pages/generateTournament';
import MemberPendingPage from '../../pages/memberPending/MemberPendingPage';
import PromoterPage from '../../pages/members/promoters/PromotersPage';
import PromoterDetailPage from '../../pages/members/promoters/detail';

const BasicEntitiesRoutes = [
  {
    path: '/members/official',
    component: <OfficialsPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/members/athlete',
    component: <AthletesPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER]
  },
  {
    path: '/members/coach',
    component: <CoachesPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/members/promoter',
    component: <PromoterPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/members/pending',
    component: <MemberPendingPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/members/draft',
    component: <MemberDraftsPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/members/draft/athlete/:id',
    component: <AthleteDetailPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/members/draft/official/:id',
    component: <OfficialDetailPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/members/draft/coach/:id',
    component: <CoachDetailPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/members/draft/promoter/:id',
    component: <PromoterDetailPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/members/athlete/:id',
    component: <AthleteDetailPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER]
  },
  {
    path: '/members/official/:id',
    component: <OfficialDetailPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/members/coach/:id',
    component: <CoachDetailPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/members/promoter/:id',
    component: <PromoterDetailPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/reviews',
    component: <ReviewsPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/events/upcoming',
    component: <EventsPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL]
  },
  {
    path: '/events/past',
    component: <EventsPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL]
  },
  {
    path: '/events/draft',
    component: <EventDraftsPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER]
  },
  {
    path: '/events/draft/:id',
    component: <EventDraftsPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER]
  },
  {
    path: '/events/:id',
    component: <EventDetailPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL]
  },
  {
    path: '/fights/:id',
    component: <FightDetailPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL]
  },
  {
    path: '/tournaments/:id',
    component: <TournamentDetailPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER, OFFICIAL]
  },
  {
    path: '/notifications',
    component: <NotificationsPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]
  },
  {
    path: '/translations',
    component: <TranslationsPage />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/logs',
    component: <LogsPage />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/email-templates',
    component: <EmailTemplatesPage />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/email-templates/:id',
    component: <ManageEmailTemplatePage />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/dynamic-pages',
    component: <DynamicPages />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/dynamic-pages/:id',
    component: <ManageDynamicPage />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/external/success',
    component: <ExternalFormSuccessPage />,
    isAuthenticated: false
  },
  {
    path: '/external/:type/:federation/:member',
    component: <ExternalFormPage />,
    isAuthenticated: false
  },
  {
    path: '/external/:type/:federation',
    component: <ExternalFormPage />,
    isAuthenticated: false
  },
  {
    path: '/generate-tournament',
    component: <GenerateTournamentPage />,
    isAuthenticated: true,
    roles: [SUPER_ADMIN]
  },
];

export default BasicEntitiesRoutes;
