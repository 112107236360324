import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react';
import { ACTIVE, DEACTIVATED, Event } from '../../../models/Event';
import { showDelete, showConfirm, showSuccess } from '../../../hooks/show-notification/show-notification';
import { Block } from '../../../styles/BasicStyles';
import { BorderColor, LabelColor } from '../../../styles/Colors';
import { useNavigate, useLocation } from 'react-router-dom';
import { User, SUPER_ADMIN, ADMIN, REGULATOR, ATHLETE, RESPONSIBLE, PROMOTER, AdminTypes } from '../../../models/User';
import { UserContext } from '../../../Context';
import { Federation } from '../../../models/Federation';
import { GetEventInitialValues } from '../../../utils/modalValues';
import { ShowEventStatus } from '../../../utils/events';
import DropdownMenu, { ActionProps } from '../../../components/dropdownMenu';
import EmptyState from '../../../components/empty/EmptyState';
import Icon from '../../../components/icon';
import LoadingOverlay from '../../../components/loadingOverlay';
import DefaultListPage from '../../../components/page/DefaultListPage';
import useFetch from 'use-http';
import Accordion from '../../../components/accordion';
import EventModal from '../manage';
import EventsFilters from './EventsFilters';
import EventTitle from './components/EventTitle';
import EventContent from './components/EventContent';
import CanBeRendered from '../../../utils/user/permissions';
import RequestChangeModal from './modals/RequestChangeModal';
import DuplicateModal from './modals/DuplicateModal';
import PromoterEditEventModal from './modals/PromoterEditEventModal';

const EventListPage = DefaultListPage<Event>();

const EventsPage = () => {
  const [modal, setModal] = useState<{ open: boolean; initialValues?: Event; }>({ open: false });
  const [requestChangeModal, setRequestChangeModal] = useState<{ open: boolean; initialValues?: Event; }>({ open: false });
  const [duplicateModal, setDuplicateModal] = useState<{ open: boolean; initialValues?: Event; }>({ open: false });
  const [promoterEditEventModal, setPromoterEditEventModal] = useState<{ open: boolean; initialValues?: Event; }>({ open: false });
  const [athletes, setAthletes] = useState<User[]>([]);
  const [federations, setFederations] = useState<Federation[]>([]);
  const [promoters, setPromoters] = useState<User[]>([]);
  const { t } = useTranslation();
  const location = useLocation();
  const { put, del } = useFetch('/events');
  const { get } = useFetch(location.pathname);
  const athletesHook = useFetch(`/members/${ATHLETE}/dropdown`);
  const promotersHook = useFetch(`/admins/type/${PROMOTER}`);
  const federationsHook = useFetch('/federations/dropdown');
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  
  useEffect(() => {
    const init = async () => {
      const { data } = await athletesHook.get();
      setAthletes(data || []);

      if(user?.type && AdminTypes.includes(user.type)) {
        const federationsResult = await federationsHook.get();
        setFederations(federationsResult?.data || []);
      }

      if(user?.type && (AdminTypes.includes(user.type) || user.type === RESPONSIBLE)) {
        const promotersResult = await promotersHook.get();
        setPromoters(promotersResult?.data || []);
      }
    };

    init();
  }, []);

  const getTitle = () => {
    switch(location.pathname) {
      case '/events/upcoming':
        return t('UPCOMING_EVENTS');
      case '/events/past':
        return t('PAST_EVENTS');
      default:
        return t('EVENTS');
    }
  }

  return (
    <EventListPage
      translate={t}
      request={{
        get,
        reloadOnChange: [location.pathname]
      }}
      storage="EVENT_FILTERS"
      header={{
        title: getTitle()
      }}
      filters={({ filters, handleChange }) => 
        <EventsFilters 
          filters={filters} 
          handleChange={handleChange}
          athletes={athletes}
          location={location.pathname}
          federations={federations}
          promoters={promoters}
        />
      }
      countLabel={`${t('EVENTS').toLowerCase()}`}
    >
      {({ rows, loading, refreshPage }) => {
        const handleCloseModal = (changed: boolean) => {
          if (changed) refreshPage();
          setModal({ open: false });
        };

        const handleDelete = async (id: string) => {
          showDelete({
            title: t('DELETE_EVENT'),
            message: t('DELETE_EVENT_MESSAGE'),
            onConfirm: async () => {
              await del(`/${id}`);
              refreshPage();
            }
          });
        };

        const handlePromoterCloseModal = (changed: boolean) => {
          if (changed) refreshPage();
          setPromoterEditEventModal({ open: false });
        };

        const onConfirmStatusToogle = async (id: string, status: string) => {
          showConfirm({
            title: t('CHANGE_STATUS'),
            message: t('CHANGE_STATUS_MESSAGE').replace('#STATUS#', t(status).toUpperCase()),
            onConfirm: async () => {
              const { success } = await put(`/${id}/change-status`, { status });
              if(success) {
                refreshPage();

                showSuccess({
                  title: t('SUCCESS'),
                  message: t('STATUS_CHANGED')
                });
              }
            }
          });
        };

        const renderActions = (row: Event) => {
          const options: ActionProps[] = [
            {
              type: 'link',
              label: t('VIEW_DETAIL'),
              onClick: () => navigate(`/events/${row._id}`)
            }
          ];

          if(user?.type === PROMOTER && user?._id?.toString() === row.promoter?._id?.toString()) {
            options.splice(1, 0,
              {
                type: 'divider'
              },
              {
                type: 'link',
                label: t('EDIT'),
                onClick: () => setPromoterEditEventModal({ open: true, initialValues: row })
              },
              {
                type: 'divider'
              },
              {
                type: 'link',
                label: t('REQUEST_CHANGE'),
                onClick: () => setRequestChangeModal({ open: true, initialValues: row })
              }
            );
          }

          if(user?.type && (AdminTypes.includes(user.type) || user.type === RESPONSIBLE)) {
            options.splice(1, 0, 
              {
                type: 'divider'
              },
              {
                type: 'link',
                label: t('EDIT'),
                onClick: () => setModal({ open: true, initialValues: GetEventInitialValues(row) })
              },
              {
                type: 'divider'
              },
              {
                type: 'link',
                label: t('DUPLICATE'),
                onClick: () => setDuplicateModal({ open: true, initialValues: row })
              },
              {
                type: 'divider'
              },
              {
                type: 'link',
                label: t('DELETE'),
                onClick: () => handleDelete(row._id)
              }
            );
          }

          if(ShowEventStatus(user?.type)) {
            if(row.status === ACTIVE) {
              options.splice(5, 0, 
                {
                  type: 'divider'
                },
                {
                  type: 'link',
                  label: t('DEACTIVATE'),
                  onClick: () => onConfirmStatusToogle(row._id, DEACTIVATED)
                }
              );
            }
            else {
              options.splice(5, 0, 
                {
                  type: 'divider'
                },
                {
                  type: 'link',
                  label: t('ACTIVATE'),
                  onClick: () => onConfirmStatusToogle(row._id, ACTIVE)
                }
              );
            }
          }

          return (
            <DropdownMenu 
              render={<Icon icon='outlined_dotsVertical' color={LabelColor} />}
              menuPosition='bottom-end'
              actions={options} 
            />
          );
        };

        return (
          <Block margin="1.25 0">
            <LoadingOverlay visible={loading} />
            {
              rows.length > 0 ?
              rows.map((row, index) =>
                <div key={index}>
                  <Accordion 
                    title={<EventTitle row={row} renderActions={renderActions} onConfirmStatusToogle={onConfirmStatusToogle} />}
                    content={<EventContent row={row} />}
                    titleClick={false}
                  />
                </div>
              )
              :
              <Block bRadius={0.5} style={{ border: `0.063rem solid ${BorderColor}` }}>
                <EmptyState />
              </Block>
            }
            <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER]}>
              <EventModal
                opened={modal.open}
                initialValues={modal.initialValues}
                onClose={handleCloseModal}
              />
            </CanBeRendered>
            <CanBeRendered types={[PROMOTER]}>
              <RequestChangeModal
                opened={requestChangeModal.open}
                onClose={() => setRequestChangeModal({ open: false })}
                initialValues={requestChangeModal.initialValues}
              />
              <PromoterEditEventModal
                opened={promoterEditEventModal.open}
                onClose={handlePromoterCloseModal}
                initialValues={promoterEditEventModal.initialValues}
              />
            </CanBeRendered>
            <CanBeRendered types={[SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE]}>
              <DuplicateModal
                opened={duplicateModal.open}
                initialValues={duplicateModal.initialValues}
                onClose={() => setDuplicateModal({ open: false })}
              />
            </CanBeRendered>
          </Block>
        );
      }}
    </EventListPage>
  );
};

export default EventsPage;
