import { Route, Routes } from 'react-router-dom';
import NotFound from '../pages/notFound';
import ServerErrorPage from '../pages/serverError';
import CombinedRoutes from './CombinedRoutes';
import AuthenticatedRoute from './types/AuthenticatedRoute';
import UnauthenticatedRoute from './types/UnauthenticatedRoute';

const ApplicationRoutes = () => (
  <Routes>
    {CombinedRoutes.map((route, key) => (
      <Route
        key={key}
        path={route.path}
        element={
          route.isAuthenticated ? (
            <AuthenticatedRoute privileged={route.superuser} roles={route.roles}>
              {route.component}
            </AuthenticatedRoute>
          ) : (
            <UnauthenticatedRoute forceValidation={route.forceValidation}>
              {route.component}
            </UnauthenticatedRoute>
          )
        }
      />
    ))}
    <Route path="/server-error" element={<ServerErrorPage />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default ApplicationRoutes;
