import { InputSize } from './Generic';
import { Block, Box, transitionsDelay } from '../../styles/BasicStyles';
import { breakpoints } from '../../styles/Breakpoints';
import styled, { css } from 'styled-components';

export const InputWrapper = styled.div<{ size?: InputSize, hasError?: boolean, hasLeftIcon?: boolean, hasRightIcon?: boolean, isExternal?: boolean, hasActionButtons?: boolean }>`
  width: 100%;

  & label {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.labelColor};
    padding-bottom: 0.5rem;
  }

  & input::placeholder,
  & textarea::placeholder {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.placeholderColor};
  }

  & label,
  & input::placeholder,
  & textarea::placeholder,
  & input,
  & textarea {
    ${({ size }) =>
      size === 'xs'
        ? css`
            font-size: 0.75rem;
            line-height: 1.063rem;
          `
        : size === 'sm'
        ? css`
            font-size: 0.875rem;
            line-height: 1.25rem;
          `
        : size === 'lg'
        ? css`
            font-size: 1rem;
            line-height: 1.375rem;
          `
        : css`
            font-size: 0.875rem;
            line-height: 1.25rem;
          `}
  }

  /* TEXT */

  & .mantine-TextInput-wrapper input,
  & .mantine-Textarea-wrapper textarea {
    background-color: ${({ theme }) => theme.colors.menuBgColor} !important;
    border: 0.063rem solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.dangerColor : theme.colors.borderColor};
    border-radius: 0.25rem;
    min-height: unset;
    height: auto;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: ${({ theme, hasError }) =>
      hasError ? theme.colors.dangerColor : theme.colors.labelColor} !important;
    transition: border ${transitionsDelay} linear;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.bgColor} !important;
      border: 0.063rem solid ${({ theme }) => theme.colors.disabledColor};
      color: ${({ theme }) => theme.colors.disabledColor} !important;
    }

    &:focus {
      border: 0.063rem solid ${({ theme }) => theme.colors.focusColor};
    }

    ${({ size, hasLeftIcon, hasRightIcon }) =>
      size === 'xs'
        ? css`
            padding: 0.188rem 0rem;
            padding-left: ${hasLeftIcon ? '2.25rem' : '0.563rem'};
            padding-right: ${hasRightIcon ? '2.25rem' : '0.563rem'};
          `
        : size === 'sm'
        ? css`
            padding: 0.438rem 0rem;
            padding-left: ${hasLeftIcon ? '2.25rem' : '0.875rem'};
            padding-right: ${hasRightIcon ? '2.25rem' : '0.875rem'};
          `
        : size === 'lg'
        ? css`
            padding: 0.75rem 0rem;
            padding-left: ${hasLeftIcon ? '2.25rem' : '0.875rem'};
            padding-right: ${hasRightIcon ? '2.25rem' : '0.875rem'};
          `
        : css`
            padding: 0.563rem 0rem;
            padding-left: ${hasLeftIcon ? '2.25rem' : '0.875rem'};
            padding-right: ${hasRightIcon ? '2.25rem' : '0.875rem'};
          `}

    ${({ isExternal, theme, hasError }) =>
      isExternal &&
      css`
        border-radius: 0;
        border: 0;
        border-bottom: 0.063rem dashed
          ${hasError ? theme.colors.dangerColor : theme.colors.placeholderColor};
        background-color: transparent !important;
        padding-left: 0;
        padding-right: 0;

        &:disabled {
          background-color: transparent !important;
          border: 0;
          border-bottom: 0.063rem dashed
            ${({ theme }) => theme.colors.disabledColor};
          color: ${({ theme }) => theme.colors.disabledColor} !important;
        }

        &:focus {
          border: 0;
          border-bottom: 0.063rem dashed
            ${({ theme }) => theme.colors.focusColor};
        }
      `}
  }

  & .mantine-Textarea-wrapper textarea {
    ${({ hasActionButtons }) =>
      hasActionButtons &&
      css`
        border-bottom: 0;

        &:focus {
          border: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
          border-bottom: 0;
        }
      `}
  }

  /* PASSWORD */

  & .mantine-PasswordInput-input {
    background-color: ${({ theme }) => theme.colors.menuBgColor};
    border: 0.063rem solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.dangerColor : theme.colors.borderColor};
    border-radius: 0.25rem;
    transition: border ${transitionsDelay} linear;

    &:focus,
    &:focus-within {
      border: 0.063rem solid ${({ theme }) => theme.colors.focusColor};
    }

    &.mantine-PasswordInput-disabled {
      background-color: ${({ theme }) => theme.colors.bgColor};
      border: 0.063rem solid ${({ theme }) => theme.colors.disabledColor};
    }

    & input {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.labelColor} !important;

      &:disabled {
        color: ${({ theme }) => theme.colors.disabledColor} !important;
      }
    }

    &,
    & input {
      ${({ size }) =>
        size === 'xs'
          ? css`
              height: 1.563rem;
              min-height: 1.563rem;
              padding-left: 0.563rem;
            `
          : size === 'sm'
          ? css`
              height: 2rem;
              min-height: 2rem;
              padding-left: 0.875rem;
            `
          : size === 'lg'
          ? css`
              height: 3rem;
              min-height: 3rem;
              padding-left: 0.875rem;
            `
          : css`
              height: 2.5rem;
              min-height: 2.5rem;
              padding-left: 0.875rem;
            `}
    }
  }

  & .mantine-PasswordInput-rightSection button:hover {
    background-color: unset;
  }

  /* SELECT */

  & .mantine-Select-input,
  & .mantine-MultiSelect-input {
    background: ${({ theme }) => theme.colors.menuBgColor};
    border: 0.063rem solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.dangerColor : theme.colors.borderColor};
    border-radius: 0.25rem;
    min-height: unset;
    height: auto;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: ${({ theme, hasError }) =>
      hasError ? theme.colors.dangerColor : theme.colors.labelColor} !important;
    transition: border ${transitionsDelay} linear;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.bgColor} !important;
      border: 0.063rem solid ${({ theme }) => theme.colors.disabledColor};
      color: ${({ theme }) => theme.colors.disabledColor} !important;
    }

    &:focus {
      border: 0.063rem solid ${({ theme }) => theme.colors.focusColor};
    }

    & .mantine-MultiSelect-values {
      min-height: unset;

      & .mantine-MultiSelect-value {
        margin: 0;
        margin-right: 0.5rem;
        margin-top: 0.125rem;
        margin-bottom: 0.125rem;
      }
    }

    // For some reason in Codewip the padding-left when there's an icon wasn't working, force it here
    &.mantine-Select-withIcon {
      padding-left: 2.25rem !important;
    }

    ${({ size }) =>
      size === 'xs'
        ? css`
            padding-top: 0.188rem;
            padding-bottom: 0.188rem;
          `
        : size === 'sm'
        ? css`
            padding-top: 0.313rem;
            padding-bottom: 0.313rem;
          `
        : size === 'lg'
        ? css`
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
          `
        : css`
            padding-top: 0.563rem;
            padding-bottom: 0.563rem;
          `}

    ${({ isExternal, theme, hasError }) =>
      isExternal &&
      css`
        border-radius: 0;
        border: 0;
        border-bottom: 0.063rem dashed
          ${hasError ? theme.colors.dangerColor : theme.colors.placeholderColor};
        background-color: transparent !important;
        padding-left: 0;
        padding-right: 0;

        & .mantine-MultiSelect-values {
          margin-left: 0;
        }

        &:disabled {
          background-color: transparent !important;
          border: 0;
          border-bottom: 0.063rem dashed
            ${({ theme }) => theme.colors.disabledColor};
          color: ${({ theme }) => theme.colors.disabledColor} !important;
        }

        &:focus {
          border: 0;
          border-bottom: 0.063rem dashed
            ${({ theme }) => theme.colors.focusColor};
        }
      `}
  }

  & .mantine-Select-dropdown,
  & .mantine-MultiSelect-dropdown {
    background: ${({ theme }) => theme.colors.menuBgColor};
    border: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
    border-radius: 0.25rem;

    & .mantine-Select-itemsWrapper,
    & .mantine-MultiSelect-itemsWrapper {
      padding: 0;

      & .mantine-Select-item,
      & .mantine-MultiSelect-item {
        color: ${({ theme }) => theme.colors.labelColor};
        transition: background-color ${transitionsDelay} linear;
        border-radius: 0;

        &:first-child {
          border-top-right-radius: 0.25rem;
          border-top-left-radius: 0.25rem;
        }

        &:last-child {
          border-bottom-right-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }

        &[data-hovered] {
          background-color: ${({ theme }) => theme.colors.g500};
        }

        &[data-selected] {
          background-color: ${({ theme }) => theme.colors.g400};
        }

        &[data-disabled] {
          color: ${({ theme }) => theme.colors.disabledColor};
        }
      }
    }
  }

  /* DATE PICKER */

  & .mantine-DatePicker-input {
    background: ${({ theme }) => theme.colors.menuBgColor};
    border: 0.063rem solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.dangerColor : theme.colors.borderColor};
    border-radius: 0.25rem;
    min-height: unset;
    height: auto;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: ${({ theme, hasError }) =>
      hasError ? theme.colors.dangerColor : theme.colors.labelColor} !important;
    transition: border ${transitionsDelay} linear;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.bgColor} !important;
      border: 0.063rem solid ${({ theme }) => theme.colors.disabledColor};
      color: ${({ theme }) => theme.colors.disabledColor} !important;
    }

    &:focus {
      border: 0.063rem solid ${({ theme }) => theme.colors.focusColor};
    }

    ${({ size }) =>
      size === 'xs'
        ? css`
            padding-top: 0.188rem;
            padding-bottom: 0.188rem;
          `
        : size === 'sm'
        ? css`
            padding-top: 0.313rem;
            padding-bottom: 0.313rem;
          `
        : size === 'lg'
        ? css`
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
          `
        : css`
            padding-top: 0.563rem;
            padding-bottom: 0.563rem;
          `}

    ${({ isExternal, theme, hasError }) =>
      isExternal &&
      css`
        border-radius: 0;
        border: 0;
        border-bottom: 0.063rem dashed
          ${hasError ? theme.colors.dangerColor : theme.colors.placeholderColor};
        background-color: transparent !important;
        padding-left: 0;
        padding-right: 0;

        &:disabled {
          background-color: transparent !important;
          border: 0;
          border-bottom: 0.063rem dashed
            ${({ theme }) => theme.colors.disabledColor};
          color: ${({ theme }) => theme.colors.disabledColor} !important;
        }

        &:focus {
          border: 0;
          border-bottom: 0.063rem dashed
            ${({ theme }) => theme.colors.focusColor};
        }
      `}
  }

  & .mantine-DatePicker-dropdown {
    background: ${({ theme }) => theme.colors.menuBgColor};
    border: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
    border-radius: 0.25rem;

    & .mantine-DatePicker-calendarHeaderLevel {
      color: ${({ theme }) => theme.colors.labelColor};
      transition: background-color ${transitionsDelay} linear;

      & svg {
        color: ${({ theme }) => theme.colors.labelColor};
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.g500};
      }
    }

    & .mantine-DatePicker-calendarHeaderControl {
      color: ${({ theme }) => theme.colors.labelColor};
      transition: background-color ${transitionsDelay} linear;

      &:hover {
        background-color: ${({ theme }) => theme.colors.g500};
      }
    }

    & .mantine-DatePicker-yearPickerControl {
      color: ${({ theme }) => theme.colors.labelColor};
      transition: background-color ${transitionsDelay} linear;

      &.mantine-DatePicker-yearPickerControlActive {
        background-color: ${({ theme }) => theme.colors.g600};
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.g500};
      }
    }

    & .mantine-DatePicker-monthPickerControl {
      color: ${({ theme }) => theme.colors.labelColor};
      transition: background-color ${transitionsDelay} linear;

      &.mantine-DatePicker-monthPickerControlActive {
        background-color: ${({ theme }) => theme.colors.g600};
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.g500};
      }
    }

    & .mantine-DatePicker-day {
      color: ${({ theme }) => theme.colors.labelColor};
      transition: background-color ${transitionsDelay} linear;

      &[data-weekend] {
        color: ${({ theme }) => theme.colors.labelColor};
      }

      &[data-outside] {
        color: ${({ theme }) => theme.colors.placeholderColor};
      }

      &[data-selected] {
        background-color: ${({ theme }) => theme.colors.g600};
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.g500};
      }

      &:disabled {
        color: ${({ theme }) => theme.colors.disabledColor};
      }
    }

    & .mantine-DatePicker-weekday {
      color: rgb(173, 181, 189) !important;
    }
  }

  /* CHECKBOX */

  & .mantine-Checkbox-root {
    & .mantine-Checkbox-body {
      display: flex;
      align-items: center;
      flex-direction: row;

      & .mantine-Checkbox-inner {
        & input {
          transition: background-color ${transitionsDelay} linear,
            border ${transitionsDelay} linear;
          background-color: ${({ theme }) => theme.colors.menuBgColor};
          border: 0.125rem solid
            ${({ theme, hasError }) =>
              hasError ? theme.colors.dangerColor : theme.colors.switchBgColor};

          &:checked {
            background-color: ${({ theme }) => theme.colors.g300};
            border: 0.125rem solid ${({ theme }) => theme.colors.g300};
          }

          &:checked + :disabled {
            background-color: ${({ theme }) => theme.colors.g500};
            border: 0.125rem solid ${({ theme }) => theme.colors.g500};
          }

          &:disabled {
            opacity: 0.5;
          }

          &:checked + .__mantine-ref-icon {
            color: ${({ theme }) => theme.colors.menuBgColor};
          }
        }
      }

      & .mantine-Checkbox-label {
        padding-bottom: 0;
      }

      ${({ size }) =>
        size === 'xs'
          ? css`
              & .mantine-Checkbox-inner,
              & .mantine-Checkbox-inner input {
                width: 0.875rem;
                height: 0.875rem;
              }

              & svg {
                width: 0.5rem;
                height: 0.5rem;
              }
            `
          : size === 'sm'
          ? css`
              & .mantine-Checkbox-inner,
              & .mantine-Checkbox-inner input {
                width: 1rem;
                height: 1rem;
              }

              & svg {
                width: 0.625rem;
                height: 0.625rem;
              }
            `
          : size === 'lg'
          ? css`
              & .mantine-Checkbox-inner,
              & .mantine-Checkbox-inner input {
                width: 1.375rem;
                height: 1.375rem;
              }

              & svg {
                width: 1rem;
                height: 1rem;
              }
            `
          : css`
              & .mantine-Checkbox-inner,
              & .mantine-Checkbox-inner input {
                width: 1.125rem;
                height: 1.125rem;
              }

              & svg {
                width: 0.75rem;
                height: 0.75rem;
              }
            `}
    }
  }

  /* TIME PICKER */

  & .mantine-TimeInput-wrapper .mantine-TimeInput-input {
    background: ${({ theme }) => theme.colors.menuBgColor};
    border: 0.063rem solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.dangerColor : theme.colors.borderColor};
    border-radius: 0.25rem;
    min-height: unset;
    height: auto;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: ${({ theme, hasError }) =>
      hasError ? theme.colors.dangerColor : theme.colors.labelColor} !important;
    transition: border ${transitionsDelay} linear;

    & .mantine-TimeInput-controls {
      height: auto;
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.bgColor} !important;
      border: 0.063rem solid ${({ theme }) => theme.colors.disabledColor};
      color: ${({ theme }) => theme.colors.disabledColor} !important;
    }

    &:focus {
      border: 0.063rem solid ${({ theme }) => theme.colors.focusColor};
    }

    & input {
      font-family: 'Inter', sans-serif;
    }

    ${({ size }) =>
      size === 'xs'
        ? css`
            padding-top: 0.125rem;
            padding-bottom: 0.125rem;
          `
        : size === 'sm'
        ? css`
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
          `
        : size === 'lg'
        ? css`
            padding-top: 0.688rem;
            padding-bottom: 0.688rem;
          `
        : css`
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          `}
  }

  /* DATE TIME PICKER */

  & .react-datetime-picker {
    width: 100%;

    &.react-datetime-picker--disabled {
      background-color: transparent;

      & .react-datetime-picker__wrapper {
        background: ${({ theme }) => theme.colors.bgColor} !important;
        border: 0.063rem solid ${({ theme }) => theme.colors.disabledColor} !important;

        & .react-datetime-picker__inputGroup__input, 
        & .react-datetime-picker__inputGroup__divider, 
        & .react-datetime-picker__inputGroup__leadingZero {
          color: ${({ theme }) => theme.colors.disabledColor} !important;
        }
      }
    }

    & input {
      height: auto;

      &:focus-visible {
        outline: 0;
      }
    }
    
    & .react-datetime-picker__wrapper {
      background: ${({ theme }) => theme.colors.menuBgColor};
      border: 0.063rem solid ${({ theme, hasError }) => hasError ? theme.colors.dangerColor : theme.colors.borderColor};
      border-radius: 0.25rem;
      transition: border ${transitionsDelay} linear;

      ${({ size }) =>
        size === 'xs'
        ? css`
            padding-left: 0.563rem;
            height: 1.75rem;
          `
        : size === 'sm'
        ? css`
            padding-left: 0.875rem;
            height: 2.25rem;
          `
        : size === 'lg'
        ? css`
            padding-left: 0.875rem;
            height: 3rem;
          `
        : css`
            padding-left: 0.875rem;
            height: 2.5rem;
      `}

      & .react-datetime-picker__inputGroup {
        display: flex;
        align-items: center;
      }

      & .react-datetime-picker__inputGroup__input, 
      & .react-datetime-picker__inputGroup__divider, 
      & .react-datetime-picker__inputGroup__leadingZero {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.labelColor};
      }

      & .react-datetime-picker__inputGroup__divider,
      & .react-datetime-picker__inputGroup__leadingZero {
        ${({ size }) =>
          size === 'xs'
            ? css`
                font-size: 0.75rem;
                line-height: 1.063rem;
              `
            : size === 'sm'
            ? css`
                font-size: 0.875rem;
                line-height: 1.25rem;
              `
            : size === 'lg'
            ? css`
                font-size: 1rem;
                line-height: 1.375rem;
              `
            : css`
                font-size: 0.875rem;
                line-height: 1.25rem;
        `}
      }

      & .react-datetime-picker__inputGroup__divider {
        padding-top: 0;
        padding-bottom: 0;
      }

      & .react-datetime-picker__inputGroup__input:invalid {
        background-color: transparent;
      }

      & .react-datetime-picker__button {
        padding-top: 0;
        padding-bottom: 0;

        & svg {
          ${({ size }) =>
            size === 'xs'
            ? css`
                width: 0.875rem;
                height: 0.875rem;
              `
            : size === 'sm'
            ? css`
                width: 1rem;
                height: 1rem;
              `
            : size === 'lg'
            ? css`
                width: 1.25rem;
                height: 1.25rem;
              `
            : css`
                width: 1.125rem;
                height: 1.125rem;
          `}
        }
      }
    }

    & .react-datetime-picker__calendar {
      z-index: 99;
      
      & .react-calendar {
        background: ${({ theme }) => theme.colors.menuBgColor};
        border: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
        border-radius: 0.25rem;

        & .react-calendar__navigation button,
        & .react-calendar__month-view__weekdays__weekday,
        & .react-calendar__month-view__days__day,
        & .react-calendar__month-view__days__day--neighboringMonth,
        & .react-calendar__year-view__months__month {
          font-family: 'Inter', sans-serif;
          font-weight: 400;
        }

        & .react-calendar__navigation button {
          color: ${({ theme }) => theme.colors.labelColor};
          transition: background-color ${transitionsDelay} linear;
          border-radius: 0.25rem;
        }

        & .react-calendar__navigation button:disabled {
          background-color: transparent;
          color: ${({ theme }) => theme.colors.disabledColor};
        }

        & .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
          background-color: ${({ theme }) => theme.colors.g500};
        }

        & .react-calendar__month-view__weekdays__weekday {
          color: #ADB5BD;

          & abbr {
            text-decoration: none;
          }
        }

        // Days
        & .react-calendar__month-view__days__day {
          color: ${({ theme }) => theme.colors.labelColor};
          font-size: 0.75rem;
          border-radius: 0.25rem;
        }

        & .react-calendar__month-view__days__day--neighboringMonth {
          color: ${({ theme }) => theme.colors.placeholderColor};
        }

        & .react-calendar__tile {
          transition: background-color ${transitionsDelay} linear;
        }

        & .react-calendar__tile--now {
          background-color: transparent;
        }

        & .react-calendar__tile--active {
          background-color: ${({ theme }) => theme.colors.g600};
        }

        & .react-calendar__tile:disabled {
          background-color: transparent;
          color: ${({ theme }) => theme.colors.disabledColor};
        }

        & .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
          background-color: ${({ theme }) => theme.colors.g500};
        }

        //Months and Years
        & .react-calendar__year-view__months__month,
        & .react-calendar__decade-view__years__year {
          color: ${({ theme }) => theme.colors.labelColor};
          border-radius: 0.25rem;
        }
      }
    }
  }

  // Disable autofill style
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[data-autocompleted] {
    background-color: transparent !important;
  }
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.dangerColor};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.063rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
`;

export const ErrorIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
  width: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CounterSection = styled.div<{ hasError: boolean }>`
  padding-top: 0.25rem;
  padding-left: 0.75rem;
  font-size: 0.75rem;
  padding-right: 0.75rem;
  color: ${({ hasError, theme }) =>
    hasError ? theme.colors.dangerColor : theme.colors.g500};
`;

export const InputLabel = styled.div<{ size?: InputSize }>`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.labelColor};
  padding-bottom: 0.5rem;

  ${({ size }) =>
    size === 'xs'
      ? css`
          font-size: 0.75rem;
          line-height: 1.063rem;
        `
      : size === 'sm'
      ? css`
          font-size: 0.875rem;
          line-height: 1.25rem;
        `
      : size === 'lg'
      ? css`
          font-size: 1rem;
          line-height: 1.375rem;
        `
      : css`
          font-size: 0.875rem;
          line-height: 1.25rem;
        `}
`;

export const ImageWrapper = styled.div<{
  size?: string;
  showDropzoneArea?: boolean;
}>`
  & .mantine-InputWrapper-label,
  & .mantine-InputWrapper-description {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.labelColor};
    padding-bottom: 0.5rem;
  }

  & .mantine-InputWrapper-description {
    color: ${({ theme }) => theme.colors.subMenuColor};
  }

  ${({ size }) =>
    size === 'xs'
      ? css`
          & .mantine-InputWrapper-label {
            font-size: 0.75rem;
            line-height: 1.063rem;
          }

          & .mantine-InputWrapper-description {
            font-size: 0.688rem;
            line-height: 1rem;
          }
        `
      : size === 'sm'
      ? css`
          & .mantine-InputWrapper-label {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }

          & .mantine-InputWrapper-description {
            font-size: 0.813rem;
            line-height: 1.125rem;
          }
        `
      : size === 'lg'
      ? css`
          & .mantine-InputWrapper-label {
            font-size: 1rem;
            line-height: 1.375rem;
          }

          & .mantine-InputWrapper-description {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
        `
      : css`
          & .mantine-InputWrapper-label {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }

          & .mantine-InputWrapper-description {
            font-size: 0.813rem;
            line-height: 1.125rem;
          }
        `}

  ${({ showDropzoneArea }) =>
    showDropzoneArea
      ? css`
          & .mantine-Dropzone-root {
            background-color: transparent;

            & .mantine-Dropzone-inner {
              pointer-events: initial;
              user-select: initial;
            }
          }
        `
      : css`
          & .mantine-Dropzone-root {
            background-color: transparent;
            border-radius: 0;
            display: inline-block;
            width: auto;

            & .mantine-Dropzone-inner {
              pointer-events: initial;
              user-select: initial;
            }
          }
        `}
`;

export const InputImageWrapper = styled.div<{
  big: boolean;
  hasError: boolean;
}>`
  background: ${({ theme }) => theme.colors.menuBgColor};
  border: 0.063rem dashed
    ${({ hasError, theme }) =>
      hasError ? theme.colors.dangerColor : theme.colors.borderColor};
  width: ${({ big }) => (big ? 5 : 3)}rem;
  height: ${({ big }) => (big ? 5 : 3)}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.25rem;
  border-radius: 50%;

  & img {
    width: auto;
    height: auto;
    max-width: ${({ big }) => (big ? 5 : 3)}rem;
    max-height: ${({ big }) => (big ? 5 : 3)}rem;
    border-radius: 50%;
  }
`;

export const CropperWrapper = styled.div`
  display: inline-block;
  width: 100%;

  .advanced-cropper {
    background: transparent;
  }
`;

export const DropzoneButton = styled(Block)`
  background-color: rgba(118, 136, 201, 0.15);
  transition: background-color ${transitionsDelay} linear;
`;

export const DropzoneWrapper = styled.div<{
  borderColor?: string;
  hasValue?: boolean;
  error?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${({ borderColor, hasValue, error, theme }) =>
    `border: 1px ${hasValue ? 'solid' : 'dashed'} ${
      error ? theme.colors.dangerColor : borderColor || theme.colors.borderColor
    }`};
  border-radius: 0.5rem;
  padding: 1.25rem 1rem;

  &:hover {
    ${DropzoneButton} {
      background-color: ${({ theme }) => theme.colors.bgColor};
    }
  }
`;

export const PhoneInputWrapper = styled.div<{
  size: InputSize;
  hasError: boolean;
  isExternal: boolean;
}>`
  & .PhoneInput {
    border: 0.063rem solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.dangerColor : theme.colors.borderColor};
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.colors.menuBgColor};

    & .PhoneInputCountry {
      padding: 0.5rem 0.75rem;
      background-color: ${({ theme }) => theme.colors.bgColor};
      border-right: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
      margin-right: 0.75rem;
      border-radius: 0.25rem;

      ${({ size }) =>
        size === 'xs'
          ? css`
              padding-top: 0.188rem;
              padding-bottom: 0.188rem;
            `
          : size === 'sm'
          ? css`
              padding-top: 0.438rem;
              padding-bottom: 0.438rem;
            `
          : size === 'lg'
          ? css`
              padding-top: 0.938rem;
              padding-bottom: 0.938rem;
            `
          : css`
              padding-top: 0.688rem;
              padding-bottom: 0.688rem;
            `}
    }

    & input {
      background-color: ${({ theme }) => theme.colors.menuBgColor};
      color: ${({ theme }) => theme.colors.labelColor};
      padding: 0;
      border: 0;
      outline: 0;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      padding-right: ${({ hasError }) => hasError && '2.25rem'};
    }

    & input::placeholder {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.placeholderColor};
    }

    & input:disabled {
      color: ${({ theme }) => theme.colors.disabledColor} !important;
    }

    & input::placeholder,
    & input {
      ${({ size }) =>
        size === 'xs'
          ? css`
              font-size: 0.75rem;
              line-height: 1.063rem;
            `
          : size === 'sm'
          ? css`
              font-size: 0.875rem;
              line-height: 1.25rem;
            `
          : size === 'lg'
          ? css`
              font-size: 1rem;
              line-height: 1.375rem;
            `
          : css`
              font-size: 0.875rem;
              line-height: 1.25rem;
            `}
    }

    & .PhoneInputCountrySelect {
      background-color: ${({ theme }) => theme.colors.menuBgColor};

      & option {
        color: ${({ theme }) => theme.colors.labelColor};
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
      }
    }

    & .PhoneInputCountrySelectArrow {
      border-color: #ffffff;
      width: 0.4rem;
      height: 0.4rem;
      margin-left: 0.3rem;
    }

    ${({ isExternal, theme, hasError }) =>
      isExternal &&
      css`
        background-color: transparent !important;
        border: 0;

        & .PhoneInputCountry {
          & .PhoneInputCountrySelect {
            background-color: #15171b;
            border-radius: 0.25rem;
          }
        }

        & input {
          background-color: transparent !important;
          border-bottom: 0.063rem dashed
            ${hasError
              ? theme.colors.dangerColor
              : theme.colors.placeholderColor};
          padding: 0.5rem 0;
        }
      `}
  }
`;

export const QuillWrapper = styled.div<{ hasError: boolean }>`
  & .mantine-RichTextEditor-root {
    border: 0.063rem solid ${({ theme, hasError }) => hasError ? theme.colors.dangerColor : theme.colors.borderColor};
    border-radius: 0.25rem;
    overflow: hidden;
  }

  .ql-editor {
    min-height: 9.375rem;
  }

  .ql-editor.ql-blank::before {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-style: normal;
    color: ${({ theme }) => theme.colors.placeholderColor} !important;
  }

  .ql-container.ql-snow {
    border: none;
    background-color: ${({ theme }) => theme.colors.menuBgColor} !important;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.labelColor};
  }

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 0.063rem solid ${({ theme }) => theme.colors.g500};
    background-color: ${({ theme }) => theme.colors.g200} !important;
  }
`;

export const FlagIcon = styled.div`
  width: 1.875rem;
  height: 100%;
  border-right: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 1.125rem;
  }
`;

export const SwitchWrapper = styled.div<{ size?: InputSize }>`
  & .mantine-Switch-body {
    display: flex;

    & .mantine-Switch-track {
      background-color: ${({ theme }) => theme.colors.switchBgColor};
      border-radius: 6.25rem;
      border: 0;
      height: 1.5rem;
      width: 2.75rem;
      min-width: unset;
      cursor: pointer;

      & .mantine-Switch-thumb {
        background: ${({ theme }) => theme.colors.menuBgColor};
        box-shadow: 0rem 0.063rem 0.188rem rgba(24, 24, 25, 0.16);
        border: 0;
      }

      ${({ size }) =>
        size === 'xs'
          ? css`
              width: 2rem;
              height: 1.125rem;

              & .mantine-Switch-thumb {
                width: 0.75rem;
                height: 0.75rem;
              }
            `
          : size === 'sm'
          ? css`
              width: 2.5rem;
              height: 1.375rem;

              & .mantine-Switch-thumb {
                width: 1rem;
                height: 1rem;
              }
            `
          : size === 'lg'
          ? css`
              width: 3rem;
              height: 1.75rem;

              & .mantine-Switch-thumb {
                width: 1.375rem;
                height: 1.375rem;
              }
            `
          : css`
              width: 2.75rem;
              height: 1.5rem;

              & .mantine-Switch-thumb {
                width: 1.125rem;
                height: 1.125rem;
              }
            `}
    }

    & .mantine-Switch-label {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.labelColor};
      font-size: 0.875rem;
    }

    & input:checked + .mantine-Switch-track {
      background-color: ${({ theme }) => theme.colors.g300};
    }

    & input:disabled + .mantine-Switch-track {
      opacity: 0.5;
    }
  }
`;

export const FileWrapper = styled.div<{ size?: string }>`
  & .mantine-InputWrapper-label,
  & .mantine-InputWrapper-description {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.labelColor};
    padding-bottom: 0.5rem;
  }

  & .mantine-InputWrapper-description {
    color: ${({ theme }) => theme.colors.subMenuColor};
  }

  & .mantine-Dropzone-root {
    background-color: transparent;

    & .mantine-Dropzone-inner {
      pointer-events: initial;
      user-select: initial;
    }
  }

  ${({ size }) =>
    size === 'xs'
      ? css`
          & .mantine-InputWrapper-label {
            font-size: 0.75rem;
            line-height: 1.063rem;
          }

          & .mantine-InputWrapper-description {
            font-size: 0.688rem;
            line-height: 1rem;
          }
        `
      : size === 'sm'
      ? css`
          & .mantine-InputWrapper-label {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }

          & .mantine-InputWrapper-description {
            font-size: 0.813rem;
            line-height: 1.125rem;
          }
        `
      : size === 'lg'
      ? css`
          & .mantine-InputWrapper-label {
            font-size: 1rem;
            line-height: 1.375rem;
          }

          & .mantine-InputWrapper-description {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
        `
      : css`
          & .mantine-InputWrapper-label {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }

          & .mantine-InputWrapper-description {
            font-size: 0.813rem;
            line-height: 1.125rem;
          }
        `}
`;

export const ComposedInputWrapper = styled(Box)<{
  hasError: boolean;
  hasFirstInput: boolean;
  hasButton: boolean;
}>` 
  display: inline-flex;
  align-items: center;
  border: 0.063rem solid ${({ theme, hasError }) =>
    hasError ? theme.colors.dangerColor : theme.colors.borderColor};
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.125rem 0.313rem;
  flex-wrap: wrap;

  & .inputButtonWrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: ${({ hasFirstInput }) => (hasFirstInput ? '70%' : '100%')};

    @media ${breakpoints.xs} {
      width: 100%;
      padding-right: 0.313rem;
    }
  }

  & .firstInput {
    width: 30%;

    & .mantine-Select-input {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      padding-right: 1.625rem;
      font-size: 0.75rem;
      line-height: 1rem;

      &::placeholder {
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }

    & .mantine-Select-dropdown .mantine-Select-item {
      font-size: 0.875rem;
      line-height: 1rem;
    }

    @media ${breakpoints.xs} {
      max-width: 100%;
      width: 100%;
      border-bottom: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
    }
  }

  & .secondInput {
    width: ${({ hasButton }) => hasButton ? '80%' : '100%'};

    & .mantine-TextInput-wrapper input {
      background-color: transparent !important;
      border: 0;

      border-radius: 0;
      border-left: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
      font-size: 0.75rem;
      line-height: 1rem;

      &::placeholder {
        font-size: 0.75rem;
        line-height: 1rem;
      }

      @media ${breakpoints.xs} {
        border-left: 0;
      }
    }

    & .mantine-Select-input {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      padding-right: 1.625rem;
      font-size: 0.75rem;
      line-height: 1rem;

      &::placeholder {
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }

    & .mantine-Select-dropdown .mantine-Select-item {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }

  & .mantine-Button-root {
    padding: 0.375rem 1rem;
    width: 20%;

    & .mantine-Button-label span {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    @media ${breakpoints.xs} {
      padding: 0.375rem;
    }
  }

  @media ${breakpoints.xs} {
    padding: 0;
  }
`;

export const DotIcon = styled.div<{ color: string, width?: number, height?: number }>`
  width: ${({ width }) => width || 0.375}rem;
  height: ${({ height }) => height || 0.375}rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;
