import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../Context';
import { Tabs } from '@mantine/core';
import { TabsWrapper } from '../tabs/styles';

const LanguageTabs: React.FC<{
  children: (lang: string) => JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const { t } = useTranslation();
  const languages = useContext(LanguageContext);

  return (
    <TabsWrapper>
      <Tabs radius="md" defaultValue="pt" keepMounted={false}>
        <Tabs.List>
          {languages.map((lang) => (
            <Tabs.Tab
              key={lang.code}
              icon={<img style={{ width: '18px' }} src={lang.icon} />}
              value={lang.code}
            >
              {t(lang.tag)}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {languages.map((lang) => (
          <Tabs.Panel key={lang.code} value={lang.code}>
            {children(lang.code)}
          </Tabs.Panel>
        ))}
      </Tabs>
    </TabsWrapper>
  );
};

export default LanguageTabs;
