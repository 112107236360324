/* eslint-disable @typescript-eslint/no-explicit-any */
import "./styles.css";
import { generateProps } from 'styled-gen';
import { FC, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

export interface TypographyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  variant?: 'title-h1' 
    | 'title' 
    | 'body' 
    | 'body-small' 
    | 'body-bold' 
    | 'sidebar-group' 
    | 'sidebar-item' 
    | 'pagination' 
    | 'table-header' 
    | 'dropdown-menu' 
    | 'dropdown-menu-small'
    | 'multi-select'
    | 'page-title'
    | 'auth-title';
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';
}

const CustomTag = styled.p<any>`
  ${generateProps};
`

export const Typography: FC<TypographyProps & any> = ({  
    children, 
    as, 
    variant = 'body',
    ...props
}) => {

  if(as === undefined) {
    if(variant == 'title-h1') {
      as = 'h1';
    }
    else {
      as = 'span';
    }
  }

  return <CustomTag 
    as={as}
    className={`typography-${variant}`}
    { ...props}
    >
      {children}
    </CustomTag>;
};

export default Typography;
