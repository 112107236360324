/* eslint-disable @typescript-eslint/no-explicit-any */
import { User, UserGroupType, UserStatusList } from '../../models/User';

export const GetFirstAndLastName = (name?: string) => {
  if(name?.includes(' ')) {
    const parts = name.split(' ');
    return `${parts[0]} ${parts[parts.length - 1]}`;
  }

  return name || '';
};

export const GetFirstAndLastInitials = (name?: string) => {
  if(name?.includes(' ')) {
    const parts = name.split(' ');
    return `${parts[0]?.charAt(0)}${parts[parts.length - 1]?.charAt(0)}`.toUpperCase();
  }
  
  return name?.charAt(0)?.toUpperCase() || '';
};

export const GetFirstInitial = (name?: string) => {
  return name?.charAt(0)?.toUpperCase() || '';
};

export const GetLastName = (name?: string) => {
  if(name?.includes(' ')) {
    const parts = name.split(' ');
    return parts[parts.length - 1];
  }

  return name || '';
};

export const GetUserStatus = (value: string | undefined) => UserStatusList.find(elem => elem._id === value) || {};

export const GetGroupName = (t: (tag: string, props?: any) => string, tag?: UserGroupType, number?: number) => {
  if(tag && number) return `${number} - ${t(tag)}`;
  if(tag) return t(tag);
  if(number) return `${number}`;

  return '';
};

// Order applied
// 1 - "Search athlete" option always on top (it has order: 1)
// 2 - Order by group names
// 3 - Disabled items go to the bottom of the list
// 4 - Order by user names
export const SortDisabledUsers = (list: User[]) => {
  return list.sort((x, y) => 
    (x.order === y.order ? 0 : x.order ? -1 : 1) || 
    (!!x.group && !!y.group && x.group.localeCompare(y.group)) || 
    (x.disabled === y.disabled ? 0 : x.disabled ? 1 : -1) || 
    x.name.localeCompare(y.name));
};
