import { Burger } from '@mantine/core';
import { HeaderWrapper, LogoImg } from './HeaderStyles';
import { Block, Box } from '../../styles/BasicStyles';
import { InfoContext, UserContext } from '../../Context';
import { FC, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TableHeader, FocusColor } from '../../styles/Colors';
import { SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE } from '../../models/User';
import { useClickOutside } from '../../hooks/outside-click';
import DropdownMenu, { ActionProps } from '../dropdownMenu';
import Logo from '../../assets/images/logo.svg';
import Icon from '../icon';
import UserAvatar from '../userAvatar';
import LanguageSelector from './LanguageSelector';
import NotificationsModal from '../notificationsModal';

interface HeaderProps {
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  showSidebar: boolean;
}

export const Header: FC<HeaderProps> = ({
  menuOpen,
  setMenuOpen,
  showSidebar
}) => {
  const [notificationModal, setNotificationModal] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const { info } = useContext(InfoContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notificationsRef = useRef(null);
  const hasSettingsList = [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE];
  const hasNotificationsList = [SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE];

  const closeNotificationsModal = () => notificationModal && setNotificationModal(false);

  useClickOutside(notificationsRef?.current, closeNotificationsModal);

  const handleLogout = () => {
    localStorage.clear();
    setUser(undefined);
  };

  const options: ActionProps[] = [
    {
      type: 'link',
      label: user?.name,
      subLabel: t(user?.type || '')
    },
    {
      type: 'divider'
    },
    {
      type: 'link',
      label: t('LOG_OUT'),
      highlight: true,
      onClick: handleLogout
    }
  ];

  if(user?.type && hasSettingsList.includes(user.type)) {
    options.splice(2, 0, {
      type: 'link',
      label: t('SETTINGS'),
      onClick: () => navigate('/my-profile')
    });
  }

  return (
    <HeaderWrapper>
      <Box fAlign="center">
        {showSidebar && (
          <Burger
            size="sm"
            opened={menuOpen}
            onClick={() => setMenuOpen(!menuOpen)}
            color="#ffffff"
            className="mobileMenu"
          />
        )}
        <LogoImg
          src={Logo}
          alt="logo"
          isLink={true}
          onClick={() => navigate('/')}
        />
      </Box>
      <Box fAlign="center" fJustify="flex-end">
        {
          !!info?.configs?.feedback_url &&
          <Block mr={{ md: 1.563, xs: 1, xxs: 0.5 }}>
            <a href={info?.configs?.feedback_url} target='_blank' rel="noreferrer" title={t('SUBMIT_FEEDBACK')}>
              <Icon icon="outlined_speakerphone" color={TableHeader} hoverColor={FocusColor} size={1.3} />
            </a>
          </Block>
        }
        {
          user?.type && hasNotificationsList.includes(user.type) &&
          <Block mr={{ md: 1.563, xs: 1, xxs: 0.5 }}>
            <Block position='relative' ref={notificationsRef}>
              <Icon icon="outlined_bell" color={TableHeader} hoverColor={FocusColor} size={1.3} onClick={() => setNotificationModal(!notificationModal)} />
              <NotificationsModal 
                open={notificationModal}
                closeNotificationsModal={closeNotificationsModal}
              />
            </Block>
          </Block>
        }
        <Block mr={{ md: 1.563, xs: 1, xxs: 0.5 }}>
          <LanguageSelector />
        </Block>
        <DropdownMenu
          render={<UserAvatar user={user} />}
          menuPosition="bottom-end"
          profileDropdown={true}
          actions={options}
        />
      </Box>
    </HeaderWrapper>
  );
};

export default Header;
