/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

export const useClickOutside = (element: HTMLElement | null, callback: () => void) => {
  const handleClick = (event: any) => {
    if(element && !element.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
