import { HeaderWrapper, LogoImg, ExternalLanguageWrapper } from './HeaderStyles';
import { FC } from 'react';
import { Box, Block } from '../../styles/BasicStyles';
import Logo from '../../assets/images/logo.svg';
import LanguageSelector from './LanguageSelector';

export const HeaderExternal: FC = () => {
  return (
    <HeaderWrapper>
      <Block position='relative' w='100%'>
        <Box fJustify='center'>
          <LogoImg src={Logo} alt='logo' isLink={false} />
        </Box>
        <ExternalLanguageWrapper>
          <LanguageSelector />
        </ExternalLanguageWrapper>
      </Block>
    </HeaderWrapper>
  );
};

export default HeaderExternal;
