import { 
  ScrollBarThumbHover, ScrollBarThumb, ScrollBarTrack, 
  BgColor, MenuBgColor, MenuActiveColor, SubMenuColor,
  Gradient,
  SuccessColor, DangerColor,
  LabelColor, PlaceholderColor, DisabledColor, FocusColor, BorderColor, 
  PaginationCountColor, PaginationActiveColor, PaginationHoverColor, PaginationColor, PaginationArrows, PaginationArrowsHover,
  TableHeader,
  DropdownMenuBg,
  AlertErrorBg, AlertErrorBorder, AlertSuccessBg, AlertSuccessBorder, AlertWarningBg, AlertWarningBorder, AlertInfoBg, AlertInfoBorder,
  SwitchBgColor,
  UserAvatarBg,
  LoginButtonColor,
  FiltersBgColor, FiltersHeaderBgColor,
  DividerColor,
  WhiteJudge, GreenJudge, YellowJudge,
  BlueFighter, RedFighter,
  GraphBorder, GraphRed, GraphYellow, GraphGreen, GraphWhiteSelected, GraphGreenSelected, GraphYellowSelected,
  ConsistencyGreen, ConsistencyGreenBg, ConsistencyYellow, ConsistencyYellowBg, ConsistencyRed, ConsistencyRedBg,
  G800, G700, G600, G500, G400, G300, G200, G100,
  B800, B700, B600, B500, B400, B300, B200, B100,
  P800, P700, P600, P500, P400, P300, P200, P100,
  R800, R700, R600, R500, R400, R300, R200, R100
} from './Colors';
import { generator } from './Generator';
import { sizes } from './Breakpoints';

const Theme = {
  generator,
  breakpoints: sizes,
  colors: {
    scrollBarThumbHover: ScrollBarThumbHover,
    scrollBarThumb: ScrollBarThumb,
    scrollBarTrack: ScrollBarTrack,
    bgColor: BgColor,
    menuBgColor: MenuBgColor,
    menuActiveColor: MenuActiveColor,
    subMenuColor: SubMenuColor,
    gradient: Gradient,
    successColor: SuccessColor,
    dangerColor: DangerColor,
    labelColor: LabelColor,
    placeholderColor: PlaceholderColor,
    disabledColor: DisabledColor,
    borderColor: BorderColor,
    focusColor: FocusColor,
    paginationCountColor: PaginationCountColor,
    paginationActiveColor: PaginationActiveColor,
    paginationHoverColor: PaginationHoverColor,
    paginationColor: PaginationColor,
    paginationArrows: PaginationArrows,
    paginationArrowsHover: PaginationArrowsHover,
    tableHeader: TableHeader,
    dropdownMenuBg: DropdownMenuBg,
    alertErrorBg: AlertErrorBg, 
    alertErrorBorder: AlertErrorBorder, 
    alertSuccessBg: AlertSuccessBg, 
    alertSuccessBorder: AlertSuccessBorder, 
    alertWarningBg: AlertWarningBg, 
    alertWarningBorder: AlertWarningBorder, 
    alertInfoBg: AlertInfoBg, 
    alertInfoBorder: AlertInfoBorder,
    switchBgColor: SwitchBgColor,
    userAvatarBg: UserAvatarBg,
    loginButtonColor: LoginButtonColor,
    filtersBgColor: FiltersBgColor,
    filtersHeaderBgColor: FiltersHeaderBgColor,
    dividerColor: DividerColor,
    whiteJudge: WhiteJudge,
    greenJudge: GreenJudge,
    yellowJudge: YellowJudge,
    blueFighter: BlueFighter,
    redFighter: RedFighter,
    graphBorder: GraphBorder,
    graphRed: GraphRed,
    graphYellow: GraphYellow,
    graphGreen: GraphGreen,
    graphWhiteSelected: GraphWhiteSelected,
    graphGreenSelected: GraphGreenSelected,
    graphYellowSelected: GraphYellowSelected,
    consistencyGreen: ConsistencyGreen, 
    consistencyGreenBg: ConsistencyGreenBg, 
    consistencyYellow: ConsistencyYellow, 
    consistencyYellowBg: ConsistencyYellowBg, 
    consistencyRed: ConsistencyRed, 
    consistencyRedBg: ConsistencyRedBg,
    g800: G800,
    g700: G700,
    g600: G600,
    g500: G500,
    g400: G400,
    g300: G300,
    g200: G200,
    g100: G100,
    b800: B800,
    b700: B700,
    b600: B600,
    b500: B500,
    b400: B400,
    b300: B300,
    b200: B200,
    b100: B100,
    p800: P800,
    p700: P700,
    p600: P600,
    p500: P500,
    p400: P400,
    p300: P300,
    p200: P200,
    p100: P100,
    r800: R800,
    r700: R700,
    r600: R600,
    r500: R500,
    r400: R400,
    r300: R300,
    r200: R200,
    r100: R100
  }
};

export default Theme;
