import { FC } from 'react';
import { Block, Box } from '../../../styles/BasicStyles';
import { MenuBgColor, BorderColor, PlaceholderColor, FocusColor } from '../../../styles/Colors';
import Typography from '../../../components/typography';
import Link from '../../../components/link';

export type Item = {
  title: string;
  value?: string;
  last?: boolean;
  link?: boolean;
}

interface Params {
  items: Item[];
}

const InfoCard: FC<Params> = ({ items }) => {
  const renderItem = (item: Item, index: number) => 
    <Box 
      key={index}
      fDirection={{ xs: 'row', xxs: 'column' }} 
      padding='0.75 0' 
      fAlign={{ xs: 'center', xxs: 'flex-start' }} 
      style={{ borderBottom: !item.last ? '0.063rem solid rgba(255, 255, 255, 0.07)' : 0}}
    >
      <Typography variant='body-small' style={{ color: PlaceholderColor }} w='30%'>{item.title}</Typography>
      <Block w={{ xs: '70%', xxs: '100%' }} pl={{ xs: 0.5, xxs: 0 }} pt={{ xs: 0, xxs: 0.25 }}>
        {
          item.link ?
          <Link href={item.value} externalUrl>
            <Typography variant='body-small' fWeight={500} color={FocusColor}>{item.value}</Typography>
          </Link>
          :
          <Typography variant='body-small' fWeight={500} color='#ffffff'>{item.value}</Typography>
        }
      </Block>
    </Box>;

  return (
    <>
      { 
        items.length > 0 &&
        <Box fDirection='column' bgColor={MenuBgColor} padding='0 2' bRadius={0.5} style={{ border: `0.063rem solid ${BorderColor}`}}>
          {
            items.map((item: Item, index: number) => renderItem(item, index))
          }
        </Box>
      }
    </>
  );
};

export default InfoCard;
