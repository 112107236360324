/* eslint-disable @typescript-eslint/no-explicit-any */
import { setIn } from 'final-form';
import { useMemo } from 'react';
import { AnyObjectSchema, ValidationError } from 'yup';
import { AnyObject } from '../../models/Generic';

const validateFormValues =
  (schema: AnyObjectSchema) => async (values: AnyObject) => {
    try {
      await schema.validate(values, { abortEarly: false });
      return {};
    } catch (err) {
      const errors = (err as ValidationError).inner.reduce(
        (formError: AnyObject, innerError) => {
          return setIn(formError, innerError.path || '', innerError.message);
        },
        {}
      );
      return errors;
    }
  };

export function validateSchema(schema: AnyObjectSchema) {
  return validateFormValues(schema);
}

export function useValidationSchema(schema: AnyObjectSchema) {
  return useMemo(() => validateFormValues(schema), [schema]);
}

export const requiredField = (value: any) => value || value >= 0 ? undefined : true;

export const requiredArray = (value: any) => value && value.length > 0 ? undefined : true;
