import { Pagination as BasePagination, PaginationProps } from './pagination';
import { Box } from '../../styles/BasicStyles';
import { PaginationCountColor } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Typography from '../typography';

export interface BasePaginationProps
  extends Omit<PaginationProps, 'count' | 'translate'> {
  page: number;
  total: number;
  limit: number;
  setPage: (page: number) => void | undefined;
  maxW?: number;
}

const CalculateCurrentItems = (
  page: number,
  pageSize: number,
  total: number
) => {
  if (!total) return { start: 0, end: 0 };
  if (total <= pageSize) return { start: 1, end: total };
  if (total <= pageSize * page)
    return { start: Math.ceil(pageSize * (page - 1)) + 1, end: total };
  return {
    start: Math.ceil(pageSize * (page - 1)) + 1,
    end: Math.ceil(pageSize * page),
  };
};

export const PaginationComponent: React.FC<BasePaginationProps> = ({
  page,
  total,
  limit,
  setPage,
  boundaryCount = 1,
  siblingCount = 2,
  maxW
}) => {
  const { t } = useTranslation();
  const pages = Math.ceil(total / limit) || 1;
  const { start, end } = CalculateCurrentItems(page, limit, total);

  return (
    <Box fAlign='center' fWrap='wrap' maxW={maxW || '100%'} margin='auto'>
      <Box fJustify={{ xs: 'flex-start', xxs: 'flex-end' }} w={{ xs: '20%', xxs: '100%' }} pb={{ xs: 0, xxs: 0.5 }}>
        <Typography variant='pagination' color={PaginationCountColor}>
          {start} {t('TO')} {end}
        </Typography>
      </Box>
      <Box fJustify='flex-end' w={{ xs: '80%', xxs: '100%' }}>
        <BasePagination
          defaultPage={page}
          onChangeHandle={setPage}
          count={pages}
          boundaryCount={boundaryCount}
          siblingCount={siblingCount}
        />
      </Box>
    </Box>
  );
};

export default PaginationComponent;
