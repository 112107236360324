import { useContext } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../Context';
import { Block } from '../../styles/BasicStyles';
import { Grid, Tabs } from '@mantine/core';
import { TabsWrapper } from '../tabs/styles';
import TextAreaInput from './TextAreaInput';
import TextInput from './TextInput';
import RichTextInput from './RichTextInput';

interface Validations {
  maxChar?: number;
}

interface TranslatableField {
  label: string;
  name: string;
  type: 'text' | 'textarea' | 'richtext';
  validations?: Validations;
}

const TranslatableFields: React.FC<{ fields: TranslatableField[]; }> = ({ fields }) => {
  const { t } = useTranslation();
  const languages = useContext(LanguageContext);

  return (
    <TabsWrapper>
      <Tabs radius="md" defaultValue="pt">
        <Tabs.List>
          {languages.map((lang) => (
            <Tabs.Tab
              key={lang.code}
              icon={<img style={{ width: '18px' }} src={lang.icon} />}
              value={lang.code}
            >
              {t(lang.tag)}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {languages.map((lang) => (
          <Tabs.Panel key={lang.code} value={lang.code}>
            <Block margin="15px 0">
              <Grid gutter={24}>
                {fields.map((field) => (
                  <Grid.Col key={field.name} xs={12}>
                    <Field name={`${field.name}.${lang.code}`}>
                      {(props) => {
                        if (field.type === 'text') {
                          return (
                            <TextInput
                              {...props}
                              label={t(field.label)}
                              placeholder={t(`INPUT_${field.label}_PLACEHOLDER`)}
                              maxChar={field?.validations?.maxChar}
                            />
                          );
                        }
                        if (field.type === 'textarea') {
                          return (
                            <TextAreaInput
                              {...props}
                              label={t(field.label)}
                              placeholder={t(`INPUT_${field.label}_PLACEHOLDER`)}
                              maxChar={field?.validations?.maxChar}
                              autosize
                            />
                          );
                        }
                        if (field.type === 'richtext') {
                          return (
                            <RichTextInput
                              {...props}
                              label={t(field.label)}
                              placeholder={t(`INPUT_${field.label}_PLACEHOLDER`)}
                            />
                          );
                        }
                      }}
                    </Field>
                  </Grid.Col>
                ))}
              </Grid>
            </Block>
          </Tabs.Panel>
        ))}
      </Tabs>
    </TabsWrapper>
  );
};

export default TranslatableFields;
