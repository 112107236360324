import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useState, useEffect, useContext } from 'react';
import { Fight } from '../../models/Fight';
import { Block } from '../../styles/BasicStyles';
import { capitalizeString } from '../../utils/strings';
import { LabelColor, PlaceholderColor } from '../../styles/Colors';
import { GetFightDate } from '../../utils/fights';
import { Event } from '../../models/Event';
import { GetFightInitialValues } from '../../utils/modalValues';
import { useNavigate } from 'react-router-dom';
import { canEditEvent } from '../../utils/events';
import { consistencyTreshold, NEEDS_REVIEW, Review, ReviewStatusList } from '../../models/Review';
import { PROMOTER } from '../../models/User';
import { UserContext } from '../../Context';
import headerActions, { HeaderBaseAction } from '../../components/header/HeaderActions';
import useFetch from 'use-http';
import LoadingOverlay from '../../components/loadingOverlay';
import PageHeader from '../../components/page/PageHeader';
import Typography from '../../components/typography';
import MainCard from './MainCard';
import Cards from './Cards';
import OfficiatingCard from './OfficiatingCard';
import ManageFightModal from '../events/detail/fightsModal/single';
import ScoreCards from './ScoreCards';
import dayjs from 'dayjs';
import LabelDropdown from '../../components/labelDropdown';
import Consistency from './Consistency';

const FightDetailPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<Fight>();
  const [event, setEvent] = useState<Event>();
  const [review, setReview] = useState<Review>();
  const [editModal, setEditModal] = useState<{ open: boolean, initialValues?: Fight }>({ open: false });
  const { t } = useTranslation();
  const { id } = useParams();
  const { get } = useFetch('/fights');
  const reviewHook = useFetch('/reviews');
  const eventHooks = useFetch('/events');
  const navigate = useNavigate();
  const userCanEditEvent = canEditEvent(event);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const init = async () => {
      await getInfo();
    };
    init();
  }, [id]);

  const getInfo = async () => {
    if(id) {
      const { data, success } = await get(`/${id}`);

      if(success) {
        const eventResult = await eventHooks.get(`/${data.event?._id}`);

        setInitialValues(data);
        setEditModal({ open: false, initialValues: GetFightInitialValues(data) });
        setEvent(eventResult?.data);
      }
      // If we don't have permission to see the event, return to homepage
      else {
        return navigate('/');
      }

      if(user?.type !== PROMOTER) {
        const reviewResult = await reviewHook.get(`/fight/${id}`);
        setReview(reviewResult?.data);
      }
    }

    setLoading(false);
  };

  const reloadInfo = async () => {
    setLoading(true);
    await getInfo();
  };

  const handleCloseModal = async (changed: boolean) => {
    if(changed) {
      setLoading(true);
      await getInfo();
    }
    else {
      setEditModal({ ...editModal, open: false });
    }
  };

  const onConfirmStatusToogle = async (id: string, status: string) => {
    const { success } = await reviewHook.put(`/${id}/change-status`, { status });
    if(success) {
      setLoading(true);
      await getInfo();
    }
  };

  let actions: HeaderBaseAction[] = [];
  if(userCanEditEvent) actions = [
    {
      type: 'button',
      variant: 'primary',
      text: t('EDIT_FIGHT'),
      onClick: () => setEditModal({ ...editModal, open: true })
    }
  ];
  
  return (
    <>
      <LoadingOverlay visible={loading} />
      <PageHeader
        title={
          <Block display='flex' fAlign='center' fWrap='wrap'>
            <Typography variant='page-title' style={{ color: LabelColor }} mr={1.25} pb={{ xs: 0, xxs: 0.5 }} w={{ xs: 'auto', xxs: '100%' }}>{`${capitalizeString(t('FIGHT'))} ${initialValues?.fight_number}`}</Typography>
            <Typography variant='body-small' lHeight={1.063} mr={1.25} pb={{ xs: 0, xxs: 0.5 }} style={{ color: PlaceholderColor }} w={{ xs: 'auto', xxs: '100%' }}>{GetFightDate(initialValues?.start_datetime)}</Typography>
            {
              user?.type !== PROMOTER && initialValues?.consistency?.consistency < consistencyTreshold && initialValues?.consistency?.status === NEEDS_REVIEW &&
              <LabelDropdown
                status={initialValues?.consistency?.status}
                list={ReviewStatusList}
                id={initialValues?.consistency?._id}
                onConfirmStatusToogle={onConfirmStatusToogle}
              />
            }
          </Block>
        }
        backLink={-1}
        actions={headerActions({
          hasSave: false,
          extra: actions
        })}
      />
      <Block display='flex' style={{ flexFlow: 'row wrap' }} margin='0 -0.563rem' mb={2.5}>
        <MainCard fight={initialValues} />
        <OfficiatingCard fight={initialValues} />
      </Block>
      {
        (!!initialValues?.result?.winner || initialValues?.record?.deductions?.length > 0 || initialValues?.record?.notes) && 
        <Block w='100%' mb={2.5}>
          <Cards fight={initialValues} reloadInfo={reloadInfo} />
        </Block>
      }
      {
        !!initialValues?.record &&
        <Block w='100%' mb={2.5}>
          <ScoreCards fight={initialValues} reloadInfo={reloadInfo} />
        </Block>
      }
      {
        user?.type !== PROMOTER && !!initialValues?.consistency?.consistency &&
        <Consistency review={review} onConfirmStatusToogle={onConfirmStatusToogle} isFightDetailPage />
      }
      <ManageFightModal
        opened={editModal.open}
        initialValues={editModal.initialValues}
        onClose={handleCloseModal}
        eventID={event?._id}
        eventOfficials={event?.officials}
        eventFederation={event?.federation?._id}
        fightNumber={initialValues?.fight_number}
        eventStart={dayjs(event?.start_datetime).startOf('day')}
        eventEnd={dayjs(event?.end_datetime).endOf('day')}
      />
    </>
  );
};

export default FightDetailPage;
