import {
  User,
  SUPER_ADMIN,
  ADMIN,
  REGULATOR,
  UserStatusList,
  INVITED,
  PROMOTER
} from '../../../models/User';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { LabelColor } from '../../../styles/Colors';
import { showConfirm, showDelete, showSuccess } from '../../../hooks/show-notification/show-notification';
import { Federation } from '../../../models/Federation';
import { AnyObject } from '../../../models/Generic';
import { UserContext } from '../../../Context';
import { useNavigate } from 'react-router-dom';
import { GetPromoterInitialValues } from '../../../utils/modalValues';
import useFetch from 'use-http';
import DefaultListPage from '../../../components/page/DefaultListPage';
import Icon from '../../../components/icon';
import Typography from '../../../components/typography';
import ManageCoachModal from './form';
import DropdownMenu, { ActionProps } from '../../../components/dropdownMenu';
import BaseTable from '../../../components/table';
import PromotersFilters from './PromotersFilters';
import UserCard from '../../../components/userCard';
import LabelDropdown from '../../../components/labelDropdown';

const PromotersListPage = DefaultListPage<User>();
const PromotersTable = BaseTable<User>();

const PromotersPage = () => {
  const [federations, setFederations] = useState<Federation[]>([]);
  const [modal, setModal] = useState<{ open: boolean; initialValues?: User }>({ open: false });
  const { t } = useTranslation();
  const { get } = useFetch(`/members/${PROMOTER}`);
  const membersHook = useFetch('/members');
  const federationsHook = useFetch('/federations/dropdown');
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      const federationsResult = await federationsHook.get();
      setFederations(federationsResult?.data || []);
    };

    if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR) init();
  }, []);

  return (
    <PromotersListPage
      translate={t}
      request={{
        get,
        searchFields: ['name', 'email', 'associate']
      }}
      storage="PROMOTERS_FILTERS"
      header={{
        title: t('PROMOTERS'),
        actions: [
          {
            text: t('ADD_NEW'),
            icon: 'outlined_plus',
            onClick: () => setModal({ open: true })
          }
        ]
      }}
      filters={({ filters, handleChange }) => (
        <PromotersFilters
          filters={filters}
          handleChange={handleChange}
          federations={federations}
        />
      )}
      countLabel={`${t('PROMOTERS').toLowerCase()}`}
    >
      {({ rows, loading, pagination, handleNewSort, refreshPage }) => {
        const handleCloseModal = (changed: boolean) => {
          if (changed) refreshPage();
          setModal({ open: false });
        };

        const handleDelete = async (id: string) => {
          showDelete({
            title: t('DELETE_MEMBER'),
            message: t('DELETE_MEMBER_MESSAGE'),
            onConfirm: async () => {
              await membersHook.del(`/${id}`);
              refreshPage();
            }
          });
        };

        const resendInvite = async (id: string) => {
          showConfirm({
            title: t('RESEND_INVITE'),
            message: t('RESEND_INVITE_MESSAGE'),
            onConfirm: async () => {
              const { success } = await membersHook.get(`/resend-invite/${id}`);
      
              if(success) {
                showSuccess({
                  title: t('USER_INVITED_SUCCESS'),
                  message: t('USER_INVITED_MESSAGE')
                });
      
                refreshPage();
              }
            }
          });
        };

        const onConfirmStatusToogle = async (id: string, status: string) => {
          const { success } = await membersHook.put(`/${id}/change-status`, { status });
          if(success) refreshPage();
        };

        const columns = [
          {
            title: t('ASSOCIATE_NUMBER'),
            dataIndex: 'associate',
            sortable: true
          },
          {
            title: t('NAME'),
            dataIndex: 'name',
            sortable: true,
            render: (_: string, row: User) => <UserCard user={row} noStatus />
          },
          {
            title: t('COUNTRY'),
            dataIndex: 'country',
            sortable: true,
            render: (country: AnyObject) => <Typography variant="table-header">{country?.name}</Typography>
          },
          {
            title: t('STATUS'),
            dataIndex: 'status',
            disableRowClick: true,
            render: (value: string, row: User) => <LabelDropdown status={value} list={UserStatusList} id={row._id} onConfirmStatusToogle={onConfirmStatusToogle} />
          },
          {
            title: t('MORE'),
            key: 'actions',
            disableRowClick: true,
            render: (row: User) => {
              const actions: ActionProps[] = [
                {
                  type: 'link',
                  label: t('VIEW_DETAIL'),
                  onClick: () => navigate(`/members/promoter/${row._id}`)
                },
                {
                  type: 'divider'
                },
                {
                  type: 'link',
                  label: t('EDIT'),
                  onClick: () =>
                    setModal({
                      open: true,
                      initialValues: GetPromoterInitialValues(row)
                    })
                },
                {
                  type: 'divider'
                },
                {
                  type: 'link',
                  label: t('DELETE'),
                  onClick: () => handleDelete(row._id)
                }
              ];

              if(row.status === INVITED) {
                actions.splice(1, 0,
                  {
                    type: 'divider'
                  },
                  {
                    type: 'link',
                    label: t('RESEND_INVITE'),
                    onClick: () => resendInvite(row._id)
                  }
                );
              }
              
              return (
                <DropdownMenu
                  render={<Icon icon="outlined_dotsHorizontal" color={LabelColor} />}
                  menuPosition="bottom-end"
                  actions={actions}
                />
              );
            }
          }
        ];

        // Show Federation column for Admin users
        if (
          user?.type === SUPER_ADMIN ||
          user?.type === ADMIN ||
          user?.type === REGULATOR
        ) {
          columns.splice(3, 0, {
            title: t('FEDERATION'),
            dataIndex: 'federation',
            sortable: true,
            render: (_: string, coach: User) => (
              <Typography variant="table-header">
                {coach.federation?.name}
              </Typography>
            )
          });
        }

        return (
          <>
            <PromotersTable
              columns={columns}
              rows={rows}
              loading={loading}
              defaultSort={pagination.sort}
              sortCallback={handleNewSort}
              onRowClick={(row) => navigate(`/members/promoter/${row._id}`)}
            />
            <ManageCoachModal
              opened={modal.open}
              initialValues={modal.initialValues}
              onClose={handleCloseModal}
            />
          </>
        );
      }}
    </PromotersListPage>
  );
};

export default PromotersPage;
