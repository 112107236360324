import * as yup from 'yup';
import { PROMOTER } from '../../../../../models/User';

const validations  = [
  yup.object({
    blue_fighter: yup.string().required(),
    red_fighter: yup.string().required(),
    weight_class: yup.string().required(),
    rounds: yup.number().required(),
    duration: yup.number().required(),
    class: yup.string().required(),
    referee: yup.string().required(),
    referee2: yup.string().required(),
    white_judge: yup.string().required(),
    green_judge: yup.string().required(),
    yellow_judge: yup.string().required(),
    record_keeper: yup.string().required()
  }),
  yup.object({
    weight_class: yup.string().required(),
    rounds: yup.number().required(),
    duration: yup.number().required(),
    class: yup.string().required(),
    referee: yup.string().required(),
    referee2: yup.string().required(),
    white_judge: yup.string().required(),
    green_judge: yup.string().required(),
    yellow_judge: yup.string().required(),
    record_keeper: yup.string().required()
  }),
  yup.object({
    blue_fighter: yup.string().required(),
    red_fighter: yup.string().required(),
    weight_class: yup.string().required(),
    rounds: yup.number().required(),
    duration: yup.number().required(),
    class: yup.string().required()
  }),
  yup.object({
    weight_class: yup.string().required(),
    rounds: yup.number().required(),
    duration: yup.number().required(),
    class: yup.string().required()
  })
];

export const GetValidationSchema = (isNew: boolean, type?: string) => {
  if(isNew) {
    if(type === PROMOTER) return validations[2];
    return validations[0];
  }

  if(type === PROMOTER) return validations[3];
  return validations[1];
};
