/* eslint-disable @typescript-eslint/no-explicit-any */
import { generateProps } from 'styled-gen';
import styled from "styled-components";

export const transitionsDelay = "0.3s";

export const CenteredPage = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray[0]};
`;

export const BasicPage = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
`;

export const BasicForm = styled.form`
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const Relative = styled.div`
  position: relative;
`;

export const Centered = styled.div<{ p?: number, dir?: "column" | "row" }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ p }) => p || 0}px;
  flex-direction: ${({ dir }) => dir || "row"};
`;

export const ErrorList = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 20px;
  padding-top: 6px;
  list-style-type: circle;
`;

export const Box = styled.div<any>`
  display: flex;
  flex-direction: ${({ fDirection }) => fDirection ? fDirection : 'row'};
  justify-content: ${({ fJustify }) => fJustify ? fJustify : 'flex-start'};
  align-items: ${({ fAlign }) => fAlign ? fAlign : 'flex-start'};
  width: 100%;
  
  ${generateProps};
`;

export const Block = styled.div<any>`
  ${generateProps};
`;

export const Image = styled.img<any>`
  ${generateProps};
`;