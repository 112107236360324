/* eslint-disable @typescript-eslint/no-explicit-any */
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import {
  PROMOTER,
  OFFICIAL,
  User,
  SUPER_ADMIN,
  ADMIN,
  REGULATOR
} from '../../../models/User';
import { BasicForm } from '../../../styles/BasicStyles';
import { useState, FC, useEffect, useContext } from 'react';
import { Event } from '../../../models/Event';
import { useNavigate } from 'react-router-dom';
import {
  showSuccess,
  showWarning,
  showConfirm
} from '../../../hooks/show-notification/show-notification';
import { AlertErrorBg, AlertSuccessBg } from '../../../styles/Colors';
import { Federation } from '../../../models/Federation';
import { UserContext } from '../../../Context';
import { GetValidationSchema } from './formHelpers';
import { ModalOverflowContent } from '../../../components/modal/index';
import { AnyObject } from '../../../models/Generic';
import { SortDisabledUsers } from '../../../utils/user';
import Modal, { ModalActions, ModalContent } from '../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../components/button';
import toFormData from '../../../utils/formdata/ToFormData';
import FormFields from './FormFields';
import dayjs from 'dayjs';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: Event;
  isDraftEvent?: boolean;
  showValidateButton?: boolean;
  showRejectButton?: boolean;
  openRejectModal?: (event?: Event) => void;
  confirmValidate?: (id?: string) => void;
  validateLoading?: boolean;
}

const { Form } = withTypes<Event>();

const ManageEventModal: FC<Params> = ({
  opened,
  onClose,
  initialValues,
  isDraftEvent = false,
  showValidateButton = false,
  showRejectButton = false,
  openRejectModal,
  confirmValidate,
  validateLoading = false
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { post, put } = useFetch(isDraftEvent || user?.type === PROMOTER ? '/event-drafts' : '/events');
  const officialsHook = useFetch(`/members/${OFFICIAL}`);
  const promotersHook = useFetch(`/admins/type/${PROMOTER}`);
  const federationsHook = useFetch(`/federations/dropdown`);
  const [officials, setOfficials] = useState<User[]>([]);
  const [promoters, setPromoters] = useState<User[]>([]);
  const [federations, setFederations] = useState<Federation[]>([]);
  const [currentOfficials, setCurrentOfficials] = useState<User[]>([]);
  const [formPristine, setFormPristine] = useState<boolean>(true);
  const adminsList = [SUPER_ADMIN, ADMIN, REGULATOR];

  useEffect(() => {
    const init = async () => {
      if(user?.type && adminsList.includes(user.type)) {
        const federationsResult = await federationsHook.get();
        setFederations(federationsResult?.data || []);
      }
      else {
        getOfficials(user?.federation?._id);
      }

      if(opened && initialValues) {
        getOfficials(initialValues?.federation);
      }

      setFormPristine(true);
    };
    init();
  }, [initialValues, opened]);

  const getOfficials = async (federation: AnyObject | undefined) => {
    if(user?.type !== PROMOTER && federation) {
      const { data } = await officialsHook.get(`/${federation}/dropdown`);
      let aux: User[] = [];

      if (data?.length > 0) {
        data.map((official: User) => {
          const existsOfficial = initialValues?.officials?.find(
            (elem: User) => elem._id === official._id
          );

          aux.push({
            _id: official._id,
            name: official.name,
            disabled:
              !!existsOfficial ||
              initialValues?.director?.toString() === official._id
                ? true
                : false
          });
        });
      }

      // Disabled items go to the end of the list per client request
      aux = SortDisabledUsers(aux);

      setOfficials(aux || []);
      setCurrentOfficials(initialValues?.officials || []);

      const promotersResult = await promotersHook.get(`/${federation}`);
      setPromoters(promotersResult?.data || []);
    }
  };

  const onSubmit = async (values: Event) => {
    // check dates
    const start = dayjs(values.start_datetime);
    const end = dayjs(values.end_datetime);

    if (start.isAfter(end) || end.isBefore(start) || start.isSame(end)) {
      return showWarning({
        title: t('WARNING'),
        message: t('WRONG_DATES_SELECTED')
      });
    }

    // Promoters can only create Events after: today + 30 days
    if (user?.type === PROMOTER) {
      if (dayjs().add(30, 'days').isAfter(values.start_datetime)) {
        return showWarning({
          title: t('WARNING'),
          message: t('EVENT_START_DATE_WARNING')
        });
      }
    }

    if(user?.type && !adminsList.includes(user.type)) values.federation = user?.federation?._id;
    if(user?.type === PROMOTER) values.promoter = user;

    let error = false;
    const payload = toFormData({ ...values, officials: currentOfficials });

    if (values._id) {
      const { success } = await put(`/${values._id}`, payload);
      if (success) {
        setCurrentOfficials([]);
        onClose(true);
      }
      else error = true;
    } 
    else {
      const { data, success } = await post(payload);
      if (success) {
        setCurrentOfficials([]);

        // If the user is a Promoter, we're creating a Draft Event so we don't navigate to the detail page, we remain in the list page and show a success alert
        if (user?.type === PROMOTER) {
          showSuccess({
            title: t('SUCCESS'),
            message: t('DRAFT_EVENT_CREATED'),
            autoClose: 10000
          });

          onClose(true);
        } else {
          // Here we pass false because we want to do the "navigate" to the detail after
          onClose(false);

          navigate(`/events/${data._id}?success=true`);
        }
      }
      else error = true;
    }

    return !error;
  };

  // When selecting a Director, remove it from the available Officials list, they can't have another role in the event. De-selecting a Diretor, add's it back to the list
  const chooseDirector = (value: string | null, oldValue: string | null) => {
    let aux: User[] = [...officials];

    if (value) {
      const index = officials.findIndex(
        (official: User) => official._id === value
      );
      if (index >= 0) aux[index].disabled = true;
    }

    if (oldValue) {
      const index = officials.findIndex((elem: User) => elem._id === oldValue);
      if (index >= 0) aux[index].disabled = false;
    }

    // Disabled items go to the end of the list per client request
    aux = SortDisabledUsers(aux);
    
    setOfficials([...aux]);
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(GetValidationSchema(isDraftEvent, user?.type))}
      initialValues={initialValues || {}}
      mutators={{
        resetOfficials: (_, state: any, utils) => {
          getOfficials(state.formState.values?.federation);
          utils.changeValue(state, 'promoter', () => null);
          utils.changeValue(state, 'director', () => null);
        }
      }}
      render={({ handleSubmit, pristine, submitting, form: { reset, mutators: { resetOfficials } } }) => {
        const handleClose = () => {
          if(!pristine || !formPristine) {
            showConfirm({
              title: t('EXIT_BEFORE_SAVE'),
              message: t('EXIT_BEFORE_SAVE_MESSAGE'),
              onConfirm: () => {
                setCurrentOfficials([]);
                onClose(false);
                reset();
              }
            });
          }
          else {
            setCurrentOfficials([]);
            onClose(false);
            reset();
          }
        };

        return (
          <Modal
            size={36.25}
            opened={opened}
            onClose={handleClose}
            title={showValidateButton ? t('VALIDATE_EVENT') : initialValues?._id ? t('EDIT_EVENT') : t('CREATE_EVENT')}
          >
            <BasicForm onSubmit={async event => {
              const result = await handleSubmit(event);
              if(result) reset();
            }}>
              <ModalOverflowContent>
                <ModalContent>
                  <FormFields
                    chooseDirector={chooseDirector}
                    officials={officials}
                    promoters={promoters}
                    federations={federations}
                    isDraftEvent={isDraftEvent}
                    resetOfficials={resetOfficials}
                    disableFields={showValidateButton}
                    setOfficials={setOfficials}
                    currentOfficials={currentOfficials}
                    setCurrentOfficials={setCurrentOfficials}
                    setFormPristine={setFormPristine}
                    initialValues={initialValues}
                  />
                </ModalContent>
              </ModalOverflowContent>
              <ModalActions>
                <Button
                  text={t('CANCEL')}
                  variant="secondary"
                  onClick={handleClose}
                />
                {
                  (showValidateButton || showRejectButton) ?
                  <>
                    {
                      showRejectButton &&
                      <Button
                        text={t('REJECT')}
                        type="button"
                        variant={AlertErrorBg}
                        disabled={validateLoading}
                        onClick={() => !!openRejectModal && openRejectModal(initialValues)}
                      />
                    }
                    {
                      showValidateButton &&
                      <Button
                        text={t('VALIDATE')}
                        type="button"
                        variant={AlertSuccessBg}
                        loading={validateLoading}
                        onClick={() => !!confirmValidate && confirmValidate(initialValues?._id)}
                      />
                    }
                  </>
                  :
                  <Button
                    text={initialValues?._id ? t('SAVE') : t('CREATE')}
                    type="submit"
                    loading={submitting}
                    disabled={pristine && formPristine}
                  />
                }
              </ModalActions>
            </BasicForm>
          </Modal>
        );
      }}
    />
  );
};

export default ManageEventModal;
