import { Input } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { FieldRenderProps } from 'react-final-form';
import { AnyObject, GenericInput, InputSize } from './Generic';
import { FC, ReactNode } from 'react';
import { CreatePreviewFile } from '../../utils/files/Conversions';
import { FileWrapper, DropzoneWrapper, DropzoneButton } from './Styles';
import { DangerColor, DisabledColor, FocusColor, LabelColor, MenuBgColor, PlaceholderColor } from '../../styles/Colors';
import { Block, Box } from '../../styles/BasicStyles';
import { showError } from '../../hooks/show-notification/show-notification';
import { useTranslation } from 'react-i18next';
import { openFile } from '../../utils/files';
import Icon from '../icon';
import Typography from '../typography';
import Link from '../link';

export interface FileInputProps extends Omit<FieldRenderProps<AnyObject>, 'input'> {
  input: GenericInput<AnyObject>;
  afterChange?: (value: Date) => void;
  label?: ReactNode;
  description?: ReactNode;
  maxSize?: number;
  errorTitle?: string;
  errorMessage?: string;
  placeholder?: string;
  size?: InputSize;
  multiple?: boolean;
  borderColor?: string;
  handleAcceptedFiles?: (inputName: string | undefined, files: AnyObject[]) => void
}

export const FileInput: FC<FileInputProps> = ({
  input,
  meta,
  label,
  description,
  maxSize = 16000000,
  errorTitle,
  errorMessage,
  placeholder,
  size = 'md',
  multiple = false,
  borderColor,
  handleAcceptedFiles
}) => {
  const { t } = useTranslation();

  const hasError = meta?.invalid && meta?.submitFailed;

  const handleAccept = (files: File[]) => {
    if(multiple) {
      if(files.length > 0) {
        const acceptedArray: AnyObject[] = [];

        files.forEach((elem) => {
          const uploaded = CreatePreviewFile(elem);
          acceptedArray.push(uploaded);
        });

        if(!!handleAcceptedFiles) handleAcceptedFiles(input.name, acceptedArray);
        input.onChange(acceptedArray);
      }
    }
    else if(files[0]) {
      if(!!handleAcceptedFiles) handleAcceptedFiles(input.name, [CreatePreviewFile(files[0])]);
      input.onChange(CreatePreviewFile(files[0]));
    }
  };

  const handleReject = () => {
    showError({
      title: errorTitle || t('INVALID_FILE'),
      message: errorMessage || t('INVALID_FILE_MESSAGE')
    });
  };

  const removeFile = (index: number) => {
    if(Array.isArray(input.value) && input.value.length > 0) {
      const aux = [...input.value];
      aux.splice(index, 1);
      input.onChange(aux);
    }
    else {
      input.onChange(null);
    }
  }

  const renderFile = (file: AnyObject, index: number) => {
    return (
      <Box 
        key={index} 
        bgColor={MenuBgColor} 
        bRadius={0.25} 
        fAlign='center' 
        fJustify='space-between' 
        padding='0.625 1'
        mb={0.75}
      >
        <Box w='85%'>
          <Link onClick={() => openFile(file)}>
            <Box fAlign='center'>
              <Icon icon='outlined_documentText' color={FocusColor} />
              <Typography variant='dropdown-menu-small' style={{ color: LabelColor }} ml={1}>
                {file?.blob?.name || file?.file?.filename}
              </Typography>
            </Box>
          </Link>
        </Box>
        <Box fJustify='flex-end' w='15%'>
          <Icon 
            icon='bold_xCircle' 
            color={DisabledColor} 
            hoverColor={FocusColor} 
            onClick={() => removeFile(index)} 
          />
        </Box>
      </Box>
    );
  };

  const renderDropzone = (
    <DropzoneWrapper borderColor={borderColor} error={hasError}>
      <Box fDirection='column' fAlign='center' fJustify='center' position='relative'>
        <Icon icon="bold_cloudUpload" size={2} color={hasError ? DangerColor : FocusColor} />
        <Typography variant='dropdown-menu' lHeight={1.188} style={{ color: hasError ? DangerColor : LabelColor }} mt={1.375}>{t('DROPZONE_TITLE')}</Typography>
        <Typography variant='dropdown-menu-small' style={{ color: hasError ? DangerColor : LabelColor }} mt={0.5}>{placeholder || t('DROPZONE_PLACEHOLDER')}</Typography>
        <DropzoneButton className='dropzoneButton' mt={0.5} bRadius={0.25} padding='0.375 0.75'>
          <Typography variant='dropdown-menu-small' fWeight={500} color={FocusColor}>{t('UPLOAD_FILE')}</Typography>
        </DropzoneButton>
      </Box>
    </DropzoneWrapper>
  );

  return (
    <>
      <FileWrapper size={size}>
        <Input.Wrapper label={label} description={description}>
          <Dropzone
            onDrop={handleAccept}
            onReject={handleReject}
            maxSize={maxSize}
            accept={[MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.pdf, MIME_TYPES.doc, MIME_TYPES.docx]}
            multiple={multiple}
            styles={{
              root: {
                border: 'none',
                padding: 0
              }
            }}
          >
            {renderDropzone}
          </Dropzone>
        </Input.Wrapper>
      </FileWrapper>
      {
        !handleAcceptedFiles && !!input.value &&
        <Block w='100%' mt={1.125}>
          <Typography as='div' variant='dropdown-menu-small' style={{ color: PlaceholderColor }} mt={1.125} mb={0.313}>
            {`${Array.isArray(input.value) && input.value.length > 0 ? input.value.length : 1} ${t('attachments')}`}
          </Typography>
          {
            (Array.isArray(input.value) && input.value.length > 0) ?
            input.value.map((elem: AnyObject, index: number) => renderFile(elem, index))
            :
            !!input.value ?
            renderFile(input.value, 0)
            :
            null
          }
        </Block>
      }
    </>
  );
};

export default FileInput;
