import * as yup from 'yup';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../../hooks/use-validation/use-validation-schema';
import { BasicForm, Block, Box } from '../../../../../styles/BasicStyles';
import { useState, FC, useEffect } from 'react';
import { User, ATHLETE } from '../../../../../models/User';
import { FocusColor, LabelColor } from '../../../../../styles/Colors';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../../components/button';
import TextInput from '../../../../../components/inputs/TextInput';
import UserCard from '../../../../../components/userCard';
import Typography from '../../../../../components/typography';

interface FormParams {
  associate: string;
}

interface Params {
  opened: boolean;
  onClose: () => void;
  onSave: (athlete?: User) => void;
  athletes: User[];
}

const { Form } = withTypes<FormParams>();

const SearchAthleteModal: FC<Params> = ({
  opened,
  onClose,
  onSave,
  athletes
}) => {
  const { t } = useTranslation();
  const [searchResult, setSearchResult] = useState<{ athlete: User | undefined, error: number }>({ athlete: undefined, error: 0 });
  const { get } = useFetch(`/members/search/${ATHLETE}`);

  useEffect(() => {
    setSearchResult({ athlete: undefined, error: 0 });
  }, [opened]);

  const onSubmit = async (values: FormParams) => {
    const { data, success } = await get(`/${values.associate}`);

    if(!success || !data) return setSearchResult({ athlete: undefined, error: 1 });

    if(success) {
      const exists = !!data && !!athletes.find(elem => elem._id === data._id) ? true : false;

      if(exists) setSearchResult({ athlete: undefined, error: 2 });
      else setSearchResult({ athlete: data, error: 0 });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          associate: yup.string().required()
        })
      )}
    >
      {({ handleSubmit, submitting, pristine, form: { reset } }) => {
        return (
          <Modal
            size={36.25}
            opened={opened}
            onClose={() => { reset(); onClose(); }}
            title={t('SEARCH_ATHLETE')}
          >
            <BasicForm onSubmit={handleSubmit}>
              <ModalOverflowContent>
                <ModalContent>
                  <Box>
                    <Block w='70%'>
                      <Field name='associate'>
                        {(props) => (
                          <TextInput
                            {...props}
                            label={`${t('ASSOCIATE_NUMBER')} *`}
                            placeholder={t('ENTER_ASSOCIATE_NUMBER')}
                          />
                        )}
                      </Field>
                    </Block>
                    <Block w='30%' display='flex' fJustify='flex-end' mt={1.84}>
                      <Button
                        text={t('SEARCH')}
                        variant={FocusColor}
                        type='submit'
                        loading={submitting}
                        disabled={pristine}
                      />
                    </Block>
                  </Box>
                  <Block mt={2}>
                    {
                      searchResult.error === 1 ?
                      <Typography variant='body-small' style={{ color: LabelColor }}>{t('NO_ATHLETE_FOUND')}</Typography>
                      :
                      searchResult.error === 2 ?
                      <Typography variant='body-small' style={{ color: LabelColor }}>{t('ATHLETE_EXISTS')}</Typography>
                      :
                      !!searchResult.athlete ?
                      <UserCard user={searchResult.athlete} showEmail />
                      :
                      null
                    }
                  </Block>
                </ModalContent>
              </ModalOverflowContent>
              <ModalActions>
                <Button
                  text={t('CANCEL')}
                  variant="secondary"
                  onClick={() => { reset(); onClose(); }}
                />
                <Button
                  text={t('SAVE')}
                  disabled={searchResult.error !== 0 || !searchResult.athlete}
                  onClick={() => { reset(); onSave(searchResult.athlete) }}
                />
              </ModalActions>
            </BasicForm>
          </Modal>
        );
      }}
    </Form>
  );
};

export default SearchAthleteModal;
