/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { Box } from '../../../../styles/BasicStyles';
import { FC, useState } from 'react';
import { Rounds } from '../../../../utils/rounds';
import { FocusColor, G800 } from '../../../../styles/Colors';
import { AnyObject } from '../../../../models/Generic';
import { Deduction, Fight } from '../../../../models/Fight';
import { TimeToMilliseconds } from '../../../../utils/dates';
import Button from '../../../../components/button';
import SelectInput from '../../../../components/inputs/SelectInput';
import TimeInput from '../../../../components/inputs/TimeInput';
import TextInput from '../../../../components/inputs/TextInput';

interface Params {
  fight?: Fight;
  fightersList: AnyObject[];
  reasonsList: AnyObject[];
  deductionsList: Deduction[];
  setDeductionsList: (values: Deduction[]) => void;
  setFormPristine: (value: boolean) => void;
}

const NewDeductionModal: FC<Params> = ({ fight, fightersList, reasonsList, deductionsList, setDeductionsList, setFormPristine }) => {
  const { t, i18n:{ language } } = useTranslation();
  const [errors, setErrors] = useState<{ round: boolean, date: boolean, fighter: boolean, points: boolean, reason: boolean }>({ round: false, date: false, fighter: false, points: false, reason: false });
  const [round, setRound] = useState('');
  const [date, setDate] = useState(undefined);
  const [fighter, setFighter] = useState('');
  const [points, setPoints] = useState(undefined);
  const [reason, setReason] = useState('');

  const onSubmit = async () => {
    if(round && date && fighter && points && reason) {
      const reasonName = reasonsList.find(elem => elem._id === reason)?.name;

      const aux: Deduction[] = [...deductionsList];
      aux.push({
        round,
        time: TimeToMilliseconds(date),
        date,
        fighter,
        fighterName: fighter === 'red' ? fight?.red_fighter?.name : fighter === 'blue' ? fight?.blue_fighter?.name : '',
        points,
        reason,
        reasonName: reasonName && reasonName[language]
      });

      setDeductionsList(aux);
      setErrors({ round: false, date: false, fighter: false, points: false, reason: false });
      setRound('');
      setDate(undefined);
      setFighter('');
      setPoints(undefined);
      setReason('');
      setFormPristine(false);
    }
    else {
      const errors = { round: false, date: false, fighter: false, points: false, reason: false };

      if(!round) errors.round = true;
      if(!date) errors.date = true;
      if(!fighter) errors.fighter = true;
      if(!points) errors.points = true;
      if(!reason) errors.reason = true;

      setErrors(errors);
    }
  };

  return (
    <Box fWrap='wrap' bgColor={G800} padding='1 0.75'>
      <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
        <SelectInput 
          label={`${t('ROUND')} *`}
          placeholder={t('CHOOSE_ROUND')}
          data={Rounds}
          meta={{}}
          input={{ value: round, onChange: (value: any) => { setErrors({ ...errors, round: false }); setRound(value) } }}
          showError={errors.round}
        />
      </Box>
      <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
        <SelectInput 
          label={`${t('FIGHTER')} *`}
          placeholder={t('CHOOSE_FIGHTER')}
          data={fightersList}
          meta={{}}
          input={{ value: fighter, onChange: (value: any) => { setErrors({ ...errors, fighter: false }); setFighter(value); } }}
          showError={errors.fighter}
        />
      </Box>
      <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
        <SelectInput 
          label={`${t('REASON')} *`}
          placeholder={t('CHOOSE_REASON')}
          data={reasonsList}
          intl
          meta={{}}
          input={{ value: reason, onChange: (value: any) => { setErrors({ ...errors, reason: false }); setReason(value); } }}
          showError={errors.reason}
        />
      </Box>
      <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
        <TimeInput 
          label={`${t('TIME')} *`} 
          withSeconds
          meta={{}}
          input={{ value: date, onChange: (value: any) => { setErrors({ ...errors, date: false }); setDate(value); } }}
          showError={errors.date}
        />
      </Box>
      <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
        <TextInput
          label={`${t('POINTS_TO_SUBTRACT')} *`}
          placeholder={t('ENTER_POINTS')}
          htmlType='number'
          onlyIntegers
          meta={{}}
          input={{ value: points || '', onChange: (value: any) => { setErrors({ ...errors, points: false }); setPoints(value); } }}
          showError={errors.points}
        />
      </Box>
      <Box fJustify='flex-end' w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mt={{ xs: 1.875, xxs: 0.25 }}>
        <Button
          text={t('ADD')}
          variant={FocusColor}
          onClick={onSubmit}
        />
      </Box>
    </Box>
  );
};

export default NewDeductionModal;
