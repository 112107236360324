import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { BgColor, MenuBgColor } from '../../styles/Colors';
import { Box } from '../../styles/BasicStyles';
import { ContentWrapper } from './AuthStyles';

interface AuthLayoutProps {
  children: React.ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({
  children
}) => (
  <Box h='100vh' bgColor={BgColor} fAlign='center'>
    <Box fDirection='column' fAlign='center' fJustify='center' padding='2.5 1'>
      <Logo style={{ width: '100%', maxWidth: '12.125rem', maxHeight: '2.5rem', marginBottom: '2.375rem' }} />
      <ContentWrapper maxW={26.25} bgColor={MenuBgColor} padding={2.5}>
        {children}
      </ContentWrapper>
    </Box>
  </Box>
);

export default AuthLayout;
