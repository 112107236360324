import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Block, Box } from '../../../styles/BasicStyles';
import { MenuBgColor, BorderColor } from '../../../styles/Colors';
import Typography from '../../../components/typography';

type Item = {
  title: string;
  subtitle: string;
  bgColor: string;
  fontColor: string;
}

interface Params {
  items: Item[];
}

const StatsCard: FC<Params> = ({ items }) => {
  const { t } = useTranslation();

  const renderItem = (item: Item, index: number) => 
    <Block 
      key={index}
      w={{ lg: '100%', sm: '25%', xs: '50%', xxs: '100%' }}
      padding={{ lg: 0, xxs: '0 0.125rem' }}
      display='flex'
    >
      <Box 
        style={{ backgroundColor: item.bgColor }} 
        padding='0.625 0' 
        fDirection='column' 
        fAlign='center' 
        fJustify='center' 
        bRadius={0.5}
        mb={0.25} 
      >
        <Typography fSize={0.938} lHeight={1.125} fWeight={500} color='#ffffff' pb={0.25}>{item.title}</Typography>
        <Typography variant='sidebar-group' lHeight={0.938} style={{ color: item.fontColor }}>{item.subtitle}</Typography>
      </Box>
    </Block>;

  return (
    <>
      { 
        items.length > 0 &&
        <Box fDirection='column' bgColor={MenuBgColor} padding='1.25' bRadius={0.5} style={{ border: `0.063rem solid ${BorderColor}`}}>
          <Typography variant='body-small' color='#ffffff' pb={0.625}>{t('QUICK_STATS').toUpperCase()}</Typography>
          <Block display='flex' w='100%' fDirection={{ lg: 'column', xxs: 'row' }} margin={{ lg: 0, xxs: '0 -0.125rem' }} fWrap='wrap'>
            {
              items.map((item: Item, index: number) => renderItem(item, index))
            }
          </Block>
        </Box>
      }
    </>
  );
};

export default StatsCard;
