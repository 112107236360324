import { useTranslation } from 'react-i18next';
import { FC, useState, useContext } from 'react';
import { Box, Block } from '../../../styles/BasicStyles';
import { AnyObject } from '../../../models/Generic';
import { InfoContext, UserContext } from '../../../Context';
import { User, SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE } from '../../../models/User';
import { Federation } from '../../../models/Federation';
import Icon from '../../../components/icon';
import TextInput from '../../../components/inputs/TextInput';
import FiltersModal from '../../../components/filtersModal';
import SelectInput from '../../../components/inputs/SelectInput';
import DateInput from '../../../components/inputs/DateInput';
import dayjs from 'dayjs';
import { EventTypes } from '../../../utils/events';

interface FiltersProps {
  filters?: AnyObject;
  handleChange: (
    field: string | string[],
    value: string | string[] | null,
    time?: number
  ) => void;
  athletes?: User[];
  location: string;
  federations?: Federation[];
  promoters?: User[];
}

const Filters: FC<FiltersProps> = ({
  filters,
  handleChange,
  athletes,
  location,
  federations,
  promoters
}) => {
  const [filtersModal, setFiltersModal] = useState<AnyObject>({ ...filters });
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);

  // When closing the modal without applying the filters, reload the filters saved in local storage
  const reloadStorageFilters = () => {
    setFiltersModal({ ...filters });
  };

  return (
    <Box fAlign="center" fJustify="flex-end">
      <Block pr={0.875}>
        <TextInput
          input={{
            value: filters?.search,
            onChange: (v: string | null) => handleChange('search', v, 1000)
          }}
          placeholder={t('SEARCH')}
          onSearch={(v: string) => handleChange('search', v)}
          icon={<Icon icon="outlined_search" />}
          clearable
        />
      </Block>
      <FiltersModal
        handleChange={handleChange}
        filters={filtersModal}
        setFilters={setFiltersModal}
        reloadStorageFilters={reloadStorageFilters}
      >
        {(user?.type === SUPER_ADMIN ||
          user?.type === ADMIN ||
          user?.type === REGULATOR) && (
          <Block mb={0.5}>
            <SelectInput
              input={{
                value: filtersModal?.federation || '',
                onChange: (v: string | null) =>
                  setFiltersModal((old) => {
                    return { ...old, federation: v };
                  })
              }}
              label={t('FEDERATION')}
              placeholder={t('CHOOSE_FEDERATION')}
              clearable
              data={federations || []}
            />
          </Block>
        )}
        {(user?.type === SUPER_ADMIN ||
          user?.type === ADMIN ||
          user?.type === REGULATOR ||
          user?.type === RESPONSIBLE) && (
          <Block mb={0.5}>
            <SelectInput
              input={{
                value: filtersModal?.promoter || '',
                onChange: (v: string | null) =>
                  setFiltersModal((old) => {
                    return { ...old, promoter: v };
                  })
              }}
              label={t('PROMOTER')}
              placeholder={t('CHOOSE_PROMOTER')}
              clearable
              data={promoters || []}
            />
          </Block>
        )}
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.country || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, country: v };
                })
            }}
            label={t('COUNTRY')}
            placeholder={t('CHOOSE_COUNTRY')}
            clearable
            data={info?.countries || []}
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.status || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, status: v };
                })
            }}
            label={t('STATUS')}
            placeholder={t('CHOOSE_STATUS')}
            clearable
            data={EventTypes}
            translate
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.fighter || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, fighter: v };
                })
            }}
            label={t('FIGHTER')}
            placeholder={t('CHOOSE_FIGHTER')}
            clearable
            data={athletes || []}
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.weight_class || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, weight_class: v };
                })
            }}
            label={t('WEIGHT_CLASS')}
            placeholder={t('CHOOSE_CLASS')}
            clearable
            data={info?.weightClasses || []}
            intl
          />
        </Block>
        <Block mb={0.5}>
          <DateInput
            input={{
              value: filtersModal?.date || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, date: v };
                })
            }}
            label={t('START_DATE')}
            placeholder={t('CHOOSE_DATE')}
            clearable
            minDate={
              location.includes('/upcoming') ? dayjs().toDate() : undefined
            }
            maxDate={
              location.includes('/past')
                ? dayjs().subtract(1, 'day').toDate()
                : undefined
            }
          />
        </Block>
      </FiltersModal>
    </Box>
  );
};

export default Filters;
