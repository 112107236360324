import { FC } from 'react';
import { DatePicker, DatePickerProps } from '@mantine/dates';
import { FieldRenderProps } from 'react-final-form';
import { GenericInput } from './Generic';
import { DangerColor, DisabledColor, LabelColor } from '../../styles/Colors';
import { InputWrapper, ErrorMessage } from './Styles';
import { InputSize } from './Generic';
import { useTranslation } from 'react-i18next';
import Icon from '../icon';

export interface DateInputProps extends Omit<FieldRenderProps<Date>, 'input'>, DatePickerProps {
  afterChange?: (value: Date) => void;
  input: GenericInput<Date>;
  size?: InputSize;
  isExternal?: boolean;
}

export const DateInput: FC<DateInputProps> = ({
  input,
  meta = {},
  afterChange,
  fixOnBlur = true,
  initialLevel = 'date',
  clearable = false,
  inputFormat = 'DD/MM/YYYY',
  size = 'md',
  isExternal = false,
  ...other
}) => {
  const { t } = useTranslation();

  const handleChange = (value: Date): void => {
    input.onChange(value);

    if (afterChange && typeof afterChange === 'function') {
      afterChange(value);
    }
  };

  const hasError = meta?.invalid && meta?.submitFailed;
  const value = input.value ? new Date(input.value) : null;
  const rightSection = 
    !other.disabled && (
    hasError ? 
    <Icon icon='bold_xCircle' color={DangerColor} size={1.25} /> 
    : 
    input.value ? 
    <Icon icon='outlined_x' color={LabelColor} size={1.125} onClick={() => input.onChange(null)} />
    :
    <Icon icon='outlined_calendar' color={DisabledColor} size={1.125} />);

  return (
    <InputWrapper size={size} hasError={hasError} isExternal={isExternal}>
      <DatePicker
        {...other}
        defaultValue={value}
        value={value}
        onChange={handleChange}
        error={hasError}
        fixOnBlur={fixOnBlur}
        initialLevel={initialLevel}
        clearable={clearable}
        inputFormat={inputFormat}
        rightSection={rightSection}
        styles={{ rightSection: { pointerEvents: input.value ? 'initial' : 'none' } }}
      />
      {
        hasError &&
        <ErrorMessage>
          {t('FIELD_REQUIRED')}
        </ErrorMessage>
      }
    </InputWrapper>
  );
};

export default DateInput;
