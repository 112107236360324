import { FC, ReactNode } from 'react';
import { ComposedInputWrapper } from './Styles';
import { Box } from '../../styles/BasicStyles';
import { LabelColor } from '../../styles/Colors';
import Typography from '../typography/index';

export interface InputProps {
  label?: string;
  firstInput?: ReactNode;
  secondInput: ReactNode;
  button?: ReactNode;
  hasError?: boolean;
}

const ComposedInput: FC<InputProps> = ({ label, firstInput, secondInput, button, hasError }) => {
  return (
    <Box fDirection='column'>
      {!!label && <Typography variant='body-small' style={{ color: LabelColor }} pb={0.5}>{label}</Typography>}
      <ComposedInputWrapper hasError={hasError} hasFirstInput={!!firstInput} hasButton={!!button}>
        {
          !!firstInput &&
          <div className='firstInput'>
            {firstInput}
          </div>
        }
        {
          (!!secondInput || !!button) &&
          <div className='inputButtonWrapper'>
            <div className='secondInput'>
              {secondInput}
            </div>
            {!!button && button}
          </div>
        }
      </ComposedInputWrapper>
    </Box>
  );
}

export default ComposedInput;