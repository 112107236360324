/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User } from '../../../../../models/User';
import { capitalizeString } from '../../../../../utils/strings';
import StatsCard from '../../../common/StatsCard';

interface Params {
  promoter?: User;
}

const StatsInfo: FC<Params> = ({ promoter }) => {
  const { t } = useTranslation();

  return (
    <StatsCard 
      items={[
        {
          title: `${promoter?.stats?.totalEventsPending} ${capitalizeString(t('EVENTS'))}`,
          subtitle: t('PENDING'),
          bgColor: 'rgba(255, 160, 0, 0.15)',
          fontColor: 'rgba(255, 160, 0, 0.5)'
        },
        {
          title: `${promoter?.stats?.totalEventsValidated} ${capitalizeString(t('EVENTS'))}`,
          subtitle: t('VALIDATED'),
          bgColor: 'rgba(61, 205, 179, 0.15)',
          fontColor: 'rgba(61, 205, 179, 0.5)'
        },
      ]}
    />
  );
};

export default StatsInfo;
