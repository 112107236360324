import {
  PasswordInput as MantinePassword,
  Popover,
  PasswordInputProps,
  Progress,
  Box,
  Text
} from '@mantine/core';
import { FieldRenderProps } from 'react-final-form';
import { GenericInput, AnyObject } from './Generic';
import { InputWrapper, ErrorMessage } from './Styles';
import { useTranslation } from 'react-i18next';
import { InputSize } from './Generic';
import { SuccessColor, DangerColor } from '../../styles/Colors';
import React, { useState } from 'react';
import Icon from '../icon';

export interface PasswordVerificationInputProps
  extends Omit<FieldRenderProps<string>, 'input'>,
    PasswordInputProps {
  afterChange?: (value: string) => void;
  input: GenericInput<string>;
  size?: InputSize;
}

interface IRequirementsComponent {
  meets: boolean;
  label: string;
}

interface IRequirements {
  label: string;
  re: AnyObject;
}

const PasswordRequirement: React.FC<IRequirementsComponent> = ({
  meets,
  label
}: {
  meets: boolean;
  label: string;
}) => (
  <Text
    color={meets ? SuccessColor : DangerColor}
    sx={{ display: 'flex', alignItems: 'center' }}
    mt={7}
    size="sm"
  >
    {meets ? (
      <Icon
        icon="bold_checkCircle"
        color={meets ? SuccessColor : DangerColor}
      />
    ) : (
      <Icon icon="bold_xCircle" color={meets ? SuccessColor : DangerColor} />
    )}{' '}
    <Box ml={10}>{label}</Box>
  </Text>
);

const BaseRequirements = [
  { re: /[0-9]/, label: 'INCLUDE_NUMBER' },
  { re: /[a-z]/, label: 'INCLUDE_LOWERCASE' },
  { re: /[A-Z]/, label: 'INCLUDE_UPPERCASE' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'INCLUDE_SYMBOL' }
];

export const PasswordVerificationInput: React.FC<
  PasswordVerificationInputProps
> = ({
  input,
  meta = {},
  label,
  placeholder,
  description,
  disabled,
  icon,
  rightSection,
  afterChange,
  variant,
  required,
  requirements = BaseRequirements,
  size
}) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const { t } = useTranslation();

  const getStrength = (password: string | undefined) => {
    let multiplier = (password || '').length > 5 ? 0 : 1;
    requirements.forEach((requirement: IRequirements) => {
      if (!requirement.re.test(password || '')) {
        multiplier += 1;
      }
    });
    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
  };

  const checks = requirements.map(
    (requirement: IRequirements, index: number) => (
      <PasswordRequirement
        key={index}
        label={t(requirement.label)}
        meets={requirement.re.test(input.value || '')}
      />
    )
  );

  const strength = getStrength(input.value);
  const color =
    strength === 100 ? SuccessColor : strength > 50 ? 'yellow' : DangerColor;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.currentTarget.value;
    input.onChange(value);
    if (afterChange && typeof afterChange === 'function') {
      afterChange(value);
    }
  };

  const hasError = meta?.submitFailed && (meta?.invalid || strength < 100);

  return (
    <Popover
      opened={popoverOpened}
      position="bottom"
      width="target"
      transition="pop"
    >
      <Popover.Target>
        <div
          onFocusCapture={() => setPopoverOpened(true)}
          onBlurCapture={() => setPopoverOpened(false)}
        >
          <InputWrapper size={size} hasError={hasError}>
            <MantinePassword
              label={label}
              placeholder={placeholder}
              description={description}
              disabled={disabled}
              variant={variant}
              icon={icon}
              rightSection={rightSection}
              value={input.value}
              onChange={handleChange}
              error={hasError}
              required={required}
            />
            {hasError && (
              <ErrorMessage>
                {meta.error
                  ? t(meta?.error?.tag, meta?.error?.values)
                  : t('FIELD_REQUIRED')}
              </ErrorMessage>
            )}
          </InputWrapper>
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Progress
          color={color}
          value={strength}
          size={5}
          style={{ marginBottom: 10 }}
        />
        <PasswordRequirement
          label={t('INCLUDE_6_CHARS')}
          meets={(input?.value || '').length > 5}
        />
        {checks}
      </Popover.Dropdown>
    </Popover>
  );
};

export default PasswordVerificationInput;
