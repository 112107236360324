import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router';
import { useState, useEffect, useContext } from 'react';
import { Event } from '../../../models/Event';
import { showSuccess } from '../../../hooks/show-notification/show-notification';
import { LabelColor, PlaceholderColor } from '../../../styles/Colors';
import { Block } from '../../../styles/BasicStyles';
import { GetEventInitialValues } from '../../../utils/modalValues';
import { UserContext } from '../../../Context';
import { AdminTypes, OFFICIAL, PROMOTER, RESPONSIBLE } from '../../../models/User';
import headerActions, { HeaderBaseAction } from '../../../components/header/HeaderActions';
import useFetch from 'use-http';
import LoadingOverlay from '../../../components/loadingOverlay';
import PageHeader from '../../../components/page/PageHeader';
import EventModal from '../manage';
import FightsList from './components/FightsList';
import Cards from './components/Cards';
import Typography from '../../../components/typography';
import RequestChangeModal from '../list/modals/RequestChangeModal';
import DuplicateModal from '../list/modals/DuplicateModal';
import PromoterEditEventModal from '../list/modals/PromoterEditEventModal';

const EventDetailPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<Event>();
  const [editModal, setEditModal] = useState<{ open: boolean, initialValues?: Event }>({ open: false });
  const [requestChangeModal, setRequestChangeModal] = useState<{ open: boolean; initialValues?: Event; }>({ open: false });
  const [duplicateModal, setDuplicateModal] = useState<{ open: boolean; initialValues?: Event; }>({ open: false });
  const [promoterEditEventModal, setPromoterEditEventModal] = useState<{ open: boolean; initialValues?: Event; }>({ open: false });
  const [officiatingTeamRoles, setOfficiatingTeamRoles] = useState<{ _id: string, roles: string[] }[]>();
  const { t } = useTranslation();
  const { id } = useParams();
  const { search } = useLocation();
  const { get } = useFetch('/events');
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    const init = async () => {
      await getInfo();
    };

    init();

    if(search.includes('success=true')) {
      showSuccess({
        title: t('SUCCESS'),
        message: t('EVENT_CREATED')
      });

      navigate(`/events/${id}`, { replace: true });
    }
  }, [id]);

  const getInfo = async () => {
    if(id) {
      const event = await get(`/${id}`);
      const officiatingTeamReq = await get(`/${id}/officiating-team`);

      if(event?.success) {
        setInitialValues(event.data);
        setEditModal({ open: false, initialValues: GetEventInitialValues(event.data) });
        setPromoterEditEventModal({ open: false, initialValues: GetEventInitialValues(event.data) });
      }
      // If we don't have permission to see the event, return to homepage
      else {
        return navigate('/');
      }

      if(officiatingTeamReq?.success) setOfficiatingTeamRoles(officiatingTeamReq.data);
    }

    setLoading(false);
  }

  const handleCloseModal = async (changed: boolean) => {
    if(changed) {
      setLoading(true);
      await getInfo();
    }
    else {
      setEditModal({ ...editModal, open: false });
    }
  }

  const handlePromoterCloseModal = async (changed: boolean) => {
    if(changed) {
      setLoading(true);
      await getInfo();
    }
    else {
      setPromoterEditEventModal({ ...promoterEditEventModal, open: false });
    }
  }

  let actions: HeaderBaseAction[] = [];
  if(user?.type && (AdminTypes.includes(user.type) || user.type === RESPONSIBLE)) {
    actions = [
      {
        type: 'button',
        variant: 'primary',
        text: t('EDIT_EVENT'),
        onClick: () => setEditModal({ ...editModal, open: true })
      },
      {
        type: 'button',
        variant: 'primary',
        text: t('DUPLICATE'),
        onClick: () => setDuplicateModal({ ...editModal, open: true })
      }
    ];
  }

  if(user?.type === PROMOTER) actions = [
    {
      type: 'button',
      variant: 'primary',
      text: t('EDIT_EVENT'),
      onClick: () => setPromoterEditEventModal({ ...promoterEditEventModal, open: true })
    },
    {
      type: 'button',
      variant: 'primary',
      text: t('REQUEST_CHANGE'),
      onClick: () => setRequestChangeModal({ ...editModal, open: true })
    }
  ];

  return (
    <>
      <LoadingOverlay visible={loading} />
      <PageHeader
        title={
          <Block display='flex' fDirection='column'>
            <Typography variant='page-title' style={{ color: LabelColor }}>{initialValues?.name}</Typography>
            <Typography variant='body-small' style={{ color: PlaceholderColor }}>{initialValues?.federation?.name}</Typography>
          </Block>
        }
        backLink={-1}
        actions={headerActions({
          hasSave: false,
          extra: actions
        })}
      />
      {
        !!initialValues &&
        <>
          <Cards 
            initialValues={initialValues} 
            officiatingTeamRoles={officiatingTeamRoles}
          />
          <FightsList 
            event={initialValues}
            getInfo={getInfo}
          />
          {
            user?.type !== OFFICIAL &&
            <EventModal
              opened={editModal.open}
              initialValues={editModal.initialValues}
              onClose={handleCloseModal}
            />
          }
          {
            user?.type === PROMOTER &&
            <>
              <RequestChangeModal
                opened={requestChangeModal.open}
                initialValues={requestChangeModal.initialValues}
                onClose={() => setRequestChangeModal({ open: false })}
              />
              <PromoterEditEventModal
                opened={promoterEditEventModal.open}
                initialValues={promoterEditEventModal.initialValues}
                onClose={handlePromoterCloseModal}
              />
            </>
          }
          {
            user?.type && (AdminTypes.includes(user.type) || user.type === RESPONSIBLE) &&
            <DuplicateModal
              opened={duplicateModal.open}
              initialValues={duplicateModal.initialValues}
              onClose={() => setDuplicateModal({ open: false })}
            />
          }
        </>
      }
    </>
  );
};

export default EventDetailPage;
