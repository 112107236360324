/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fight } from '../../models/Fight';
import { useTranslation } from 'react-i18next';
import { FC, useState, useContext } from 'react';
import { Box } from '../../styles/BasicStyles';
import { LabelColor, FocusColor, PlaceholderColor } from '../../styles/Colors';
import { capitalizeString } from '../../utils/strings';
import { GetFightDate, GetFightDecision } from '../../utils/fights';
import { AnyObject } from '../../models/Generic';
import { GetFightInitialValues } from '../../utils/modalValues';
import { useNavigate } from 'react-router-dom';
import { canEditEvent } from '../../utils/events';
import { Event } from '../../models/Event';
import { UserContext } from '../../Context';
import { ADMIN, REGULATOR, RESPONSIBLE, SUPER_ADMIN } from '../../models/User';
import { TournamentRound } from '../../models/Tournament';
import { GetFightRoundName, GetWinnerOf } from '../../utils/tournament/single';
import DropdownMenu, { ActionProps } from '../../components/dropdownMenu';
import useFetch from 'use-http';
import DefaultListPage from '../../components/page/DefaultListPage';
import Icon from '../../components/icon';
import Typography from '../../components/typography';
import BaseTable from '../../components/table';
import ManageSingleFightModal from '../events/detail/fightsModal/single';
import UserCard from '../../components/userCard';
import FightStatus from '../../components/fightStatus';

const FightsList = DefaultListPage<Fight>();
const FightsTable = BaseTable<Fight>();

interface Params {
  tournamentID?: string;
  event?: Event;
  reloadOnChange: string;
  bracket?: TournamentRound[]; 
  reloadInfo: () => void;
}

const FightsListComponent: FC<Params> = ({ tournamentID, event, reloadOnChange, bracket, reloadInfo }) => {
  const [singleFightModal, setSingleFightModal] = useState<{ open: boolean, initialValues?: Fight, index?: number }>({ open: false });
  const { t, i18n: { language } } = useTranslation();
  const { post } = useFetch('/fights');
  const { get } = useFetch(`/fights/tournament/${tournamentID}`);
  const navigate = useNavigate();
  const userCanEditEvent = canEditEvent(event);
  const { user } = useContext(UserContext);

  return (
    <FightsList
      translate={t}
      request={{
        get,
        fetchAll: true,
        reloadOnChange: [reloadOnChange]
      }}
      tableTitle={`${t('FIGHTS').toUpperCase()}`}
    >
      {({ rows, loading, pagination, handleNewSort }) => {
        const moveFight = async (list: AnyObject[]) => {
          await post('/tournament/reorder', { event: event?._id, tournament: tournamentID, list });
          reloadInfo();
        };

        const handleCloseFightModal = (changed: boolean) => {
          if (changed) reloadInfo();
          setSingleFightModal({ open: false });
        };

        const columns: AnyObject[] = [ 
          { 
            title: `${capitalizeString(t('FIGHT'))} #`, 
            dataIndex: 'start_datetime', 
            render: (_: string, fight: Fight) => (
              <Box fDirection='column'>
                <Typography variant='body-small' color={FocusColor}>{`#${fight.fight_number}`}</Typography>
                <Typography variant='sidebar-group' style={{ color: PlaceholderColor }}>{GetFightDate(fight.start_datetime)}</Typography>
              </Box>
            )
          },
          { 
            title: t('ROUND'),
            dataIndex: 'round',
            render: (_: AnyObject, fight: Fight) => <Typography variant='body-small' style={{ color: LabelColor }}>{GetFightRoundName(t, fight, bracket)}</Typography>
          },
          {
            title: t('DURATION'),
            key: 'duration',
            align: 'center',
            render: (fight: Fight) => (
              <Box fJustify='center'>
                <Typography tAlign='center' variant="body-small" style={{ color: LabelColor }}>
                  {`${fight.rounds}x${fight.duration}`}
                </Typography>
              </Box>
            )
          },
          { 
            title: t('WEIGHT_CLASS'),
            dataIndex: 'weight_class', 
            render: (value: AnyObject) => <Typography variant='body-small' style={{ color: LabelColor }}>{value?.name && value.name[language]}</Typography>
          },
          { 
            title: t('RED_FIGHTER'),
            dataIndex: 'red_fighter', 
            render: (_: string, fight: Fight) => 
              <UserCard 
                user={fight.red_fighter} 
                fight={fight} 
                noUserText={GetWinnerOf(t, fight._id, bracket, 'red')}
                showFederationAlert
              />
          },
          { 
            title: t('BLUE_FIGHTER'),
            dataIndex: 'blue_fighter', 
            render: (_: string, fight: Fight) => 
              <UserCard 
                user={fight.blue_fighter} 
                fight={fight} 
                noUserText={GetWinnerOf(t, fight._id, bracket, 'blue')}
                showFederationAlert
              />
          },
          { 
            title: t('FIGHT_ENDING'),
            dataIndex: 'result',
            render: (_: string, fight: Fight) => <Typography variant='table-header'>{GetFightDecision(t, fight.result?.decision, fight?.result?.submission?.name[language])}</Typography>
          },
          { 
            title: t('MORE'),
            dataIndex: 'actions', 
            disableRowClick: true,
            render: (_: any, fight: Fight, index: number) => {
              const options: ActionProps[] = [
                {
                  type: 'link',
                  label: t('VIEW_FIGHT'),
                  onClick: () => navigate(`/fights/${fight._id}`)
                }
              ];

              if(userCanEditEvent) {
                options.splice(1, 0, 
                  {
                    type: 'divider'
                  },
                  {
                    type: 'link',
                    label: t('EDIT_FIGHT'),
                    onClick: () => setSingleFightModal({ open: true, initialValues: GetFightInitialValues(fight), index })
                  }
                );
              }
              
              return (
                <DropdownMenu 
                  render={<Icon icon='outlined_dotsHorizontal' color={LabelColor} />}
                  menuPosition='bottom-end'
                  actions={options} 
                />
              );
            }
          }
        ];

        // If the User is an Admin, show a column with the Fight Status
        if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR || user?.type === RESPONSIBLE) {
          columns.splice(6, 0, {
            title: t('STATUS'),
            dataIndex: 'status',
            align: 'center',
            render: (_: any, fight: Fight) => <Box fJustify='center'><FightStatus fight={fight} /></Box>
          });
        }

        return (
          <>
            <FightsTable
              columns={columns}
              rows={rows}
              loading={loading}
              defaultSort={pagination.sort}
              sortCallback={handleNewSort}
              tableStyles={{
                compact: true,
                alternateRowColor: true,
                noBorderRadius: true,
                noSideBorders: true
              }}
              onRowClick={(row) => navigate(`/fights/${row._id}`)}
              moveItem={moveFight}
              fixColumns={[0]}
            />
            <ManageSingleFightModal
              opened={singleFightModal.open}
              initialValues={singleFightModal.initialValues}
              onClose={handleCloseFightModal}
              eventOfficials={event?.officials}
              eventID={event?._id}
              fightNumber={singleFightModal.initialValues?.fight_number}
              eventFederation={event?.federation?._id}
            />
          </>
        );
      }}
    </FightsList>
  );
};

export default FightsListComponent;
