import * as yup from "yup";
import { Field, withTypes } from "react-final-form";
import { ResetValues } from "../../../models/Info";
import { useValidationSchema } from "../../../hooks/use-validation/use-validation-schema";
import { BasicForm, Box, Block } from "../../../styles/BasicStyles";
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '../../../hooks/show-notification/show-notification';
import { useParams, useNavigate } from 'react-router';
import { FocusColor, LabelColor, LoginButtonColor } from '../../../styles/Colors';
import AuthLayout from "../AuthLayout";
import useFetch from "use-http";
import Button from '../../../components/button';
import PasswordInput from '../../../components/inputs/PasswordInput';
import Typography from '../../../components/typography';
import PasswordVerificationInput from '../../../components/inputs/PasswordVerificationInput';

const { Form } = withTypes<ResetValues>();

export const RecoverPage = () => {
  const { post } = useFetch("/auth");
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  const onSubmit = async (values: ResetValues) => {
    if(values.password !== values.confirm_password) {
      return showError({
        title: t('ERROR'),
        message: t('PASSWORDS_DONT_MATCH')
      });
    }

    const { success } = await post("/reset-password", { ...values, token });
    
    if(success) {
      showSuccess({
        title: t('SUCCESS'),
        message: t('PASSWORD_CHANGED')
      });

      navigate('/login');
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          password: yup.string().required().min(6),
          confirm_password: yup.string().required().min(6)
        })
      )}
    >
      {({ handleSubmit, submitting }) => (
        <AuthLayout>
          <BasicForm onSubmit={handleSubmit}>
            <Block mb={2.25} tAlign='center'>
              <Typography variant='auth-title' style={{ color: LabelColor }}>{t('RESET_PASSWORD')}</Typography>
            </Block>
            <Box fDirection='column'>
              <Block w='100%' mb={1.5}>
                <Field name="password">
                  {(props) => (
                    <PasswordVerificationInput
                      {...props}
                      label={t('PASSWORD')}
                      placeholder={t('ENTER_PASSWORD')}
                      size="lg"
                    />
                  )}
                </Field>
              </Block>
              <Block w='100%' mb={2.5}>
                <Field name="confirm_password">
                  {(props) => (
                    <PasswordInput
                      {...props}
                      label={t('CONFIRM_PASSWORD')}
                      placeholder={t('CONFIRM_PASSWORD')}
                      size='lg'
                    />
                  )}
                </Field>
              </Block>
              <Button
                text={t('SAVE')}
                type='submit'
                loading={submitting}
                w='100%'
                variant={FocusColor}
                textColor={LoginButtonColor}
              />
            </Box>
          </BasicForm>
        </AuthLayout>
      )}
    </Form>
  );
};

export default RecoverPage;
