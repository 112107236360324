import { useTranslation } from 'react-i18next';
import { User, DocumentType } from '../../../models/User';
import { FC } from 'react';
import { Block, Box } from '../../../styles/BasicStyles';
import { FocusColor, LabelColor, MenuBgColor } from '../../../styles/Colors';
import { openFile } from '../../../utils/files';
import Icon from '../../../components/icon';
import Link from '../../../components/link';
import Typography from '../../../components/typography';
import dayjs from 'dayjs';

interface Params {
  initialValues?: User;
}

const DraftFileList: FC<Params> = ({ initialValues }) => {
  const { t } = useTranslation();

  const renderFiles = (title: string, files?: DocumentType[], first?: boolean) => {
    if(!files || files.length === 0) return null;

    return (
      <Block w='100%' pt={first ? 0 : 0.75}>
        <Typography as='div' variant='body-small' style={{ color: LabelColor }} mb={0.5}>{t(title)}</Typography>
        {
          files.map((elem: DocumentType, index: number) =>
            <Box 
              key={index} 
              bgColor={MenuBgColor} 
              bRadius={0.25} 
              fAlign='center' 
              fJustify='space-between' 
              padding='0.625 1'
              mb={0.25}
              fWrap='wrap'
            >
              <Box w={{ xs: '50%', xxs: '100%' }}>
                <Link onClick={() => openFile(elem.file || {})}>
                  <Box fAlign='center'>
                    <Icon icon='outlined_documentText' color={FocusColor} />
                    <Typography variant='dropdown-menu-small' style={{ color: LabelColor }} ml={1}>
                      {elem.file?.filename}
                    </Typography>
                  </Box>
                </Link>
              </Box>
              <Box w={{ xs: '50%', xxs: '100%' }} fAlign='center' mt={{ xs: 0, xxs: 0.5 }}>
                <Typography variant='dropdown-menu-small' style={{ color: LabelColor }}>{t('EXPIRATION_DATE')}:</Typography>
                <Typography variant='dropdown-menu-small' fWeight={500} style={{ color: LabelColor }} pl={0.5}>{!!elem.expiration_date ? dayjs(elem.expiration_date).format('DD MMM, YYYY') : '-'}</Typography>
              </Box>
            </Box>
          )
        }
      </Block>
    );
  };

  return (
    <>
      <Block pb={0.688} pt={1.5}>
        <Typography variant='body-small' color='#ffffff'>{t('DOCUMENTS').toUpperCase()}</Typography>
      </Block>
      <Block w='100%'>
        {renderFiles('IDENTIFICATION_DOCUMENT', initialValues?.identification, true)}
        {renderFiles('PAYMENT_PROOF', initialValues?.payment)}
        {renderFiles('SPORTS_MEDICAL_EXAMINATION', initialValues?.medical)}
        {renderFiles('CLINICAL_ANALYSIS_REPORT', initialValues?.clinical)}
        {renderFiles('RESIDENCE_PROOF', initialValues?.residence)}
        {renderFiles('OTHER_DOCUMENTS', initialValues?.others)}
        {renderFiles('CERTIFICATION_DOCUMENTS', initialValues?.certification)}
        {renderFiles('GUARDIAN_IDENTIFICATION_DOCUMENT', initialValues?.guardian_identification)}
        {renderFiles('GUARDIAN_WAIVER_DOCUMENT', initialValues?.guardian_waiver)}
      </Block>
    </>
  );
};

export default DraftFileList;
