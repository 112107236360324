import { TimeInput as MantineTime, TimeInputProps as MantineTimeInputProps } from '@mantine/dates';
import { FieldRenderProps } from 'react-final-form';
import { GenericInput, InputSize } from './Generic';
import { FC } from 'react';
import { InputWrapper, ErrorMessage } from './Styles';
import { useTranslation } from 'react-i18next';

export interface TimeInputProps extends Omit<FieldRenderProps<Date>, 'input'>, MantineTimeInputProps {
  afterChange?: (value: Date) => void;
  input: GenericInput<Date>;
  size?: InputSize;
  showError?: boolean;
}

export const TimeInput: FC<TimeInputProps> = ({
  input,
  meta = {},
  label,
  description,
  disabled,
  icon,
  afterChange,
  variant,
  required,
  clearable = false,
  withSeconds = false,
  size = 'md',
  showError = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleChange = (value: Date): void => {
    input.onChange(value);

    if (afterChange && typeof afterChange === 'function') {
      afterChange(value);
    }
  };

  const hasError = showError || (meta?.invalid && meta?.submitFailed);

  return (
    <InputWrapper size={size} hasError={hasError}>
      <MantineTime
        {...rest}
        label={label}
        description={description}
        disabled={disabled}
        variant={variant}
        icon={icon}
        value={input.value ? new Date(input.value) : null}
        onChange={handleChange}
        error={hasError}
        required={required}
        clearable={clearable}
        withSeconds={withSeconds}
        format="24"
      />
      {
        hasError &&
        <ErrorMessage>
          {t('FIELD_REQUIRED')}
        </ErrorMessage>
      }
    </InputWrapper>
  );
};

export default TimeInput;
