import * as yup from 'yup';
import { useContext } from 'react';
import { Field, withTypes } from 'react-final-form';
import { LoginValues } from '../../../models/Info';
import { UserContext } from '../../../Context';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { BasicForm, Box, Block } from '../../../styles/BasicStyles';
import { AuthTokenKey } from '../../../utils/requests/LocalStorageKeys';
import { useTranslation } from 'react-i18next';
import {
  FocusColor,
  LabelColor,
  LoginButtonColor
} from '../../../styles/Colors';
import AuthLayout from '../AuthLayout';
import useFetch from 'use-http';
import Button from '../../../components/button';
import TextInput from '../../../components/inputs/TextInput';
import PasswordInput from '../../../components/inputs/PasswordInput';
import Link from '../../../components/link';
import Typography from '../../../components/typography';

const { Form } = withTypes<LoginValues>();

export const LoginPage = () => {
  const { post } = useFetch('/auth');
  const { setUser } = useContext(UserContext);
  const { t } = useTranslation();

  const onSubmit = async (values: LoginValues) => {
    const { data, success } = await post('/login', values);
    if (success) {
      localStorage.setItem(AuthTokenKey, data.token);
      setUser(data.user);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          email: yup.string().email().required(),
          password: yup.string().required().min(6)
        })
      )}
    >
      {({ handleSubmit, submitting }) => (
        <AuthLayout>
          <BasicForm onSubmit={handleSubmit}>
            <Block mb={2.25} tAlign="center">
              <Typography variant="auth-title" style={{ color: LabelColor }}>
                {t('LOGIN')}
              </Typography>
            </Block>
            <Box fDirection="column">
              <Block w="100%" mb={1.5}>
                <Field name="email">
                  {(props) => (
                    <TextInput
                      {...props}
                      label={t('EMAIL')}
                      placeholder={t('ENTER_EMAIL')}
                      type="email"
                      size="lg"
                    />
                  )}
                </Field>
              </Block>
              <Box
                fAlign="center"
                fJustify="space-between"
                mb={0.5}
                fWrap="wrap"
              >
                <Block w={{ xs: '50%', xxs: '100%' }}>
                  <Typography
                    variant="body-small"
                    style={{ color: LabelColor }}
                  >
                    {t('PASSWORD')}
                  </Typography>
                </Block>
                <Block w={{ xs: '50%', xxs: '100%' }} tAlign="right">
                  <Link href="/recover-password">
                    <Typography variant="body-small" color={FocusColor}>
                      {t('FORGOT_PASSWORD')}
                    </Typography>
                  </Link>
                </Block>
              </Box>
              <Block w="100%" mb={2.5}>
                <Field name="password">
                  {(props) => (
                    <PasswordInput
                      {...props}
                      placeholder={t('ENTER_PASSWORD')}
                      size="lg"
                    />
                  )}
                </Field>
              </Block>
              <Button
                text={t('LOGIN')}
                type="submit"
                loading={submitting}
                w="100%"
                variant={FocusColor}
                textColor={LoginButtonColor}
              />
            </Box>
          </BasicForm>
        </AuthLayout>
      )}
    </Form>
  );
};

export default LoginPage;
