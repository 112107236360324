import { useContext } from 'react';
import { UserContext } from '../../Context';
import { ACTIVE, DEACTIVATED, AWAITING_VALIDATION, Event, REJECTED } from '../../models/Event';
import { AnyObject } from '../../models/Generic';
import { ADMIN, OFFICIAL, PROMOTER, REGULATOR, SUPER_ADMIN } from '../../models/User';
import { Fight } from '../../models/Fight';
import { CODEWIP, LOCAL, STAGING } from '../../models/Environment';
import dayjs from 'dayjs';

export const EventTypes = [
  { _id: ACTIVE, name: ACTIVE, bgColor: 'rgba(76, 175, 80, 0.12)', fontColor: '#4CAF50' },
  { _id: DEACTIVATED, name: DEACTIVATED, bgColor: 'rgba(217, 99, 99, 0.1)', fontColor: '#D96363' }
];

export const GetEventType = (value: string | undefined) => {
  const found: AnyObject = EventTypes.find(elem => elem._id === value) || {};

  return found;
};

export const EventDraftTypes = [
  { _id: AWAITING_VALIDATION, name: AWAITING_VALIDATION, bgColor: 'rgba(255, 160, 0, 0.09)', fontColor: '#FFA000' },
  { _id: REJECTED, name: REJECTED, bgColor: 'rgba(217, 99, 99, 0.1)', fontColor: '#D96363' }
];

export const GetEventDraftType = (value: string | undefined) => {
  const found: AnyObject = EventDraftTypes.find(elem => elem._id === value) || {};

  return found;
};

export const canEditEvent = (event?: Event) => {
  const { user } = useContext(UserContext);

  // Officials can only add/edit/remove fights before the Event end date
  if(event?.end_datetime && user?.type === OFFICIAL && dayjs().isAfter(event.end_datetime)) return false;

  // Promoters can only add/edit/remove fights before the Event start date
  if(event?.start_datetime && user?.type === PROMOTER && dayjs().isAfter(event.start_datetime)) return false;

  return true;
};

export const GetEventConsistency = (event?: Event) => {
  if(!event || event?.fights?.length === 0) return undefined;

  let total = 0, count = 0;

  event?.fights?.forEach((fight: Fight) => {
    if(fight.consistency?.consistency >= 0) {
      total += fight.consistency?.consistency;
      count++;
    }
  });

  return count > 0 ? total / count : 0;
};

export const ShowEventStatus = (type?: string) => {
  const adminList = [SUPER_ADMIN, ADMIN, REGULATOR];
  const envList = [LOCAL, CODEWIP, STAGING];
  const env = process.env.REACT_APP_ENV || '';

  // Status in only visible:
  // For Super Admin, Admin and Regulator
  // In Local, Codewip and Staging environments
  if(type && adminList.includes(type) && envList.includes(env)) return true;
  return false
};
