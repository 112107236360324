import { Size } from './index';
import styled, { css } from 'styled-components';

export const AvatarWrapper = styled.div<{ size: Size, borderColor?: string }>` 
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.userAvatarBg};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  & span {
    font-family: 'Inter', sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
  }

  ${({ borderColor }) => !!borderColor &&
    css`
      border: 0.125rem solid ${borderColor};
    `
  }
  
  ${({ size }) =>
    size === 'xxs' ?
    css`
      width: 1rem;
      height: 1rem;

      & span {
        font-size: 0.625rem;
        line-height: 0.75rem;
      }
    `
    :
    size === 'xs' ?
    css`
      width: 1.75rem;
      height: 1.75rem;
    `
    :
    size === 'sm' ?
    css`
      width: 2rem;
      height: 2rem;
    `
    :
    size === 'lg' ?
    css`
      width: 2.875rem;
      height: 2.875rem;
    `
    :
    size === 'xl' ?
    css`
      width: 5rem;
      height: 5rem;

      & span {
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    `
    :
    css`
      width: 2.5rem;
      height: 2.5rem;
    `
  }
`;
