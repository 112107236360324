import styled, { css } from 'styled-components';

export const BracketWrapper = styled.div<{ scale: number, editMode?: boolean }>`
  overflow-x: auto;
  
  ${({ editMode, scale }) => !editMode && 
    css`
      transform-origin: top left;
      transform: scale(${scale || 1});
      width: calc(100% / (${scale || 1}));
    `
  }
`;

export const Column = styled.div` 
  flex: 0;
  display: flex;
  flex-direction: column;

  &:last-child {
    & > div {
      & div:after, & div:before {
        display: none;
      }
    }
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: center;
  height: 100%;
`;

export const FighterWrapper = styled.div<{ isBye?: boolean }>`
  padding: 1rem 2.813rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;

  ${({ isBye }) =>
    isBye ?
    css`
      & > div {
        background-color: transparent;
      }
    `
    :
    css`
      &:after {
        content: '';
        position: absolute;
        height: 50%;
        width: 2.813rem;
        right: 0;
      }

      &:nth-child(odd):not(:last-child):after {
        border-top: 0.063rem solid rgba(118, 136, 201, 0.7);
        border-top-right-radius: 0.75rem;
        border-right: 0.063rem solid rgba(118, 136, 201, 0.7);
        top: calc(50% - 0.031rem);
      }

      &:nth-child(even):after {
        border-bottom: 0.063rem solid rgba(118, 136, 201, 0.7);
        border-bottom-right-radius: 0.75rem;
        border-right: 0.063rem solid rgba(118, 136, 201, 0.7);
        top: -0.031rem;
      }

      &:nth-child(even):before {
        content: '';
        border-top: 0.063rem solid rgba(118, 136, 201, 0.7);
        position: absolute;
        top: -0.031rem;
        width: 2.813rem;
        right: -2.813rem;
      }
    `
  }
`;

export const FighterContent = styled.div<{ hasFighter: boolean }>`
  padding: 0.3rem 1.75rem;
  min-width: 10rem;
  min-height: 3rem; 
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ hasFighter }) => hasFighter ? 'flex-start' : 'center'};
  border-radius: 0.25rem;
  background-color: rgba(217, 217, 217, 0.1);
`;

export const FightInfo = styled.div`
  position: absolute;
  left: 2.813rem;
  bottom: -0.5rem;
  height: 1.063rem;
  width: 100%;
  max-width: 10rem;
  text-align: center;
`;

export const EditFighterContent = styled.div<{ top: number, left: number }>`
  min-width: 10rem;
  min-height: 3rem; 
  width: 100%;

  & > div, 
  & .mantine-Select-root,
  & .mantine-Select-root > div:first-child,
  & .mantine-Select-wrapper,
  & .mantine-Select-input {
    height: 100%;
  }

  & .mantine-Select-input {
    border: 0.063rem solid ${({ theme }) => theme.colors.focusColor} !important;
  }

  & .mantine-Select-dropdown {
    position: fixed;
    top: ${({ top }) => top >= 0 ? top : 'inherit'} !important;
    left: ${({ left }) => left >= 0 ? left : 'inherit'} !important;
  }
`;
