import { useTranslation } from 'react-i18next';
import { FC, useState, useContext } from 'react';
import { Box, Block } from '../../../styles/BasicStyles';
import { AnyObject } from '../../../models/Generic';
import { InfoContext } from '../../../Context';
import { User } from '../../../models/User';
import Icon from '../../../components/icon';
import TextInput from '../../../components/inputs/TextInput';
import FiltersModal from '../../../components/filtersModal';
import SelectInput from '../../../components/inputs/SelectInput';

interface FiltersProps {
  filters?: AnyObject;
  handleChange: (
    field: string | string[],
    value: string | string[] | null,
    time?: number
  ) => void;
  responsibles?: User[];
}

const Filters: FC<FiltersProps> = ({ filters, handleChange, responsibles }) => {
  const [filtersModal, setFiltersModal] = useState<AnyObject>({ ...filters });
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);

  // When closing the modal without applying the filters, reload the filters saved in local storage
  const reloadStorageFilters = () => {
    setFiltersModal({ ...filters });
  };

  return (
    <Box fAlign="center">
      <Block pr={0.875}>
        <TextInput
          input={{
            value: filters?.search,
            onChange: (v: string | null) => handleChange('search', v, 1000)
          }}
          placeholder={t('SEARCH')}
          onSearch={(v: string | null) => handleChange('search', v)}
          icon={<Icon icon="outlined_search" />}
          clearable
        />
      </Block>
      <FiltersModal
        handleChange={handleChange}
        filters={filtersModal}
        setFilters={setFiltersModal}
        reloadStorageFilters={reloadStorageFilters}
      >
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.country || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, country: v };
                })
            }}
            label={t('COUNTRY')}
            placeholder={t('CHOOSE_COUNTRY')}
            clearable
            data={info?.countries || []}
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.responsible || '',
              onChange: (v: string | null) =>
                setFiltersModal((old) => {
                  return { ...old, responsible: v };
                })
            }}
            label={t('RESPONSIBLE')}
            placeholder={t('CHOOSE_RESPONSIBLE')}
            clearable
            data={responsibles || []}
          />
        </Block>
      </FiltersModal>
    </Box>
  );
};

export default Filters;
