import { User } from '../../models/User';
import { GetFirstAndLastInitials, GetFirstInitial } from '../../utils/user';
import { AvatarWrapper } from './styles';
import { AnyObject } from '../../models/Generic';
import Placeholder from '../../assets/images/avatar.png';

export type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;

type AvatarProps = {
  user: User | AnyObject | undefined;
  size?: Size;
  borderColor?: string;
  firstInitial?: boolean;
};

const UserAvatar: React.FC<AvatarProps> = ({ user, size, borderColor, firstInitial = false }) => {
  return (
    <AvatarWrapper size={size} borderColor={borderColor}>
      {user?.photo?.url ? (
        <img src={user.photo.url} alt="user image" />
      ) : user?.name ? (
        <span>{firstInitial ? GetFirstInitial(user.name) : GetFirstAndLastInitials(user.name)}</span>
      ) : (
        <img src={Placeholder} alt="user image" />
      )}
    </AvatarWrapper>
  );
};

export default UserAvatar;
