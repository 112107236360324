/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { SelectedFighters, Tournament, TournamentFight, TournamentRound } from '../../models/Tournament';
import { Block } from '../../styles/BasicStyles';
import { capitalizeString } from '../../utils/strings';
import { LabelColor, PlaceholderColor } from '../../styles/Colors';
import { GetFightDate } from '../../utils/fights';
import { Event } from '../../models/Event';
import { canEditEvent } from '../../utils/events';
import { showSuccess } from '../../hooks/show-notification/show-notification';
import { ATHLETE, User } from '../../models/User';
import { HasFight } from '../../utils/tournament/single';
import { toggleAthlete } from '../events/detail/fightsModal/tournament/formHelpers';
import { GetGroupName } from '../../utils/user';
import useFetch from 'use-http';
import headerActions, { HeaderBaseAction } from '../../components/header/HeaderActions';
import LoadingOverlay from '../../components/loadingOverlay';
import PageHeader from '../../components/page/PageHeader';
import Typography from '../../components/typography';
import ManageTournamentModal from '../events/detail/fightsModal/tournament';
import FightsList from './FightsList';
import Bracket from './Bracket';
import dayjs from 'dayjs';
import SearchAthleteModal from '../events/detail/fightsModal/searchAthlete';

const TournamentDetailPage = () => {
  const [loading, setLoading] = useState(true);
  const [reloadFightList, setReloadFightList] = useState<string>('');
  const [initialValues, setInitialValues] = useState<Tournament>();
  const [bracket, setBracket] = useState<TournamentRound[]>();
  const [allAthletes, setAllAthletes] = useState<User[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [event, setEvent] = useState<Event>();
  const [athletes, setAthletes] = useState<User[]>([]);
  const [searchModal, setSearchModal] = useState<{ open: boolean, fight?: string, type?: 'red_fighter' | 'blue_fighter' }>({ open: false });
  const [selectedFighters, setSelectedFighters] = useState<SelectedFighters[]>([]);
  const { t } = useTranslation();
  const { id } = useParams();
  const { get } = useFetch('/tournaments');
  const eventHooks = useFetch('/events');
  const navigate = useNavigate();
  const userCanEditEvent = canEditEvent(event);
  const { search } = useLocation();
  const athletesHook = useFetch(`/members/${ATHLETE}`);

  useEffect(() => {
    const init = async () => {
      await getInfo();
    };

    init();

    if(search.includes('success=true')) {
      showSuccess({
        title: t('SUCCESS'),
        message: t('TOURNAMENT_CREATED')
      });

      navigate(`/tournaments/${id}`, { replace: true });
    }
  }, [id]);

  useEffect(() => {
    if(!!initialValues && allAthletes?.length > 0) loadAthletes();
  }, [initialValues, allAthletes]);

  const getInfo = async () => {
    if(id) {
      const tournamentResult = await get(`/${id}`);
      const bracketResult = await get(`/${id}/bracket`);

      if(tournamentResult?.success) {
        const eventResult = await eventHooks.get(`/${tournamentResult.data.event}`);
        const athletesResult = await athletesHook.get('/all/dropdown');

        setInitialValues(tournamentResult.data);
        setBracket(bracketResult?.data || []);
        setEvent(eventResult?.data);
        setAllAthletes(athletesResult?.data || []);
      }
      // If we don't have permission to see the event, return to homepage
      else {
        return navigate('/');
      }
    }

    setLoading(false);
  };

  const loadAthletes = () => {
    const athletesList: User[] = [];

    // First option is the one to search for other Federations athletes
    athletesList.push({
      _id: '1',
      name: t('SEARCH_ATHLETE'),
      group: GetGroupName(t, 'EXTERNAL', 1),
      disabled: false
    });

    if(!!initialValues && initialValues.fighters && initialValues.fights && allAthletes.length > 0) {
      const federationList = allAthletes.filter((elem: User) => elem.federation === event?.federation?._id) || [];

      federationList.map((athlete: User) => {
        // Athletes that are in the tournament belong to one group, the rest to another
        let group = GetGroupName(t, 'FEDERATION', 3);
        const index: any = initialValues.fighters?.findIndex((elem: User) => elem._id === athlete._id);
        if(index >= 0) group = GetGroupName(t, 'IN_TOURNAMENT', 2);

        athletesList.push({ 
          _id: athlete._id, 
          name: athlete.name, 
          status: athlete.status,
          disabled: HasFight(athlete._id, bracket),
          group
        });
      });

      // We need to check if there are fighters in the tournament that don't exist in the Federation list, and add them
      if(initialValues.fighters.length > 0) {
        initialValues.fighters.forEach((fighter: User) => {
          const index = federationList.findIndex((elem: User) => elem._id === fighter._id);

          // If this fighter doesn't exist in the Federation, add him to the list
          if(index === -1) {
            athletesList.push({ 
              _id: fighter._id, 
              name: fighter.name, 
              status: fighter.status,
              disabled: HasFight(fighter._id, bracket),
              group: GetGroupName(t, 'EXTERNAL', 1)
            });
          }
        });
      }

      // We need to check each fight of the tournament to see if there are any fighters not in the tournament and not in the federation, and add them
      if(initialValues.fights.length > 0) {
        initialValues.fights.forEach((fight: TournamentFight) => {
          if(!!fight.fight?.red_fighter?._id) {
            const index = athletesList.findIndex((elem: User) => elem._id === fight.fight?.red_fighter?._id);

            if(index === -1) {
              athletesList.push({ 
                _id: fight.fight?.red_fighter?._id, 
                name: fight.fight?.red_fighter?.name, 
                status: fight.fight?.red_fighter?.status,
                disabled: HasFight(fight.fight?.red_fighter?._id, bracket),
                group: GetGroupName(t, 'EXTERNAL', 1)
              });
            }
          }

          if(!!fight.fight?.blue_fighter?._id) {
            const index = athletesList.findIndex((elem: User) => elem._id === fight.fight?.blue_fighter?._id);

            if(index === -1) {
              athletesList.push({ 
                _id: fight.fight?.blue_fighter?._id, 
                name: fight.fight?.blue_fighter?.name, 
                status: fight.fight?.blue_fighter?.status,
                disabled: HasFight(fight.fight?.blue_fighter?._id, bracket),
                group: GetGroupName(t, 'EXTERNAL', 1)
              });
            }
          }
        });
      }
    }

    setAthletes(athletesList);
  };

  const reloadInfo = async () => {
    setLoading(true);
    // Used to force fight list reload
    setReloadFightList(dayjs().format('YYYYMMDDHHmmss'));
    await getInfo();
  };

  const handleCloseModal = async (changed: boolean) => {
    if(changed) {
      setLoading(true);
      await getInfo();
    }
    setModalOpen(false);
  }

  // When selecting an Athlete, mark it as disabled. When removing a selected Athlete, mark it as enabled
  const checkAthlete = (value: string | null, oldValue: string | null, fight?: string, type?: 'red_fighter' | 'blue_fighter') => {
    const aux = [...athletes];
    toggleAthlete(value, oldValue, athletes, aux, setSearchModal, { fight, type }, true);
    setAthletes(aux);
  };

  const handleCloseSearchModal = () => {
    // On cancel remove "Search athlete" selected option
    const aux: SelectedFighters[] = [...selectedFighters];

    const index = aux.findIndex(elem => elem.fight === searchModal.fight);
    if(index >= 0 && !!searchModal.type) aux[index][searchModal.type] = undefined;

    setSelectedFighters(aux);
    setSearchModal({ open: false });
  };

  const handleSaveSearchModal = async (athlete?: User) => {
    if(!!athlete && searchModal.type) {
      const aux = [...athletes];
      const auxSelected: SelectedFighters[] = [...selectedFighters];

      // Add the new athlete (the first element must be the one to search athlete)
      aux.push({
        _id: athlete._id,
        name: athlete.name,
        status: athlete.status,
        group: GetGroupName(t, 'EXTERNAL', 1),
        disabled: true
      });

      // Find the fight in the array
      const valueIndex = auxSelected.findIndex(elem => elem.fight === searchModal.fight);

      // Save old value for later use
      const oldValue = valueIndex >= 0 ? auxSelected[valueIndex][searchModal.type] : '';

      // Select the new value
      auxSelected[valueIndex][searchModal.type] = athlete._id;

      // If there was already a selected fighter, enable it
      if(oldValue) {
        const index = aux.findIndex((athlete: User) => athlete._id === oldValue);
        if (index >= 0) aux[index].disabled = false;
      }

      setAthletes(aux);
      setSelectedFighters(auxSelected);
      setSearchModal({ open: false });
    }
  };

  let actions: HeaderBaseAction[] = [];
  if(userCanEditEvent) actions = [
    {
      type: 'button',
      variant: 'primary',
      text: t('EDIT_TOURNAMENT'),
      onClick: () => setModalOpen(true)
    }
  ];

  return (
    <>
      <LoadingOverlay visible={loading} />
      <PageHeader
        title={
          <Block display='flex' fAlign='center' fWrap='wrap'>
            <Typography variant='page-title' style={{ color: LabelColor }} mr={1.25} w={{ xs: 'auto', xxs: '100%' }}>{capitalizeString(t('TOURNAMENT'))}</Typography>
            <Block display='flex' fDirection='column' w={{ xs: 'auto', xxs: '100%' }} pt={{ xs: 0, xxs: 0.5 }}>
              <Typography variant='body-small' lHeight={1.063} style={{ color: PlaceholderColor }}>{initialValues?.name}</Typography>
              {
                initialValues?.start_datetime && initialValues?.end_datetime &&
                <Typography variant='body-small' lHeight={1.063} style={{ color: PlaceholderColor }} pt={0.3}>
                  {`${GetFightDate(initialValues?.start_datetime)} - ${GetFightDate(initialValues?.end_datetime)}`}
                </Typography>
              }
            </Block>
          </Block>
        }
        backLink={-1}
        actions={headerActions({
          hasSave: false,
          extra: actions
        })}
      />
      <Bracket 
        tournament={initialValues}
        bracket={bracket} 
        athletes={athletes}
        reloadInfo={reloadInfo}
        checkAthlete={checkAthlete}
        selectedFighters={selectedFighters}
        setSelectedFighters={setSelectedFighters}
        loadAthletes={loadAthletes}
      />
      <Block w='100%' mt={3}>
        <FightsList 
          event={event}
          tournamentID={id}
          reloadOnChange={reloadFightList}
          bracket={bracket} 
          reloadInfo={reloadInfo}
        />
      </Block>
      <ManageTournamentModal
        opened={modalOpen}
        initialValues={initialValues}
        onClose={handleCloseModal}
        eventID={event?._id}
        eventFederation={event?.federation?._id}
      />
      <SearchAthleteModal 
        opened={searchModal.open}
        onClose={handleCloseSearchModal}
        onSave={handleSaveSearchModal}
        athletes={athletes}
      />
    </>
  );
};

export default TournamentDetailPage;
