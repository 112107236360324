import * as yup from 'yup';
import { CustomField } from '../../../models/CustomField';
import { AnyObject } from '../../../models/Generic';

// TODO: finish type CHECKBOX validations, we need a new function in yup to validate if at least one of the checkbox's is selected
// This works but it's not injecting the error in checkbox meta, and only after this validation, it validates the rest of the fields
// Uncomment below to test checkbox validations

// yup.addMethod(yup.object, 'atLeastOneOf', function(list) {
//   return this.test({
//     name: 'atLeastOneOf',
//     message: '${path} must have at least one of these keys: ${keys}',
//     exclusive: true,
//     params: { keys: list.join(', ') },
//     test: value => value == null || list.some((f: string) => value[f] != null)
//   })
// });

export const GetFormValidations = (customFields?: CustomField[]) => {
  const validations: AnyObject = {};

  if(customFields && customFields.length > 0) {
    customFields.forEach((customField: CustomField) => {
      if(customField.required && !customField.showOnChange) {
        // if(customField.fieldType === 'CHECKBOX' && customField.fieldCheckboxOptions && customField.fieldCheckboxOptions.length > 0) {
        //   customField.fieldCheckboxOptions.forEach((option: FieldCheckboxType) => 
        //     validations[option.fieldName] = yup.boolean().required()
        //   );
        // }
        // else {
          let validationType = null;

          if(customField.fieldType === 'TEXT' || customField.fieldType === 'SELECT' || customField.fieldType === 'PHONE') validationType = yup.string().required();
          else if(customField.fieldType === 'WEIGHT' || customField.fieldType === 'HEIGHT') validationType = yup.number().required();
          else if(customField.fieldType === 'FILE' || customField.fieldType === 'PHOTO') validationType = yup.mixed().required();
          else if(customField.fieldType === 'DATE') validationType = yup.date().required();
          else if(customField.fieldType === 'EMAIL') validationType = yup.string().email().required();
          else if(customField.fieldType === 'MULTI-SELECT') validationType = yup.array().of(yup.string()).required();

          if(validationType) validations[customField.fieldName] = validationType;
        // }
      }
    });
  }

  // return yup.object(validations).atLeastOneOf(['JUDGE', 'REFEREE']);

  return yup.object(validations);
};
