import { useTranslation } from 'react-i18next';
import { FC, useState, useContext } from 'react';
import { Box, Block } from '../../../../styles/BasicStyles';
import { AnyObject } from '../../../../models/Generic';
import { FocusColor } from '../../../../styles/Colors';
import { Fight, DecisionList } from '../../../../models/Fight';
import { Tournament } from '../../../../models/Tournament';
import { FightTypes } from '../../../../utils/fights';
import { ADMIN, REGULATOR, RESPONSIBLE, SUPER_ADMIN, User } from '../../../../models/User';
import { InfoContext, UserContext } from '../../../../Context';
import FiltersModal from '../../../../components/filtersModal';
import SelectInput from '../../../../components/inputs/SelectInput';
import DropdownButton from '../../../../components/dropdownButton';
import DateInput from '../../../../components/inputs/DateInput';

interface FiltersProps {
  filters?: AnyObject;
  handleChange: (field: string | string[], value: string | string[] | null, time?: number) => void;
  setSingleFightModal: (obj: { open: boolean, initialValues?: Fight }) => void;
  setTournamentModal: (obj: { open: boolean, initialValues?: Tournament }) => void;
  athletes: User[];
  userCanEditEvent: boolean;
}

const StatusList = [
  { _id: 'COMPLETE', name: 'COMPLETE' },
  { _id: 'INCOMPLETE', name: 'INCOMPLETE' }
];

const Filters: FC<FiltersProps> = ({ filters, handleChange, setSingleFightModal, setTournamentModal, athletes, userCanEditEvent }) => {
  const [filtersModal, setFiltersModal] = useState<AnyObject>({ ...filters });
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);

  // When closing the modal without applying the filters, reload the filters saved in local storage
  const reloadStorageFilters = () => {
    setFiltersModal({ ...filters });
  }
  
  return (
    <Box fAlign='center' fJustify='flex-end'>
      <FiltersModal 
        handleChange={handleChange}
        filters={filtersModal}
        setFilters={setFiltersModal}
        reloadStorageFilters={reloadStorageFilters}
      >
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.type || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, type: v }})
            }}
            label={t('TYPE')}
            placeholder={t('CHOOSE_TYPE')}
            clearable
            data={FightTypes}
            translate
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.fighter || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, fighter: v }})
            }}
            label={t('FIGHTER')}
            placeholder={t('CHOOSE_FIGHTER')}
            clearable
            data={athletes}
          />
        </Block>
        <Block mb={0.5}>
          <DateInput
            input={{
              value: filtersModal?.start_datetime || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, start_datetime: v }})
            }}
            label={t('START_DATE')}
            placeholder={t('CHOOSE_DATE')}
            clearable
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: filtersModal?.weight_class || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, weight_class: v }})
            }}
            label={t('WEIGHT_CLASS')}
            placeholder={t('CHOOSE_CLASS')}
            clearable
            data={info?.weightClasses || []}
            intl
          />
        </Block>
        <Block mb={0.5}>
          <SelectInput
            input={{
              value: (filtersModal && filtersModal['result.decision']) || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, 'result.decision': v }})
            }}
            label={t('FIGHT_ENDING')}
            placeholder={t('CHOOSE_RESULT')}
            clearable
            data={DecisionList}
            translate
          />
        </Block>
        {
          (user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR || user?.type === RESPONSIBLE) &&
          <Block mb={0.5}>
            <SelectInput
              input={{
                value: filtersModal?.status || '',
              onChange: (v: string | null) => setFiltersModal((old) => { return { ...old, status: v }})
              }}
              label={t('STATUS')}
              placeholder={t('CHOOSE_STATUS')}
              clearable
              data={StatusList}
              translate
            />
          </Block>
        }
      </FiltersModal>
      {
        userCanEditEvent &&
        <Block ml={1}>
          <DropdownButton 
            icon='outlined_plus'
            text={t('ADD_NEW')}
            size='xs'
            bgColor={FocusColor}
            actions={[
              {
                type: 'link',
                label: t('SINGLE_FIGHT'),
                onClick: () => setSingleFightModal({ open: true })
              },
              {
                type: 'divider'
              },
              {
                type: 'link',
                label: t('TOURNAMENT'),
                onClick: () => setTournamentModal({ open: true })
              }
            ]}
          />
        </Block>
      }
    </Box>
  );
};

export default Filters;
