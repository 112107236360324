/* eslint-disable @typescript-eslint/no-explicit-any */
import { User, SUPER_ADMIN, ADMIN, REGULATOR, RESPONSIBLE, PROMOTER } from '../../models/User';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { Block, Box, Image } from '../../styles/BasicStyles';
import { LabelColor } from '../../styles/Colors';
import { showConfirm, showDelete, showSuccess } from '../../hooks/show-notification/show-notification';
import { Federation } from '../../models/Federation';
import { UserContext } from '../../Context';
import { GetEventDraftInitialValues } from '../../utils/modalValues';
import { Event, AWAITING_VALIDATION } from '../../models/Event';
import { GetEventDraftType } from '../../utils/events';
import { useParams, useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import DefaultListPage from '../../components/page/DefaultListPage';
import Icon from '../../components/icon';
import Typography from '../../components/typography';
import ManageEventModal from '../events/manage';
import DropdownMenu, { ActionProps } from '../../components/dropdownMenu';
import BaseTable from '../../components/table';
import EventDraftsFilters from './EventDraftsFilters';
import dayjs from 'dayjs';
import Label from '../../components/label';
import RejectEventModal from './RejectEventModal';

const EventDraftsListPage = DefaultListPage<Event>();
const EventDraftsTable = BaseTable<Event>();

const EventDraftsPage = () => {
  const [federations, setFederations] = useState<Federation[]>([]);
  const [promoters, setPromoters] = useState<User[]>([]);
  const [modal, setModal] = useState<{ open: boolean; initialValues?: Event; validateButton?: boolean; rejectButton?: boolean; }>({ open: false });
  const [rejectModal, setRejectModal] = useState<{ open: boolean; event?: Event; }>({ open: false });
  const { t } = useTranslation();
  const eventsHook = useFetch('event-drafts');
  const federationsHook = useFetch('/federations');
  const promotersHook = useFetch(`/admins/type/${PROMOTER}`);
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR) {
        const federationsResult = await federationsHook.get('/dropdown');
        setFederations(federationsResult?.data || []);
      }

      if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR || user?.type === RESPONSIBLE) {
        const promotersResult = await promotersHook.get();
        setPromoters(promotersResult?.data || []);
      }
    };

    init();
  }, []);

  // Open the modal with a specific event (for link in email)
  useEffect(() => {
    const init = async () => {
      const { success, data } = await eventsHook.get(`/${id}`);

      if(success) setModal({ open: true, initialValues: GetEventDraftInitialValues(data) });

      navigate('/events/draft', { replace: true });
    };

    if(id) init();
  }, [id]);

  return (
    <EventDraftsListPage
      translate={t}
      request={{
        get: eventsHook.get,
        searchFields: ['name']
      }}
      storage='EVENT_DRAFTS_FILTERS'
      header={{ title: t('EVENT_DRAFTS') }}
      filters={({ filters, handleChange }) => 
        <EventDraftsFilters 
          filters={filters} 
          handleChange={handleChange}
          federations={federations} 
          promoters={promoters}
        />
      }
      countLabel={`${t('EVENTS').toLowerCase()}`}
    >
      {({ rows, loading, pagination, handleNewSort, refreshPage }) => {
        const handleCloseModal = (changed: boolean) => {
          if (changed) refreshPage();
          setModal({ open: false });
          setRejectModal({ open: false });
        };

        const handleDelete = async (id: string) => {
          showDelete({
            title: t('DELETE_EVENT'),
            message: t('DELETE_EVENT_MESSAGE'),
            onConfirm: async () => {
              await eventsHook.del(`/${id}`);
              refreshPage();
            }
          });
        };

        const confirmValidate = (id?: string) => {
          if(id) {
            showConfirm({
              title: t('VALIDATE_EVENT'),
              message: t('VALIDATE_EVENT_MESSAGE'),
              onConfirm: async () => {
                const { success } = await eventsHook.put(`/${id}/validate`);

                if(success) {
                  refreshPage();
                  setModal({ open: false });

                  showSuccess({
                    title: t('EVENT_VALIDATED'),
                    message: t('EVENT_VALIDATED_MESSAGE'),
                    autoClose: 10000
                  });
                }
              }
            });
          }
        };

        const openRejectModal = async (event?: Event) => setRejectModal({ open: true, event });

        const openValidateModal = async (event: Event) => {
          if(user?.type === PROMOTER) {
            setModal({ open: true, initialValues: GetEventDraftInitialValues(event), validateButton: false, rejectButton: false })
          }
          else {
            setModal({ open: true, initialValues: GetEventDraftInitialValues(event), validateButton: true, rejectButton: event.status === AWAITING_VALIDATION });
          }
        };

        const columns = [
          { 
            title: t('NAME'), 
            dataIndex: 'name', 
            sortable: true,
            render: (value: string, event: Event) => (
              <Box fAlign='center'>
                <Block bgColor='#33353E' bRadius={0.25} display='flex' fAlign='center' fJustify='center' w={2} h={2} mr={0.75}>
                  {
                    event.photo?.url ?
                    <Image src={event.photo.url} alt='event logo' w={2} h={2} />
                    :
                    <Icon icon='outlined_calendar' size={1.125} color='rgba(255, 255, 255, 0.1)' />
                  }
                </Block>
                <Block>
                  <Typography as='div' variant='dropdown-menu' style={{ color: LabelColor }}>{value}</Typography>
                </Block>
              </Box>
            )
          },
          {
            title: t('START_DATE'),
            dataIndex: 'start_datetime',
            sortable: true,
            render: (_: any, event: Event) => 
              <Typography as='div' variant='dropdown-menu-small' style={{ color: LabelColor }}>
                {dayjs(event.start_datetime).format('DD MMM, YYYY HH:mm')}
              </Typography>
          },
          {
            title: t('LOCATION'),
            key: 'location',
            render: (_: any, event: Event) => 
              <Typography variant='dropdown-menu-small' style={{ color: LabelColor }}>
                {
                  event?.location && event?.country ?
                  `${event.location}, ${event.country.name}`
                  :
                  event?.location ?
                  event.location
                  :
                  event?.country ?
                  event.country.name
                  :
                  ''
                }
              </Typography>
          },
          {
            title: t('STATUS'),
            key: 'status',
            render: (_: any, event: Event) => {
              const status = GetEventDraftType(event.status);

              return (
                <Label 
                  text={t(status?.name)} 
                  fontColor={status?.fontColor} 
                  bgColor={status?.bgColor} 
                />
              );
            }
          },
          { 
            title: t('MORE'),
            key: 'actions', 
            render: (event: Event ) => {
              const options: ActionProps[] = [
                {
                  type: 'link',
                  label: t('EDIT'),
                  onClick: () => setModal({ open: true, initialValues: GetEventDraftInitialValues(event), validateButton: false, rejectButton: false })
                },
                {
                  type: 'divider'
                },
                {
                  type: 'link',
                  label: t('DELETE'),
                  onClick: () => handleDelete(event._id)
                }
              ];

              // Show option to validate/reject event drafts
              if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR || user?.type === RESPONSIBLE) {
                options.unshift(
                  {
                    type: 'link',
                    label: t('VALIDATE_EVENT'),
                    onClick: () => openValidateModal(event)
                  },
                  {
                    type: 'divider'
                  }
                );
              }

              return (
                <DropdownMenu 
                  render={<Icon icon='outlined_dotsHorizontal' color={LabelColor} />}
                  menuPosition='bottom-end'
                  actions={options} 
                />
              );
            }
          }
        ];

        // ShowPromoter column for Admin or Responsible users
        if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR || user?.type === RESPONSIBLE) {
          columns.splice(1, 0, {
            title: t('PROMOTER'),
            dataIndex: 'promoter',
            sortable: true,
            render: (_: any, event: Event) => <Typography variant='table-header'>{event.promoter?.name}</Typography>
          });
        }

        // Show Federation column for Admin users
        if(user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR) {
          columns.splice(2, 0, {
            title: t('FEDERATION'),
            dataIndex: 'federation',
            sortable: true,
            render: (_: any, event: Event) => <Typography variant='table-header'>{event.federation?.name}</Typography>
          });
        }

        return (
          <>
            <EventDraftsTable
              columns={columns}
              rows={rows}
              loading={loading}
              defaultSort={pagination.sort}
              sortCallback={handleNewSort}
              onRowClick={(row) => openValidateModal(row)}
            />
            <ManageEventModal
              opened={modal.open}
              initialValues={modal.initialValues}
              onClose={handleCloseModal}
              isDraftEvent={true}
              showValidateButton={modal.validateButton}
              showRejectButton={modal.rejectButton}
              openRejectModal={openRejectModal}
              confirmValidate={confirmValidate}
              validateLoading={eventsHook.loading}
            />
            <RejectEventModal
              opened={rejectModal.open}
              event={rejectModal.event}
              onClose={handleCloseModal}
            />
          </>
        );
      }}
    </EventDraftsListPage>
  );
};

export default EventDraftsPage;
