import { breakpoints } from '../../styles/Breakpoints';
import { Modal } from '@mantine/core';
import styled, { css } from 'styled-components';

export const ModalWrapper = styled(Modal)`
  z-index: 999999;
  
  & .mantine-Modal-inner {
    overflow: hidden;
    padding: 1rem 0.625rem;
    
    & .mantine-Modal-modal {
      background-color: ${({ theme }) => theme.colors.bgColor};
      border-radius: 0.5rem;
      padding: 0;
      width: 100%;
      max-width: ${({ size }) => size || 37.5}rem;
    }
  }
`;

export const TitleWrapper = styled.div<{ hasSubtitle: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.menuBgColor};
  border-bottom: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
  padding: 1.5rem 1.25rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;

  ${({ hasSubtitle }) => hasSubtitle &&
    css`
      flex-wrap: wrap;

      @media ${breakpoints.xs} {
        justify-content: flex-start;

        & .title, & .subtitle {
          width: 100%;
        }

        & .title {
          margin-bottom: 0.5rem;
        }
      }
    `
  }

  @media ${breakpoints.md} {
    padding: 1.5rem 1.25rem;
  }

  @media ${breakpoints.sm} {
    padding: 1rem 1.25rem;
  }

  @media ${breakpoints.xs} {
    padding: 0.75rem 1.25rem;
  }
`;

export const OverflowWrapper = styled.div` 
  max-height: 70vh;
  overflow-y: auto;

  @media (max-height: 37.5rem) {
    max-height: 60vh;
  }
`;

export const ContentWrapper = styled.div` 
  padding: 1.25rem;
`;

export const ActionsWrapper = styled.div` 
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.menuBgColor};
  border-top: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
  padding: 1.063rem 1rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;

  & button {
    margin-left: 0.75rem;
  }

  @media ${breakpoints.xs} {
    padding: 0.5rem 1rem;
  }
`;
