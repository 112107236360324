import { Grid } from '@mantine/core';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { SUPER_ADMIN, ADMIN, REGULATOR } from '../../../../models/User';
import { AspectRatios } from '../../../../utils/files/Conversions';
import { OfficialRoles } from '../../../../utils/officialRoles';
import { Federation } from '../../../../models/Federation';
import { FC, useContext } from 'react';
import { InfoContext, UserContext } from '../../../../Context';
import { ModalContent } from '../../../../components/modal';
import { Club } from '../../../../models/Club';
import { AnyObject } from '../../../../models/Generic';
import { Languages } from '../../../../utils/languages/AvailableTranslations';
import TextInput from '../../../../components/inputs/TextInput';
import ImageInput from '../../../../components/inputs/ImageInput';
import PhoneInput from '../../../../components/inputs/PhoneInput';
import DateInput from '../../../../components/inputs/DateInput';
import SelectInput from '../../../../components/inputs/SelectInput';
import MultiSelectInput from '../../../../components/inputs/MultiSelectInput';
import TextAreaInput from '../../../../components/inputs/TextAreaInput';

interface Params {
  federations: Federation[];
  clubs: Club[];
  resetClub: (...args: AnyObject[]) => void;
  disableFields?: boolean;
}

const FormFields: FC<Params> = ({ federations, clubs, resetClub, disableFields = false }) => {
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);

  return (
    <ModalContent>
      <Grid gutter="xl">
        <Grid.Col xs={12}>
          <Field name="photo">
            {(props) => (
              <ImageInput 
                {...props} 
                ratio={AspectRatios['1:1']} 
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="name">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('FULL_NAME')} *`}
                placeholder={t('ENTER_NAME')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="birthday">
            {(props) => (
              <DateInput
                {...props}
                label={`${t('BIRTH_DATE')} *`}
                placeholder={t('CHOOSE_DATE')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="gender">
            {(props) => (
              <SelectInput
                {...props}
                label={t('GENDER')}
                placeholder={t('CHOOSE_GENDER')}
                clearable
                data={info?.genders || []}
                translate
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="nationality">
            {(props) => (
              <TextInput
                {...props}
                label={t('NATIONALITY')}
                placeholder={t('ENTER_NATIONALITY')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="address">
            {(props) => (
              <TextInput
                {...props}
                label={t('ADDRESS')}
                placeholder={t('ENTER_ADDRESS')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="postal_code">
            {(props) => (
              <TextInput
                {...props}
                label={t('POSTAL_CODE')}
                placeholder={t('ENTER_POSTAL_CODE')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="city">
            {(props) => (
              <TextInput
                {...props}
                label={t('CITY')}
                placeholder={t('ENTER_CITY')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="country">
            {(props) => (
              <SelectInput
                {...props}
                label={t('COUNTRY')}
                placeholder={t('CHOOSE_COUNTRY')}
                clearable
                data={info?.countries || []}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="email">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('EMAIL')} *`}
                htmlType="email"
                placeholder={t('ENTER_EMAIL')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="phone">
            {(props) => (
              <PhoneInput
                {...props}
                label={t('PHONE_NUMBER')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      {
        (user?.type === SUPER_ADMIN || user?.type === ADMIN || user?.type === REGULATOR) &&
        <Grid gutter="xl">
          <Grid.Col xs={12}>
            <Field name="federation">
              {(props) => (
                <SelectInput
                  {...props}
                  label={`${t('FEDERATION')} *`}
                  placeholder={t('CHOOSE_FEDERATION')}
                  clearable
                  data={federations}
                  afterChange={() => resetClub()}
                  disabled={disableFields}
                />
              )}
            </Field>
          </Grid.Col>
        </Grid>
      }
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="weight">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('WEIGHT')} (kg)`}
                placeholder={t('ENTER_WEIGHT')}
                htmlType="number"
                min={0}
                step={0.01}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="height">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('HEIGHT')} (cm)`}
                placeholder={t('ENTER_HEIGHT')}
                htmlType="number"
                min={0}
                step={0.1}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="martial_arts">
            {(props) => (
              <MultiSelectInput
                {...props}
                label={t('MARTIAL_ARTS')}
                placeholder={t('CHOOSE_MARTIALS_ARTS')}
                clearable
                data={info?.martialArts || []}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="class">
            {(props) => 
              <MultiSelectInput
                {...props}
                label={t('RATING_CLASS')}
                placeholder={t('CHOOSE_CLASS')}
                clearable
                data={info?.evaluationClasses || []}
                intl
                disabled={disableFields}
              />
            }
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="club">
            {(props) => (
              <SelectInput
                {...props}
                label={t('CLUB')}
                placeholder={t('CHOOSE_CLUB')}
                clearable
                data={clubs}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <Field name="weight_class">
            {(props) => (
              <SelectInput
                {...props}
                label={t('WEIGHT_CLASS')}
                placeholder={t('CHOOSE_CLASS')}
                clearable
                intl
                data={info?.weightClasses || []}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12}>
          <Field name="roles">
            {(props) => (
              <MultiSelectInput
                {...props}
                label={t('POSSIBLE_ROLES')}
                placeholder={t('CHOOSE_ROLES')}
                clearable
                data={OfficialRoles}
                translate
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12}>
          <Field name="certifications">
            {(props) => (
              <TextInput
                {...props}
                label={t('CERTIFICATIONS')}
                placeholder={t('ENTER_CERTIFICATIONS')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12}>
          <Field name="info">
            {(props) => (
              <TextAreaInput
                {...props}
                label={t('ADDITIONAL_INFO')}
                minRows={3}
                autosize
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={6}>
          <Field name="language">
            {(props) => (
              <SelectInput
                {...props}
                label={t('LANGUAGE')}
                placeholder={t('CHOOSE_LANGUAGE')}
                clearable
                data={Languages || []}
                keyValue='code'
                translate
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
    </ModalContent>
  );
};

export default FormFields;
