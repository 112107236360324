import { Textarea, TextareaProps } from '@mantine/core';
import { FieldRenderProps } from 'react-final-form';
import { GenericInput, InputSize } from './Generic';
import { InputWrapper, ErrorMessage, CounterSection } from './Styles';
import { useTranslation } from 'react-i18next';
import { DangerColor } from '../../styles/Colors';
import React from 'react';
import Icon from '../icon';

export interface TextAreaInputProps
  extends Omit<FieldRenderProps<string>, 'input'>,
    TextareaProps {
  afterChange?: (value: string) => void;
  input: GenericInput<string>;
  maxChar?: number;
  size?: InputSize;
  hasActionButtons?: boolean;
}

export const TextAreaInput: React.FC<TextAreaInputProps> = ({
  input,
  meta,
  label,
  placeholder,
  description,
  icon,
  rightSection,
  afterChange,
  autosize = true,
  disabled,
  variant,
  maxRows,
  minRows = 4,
  required,
  maxChar,
  size = 'md',
  hasActionButtons = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const value = event.currentTarget.value;
    input.onChange(value);
    if (afterChange && typeof afterChange === 'function') {
      afterChange(value);
    }
  };

  const length = input.value ? input.value.length : 0;
  const missingField = meta?.invalid && meta?.submitFailed;
  const hasError = missingField || (maxChar && length > maxChar);
  const rightSectionError = hasError ? (
    <Icon icon="bold_xCircle" color={DangerColor} size={1.25} />
  ) : (
    ''
  );

  return (
    <InputWrapper
      size={size}
      hasError={hasError}
      hasLeftIcon={!!icon}
      hasRightIcon={!!rightSection}
      hasActionButtons={hasActionButtons}
    >
      <Textarea
        {...rest}
        label={label}
        placeholder={placeholder}
        description={description}
        disabled={disabled}
        icon={icon}
        rightSection={rightSection || rightSectionError}
        autosize={autosize}
        variant={variant}
        maxRows={maxRows}
        minRows={minRows}
        value={input.value}
        onChange={handleChange}
        error={hasError}
        required={required}
      />
      {missingField ? (
        <ErrorMessage>
          {meta.error
            ? t(meta?.error?.tag, meta?.error?.values)
            : t('FIELD_REQUIRED')}
        </ErrorMessage>
      ) : maxChar ? (
        <CounterSection hasError={hasError}>
          {length}/{maxChar}
        </CounterSection>
      ) : null}
    </InputWrapper>
  );
};

export default TextAreaInput;
