/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../hooks/use-validation/use-validation-schema';
import { BasicForm, Block } from '../../../../styles/BasicStyles';
import { FC, useContext, useEffect, useState } from 'react';
import { Fight, Pause } from '../../../../models/Fight';
import { showConfirm, showSuccess } from '../../../../hooks/show-notification/show-notification';
import { MillisecondsToDate } from '../../../../utils/dates';
import { InfoContext } from '../../../../Context';
import { AnyObject } from '../../../../models/Generic';
import Divider from '../../../../components/divider';
import NewPause from './NewPause';
import Modal, { ModalActions, ModalOverflowContent } from '../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../components/button';
import PauseItem from '../../PauseItem';

interface Params {
  opened: boolean;
  onClose: () => void;
  initialValues?: any;
  fight?: Fight;
  reloadInfo: () => void;
}

export interface FormInitialValues {
  pauses: Pause[];
}

const { Form } = withTypes<FormInitialValues>();

const EditPausesModal: FC<Params> = ({ opened, onClose, initialValues, fight, reloadInfo }) => {
  const { t, i18n: { language } } = useTranslation();
  const { info } = useContext(InfoContext);
  const { put } = useFetch('/fights');
  const [pausesList, setPausesList] = useState<Pause[]>([]);
  const [editItem, setEditItem] = useState<number | undefined>(undefined);
  const [formPristine, setFormPristine] = useState<boolean>(true);
  
  useEffect(() => {
    setPausesList(getInitialValues());
    setEditItem(undefined);
    setFormPristine(true);
  }, [opened, initialValues]);

  const onSubmit = async (values: FormInitialValues) => {
    const { success } = await put(`/${fight?._id}/pauses`, values);

    if(success) {
      showSuccess({
        title: t('SUCCESS'),
        message: t('FIGHT_CHANGE_SUCCESS')
      });

      onClose();
      reloadInfo();
    }
  };

  const getInitialValues = () => {
    const list: Pause[] = [];
    fight?.record?.pauses.forEach((pause: AnyObject) => {
      list.push({
        round: pause.round,
        time: pause.time,
        start: pause.start,
        startTime: MillisecondsToDate(pause.start || 0),
        duration: MillisecondsToDate(pause.time || 0),
        reason: pause.reason?._id,
        reasonName: pause.reason?._id && pause.reason?.name ? pause.reason.name[language] : ''
      });
    });

    return list;
  };

  const reasonsList = info?.submissions?.filter((elem: AnyObject) => elem.type === 'ACCIDENTAL' || elem.type === 'FIGHTER_FAULT' || elem.type === 'ILLEGAL_BLOW') || [];

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          pauses: yup.array().of(yup.object({
            round: yup.number().required(),
            startTime: yup.date().required(),
            duration: yup.date().required()
          }))
        })
      )}
      initialValues={{ pauses: pausesList } || {}}
      render={({ handleSubmit, submitting, form: { reset, submit } }) => {
        const handleClose = () => {
          if(!formPristine) {
            showConfirm({
              title: t('EXIT_BEFORE_SAVE'),
              message: t('EXIT_BEFORE_SAVE_MESSAGE'),
              onConfirm: () => {
                onClose();
                reset();
              }
            });
          }
          else {
            onClose();
            reset();
          }
        };

        const handleSave = () => {
          showConfirm({
            title: t('CONFIRM_BEFORE_SAVE'),
            message: t('CONFIRM_BEFORE_SAVE_FIGHT'),
            onConfirm: () => submit()
          });
        }

        return (
          <Modal
            size={38}
            opened={opened}
            onClose={handleClose}
            title={t('VIEW_EDIT_PAUSES')}
          >
            <BasicForm onSubmit={handleSubmit}>
              <ModalOverflowContent>
                <NewPause 
                  reasonsList={reasonsList}
                  pausesList={pausesList}
                  setPausesList={setPausesList}
                  setFormPristine={setFormPristine}
                />
                <Divider />
                {
                  pausesList.length > 0 &&
                  pausesList.map((elem: Pause, index: number) =>
                    <Block w='100%' key={index}>
                      <PauseItem 
                        pause={elem} 
                        index={index} 
                        reasonsList={reasonsList}
                        editItem={editItem}
                        setEditItem={setEditItem}
                        pausesList={pausesList}
                        setPausesList={setPausesList}
                        setFormPristine={setFormPristine}
                      />
                    </Block>
                  )
                }
              </ModalOverflowContent>
              <ModalActions>
                <Button
                  text={t('CANCEL')}
                  variant="secondary"
                  onClick={handleClose}
                />
                <Button
                  text={t('SAVE')}
                  loading={submitting}
                  disabled={formPristine}
                  onClick={handleSave}
                />
              </ModalActions>
            </BasicForm>
          </Modal>
        );
      }}
    />
  );
};

export default EditPausesModal;
