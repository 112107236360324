import { Box, Block } from '../../../styles/BasicStyles';
import { LabelColor, FocusColor, RedFighter, BlueFighter, PlaceholderColor } from '../../../styles/Colors';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { REJECTED, REVIEWED, Review, ReviewStatusList, consistencyTreshold } from '../../../models/Review';
import Typography from '../../../components/typography';
import UserCard from '../../../components/userCard';
import Consistency from '../../../components/consistency';
import LabelDropdown from '../../../components/labelDropdown';
import dayjs from 'dayjs';

type TitleProps = {
  row: Review,
  onConfirmStatusToogle: (id: string, status: string) => void,
  isFightDetailPage?: boolean
};

const Title: FC<TitleProps> = ({ row, onConfirmStatusToogle, isFightDetailPage }) => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const enterDetailPage = () => navigate(`/fights/${row.fight?._id}`);

  const renderReviewedBy = () => {
    let status = '';
    if(row.status === REJECTED) status = t('REJECTED');
    else if(row.status === REVIEWED) status = t('REVIEWED');

    return (
      <Typography mt={0.25} variant='sidebar-group' style={{ color: PlaceholderColor }} tAlign='center'>{`${status} ${t('ON')} ${dayjs(row.last_review?.date).format('DD/MM/YYYY')} ${t('BY')} ${row.last_review?.name}`}</Typography>
    );
  };

  const renderDesktopTitle = () => {
    return (
      <Box fAlign='center' display={{ sm: 'flex', xxs: 'none' }}>
        <Block padding='0 0.5 0 0' w='15%' onClick={enterDetailPage}>
          <Typography variant='dropdown-menu-small' style={{ color: FocusColor }}>{row.fight?.event?.name}</Typography>
        </Block>
        <Block padding='0 0.5' w='15%' onClick={enterDetailPage}>
          <Typography as='div' variant='dropdown-menu-small' style={{ color: LabelColor }}>{t(row.fight?.result?.decision || '')}</Typography>
          {
            !!row.fight?.result?.submission?.name &&
            <Typography as='div' pt={0.25} variant='dropdown-menu-small' style={{ color: PlaceholderColor }}>{row.fight?.result?.submission?.name[language]}</Typography>
          }
        </Block>
        <Block padding='0 0.5' w='20%' onClick={enterDetailPage}>
          <UserCard user={row.fight?.red_fighter} noStatus borderColor={RedFighter} center />
        </Block>
        <Block padding='0 0.5' w='20%' onClick={enterDetailPage}>
          <UserCard user={row.fight?.blue_fighter} noStatus borderColor={BlueFighter} center />
        </Block>
        <Block margin='0 0.5' w='9%' onClick={enterDetailPage}>
          <Consistency value={row.consistency} />
        </Block>
        <Block margin='0 0 0 0.5' w='18%' display='flex' fAlign='center' fDirection='column' onClick={enterDetailPage}>
          {
            (!isFightDetailPage || (isFightDetailPage && row.consistency < consistencyTreshold)) &&
            <>
              <LabelDropdown
                status={row.status}
                list={ReviewStatusList}
                id={row._id}
                onConfirmStatusToogle={onConfirmStatusToogle}
                disabled={row.status === REJECTED || row.status === REVIEWED}
              />
              {
                !!row.last_review?.name && !!row.last_review?.date && 
                renderReviewedBy()
              }
            </>
          }
        </Block>
      </Box>
    );
  };

  const renderMobileTitle = () => {
    return (
      <Box padding='0 0.5 0 0' fDirection='column' display={{ sm: 'none', xxs: 'flex' }} onClick={enterDetailPage}>
        <Typography variant='dropdown-menu-small' pb={0.5} style={{ color: FocusColor }}>{row.fight?.event?.name}</Typography>
        <Typography variant='dropdown-menu-small' pb={0.5} style={{ color: LabelColor }}>
          {t(row.fight?.result?.decision || '')}
          {
            !!row.fight?.result?.submission?.name &&
            <span style={{ paddingLeft: '0.25rem', color: PlaceholderColor }}>({row.fight?.result?.submission?.name[language]})</span>
          }
        </Typography>
        <Block pb={0.5}>
          <UserCard user={row.fight?.red_fighter} noStatus borderColor={RedFighter} />
        </Block>
        <Block pb={0.5}>
          <UserCard user={row.fight?.blue_fighter} noStatus borderColor={BlueFighter} />
        </Block>
        <Block pb={0.5}>
          <Consistency value={row.consistency} />
        </Block>
        <Block display='flex' fDirection='column'>
          <LabelDropdown
            status={row.status}
            list={ReviewStatusList}
            id={row._id}
            onConfirmStatusToogle={onConfirmStatusToogle}
            disabled={row.status === REJECTED || row.status === REVIEWED}
          />
          {
            !!row.last_review?.name && !!row.last_review?.date && 
            renderReviewedBy()
          }
        </Block>
      </Box>
    );
  };

  return (
    <>
      {renderDesktopTitle()}
      {renderMobileTitle()}
    </>
  );
};

export default Title;