/* eslint-disable react/display-name */
import { FC, forwardRef } from 'react';
import { Fight } from '../../models/Fight';
import { Tooltip } from '@mantine/core';
import { DangerColor, SubMenuColor, SuccessColor } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import { Block } from '../../styles/BasicStyles';
import Icon from '../icon';
import Typography from '../typography';

interface Params {
  fight: Fight;
}

const Status = forwardRef<HTMLDivElement, { errors: string[] }>((props, ref) => (
  <div ref={ref} {...props}>
    <Icon icon={props.errors.length > 0 ? 'outlined_xCircle' : 'outlined_checkCircle'} color={props.errors.length > 0 ? DangerColor : SuccessColor} size={1.5} />
  </div>
));

const FightStatus: FC<Params> = ({ fight }) => {
  const { t } = useTranslation();

  const errors = [];
  if(!fight.white_judge) errors.push('WHITE_JUDGE');
  if(!fight.green_judge) errors.push('GREEN_JUDGE');
  if(!fight.yellow_judge) errors.push('YELLOW_JUDGE');
  if(!fight.referee) errors.push('REFEREE');
  if(!fight.referee2) errors.push('SECOND_REFEREE');

  const renderLabel = (status: string, errors: string[]) => {
    return (
      <>
        <Typography as='div' variant='sidebar-group' lHeight={0.875}>{t(status)}</Typography>
        {
          errors.length > 0 && 
          <Block pt={0.3}>
            {
              errors.map((error: string, index: number) =>
                <Typography as='div' key={index} variant='sidebar-group' lHeight={0.875} style={{ color: SubMenuColor }} pt={0.2}>{t(error)}</Typography>
              )
            }
          </Block>
        }
      </>
    );
  };

  return (
    <Tooltip label={renderLabel(errors.length > 0 ? 'INCOMPLETE' : 'COMPLETE', errors)} style={{ position: 'fixed' }}>
      <Status errors={errors} />
    </Tooltip>
  );
};

export default FightStatus;
