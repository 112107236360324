import { useTranslation } from 'react-i18next';
import { Option, OptionName } from '../styles';
import { SidebarOptionProps } from '../options';
import { Box } from '../../../styles/BasicStyles';

interface ItemProps {
  option: SidebarOptionProps;
  isSubMenu: boolean;
}

const Item: React.FC<ItemProps> = ({ option, isSubMenu }) => {
  const { t } = useTranslation();

  return (
    <Option to={option.url || '/'} end={option.url === '/'} isSubMenu={isSubMenu}>
      <Box fAlign="center">
        {option.icon}
        <OptionName
          variant='sidebar-item'
          isSubMenu={isSubMenu}
          hasIcon={!!option.icon}
        >
          {t(option.name || '')}
        </OptionName>
      </Box>
    </Option>
  );
};

export default Item;
