import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { showDelete } from '../../hooks/show-notification/show-notification';
import { ButtonVariants, ButtonSizes, ButtonTypes } from '../button';
import { DangerColor } from '../../styles/Colors';

export interface HeaderBaseAction {
  text: string;
  onClick?: () => void;
  color?: string;
  type?: ButtonTypes;
  loading?: boolean;
  disabled?: boolean;
  variant?: ButtonVariants | string;
  size?: ButtonSizes;
  custom?: React.ReactNode;
}

interface ActionProps {
  submitting?: boolean;
  pristine?: boolean;
  hasSave?: boolean;
  onDelete?: (() => Promise<void>) | (() => void);
  deleteTitle?: string;
  deleteMessage?: string;
  backLink?: string;
  extra?: HeaderBaseAction[];
}

type HeaderActionsFunction = (props: ActionProps) => HeaderBaseAction[];

const headerActions: HeaderActionsFunction = ({
  submitting,
  pristine,
  hasSave = true,
  onDelete,
  deleteTitle = 'DELETE_ENTITY',
  deleteMessage = 'DELETE_ENTITY_MESSAGE',
  backLink,
  extra = []
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const base: HeaderBaseAction[] = [...extra];

  if(hasSave) {
    base.unshift({
      text: t('SAVE'),
      type: 'submit',
      loading: submitting,
      disabled: pristine
    });
  }

  if(!!onDelete) {
    base.unshift({
      text: t('DELETE'),
      variant: DangerColor,
      onClick: () =>
        showDelete({
          title: t(deleteTitle),
          message: t(deleteMessage),
          onConfirm: onDelete
        })
    });
  }

  if(!!backLink) {
    base.unshift({
      text: t('BACK'),
      onClick: () => navigate(backLink),
      variant: 'secondary'
    });
  }

  return base;
};

export default headerActions;
