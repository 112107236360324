import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Field, withTypes } from 'react-final-form';
import { DynamicPage } from '../../models/DynamicPage';
import { BasicForm } from '../../styles/BasicStyles';
import { useState, useEffect } from 'react';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import LoadingOverlay from '../../components/loadingOverlay';
import PageHeader from '../../components/page/PageHeader';
import TranslatableFields from '../../components/inputs/TranslatableFields';
import TextInput from '../../components/inputs/TextInput';

const { Form } = withTypes<DynamicPage>();

const ManageDynamicPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<DynamicPage>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const intlObject = useIntlValidation();

  const { get, put } = useFetch('/dynamic-pages');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: DynamicPage) => {
    if(id) {
      const { success } = await put(`/${values._id}`, values);
      if (success) navigate('/dynamic-pages');
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues || {}}
      validate={useValidationSchema(
        yup.object({
          title: intlObject({ required: true }),
          content: intlObject({ required: true, html: true })
        })
      )}
    >
      {({ handleSubmit, submitting, pristine }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <PageHeader
            title={t('EDIT_DYNAMIC_PAGE')}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/dynamic-pages'
            })}
          />
          <Grid gutter="xl">
            <Grid.Col xs={12}>
              <Field name="tag">
                {(props) => (
                  <TextInput {...props} label={t('TAG')} disabled />
                )}
              </Field>
            </Grid.Col>
          </Grid>
          <Grid gutter="xl">
            <Grid.Col xs={12}>
              <TranslatableFields
                fields={[
                  {
                    label: 'TITLE',
                    name: 'title',
                    type: 'text'
                  },
                  { 
                    label: 'CONTENT', 
                    name: 'content', 
                    type: 'richtext' 
                  }
                ]}
              />
            </Grid.Col>
          </Grid>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageDynamicPage;
