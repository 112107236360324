import { FC, ReactNode } from 'react';
import { LabelWrapper, LabelContent } from './styles';
import { DisabledColor, MenuBgColor } from '../../styles/Colors';
import Typography from '../typography';
import Icon, { IconTypes } from '../icon';

export type LabelSize = 'xs' | 'md' | undefined;

export interface LabelProps {
  text: string | ReactNode;
  bgColor?: string;
  fontColor?: string;
  size?: LabelSize;
  bRadius?: string;
  fSize?: number;
  lHeight?: number;
  icon?: IconTypes;
  iconColor?: string;
}

export const Label: FC<LabelProps> = ({ 
  text, 
  bgColor = DisabledColor, 
  fontColor = MenuBgColor, 
  size = 'md', 
  bRadius = '6.25rem', 
  fSize = 0.75,
  lHeight= 1.063,
  icon,
  iconColor
}) => {
  return (
    <LabelWrapper>
      <LabelContent bRadius={bRadius} bgColor={bgColor} size={size}>
        {
          !!icon &&
          <Icon icon={icon} color={iconColor} style={{ paddingRight: '0.25rem' }} />
        }
        <Typography 
          fSize={fSize} 
          lHeight={lHeight} 
          fWeight={500} 
          style={{ color: fontColor }}
          tAlign='center'
        >
          {text}
        </Typography>
      </LabelContent>
    </LabelWrapper>
  );
};

export default Label;
