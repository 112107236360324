import { User, RESPONSIBLE } from '../../../models/User';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Block, Box } from '../../../styles/BasicStyles';
import { LabelColor, SubMenuColor, FocusColor } from '../../../styles/Colors';
import { useDidUpdate } from '@mantine/hooks';
import { AnyObject } from '../../../components/inputs/Generic';
import { TableSortProps } from '../../../components/table';
import { PaginationObject } from '../../../components/page/DefaultListPage';
import { Federation } from '../../../models/Federation';
import useFetch from 'use-http';
import Icon from '../../../components/icon';
import Typography from '../../../components/typography';
import ManageFederationModal from './ManageFederationModal';
import DropdownMenu from '../../../components/dropdownMenu';
import BaseTable from '../../../components/table';
import ConstructPaginationRequest from '../../../utils/requests/ConstructPaginationRequest';
import PageFilters from '../../../components/page/PageFilters';
import Button from '../../../components/button';
import Filters from './Filters';

const FederationsTable = BaseTable<Federation>();

const FederationsPage = () => {
  const [responsibles, setResponsibles] = useState<User[]>([]);
  const [modal, setModal] = useState<{
    open: boolean;
    initialValues?: Federation;
  }>({ open: false });
  const [pagination, setPagination] = useState<PaginationObject>({
    page: 1,
    limit: 20
  });
  const [rows, setRows] = useState<Federation[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const { t } = useTranslation();
  const { get } = useFetch('/federations');
  const responsiblesHook = useFetch(`/admins/type/${RESPONSIBLE}`);

  useEffect(() => {
    const init = async () => {
      const defaultPagination: PaginationObject = {
        page: 1,
        limit: 20
      };

      const json = localStorage.getItem('FEDERATIONS_FILTERS');
      const obj = JSON.parse(json || '{}');
      if (obj.page) defaultPagination.page = obj.page;
      if (obj.limit) defaultPagination.limit = obj.limit;
      if (obj.filters) defaultPagination.filters = obj.filters;
      if (obj.sort) defaultPagination.sort = obj.sort;

      setPagination(defaultPagination);

      const { data } = await responsiblesHook.get();
      setResponsibles(data);

      setReady(true);
    };
    init();
  }, []);

  useDidUpdate(() => {
    const init = async () => {
      setLoading(true);

      const url = ConstructPaginationRequest(
        `/${pagination.page}/${pagination.limit}`,
        pagination.filters,
        pagination.sort,
        ['name', 'phone', 'email', 'address', 'postal_code', 'city']
      );

      const result = await get(url);

      if (pagination.page > 1 && (result?.data?.items || []).length === 0) {
        const pag = { ...pagination, page: 1 };
        handleNewPagination(pag);
      } else {
        setRows(result?.data?.items || []);
        setTotal(result?.data?.total || 0);
        setLoading(false);
      }
    };
    init();
  }, [pagination]);

  const handleNewPagination = (pag: PaginationObject) => {
    localStorage.setItem('FEDERATIONS_FILTERS', JSON.stringify(pag));
    setPagination(pag);
  };

  const handleNewPage = (page: number) => {
    const pag = { ...pagination, page };
    handleNewPagination(pag);
  };

  const handleNewSort = (sort: TableSortProps) => {
    const pag = { ...pagination, sort };
    handleNewPagination(pag);
  };

  const handleNewFilters = (filters: AnyObject) => {
    const pag = { ...pagination, page: 1, filters };
    handleNewPagination(pag);
  };

  const refreshPage = (params?: PaginationObject) => {
    const pag = params ? { ...params } : { ...pagination };
    handleNewPagination(pag);
  };

  const handleCloseModal = (changed: boolean) => {
    if (changed) refreshPage();
    setModal({ open: false });
  };

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      sortable: true
    },
    {
      title: t('RESPONSIBLE'),
      dataIndex: 'responsible',
      sortable: true,
      render: (value: User) => (
        <Typography variant="table-header">{value?.name}</Typography>
      )
    },
    {
      title: t('COUNTRY'),
      dataIndex: 'country',
      sortable: true,
      render: (value: AnyObject) => (
        <Typography variant="table-header">{value?.name}</Typography>
      )
    },
    {
      title: t('ACRONYM'),
      dataIndex: 'acronym',
      sortable: true
    },
    {
      title: t('EXTERNAL_CODE'),
      dataIndex: 'code',
      sortable: true
    },
    {
      title: t('ACTION'),
      key: 'actions',
      render: (federation: Federation) => (
        <DropdownMenu
          render={<Icon icon="outlined_dotsVertical" color={LabelColor} />}
          menuPosition="bottom-end"
          actions={[
            {
              type: 'link',
              label: t('EDIT'),
              onClick: () =>
                setModal({
                  open: true,
                  initialValues: {
                    ...federation,
                    country: federation.country?._id,
                    responsible: federation.responsible?._id
                  }
                })
            }
          ]}
        />
      )
    }
  ];

  if(!ready) return null;
  
  return (
    <Block maxW={62.5} margin="auto" mt={3}>
      <Box fAlign="center" fJustify="space-between" mb={1.688}>
        <Box fAlign="center">
          <Typography
            variant="page-title"
            style={{
              fontWeight: 500,
              color: LabelColor,
              marginRight: '0.75rem'
            }}
          >
            {t('FEDERATIONS')}
          </Typography>
          <Typography
            variant="body-small"
            style={{ color: SubMenuColor }}
          >{`${total} ${t('ITEMS')}`}</Typography>
        </Box>
      </Box>
      <Box fAlign="center" fJustify="space-between" fWrap="wrap">
        <Box w={{ sm: '70%', xxs: '100%' }}>
          <PageFilters
            initialFilters={pagination.filters}
            onChange={handleNewFilters}
          >
            {({ filters, handleChange }) => (
              <Filters
                filters={filters}
                handleChange={handleChange}
                responsibles={responsibles}
              />
            )}
          </PageFilters>
        </Box>
        <Block
          w={{ sm: '30%', xxs: '100%' }}
          display="flex"
          fJustify={{ sm: 'flex-end', xxs: 'flex-start' }}
          mt={{ sm: '0', xxs: '0.5rem' }}
        >
          <Button
            text={t('ADD')}
            icon="outlined_plus"
            variant={FocusColor}
            onClick={() => setModal({ open: true })}
          />
        </Block>
      </Box>
      <FederationsTable
        columns={columns}
        rows={rows}
        loading={loading}
        defaultSort={pagination.sort}
        sortCallback={handleNewSort}
        tableStyles={{
          compact: true
        }}
        pagination={{
          total,
          page: pagination.page,
          setPage: handleNewPage,
          limit: pagination.limit
        }}
      />
      <ManageFederationModal
        opened={modal.open}
        initialValues={modal.initialValues}
        onClose={handleCloseModal}
      />
    </Block>
  );
};

export default FederationsPage;
