import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../../../styles/BasicStyles';
import { FC, useContext, useState, useEffect } from 'react';
import { ResultType, DecisionList } from '../../../../models/Fight';
import { showConfirm, showSuccess } from '../../../../hooks/show-notification/show-notification';
import { Rounds } from '../../../../utils/rounds';
import { MillisecondsToDate } from '../../../../utils/dates';
import { AnyObject } from '../../../../models/Generic';
import { InfoContext } from '../../../../Context';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../components/button';
import SelectInput from '../../../../components/inputs/SelectInput';
import TimeInput from '../../../../components/inputs/TimeInput';

interface Params {
  opened: boolean;
  onClose: () => void;
  initialValues: ResultType;
  fightID?: string;
  fightDuration: number;
  reloadInfo: () => void;
}

const { Form } = withTypes<ResultType>();

const winners = [
  {
    _id: 'RED',
    name: 'RED'
  },
  {
    _id: 'BLUE',
    name: 'BLUE'
  },
  {
    _id: 'DRAW',
    name: 'RESULT_DRAW'
  }
]

const EditWinnerModal: FC<Params> = ({ opened, onClose, initialValues, fightID, fightDuration, reloadInfo }) => {
  const { t } = useTranslation();
  const { put } = useFetch('/fights');
  const [extraField, setExtraField] = useState<{ display: boolean, list: AnyObject[] }>({ display: false, list: [] });
  const { info } = useContext(InfoContext);

  useEffect(() => {
    if(initialValues?.decision === 'OTHER' || initialValues?.decision === 'SUBMISSION') {
      const list = initialValues?.decision === 'SUBMISSION' ?
        info?.submissions?.filter((elem: AnyObject) => elem.type === 'HEAD' || elem.type === 'LOWER_BODY' || elem.type === 'UPPER_BODY') || []
        :
        initialValues?.decision === 'OTHER' ?
        info?.submissions?.filter((elem: AnyObject) => elem.type === 'OTHER') || []
        :
        [];

      setExtraField({ display: true, list });
    }
  }, [opened, initialValues]);

  const onSubmit = async (values: ResultType) => {
    const { success } = await put(`/${fightID}/winner`, values);

    if(success) {
      showSuccess({
        title: t('SUCCESS'),
        message: t('FIGHT_CHANGE_SUCCESS')
      });

      onClose();
      reloadInfo();
    }
  };

  const checkDecision = (value: string | null) => {
    if(value === 'SUBMISSION') {
      const list = info?.submissions?.filter((elem: AnyObject) => elem.type === 'HEAD' || elem.type === 'LOWER_BODY' || elem.type === 'UPPER_BODY') || [];
      setExtraField({ display: true, list });
    }
    else if(value === 'OTHER') {
      const list = info?.submissions?.filter((elem: AnyObject) => elem.type === 'OTHER') || [];
      setExtraField({ display: true, list });
    }
    else {
      setExtraField({ display: false, list: [] });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          winner: yup.string().required(),
          date: yup.date().required(),
          round: yup.number().required(),
          decision: yup.string().required(),
          submission: yup.string()
        })
      )}
      initialValues={{ 
        ...initialValues, 
        date: MillisecondsToDate((initialValues?.time - (initialValues?.round - 1) * fightDuration * 60000) || 0, true) 
      } || {}}
      render={({ handleSubmit, pristine, submitting, form: { reset, submit } }) => {
        const handleClose = () => {
          if(!pristine) {
            showConfirm({
              title: t('EXIT_BEFORE_SAVE'),
              message: t('EXIT_BEFORE_SAVE_MESSAGE'),
              onConfirm: () => {
                onClose();
                reset();
              }
            });
          }
          else {
            onClose();
            reset();
          }
        };

        const handleSave = () => {
          showConfirm({
            title: t('CONFIRM_BEFORE_SAVE'),
            message: t('CONFIRM_BEFORE_SAVE_FIGHT'),
            onConfirm: () => submit()
          });
        }

        return (
          <Modal
            size={30}
            opened={opened}
            onClose={handleClose}
            title={t('EDIT_WINNER')}
          >
            <BasicForm onSubmit={handleSubmit}>
              <ModalOverflowContent>
                <ModalContent>
                  <Grid gutter="xl">
                    <Grid.Col xs={12}>
                      <Field name="winner">
                        {(props) => 
                          <SelectInput 
                            {...props} 
                            label={`${t('WINNER')} *`}
                            placeholder={t('CHOOSE_WINNER')}
                            data={winners}
                            translate
                          />
                        }
                      </Field>
                    </Grid.Col>
                    <Grid.Col xs={12}>
                      <Field name="date">
                        {(props) => 
                          <TimeInput 
                            {...props} 
                            label={`${t('TIME')} *`} 
                            withSeconds
                          />
                        }
                      </Field>
                    </Grid.Col>
                    <Grid.Col xs={12}>
                      <Field name="round">
                        {(props) => 
                          <SelectInput 
                            {...props} 
                            label={`${t('ROUND')} *`}
                            placeholder={t('CHOOSE_ROUND')}
                            data={Rounds}
                          />
                        }
                      </Field>
                    </Grid.Col>
                    <Grid.Col xs={12}>
                      <Field name="decision">
                        {(props) => 
                          <SelectInput 
                            {...props} 
                            label={`${t('DECISION')} *`}
                            placeholder={t('CHOOSE_DECISION')}
                            data={DecisionList}
                            translate
                            afterChange={(value) => checkDecision(value)}
                          />
                        }
                      </Field>
                    </Grid.Col>
                    {
                      extraField.display && extraField.list.length > 0 &&
                      <Grid.Col xs={12}>
                      <Field name="submission">
                        {(props) => 
                          <SelectInput 
                            {...props} 
                            label={t('TYPE')}
                            placeholder={t('CHOOSE_TYPE')}
                            data={extraField.list}
                            intl
                            clearable
                          />
                        }
                      </Field>
                    </Grid.Col>
                    }
                  </Grid>
                </ModalContent>
              </ModalOverflowContent>
              <ModalActions>
                <Button
                  text={t('CANCEL')}
                  variant="secondary"
                  onClick={handleClose}
                />
                <Button
                  text={t('SAVE')}
                  loading={submitting}
                  disabled={pristine}
                  onClick={handleSave}
                />
              </ModalActions>
            </BasicForm>
          </Modal>
        );
      }}
    />
  );
};

export default EditWinnerModal;
