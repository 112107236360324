/* eslint-disable @typescript-eslint/no-explicit-any */
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { BasicForm } from '../../../../styles/BasicStyles';
import { FC } from 'react';
import { Event } from '../../../../models/Event';
import { showSuccess, showError } from '../../../../hooks/show-notification/show-notification';
import { LabelColor } from '../../../../styles/Colors';
import { AnyObject } from '../../../../models/Generic';
import { useNavigate } from 'react-router-dom';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../components/button';
import Typography from '../../../../components/typography';

interface Params {
  opened: boolean;
  onClose: () => void;
  initialValues?: Event;
}

const { Form } = withTypes<AnyObject>();

const DuplicateModal: FC<Params> = ({ opened, onClose, initialValues }) => {
  const { t } = useTranslation();
  const { get } = useFetch('/events/duplicate');
  const navigate = useNavigate();

  const onSubmit = async () => {
    const { data, success } = await get(`/${initialValues?._id}`);
    if(success) {
      if(data) {
        showSuccess({
          title: t('SUCCESS'),
          message: t('EVENT_DUPLICATED')
        });

        navigate(`/events/${data._id}`);
      }
      else {
        showError({
          title: t('ERROR'),
          message: t('DUPLICATE_EVENT_ERROR')
        });
      }

      onClose();
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => {
        return (
          <Modal
            size={36.25}
            opened={opened}
            onClose={onClose}
            title={t('DUPLICATE_EVENT')}
          >
            <BasicForm onSubmit={handleSubmit}>
              <ModalOverflowContent>
                <ModalContent>
                  <Typography as='div' variant='body' style={{ color: LabelColor }} mb={1}>
                    {t('EVENT')}: <span style={{ fontWeight: 500 }}>{initialValues?.name}</span>
                  </Typography>
                  <Typography as='div'variant='body-small' style={{ color: LabelColor }} mb={0.75}>
                    {t('CONFIRM_DUPLICATE_EVENT')}
                  </Typography>
                  <Typography as='div' variant='body-small' style={{ color: LabelColor }} mb={0.125}>
                    {t('CONFIRM_DUPLICATE_EVENT_MESSAGE_1')}: <span style={{ fontWeight: 500 }}>{`${initialValues?.name} - COPY`}</span>
                  </Typography>
                  <Typography as='div' variant='body-small' style={{ color: LabelColor }}>
                    {t('CONFIRM_DUPLICATE_EVENT_MESSAGE_2')}
                  </Typography>
                </ModalContent>
              </ModalOverflowContent>
              <ModalActions>
                <Button
                  text={t('CANCEL')}
                  variant="secondary"
                  onClick={onClose}
                />
                <Button
                  text={t('COPY')}
                  type="submit"
                  loading={submitting}
                />
              </ModalActions>
            </BasicForm>
          </Modal>
        );
      }}
    />
  );
};

export default DuplicateModal;
