import { ACTIVE, UserStatusList } from '../../models/User';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Block } from '../../styles/BasicStyles';
import { Tooltip } from '@mantine/core';

type UserProps = {
  status: string;
};

const UserStatus: FC<UserProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <>
      {
        // Active if the default state, no need to show a green dot for this case
        status !== ACTIVE &&
        <Tooltip label={t(status)}>
          <Block 
            display='flex' 
            fShrink={0} 
            ml={0.5} 
            bRadius='50%' 
            w={0.75} 
            h={0.75} 
            style={{ backgroundColor: UserStatusList.find(elem => elem._id === status)?.fontColor || 'transparent' }} 
          />
        </Tooltip>
      }
    </>
  );
};

export default UserStatus;