import { useTranslation } from 'react-i18next';
import { LabelColor } from '../../../styles/Colors';
import { useEffect } from 'react';
import { useState } from 'react';
import { Configs } from '../../../models/Configs';
import { Card, Item, renderLink } from '../profile';
import Typography from '../../../components/typography';
import EditConfigsModal from './EditConfigsModal';
import useFetch from 'use-http';
import LoadingOverlay from '../../../components/loadingOverlay';

const configsID = '640af0b91775775aa1fef217';

const ConfigsComponent = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState<{ open: boolean; type?: string; initialValues?: Configs; }>({ open: false });
  const { get } = useFetch('/configs');

  useEffect(() => {
    const init = async () => {
      await getInfo();
    };
    init();
  }, []);

  const getInfo = async () => {
    const { data } = await get(`/${configsID}`);

    setModal({ open: false, initialValues: data || {} });
    setLoading(false);
  }

  const handleCloseModal = async (changed: boolean) => {
    if(changed) {
      setLoading(true);
      await getInfo();
    }
    else {
      setModal({ ...modal, open: false });
    }
  }

  return (
    <>
      <LoadingOverlay visible={loading} />
      <Card mt={3.125}>
        <Item title={t('FEEDBACK_URL')}>
          <Typography variant="body-small" style={{ color: LabelColor }} wBreak='break-word' mr={1}>
            {modal.initialValues?.feedback_url}
          </Typography>
          {renderLink('EDIT', () =>
            setModal({ open: true, type: 'FEEDBACK_URL', initialValues: modal.initialValues })
          )}
        </Item>
      </Card>
      <EditConfigsModal
        opened={modal.open}
        initialValues={modal.initialValues}
        type={modal.type}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default ConfigsComponent;
