import { transitionsDelay } from '../../styles/BasicStyles';
import { generateProps } from 'styled-gen';
import { ButtonProps, ButtonSizes } from './index';
import { Button } from '@mantine/core';
import styled, { css } from 'styled-components';

export const ButtonWrapper = styled(Button)<ButtonProps>`
  font-family: 'Inter', sans-serif;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: background-color ${transitionsDelay} linear, border-color ${transitionsDelay} linear, opacity ${transitionsDelay} linear;
  appearance: none;
  line-height: 0;

  ${({ size, round }) => 
    size === 'xs' ?
      css`
        padding: ${round ? '0' : '0.438rem 0.688rem'};
        border-radius: ${round ? '50%' : '0.25rem'};
        width: ${round ? '1.875rem' : 'auto'};
        height: ${round ? '1.875rem' : 'auto'};

        & svg {
          width: 0.875rem;
          height: 0.875rem;
        }
      `
    :
    size === 'sm' ?
      css`
        padding: ${round ? '0' : '0.563rem 0.813rem'};
        border-radius: ${round ? '50%' : '0.375rem'};
        width: ${round ? '2.125rem' : 'auto'};
        height: ${round ? '2.125rem' : 'auto'};
      `
    :
    size === 'lg' ?
      css`
        padding: ${round ? '0' : '0.563rem 1.063rem'};
        border-radius: ${round ? '50%' : '0.375rem'};
        width: ${round ? '2.625rem' : 'auto'};
        height: ${round ? '2.625rem' : 'auto'};

        & svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      `
    :
    size === 'xl' ?
      css`
        padding: ${round ? '0' : '0.813rem 1.563rem'};
        border-radius: ${round ? '50%' : '0.375rem'};
        width: ${round ? '3.125rem' : 'auto'};
        height: ${round ? '3.125rem' : 'auto'};

        & svg {
          width: 1.375rem;
          height: 1.375rem;
        }
      `
    :
      css`
        padding: ${round ? '0' : '0.563rem 1.063rem'};
        border-radius: ${round ? '50%' : '0.375rem'};
        width: ${round ? '2.375rem' : 'auto'};
        height: ${round ? '2.375rem' : 'auto'};

        & svg {
          width: 1.125rem;
          height: 1.125rem;
        }
      `
  }

  ${({ variant, theme, textColor }) => 
    variant === 'primary' ?
      css`
        box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.05);
        background-color: #232429 !important;
        border: 0.063rem solid #232429 !important;

        &:hover {
          background-color: transparent !important;
          border-color: rgba(230, 231, 233, 0.16) !important;
        }
      `
    :
    variant === 'secondary' ? 
      css`
        border: 0.063rem solid rgba(230, 231, 233, 0.16) !important;
        background-color: transparent !important;
        filter: drop-shadow(0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.05));

        &:hover {
          background-color: #232429 !important;
          border-color: transparent !important;
        }
      `
    :
    variant === 'gradient' ? 
      css`
        box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.05);
        background: ${theme?.colors?.gradient || 'linear-gradient(90.2deg, #CC0000 -20.86%, #800080 51.08%, #282DD6 118.59%)'} !important;
        border: 0;

        &:hover {
          background-size: initial !important !important;
          opacity: 0.8;
        }
      `
    :
      css`
        background-color: ${variant} !important;
        border: 0.063rem solid ${variant} !important;

        & span {
          transition: color ${transitionsDelay} linear;
          color: ${textColor || theme?.colors?.labelColor || 'rgba(255, 255, 255, 0.9)'} !important;
        }

        &:hover {
          background-color: transparent !important;

          & .icon {
            & svg path[fill] {
              fill: ${variant};
            }

            & svg path[stroke] {
              stroke: ${variant};
            }
          }

          & span {
            color: ${variant} !important;
          }
        }
      `
  }

  &:not(:disabled) {
    &:hover {
      cursor: pointer;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &[data-loading] {
    opacity: 0.8;

    &:before {
      display: none;
    }

    & .mantine-Button-leftIcon {
      margin-right: ${({ round }) => round ? 0 : '0.375rem'};

      & svg {
        stroke: ${({ textColor, theme }) => textColor || theme?.colors?.labelColor || 'rgba(255, 255, 255, 0.9)'};
      }
    }
  }

  & .icon {
    margin-right: ${({ round, reverseIcon }) => round || reverseIcon ? 0 : '0.375rem'};
    margin-left: ${({ round, reverseIcon }) => round || !reverseIcon ? 0 : '0.375rem'};

    & svg path[fill] {
      transition: fill ${transitionsDelay} linear;
    }

    & svg path[stroke] {
      transition: stroke ${transitionsDelay} linear;
    }
  }

  ${generateProps};
`;

export const ButtonText = styled.span<{ size: ButtonSizes, textColor?: string }>` 
  font-weight: 500;
  color: ${({ theme, textColor }) => textColor || theme?.colors?.labelColor || 'rgba(255, 255, 255, 0.9)'};

  ${({ size }) => 
    size === 'xs' ?
      css`
        font-size: 0.75rem;
        line-height: 1rem;
      `
    :
    size === 'sm' ?
      css`
        font-size: 0.875rem;
        line-height: 1rem;
      `
    :
    size === 'lg' ?
      css`
        font-size: 1rem;
        line-height: 1.5rem;
      `
    :
    size === 'xl' ?
      css`
        font-size: 1rem;
        line-height: 1.5rem;
      `
    :
      css`
        font-size: 0.875rem;
        line-height: 1.25rem;
      `
  }
`;