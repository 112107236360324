/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from '@mantine/core';
import { Fragment, useState } from 'react';
import { Block, Box } from '../../styles/BasicStyles';
import { TournamentRound } from '../../models/Tournament';
import { Column, ColumnWrapper, FighterWrapper, FighterContent, BracketWrapper } from '../tournaments/styles';
import useFetch from 'use-http';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/button';
import Icon from '../../components/icon';

const GenerateTournamentPage = () => {
  const [scale, setScale] = useState<number>(1);
  const [total, setTotal] = useState<string>('0');
  const [bracket, setBracket] = useState({ result: [], brackets: [] });
  const { get, loading } = useFetch('/tournaments/generate-test');

  const generateTournament = async () => {
    const result = await get(`/${total}`);
    setBracket(result?.data);
  };

  const renderFighter = (isBye: boolean, fighter?: string) => {
    return (
      <FighterWrapper isBye={isBye}>
        <FighterContent hasFighter={!!fighter}>
          {fighter}
        </FighterContent>
      </FighterWrapper>
    );
  };

  const scaleBracket = (type: 'up' | 'down') => {
    let aux = scale;

    if((type === 'down' && aux <= 0.5) || (type === 'up' && aux >= 1)) return;

    if(type === 'up') aux += 0.1;
    if(type === 'down') aux -= 0.1;
    setScale(Math.round(aux * 10) / 10);
  };

  return (
    <>
      <Grid>
        <Grid.Col xs={6}>
          <TextInput
            input={{
              value: total,
              onChange: (text: string | null) => setTotal(text || '0')
            }}
            placeholder='Define number of fighters'
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <Button 
            text='Generate'
            onClick={generateTournament}
            disabled={loading}
            loading={loading}
          />
        </Grid.Col>
      </Grid>
      <Box fAlign='center' margin='2 0'>
        <Icon icon='outlined_zoomOut' size={1.5} style={{ paddingRight: '0.5rem' }} onClick={() => scaleBracket('down')}/>
        <Icon icon='outlined_zoomIn' size={1.5} onClick={() => scaleBracket('up')} />
      </Box>
      {
        bracket.result?.length > 0 &&
        <BracketWrapper scale={scale}>
          <Block display='flex' fDirection='row' mb={3}>
          {
            bracket.result.map((round: TournamentRound, index: number) =>
              <Column key={index}>
                <ColumnWrapper>
                  {
                    round?.fights?.length > 0 &&
                    round.fights.map((fight: any, fightIndex: number) =>
                      <Fragment key={`${index}_${fightIndex}`}>
                        {
                          fight.bye ?
                          <>
                            {renderFighter(true, '')}
                            {renderFighter(true, '')}
                          </>
                          :
                          !!fight.winner ?
                          renderFighter(false, fight.winner)
                          :
                          <>
                            {renderFighter(false, fight.red_fighter)}
                            {renderFighter(false, fight.blue_fighter)}
                          </>
                        }
                      </Fragment>
                    )
                  }
                </ColumnWrapper>
              </Column>
            )
          }
          </Block> 
        </BracketWrapper>
      }
    </>
  );
};

export default GenerateTournamentPage;
