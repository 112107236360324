import { Block, Image } from '../../styles/BasicStyles';
import { UserContext } from '../../Context';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Languages, getLanguage } from '../../utils/languages/AvailableTranslations';
import Icon from '../icon';
import DropdownMenu from '../dropdownMenu';
import Typography from '../typography';
import useFetch from 'use-http';

export const LanguageSelector: FC = () => {
  const { user, setUser } = useContext(UserContext);
  const { i18n: { changeLanguage, language} } = useTranslation();
  const { put } = useFetch('/users');
  
  const toggleLanguage = async (code: string) => {
    changeLanguage(code);

    if(user?._id) {
      const { data, success } = await put('/change-language', { language: code });
      if(success) setUser(data);
    }
  };

  return (
    <DropdownMenu 
      render={
        <Block display='flex' fAlign='center'>
          <Image src={getLanguage(language)?.icon} w={1.25} h={1.25} mr={0.25} />
          <Icon icon='outlined_chevronDown' size={1} />
        </Block>
      }
      menuPosition='bottom-end'
      actions={Languages.filter(elem => elem.code !== language).map(elem => ({
        type: 'link',
        render: 
          <Block display='flex' fAlign='center'>
            <Image src={elem.icon} w={1.15} h={1.15} mr={0.5} />
            <Typography variant='body-small' fWeight={500}>{elem.name}</Typography>
          </Block>,
        onClick: () => toggleLanguage(elem.code)
      }))} 
    />
  );
};

export default LanguageSelector;
