import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../hooks/use-validation/use-validation-schema';
import { AWAITING_REVIEW, Document, REJECTED } from '../../../../models/Document';
import { BasicForm, Box } from '../../../../styles/BasicStyles';
import { InfoContext, UserContext } from '../../../../Context';
import { FC, useContext } from 'react';
import { DocumentStatus } from '../../../../utils/user/documents';
import { FocusColor, LabelColor, MenuBgColor } from '../../../../styles/Colors';
import { openFile } from '../../../../utils/files';
import { ATHLETE, COACH, OFFICIAL, PROMOTER } from '../../../../models/User';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../components/button';
import SelectInput from '../../../../components/inputs/SelectInput';
import DateInput from '../../../../components/inputs/DateInput';
import toFormData from '../../../../utils/formdata/ToFormData';
import FileInput from '../../../../components/inputs/FileInput';
import Typography from '../../../../components/typography';
import Icon from '../../../../components/icon';
import Link from '../../../../components/link';

const validations = [
  yup.object({
    category: yup.string().required(),
    status: yup.string().required(),
    file: yup.object().required()
  }),
  yup.object({
    category: yup.string().required(),
    status: yup.string().required()
  }),
  yup.object({
    category: yup.string().required(),
    file: yup.object().required()
  }),
  yup.object({
    category: yup.string().required(),
  })
];

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: Document;
  userID?: string;
  openRejectModal?: (values: { open: boolean, document?: Document, closeDocumentModal?: boolean }) => void;
}

const { Form } = withTypes<Document>();

const ManageDocumentModal: FC<Params> = ({ opened, onClose, initialValues, userID, openRejectModal }) => {
  const { t } = useTranslation();
  const { post, put } = useFetch('/user-documents');
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);

  const onSubmit = async (values: Document) => {
    if(!!userID) {
      let error = false;
      
      if(initialValues?.status !== REJECTED && values.status === REJECTED && !!openRejectModal) {
        openRejectModal({ open: true, document: values, closeDocumentModal: true });
      }
      else {
        // If the current User is a Member, it can't select a status
        if(!values._id && (user?.type === ATHLETE || user?.type === OFFICIAL || user?.type === COACH || user?.type === PROMOTER)) values.status = AWAITING_REVIEW;
        if(!values.expiration_date) delete values.expiration_date;

        const payload = toFormData({ ...values, user: userID });

        if(values._id) {
          const { success } = await put(`/${values._id}`, payload);
          if(success) onClose(true);
          else error = true;
        } 
        else {
          const { success } = await post(payload);
          if(success) onClose(true);
          else error = true;
        }
      }

      return !error;
    }
  };

  const getValidationSchema = () => {
    if(user?.type !== ATHLETE && user?.type !== OFFICIAL && user?.type !== COACH && user?.type !== PROMOTER) {
      if(initialValues?._id) return validations[1];
      return validations[0];
    }
    else {
      if(initialValues?._id) return validations[3];
      return validations[2];
    }
  };

  return (
    <Modal
      size={32.5}
      opened={opened}
      onClose={() => onClose(false)}
      title={initialValues && initialValues._id ? t('EDIT_DOCUMENT') : t('UPLOADING_DOCUMENT')}
    >
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema(getValidationSchema())}
        initialValues={initialValues || {}}
      >
        {({ handleSubmit, submitting, form: { reset } }) => (
          <BasicForm onSubmit={async event => {
            const result = await handleSubmit(event);
            if(result) reset();
          }}>
            <ModalOverflowContent>
              <ModalContent>
                <Grid gutter="xl">
                  <Grid.Col xs={12}>
                    <Field name="category">
                      {(props) => 
                        <SelectInput
                          {...props}
                          label={`${t('DOCUMENT_CATEGORY')} *`}
                          placeholder={t('CHOOSE_CATEGORY')}
                          clearable
                          data={info?.documentCategories || []}
                          intl
                        />
                      }
                    </Field>
                  </Grid.Col>
                </Grid>
                <Grid gutter="xl">
                  <Grid.Col xs={12} sm={user?.type !== ATHLETE && user?.type !== OFFICIAL && user?.type !== COACH && user?.type !== PROMOTER ? 6 : 12}>
                    <Field name="expiration_date">
                      {(props) =>
                        <DateInput
                          {...props}
                          label={t('EXPIRATION_DATE')}
                          placeholder={t('CHOOSE_DATE')}
                        />
                      }
                    </Field>
                  </Grid.Col>
                  {
                    user?.type !== ATHLETE && user?.type !== OFFICIAL && user?.type !== COACH && user?.type !== PROMOTER &&
                    <Grid.Col xs={12} sm={6}>
                      <Field name="status">
                        {(props) =>
                          <SelectInput
                            {...props}
                            label={`${t('DOCUMENT_STATUS')} *`}
                            placeholder={t('CHOOSE_STATUS')}
                            clearable
                            data={DocumentStatus}
                            translate
                          />
                        }
                      </Field>
                    </Grid.Col>
                  }
                </Grid>
                {
                  initialValues?._id ?
                  <Box fDirection='column' mt={0.75}>
                    <Typography variant='body-small' style={{ color: LabelColor }} pb={0.5}>{t('ATTACHED_FILE')}</Typography>
                    <Box 
                      bgColor={MenuBgColor} 
                      bRadius={0.25} 
                      fAlign='center' 
                      fJustify='space-between' 
                      padding='0.625 1'
                      mb={0.75}
                    >
                      <Link onClick={() => openFile(initialValues.file)}>
                        <Box fAlign='center'>
                          <Icon icon='outlined_documentText' color={FocusColor} />
                          <Typography variant='dropdown-menu-small' style={{ color: LabelColor }} ml={1}>
                            {initialValues.file?.filename}
                          </Typography>
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                  :
                  <Grid gutter="xl">
                    <Grid.Col xs={12}>
                      <Field name="file">
                        {(props) =>
                          <FileInput 
                            {...props}
                            label={`${t('ATTACH_DOCUMENTATION')} *`}
                          />
                        }
                      </Field>
                    </Grid.Col>
                  </Grid>
                }
              </ModalContent>
            </ModalOverflowContent>
            <ModalActions>
              <Button text={t('CANCEL')} variant='secondary' onClick={() => onClose(false)} />
              <Button text={initialValues?._id ? t('SAVE') : t('CREATE')} type='submit' loading={submitting} />
            </ModalActions>
          </BasicForm>
        )}
      </Form>
    </Modal>
  );
};

export default ManageDocumentModal;
