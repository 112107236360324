import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '../../styles/BasicStyles';
import NotFoundImg from '../../assets/images/404.svg';
import Typography from '../../components/typography';
import Button from '../../components/button';
import Layout from '../../components/layout/Layout';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Layout showSidebar={false}>
      <Box fDirection='column' fJustify='center' fAlign='center' h='100%'>
        <img src={NotFoundImg} alt='404 image' />
        <Typography variant='title' as='h1' color='#ffffff' style={{ marginTop: '1rem', marginBottom: '2.5rem', fontSize: '1.625rem', lineHeight: '2rem', textAlign: 'center' }}>{t('PAGE_NOT_FOUND')}</Typography>
        <Button 
          text={t('BACK_TO_HOMEPAGE')} 
          onClick={() => navigate('/')} 
        />
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
