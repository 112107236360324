import { Menu, MenuItemProps } from '@mantine/core';
import { transitionsDelay } from '../../styles/BasicStyles';
import styled, { css } from 'styled-components';

export const StyledDropdown = styled(Menu.Dropdown)<{ profileDropdown: boolean }>`
  &.mantine-Menu-dropdown {
    background: ${({ theme }) => theme.colors.dropdownMenuBg};
    border-radius: ${({ profileDropdown }) => profileDropdown ? '1.25rem' : '0.5rem'};
    border: 0;
    padding: ${({ profileDropdown }) => profileDropdown ? '0.25rem 0' : 0};
    min-width: ${({ profileDropdown }) => profileDropdown ? '12.5rem' : '8.5rem'};
    // TODO: position fixed added because absolute caused issues with overflow auto in table component (content was being cut), remove this if other issue appears
    position: fixed;
  }
`;

export const StyledItem = styled(Menu.Item)<MenuItemProps & { key: number, highlight: boolean | undefined, onClick?: () => void, profileDropdown: boolean }>`
  padding: ${({ profileDropdown }) => profileDropdown ? '0.625rem 1.5rem' : '0.813rem 1rem'};
  background-color: transparent !important;
  font-family: 'Inter', sans-serif;
  cursor: ${({ onClick }) => !!onClick ? 'pointer' : 'initial'};

  & span {
    transition: color ${transitionsDelay} linear;
    color: ${({ highlight, theme, profileDropdown }) => highlight ? theme.colors.b200 : profileDropdown ? '#ffffff' : theme.colors.tableHeader};
  }

  ${({ onClick }) => !!onClick && 
    css`
      &:hover {
        & span {
          color: ${({ theme }) => theme.colors.b200};
        }
      }
    `
  }
`;

export const StyledDivider = styled(Menu.Divider)<MenuItemProps & { key: number, profileDropdown: boolean }>`
  border: 0.063rem solid ${({ profileDropdown }) => profileDropdown ? 'rgba(255, 255, 255, 0.09)' : 'rgba(255, 255, 255, 0.06)'};
  margin: ${({ profileDropdown }) => profileDropdown ? '0.25rem 0' : 0};
`;
