import { Menu, MenuItemProps } from '@mantine/core';
import { Box, transitionsDelay } from '../../styles/BasicStyles';
import { DropdownSize } from './index';
import styled, { css } from 'styled-components';

export const StyledMenu = styled(Menu)`
  width: 100%;
`;

export const StyledDropdown = styled(Menu.Dropdown)`
  &.mantine-Menu-dropdown {
    background: ${({ theme }) => theme.colors.filtersBgColor};
    border-radius: 0.25rem;
    border: 0;
    padding: 0.625rem 0rem;
    width: 100% !important;
  }
`;

export const StyledItem = styled(Menu.Item)<MenuItemProps & { key: number, onClick?: () => void }>`
  padding: 0.375rem 1.25rem;
  background-color: transparent !important;
  font-family: 'Inter', sans-serif;
  cursor: ${({ onClick }) => !!onClick ? 'pointer' : 'initial'};

  & span {
    transition: color ${transitionsDelay} linear;
    color: ${({ theme }) => theme.colors.labelColor};
  }

  ${({ onClick }) => !!onClick && 
    css`
      &:hover {
        & span {
          color: ${({ theme }) => theme.colors.b200};
        }
      }
    `
  }
`;

export const StyledTitle = styled(Menu.Item)<MenuItemProps & { key: number }>`
  padding: 0.375rem 1.25rem 0.75rem 1.25rem;
  background-color: transparent !important;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.031rem;
  cursor: initial;

  & span {
    color: ${({ theme }) => theme.colors.placeholderColor};
  }
`;

export const StyledDivider = styled(Menu.Divider)<MenuItemProps & { key: number }>`
  border: 0.063rem solid rgba(255, 255, 255, 0.06);
  margin: 0.625rem 0;
`;

export const ButtonWrapper = styled(Box)<{ bgColor: string, size: DropdownSize }>`
  background: ${({ bgColor }) => bgColor};
  box-shadow: 0 0.063rem 0.125rem rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;

  ${({ size }) =>
    size === 'xs' ?
    css`
      padding: 0.375rem 0.938rem;
    `
    :
    css`
      padding: 0.875rem;
    `
  }
`;
