import { AnyObject } from "./Generic";
import { Federation } from './Federation';

export const NEW_EVENT = 'NEW_EVENT';
export const NEW_FIGHT = 'NEW_FIGHT';
export const NEW_OFFICIAL = 'NEW_OFFICIAL';
export const NEW_ATHLETE = 'NEW_ATHLETE';
export const NEW_COACH = 'NEW_COACH';
export const NEW_PROMOTER = 'NEW_PROMOTER';
export const NEW_REVIEW = 'NEW_REVIEW';
export const NEW_PENDING_MEMBER_OFFICIAL = 'NEW_PENDING_MEMBER_OFFICIAL';
export const NEW_PENDING_MEMBER_ATHLETE = 'NEW_PENDING_MEMBER_ATHLETE';
export const NEW_PENDING_MEMBER_COACH = 'NEW_PENDING_MEMBER_COACH';
export const NEW_PENDING_MEMBER_PROMOTER = 'NEW_PENDING_MEMBER_PROMOTER';
export const NEW_PENDING_EVENT = 'NEW_PENDING_EVENT';
export const OFFICIAL_PENDING = 'OFFICIAL_PENDING';
export const ATHLETE_PENDING = 'ATHLETE_PENDING';
export const COACH_PENDING = 'COACH_PENDING';

export interface Notification {
  federation: Federation;
  text: AnyObject;
  type: string;
  record?: string;
  createdAt: Date;
}
