import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { User } from '../../../../../models/User';
import Typography from '../../../../../components/typography';
import InfoCard from '../../../common/InfoCard';
import dayjs from 'dayjs';

interface Params {
  promoter?: User;
  isDraftMember: boolean;
}

// TODO: check what to do with dates "Renewal"

const InfoCards: FC<Params> = ({ promoter, isDraftMember }) => {
  const { t } = useTranslation();

  let address = '';
  if(promoter?.address) address = promoter.address;
  if(promoter?.postal_code) address = address ? `${address} ${promoter.postal_code}` : promoter.postal_code;
  if(promoter?.city) address = address ? `${address}, ${promoter.city}` : promoter.city;
  
  const federationInfo = [
    {
      title: t('FEDERATION'),
      value: promoter?.federation?.name
    }
  ];

  if(!isDraftMember) {
    federationInfo.push({
      title: t('AFFILIATION_NUMBER'),
      value: promoter?.associate
    });
  }

  return (
    <>
      <InfoCard 
        items={[
          {
            title: t('PROMOTER_EMAIL'),
            value: promoter?.email
          },
          {
            title: t('PROMOTER_PHONE_NUMBER'),
            value: promoter?.phone
          },
          {
            title: t('PROMOTER_RESPONSABLE_NAME'),
            value: promoter?.responsible_name
          },
          {
            title: t('PROMOTER_RESPONSABLE_EMAIL'),
            value: promoter?.responsible_email
          },
          {
            title: t('PROMOTER_RESPONSABLE_CONTACT'),
            value: promoter?.responsible_phone
          },
          {
            title: t('PROMOTER_CREATION_DATE'),
            value: promoter?.promoter_creation ? dayjs(promoter?.promoter_creation).format('DD MMM, YYYY') : ''
          },
          {
            title: t('TAX_NUMBER'),
            value: promoter?.nif
          },
          {
            title: t('ADDRESS'),
            value: address
          },
          {
            title: t('COUNTRY'),
            value: promoter?.country?.name
          }
        ]}
      />
      <Typography variant='body-small' color='#ffffff' pb={0.688} pt={1.5}>{t('FEDERATION_INFO').toUpperCase()}</Typography>
      <InfoCard 
        items={federationInfo}
      />
    </>
  );
};

export default InfoCards;
