import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Fight } from '../../models/Fight';
import { Block, Box } from '../../styles/BasicStyles';
import { LabelColor, MenuBgColor, PlaceholderColor, BorderColor, BlueFighter, RedFighter, DisabledColor } from '../../styles/Colors';
import { User } from '../../models/User';
import { AnyObject } from '../../models/Generic';
import { GetFirstAndLastName } from '../../utils/user';
import Typography from '../../components/typography';
import UserAvatar from '../../components/userAvatar';
import Label from '../../components/label';
import UserStatus from '../../components/userStatus';

interface Params {
  fight?: Fight;
}

const MainCard: FC<Params> = ({ fight }) => {
  const { t, i18n: { language } } = useTranslation();

  const renderFighter = (fighter?: User | AnyObject, isWinner?: boolean, hasWinner?: boolean, color?: string) => {
    return (
      <Block w={{ xs: '40%', xxs: '100%' }}>
        {
          hasWinner &&
          <>
            {
              isWinner ?
              <Block mb={0.25} ml={4.313}>
                <Label 
                  text={t('WINNER').toUpperCase()} 
                  fontColor='#ffffff' 
                  bgColor='rgba(255, 255, 255, 0.25)' 
                  bRadius='0.25rem' 
                  size='xs' 
                  fSize={0.688}
                  lHeight={0.938}
                />
              </Block>
              :
              <Block mb={1.563} display={{ xs: 'block', xxs: 'none' }} />
            }
          </>
        }
        <Block display='flex' fAlign='center' fDirection='row'>
          <Block display='flex' fShrink={0}>
            <UserAvatar user={fighter} size='lg' borderColor={color} />
          </Block>
          <Block display='flex' fDirection='column' pl={1.375}>
            <Box fAlign='center'>
              <Typography fWeight={500} fSize={1.25} lHeight={1.75} style={{ color: LabelColor }}>{GetFirstAndLastName(fighter?.name)}</Typography>
              <UserStatus status={fighter?.status || ''} />
            </Box>
            {
              !!fighter?.weight && 
              <Typography fWeight={400} fSize={0.938} lHeight={1.313} style={{ color: PlaceholderColor }}>{fighter?.weight} kg</Typography>
            }
          </Block>
        </Block>
      </Block>
    );
  };

  return (
    <Block display='flex' w={{ lg: '50%', xxs: '100%' }} padding='0 0.563rem'>
      <Block 
        bgColor={MenuBgColor} 
        padding="1.25 1.5" 
        bRadius={0.5} 
        style={{ border: `0.063rem solid ${BorderColor}`}} 
        w='100%'
      >
        <Block display='flex' fAlign='center' fDirection='row' fWrap='wrap'>
          {renderFighter(fight?.red_fighter, fight?.result?.winner === 'RED', !!fight?.result?.winner, RedFighter)}
          <Block w={{ xs: '20%', xxs: 2.938 }} tAlign={'center'} padding={{ xs: 0, xxs: '0.625 0' }}>
            <Typography fWeight={600} fSize={1.188} lHeight={1.688} style={{ color: PlaceholderColor }}>VS</Typography>
          </Block>
          {renderFighter(fight?.blue_fighter, fight?.result?.winner === 'BLUE', !!fight?.result?.winner, BlueFighter)}
        </Block>
        <Block display='flex' fAlign='flex-start' fDirection='row' fWrap='wrap' margin='0 -0.25rem' mt={2.125}>
          <Block display='flex' fDirection='column' w={{ xs: '50%', xxs: '100%' }} padding='0 0.25' mb={0.75}>
            <Typography variant='body-small' fWeight={500} style={{ color: DisabledColor }} pb={0.313}>{t('FIGHT_CATEGORY')}:</Typography>
            <Typography variant='dropdown-menu' lHeight={1.75} style={{ color: LabelColor }}>{fight?.class?.name && fight.class.name[language]}</Typography>
          </Block>
          <Block display='flex' fDirection='column' w={{ xs: '50%', xxs: '100%' }} padding='0 0.25' mb={0.75}>
            <Typography variant='body-small' fWeight={500} style={{ color: DisabledColor }} pb={0.313}>{t('WEIGHT_CLASS')}:</Typography>
            <Typography variant='dropdown-menu' lHeight={1.75} style={{ color: LabelColor }}>{fight?.weight_class?.name && fight.weight_class.name[language]}</Typography>
          </Block>
        </Block>
      </Block>
    </Block>
  );
};

export default MainCard;
