import { breakpoints } from "../../styles/Breakpoints";
import styled from "styled-components";

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.5rem;
  z-index: 999999;
  padding: 0rem 1.25rem;
  background-color: ${({ theme }) => theme.colors.menuBgColor};
  box-shadow: inset 0rem -0.063rem 0rem ${({ theme }) => theme.colors.borderColor};
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .mobileMenu {
    display: none;
  }

  @media ${breakpoints.md} {
    padding: 0rem 0.625rem;

    & .mobileMenu {
      display: block;
    }

    & .mantine-Avatar-root {
      width: 1.875rem;
      min-width: 1.875rem;
      height: 1.875rem;
    }
  }
`;

export const LogoImg = styled.img<{ isLink: boolean }>`
  margin-left: 0rem;
  max-height: 2.188rem;
  max-width: 10.625rem;
  cursor: ${({ isLink }) => isLink ? 'pointer' : 'initial'};

  @media ${breakpoints.md} {
    margin-left: 1rem;
    max-height: 1.875rem;
    max-width: 9.375rem;
  }

  @media ${breakpoints.sm} {
    margin-left: 0.5rem;
    max-width: 7.5rem;
  }

  @media ${breakpoints.xs} {
    margin-left: 0.5rem;
    max-width: 6.5rem;
  }
`;

export const ExternalLanguageWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;
