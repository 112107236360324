import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Translation } from '../../models/Translation';
import { LanguageContext } from '../../Context';
import { GetPlatformTag } from '../../utils/platforms/Platforms';
import { showDelete } from '../../hooks/show-notification/show-notification';
import { Box, Block } from '../../styles/BasicStyles';
import { BorderColor, LabelColor } from '../../styles/Colors';
import { Grid, Tabs } from '@mantine/core';
import { TabsWrapper } from '../../components/tabs/styles';
import EmptyState from '../../components/empty/EmptyState';
import Typography from '../../components/typography';
import DropdownMenu from '../../components/dropdownMenu';
import Icon from '../../components/icon';
import LoadingOverlay from '../../components/loadingOverlay';
import DefaultListPage from '../../components/page/DefaultListPage';
import useFetch from 'use-http';
import TranslationModal from './TranslationModal';
import TranslationFilters from './TranslationFilters';

const TranslateListPage = DefaultListPage<Translation>();

const TranslationsPage = () => {
  const [modal, setModal] = useState<{
    open: boolean;
    initialValues?: Translation;
  }>({ open: false });

  const { t } = useTranslation();
  const languages = useContext(LanguageContext);
  const { get, del } = useFetch('/translations');

  return (
    <TranslateListPage
      translate={t}
      request={{
        get,
        searchFields: ['tag', 'value|intl']
      }}
      storage="TRANSLATION_FILTERS"
      header={{
        title: t('TRANSLATIONS'),
        actions: [
          {
            text: t('ADD_NEW'),
            icon: 'outlined_plus',
            onClick: () => setModal({ open: true })
          }
        ]
      }}
      filters={TranslationFilters}
    >
      {({ rows, loading, refreshPage }) => {
        const handleCloseModal = (changed: boolean) => {
          if (changed) refreshPage();
          setModal({ open: false });
        };

        const handleDelete = async (tag: string) => {
          showDelete({
            title: t('DELETE_TRANSLATION'),
            message: t('DELETE_TRANSLATION_MESSAGE'),
            onConfirm: async () => {
              await del(`/${tag}`);
              refreshPage();
            }
          });
        };

        return (
          <Block margin="1.25 0">
            <LoadingOverlay visible={loading} />
            {
              rows.length > 0 ?
              <Grid gutter="lg">
                {
                  rows.map((row) =>
                    <Grid.Col key={row.tag} xs={12} sm={6} md={12} lg={6}>
                      <Block bRadius="0.5" padding='0.625 0.938' style={{ border: `0.063rem solid ${BorderColor}` }}>
                        <Box fJustify='space-between'>
                          <Block>
                            <Box mb={0.625}>
                              <Typography variant="body" style={{ color: LabelColor }}>
                                <Typography variant="body-bold">
                                  {t('TAG')}:{' '}
                                </Typography>
                                {row.tag}
                              </Typography>
                            </Box>
                            <Box mb={0.625}>
                              <Typography variant="body" style={{ color: LabelColor }}>
                                <Typography variant="body-bold">
                                  {t('PLATFORM')}:{' '}
                                </Typography>
                                {t(GetPlatformTag(row.platform))}
                              </Typography>
                            </Box>
                          </Block>
                          <DropdownMenu 
                            render={<Icon icon='outlined_dotsVertical' color={LabelColor} />}
                            menuPosition='bottom-end'
                            actions={[
                              {
                                type: 'link',
                                label: t('EDIT'),
                                onClick: () => setModal({ open: true, initialValues: row })
                              },
                              {
                                type: 'divider'
                              },
                              {
                                type: 'link',
                                label: t('DELETE'),
                                onClick: () => handleDelete(row.tag)
                              }
                            ]}
                          />
                        </Box>
                        <TabsWrapper>
                          <Tabs radius="md" defaultValue="pt">
                            <Tabs.List>
                              {
                                languages.map((lang) => 
                                  <Tabs.Tab
                                    key={lang.code}
                                    icon={
                                      <img style={{ width: '18px' }} src={lang.icon} />
                                    }
                                    value={lang.code}
                                  >
                                    {t(lang.tag)}
                                  </Tabs.Tab>
                                )
                              }
                            </Tabs.List>
                            {
                              languages.map((lang) =>
                                <Tabs.Panel key={lang.code} value={lang.code}>
                                  <Block
                                    borderRadius="16px"
                                    margin="10px 0 0 0"
                                    padding="10px"
                                  >
                                    <Typography variant="body">
                                      {(row?.value && row.value[lang.code]) || 'N/A'}
                                    </Typography>
                                  </Block>
                                </Tabs.Panel>
                              )
                            }
                          </Tabs>
                        </TabsWrapper>
                      </Block>
                    </Grid.Col>
                  )
                }
              </Grid>
              :
              <Block bRadius={0.5} style={{ border: `0.063rem solid ${BorderColor}` }}>
                <EmptyState />
              </Block>
            }
            <TranslationModal
              opened={modal.open}
              initialValues={modal.initialValues}
              onClose={handleCloseModal}
            />
          </Block>
        );
      }}
    </TranslateListPage>
  );
};

export default TranslationsPage;
