export const ScrollBarThumbHover = '#444444';
export const ScrollBarThumb = '#6F6F6F';
export const ScrollBarTrack = '#F5F5F5';

export const BgColor = '#15161A';
export const MenuBgColor = '#1B1C21';
export const MenuActiveColor = '#22252E';

export const SuccessColor = '#4CAF50';
export const DangerColor = '#DD425A';

export const LabelColor = 'rgba(255, 255, 255, 0.9)';
export const PlaceholderColor = 'rgba(255, 255, 255, 0.42)';
export const DisabledColor = 'rgba(255, 255, 255, 0.32)';
export const SubMenuColor = 'rgba(255, 255, 255, 0.6)';
export const FocusColor = '#7688C9';
export const BorderColor = '#27282E';

export const PaginationCountColor = '#565758';
export const PaginationActiveColor = 'rgba(118, 136, 201, 0.8)';
export const PaginationHoverColor = 'rgba(118, 136, 201, 0.08)';
export const PaginationColor = 'rgba(255, 255, 255, 0.9)';
export const PaginationArrows = 'rgba(255, 255, 255, 0.32)';
export const PaginationArrowsHover = 'rgba(255, 255, 255, 0.9)';

export const TableHeader = 'rgba(255, 255, 255, 0.7)';

export const DropdownMenuBg = '#2F3139';

export const AlertErrorBg = '#dd425a';
export const AlertErrorBorder = 'rgba(221, 66, 90, 0.35)';
export const AlertSuccessBg = '#4CAF50';
export const AlertSuccessBorder = 'rgba(76, 175, 80, 0.35)';
export const AlertWarningBg = '#FFA000';
export const AlertWarningBorder = 'rgba(255, 160, 0, 0.35)';
export const AlertInfoBg = '#00BDCB';
export const AlertInfoBorder = 'rgba(0, 189, 203, 0.35)';

export const SwitchBgColor = '#5A5B63';

export const UserAvatarBg = '#44454A';

export const LoginButtonColor = '#181819';

export const FiltersBgColor = '#232429';
export const FiltersHeaderBgColor = '#1A1B1F';

export const DividerColor = 'rgba(255, 255, 255, 0.1)';

export const WhiteJudge = '#ffffff';
export const GreenJudge = '#59D683';
export const YellowJudge = '#E4E36E';

export const BlueFighter = '#282DD6';
export const RedFighter = '#CC0000';

export const GraphBorder = '#787878';
export const GraphRed = '#EC4840';
export const GraphYellow = '#FFFD61';
export const GraphGreen = '#4A863D';
export const GraphWhiteSelected = 'rgba(255, 255, 255, 0.7)';
export const GraphGreenSelected = 'rgba(89, 214, 131, 0.7)';
export const GraphYellowSelected = 'rgba(228, 227, 110, 0.7)';

export const ConsistencyGreen = '#4CAF50';
export const ConsistencyGreenBg = 'rgba(76, 175, 80, 0.15)';
export const ConsistencyYellow = '#FFFD61';
export const ConsistencyYellowBg = 'rgba(255, 253, 97, 0.3)';
export const ConsistencyRed = '#EC4840';
export const ConsistencyRedBg = 'rgba(236, 72, 64, 0.15)';

export const G800 = '#111827';
export const G700 = '#1F2937';
export const G600 = '#374151';
export const G500 = '#4B5563';
export const G400 = '#6B7280';
export const G300 = '#9CA3AF';
export const G200 = '#E5E7EB';
export const G100 = '#F9FAFB';

export const B800 = '#1C219B';
export const B700 = '#2328BE';
export const B600 = '#282DD6';
export const B500 = '#4147DC';
export const B400 = '#5357DF';
export const B300 = '#7579E6';
export const B200 = '#BABCF2';
export const B100 = '#DDDDF9';

export const P800 = '#520052';
export const P700 = '#660066';
export const P600 = '#800080';
export const P500 = '#A300A3';
export const P400 = '#FF5CFF';
export const P300 = '#FFADFF';
export const P200 = '#FFD6FF';
export const P100 = '#FFEBFF';

export const R800 = '#A30000';
export const R700 = '#B80000';
export const R600 = '#CC0000';
export const R500 = '#F50000';
export const R400 = '#FF4747';
export const R300 = '#FF7070';
export const R200 = '#FFC2C2';
export const R100 = '#FFD6D6';

export const Gradient = 'linear-gradient(90.2deg, #CC0000 -20.86%, #800080 51.08%, #282DD6 118.59%)';