import { TableProps, TableRowProps, TableHeadProps, TableBodyProps, TableCellProps } from './table';
import { AnyObject } from '../../models/Generic';
import styled, { css } from 'styled-components';

export const TableComponent = styled.table<TableProps & { tableStyles?: AnyObject, showTableHeaders?: boolean }>`
  border: none;
  border-spacing: ${({ tableStyles }) => tableStyles?.compact ? '0' : '0 0.75rem'};
  width: 100%;
  border-collapse: separate; 
  margin-top: ${({ tableStyles }) => tableStyles?.compact ? '0.75rem' : '0'};

  /* TABLE HEADER */

  & thead > tr {
    height: 3rem;
    background: ${({ theme }) => theme.colors.menuBgColor};
    color: ${({ theme }) => theme.colors.tableHeader};

    & td.sticky {
      background: ${({ theme }) => theme.colors.menuBgColor};
      z-index: 1;
    }
  }

  & thead > tr > td {
    padding: 0.25rem 0.5rem;
    border-top: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
    border-bottom: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
  }

  & thead > tr > td:first-child {
    border-left: ${({ tableStyles, theme }) => tableStyles?.noSideBorders ? 0 : `0.063rem solid ${theme.colors.borderColor}`};
    border-top-left-radius: ${({ tableStyles }) => tableStyles?.noBorderRadius ? 0 : '0.5rem'};

    ${({ tableStyles }) => !tableStyles?.compact &&
      css`
        border-bottom-left-radius: ${tableStyles?.noBorderRadius ? 0 : '0.5rem'};
      `
    }
  }

  & thead > tr > td:last-child {
    border-right: ${({ tableStyles, theme }) => tableStyles?.noSideBorders ? 0 : `0.063rem solid ${theme.colors.borderColor}`};
    border-top-right-radius: ${({ tableStyles }) => tableStyles?.noBorderRadius ? 0 : '0.5rem'};

    ${({ tableStyles }) => !tableStyles?.compact &&
      css`
        border-bottom-right-radius: ${tableStyles?.noBorderRadius ? 0 : '0.5rem'};
      `
    }
  }

  /* TABLE BODY */

  & tbody > tr {
    height: ${({ tableStyles }) => tableStyles?.compact ? '3.5rem' : '4rem'};
    padding: 0.25rem 0.5rem;
    color: ${({ theme }) => theme.colors.labelColor};

    ${({ tableStyles }) => tableStyles?.alternateRowColor ?
      css`
        &:nth-child(odd) {
          background: ${({ theme }) => theme.colors.bgColor};

          & td.sticky {
            background: ${({ theme }) => theme.colors.bgColor};
            z-index: 1;
          }
        }

        &:nth-child(even) {
          background: ${({ theme }) => theme.colors.menuBgColor};

          & td.sticky {
            background: ${({ theme }) => theme.colors.menuBgColor};
            z-index: 1;
          }
        }
      `
      :
      css`
        background: ${({ theme }) => theme.colors.menuBgColor};

        & td.sticky {
          background: ${({ theme }) => theme.colors.menuBgColor};
          z-index: 1;
        }
      `
    }
  }

  & tbody > tr > td {
    padding: 0.25rem 0.5rem;

    ${({ tableStyles }) => tableStyles?.compact ?
      css`
        border-bottom: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
      `
      :
      css`
        border-top: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
        border-bottom: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
      `
    }
  }

  & tbody > tr > td:first-child {
    border-left: ${({ tableStyles, theme }) => tableStyles?.noSideBorders ? 0 : `0.063rem solid ${theme.colors.borderColor}`};

    ${({ tableStyles }) => !tableStyles?.compact &&
      css`
        border-top-left-radius: ${tableStyles?.noBorderRadius ? 0 : '0.5rem'};
        border-bottom-left-radius: ${tableStyles?.noBorderRadius ? 0 : '0.5rem'};
      `
    }
  }

  & tbody > tr:first-child {
    ${({ showTableHeaders, tableStyles }) => !showTableHeaders &&
      css`
        & > td {
          border-top: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
        }

        & > td:first-child {
          border-top-left-radius: ${tableStyles?.noBorderRadius ? 0 : '0.5rem'};
        }

        & > td:last-child {
          border-top-right-radius: ${tableStyles?.noBorderRadius ? 0 : '0.5rem'};
        }
      `
    }
  }

  & tbody > tr > td:last-child {
    border-right: ${({ tableStyles, theme }) => tableStyles?.noSideBorders ? 0 : `0.063rem solid ${theme.colors.borderColor}`};

    ${({ tableStyles }) => !tableStyles?.compact &&
      css`
        border-bottom-right-radius: ${tableStyles?.noBorderRadius ? 0 : '0.5rem'};
        border-top-right-radius: ${tableStyles?.noBorderRadius ? 0 : '0.5rem'};
      `
    }
  }

  & tbody > tr:last-child {
    ${({ tableStyles }) => tableStyles?.compact &&
      css`
        & > td:first-child {
          border-bottom-left-radius: ${tableStyles?.noBorderRadius ? 0 : '0.5rem'};
        }

        & > td:last-child {
          border-bottom-right-radius: ${tableStyles?.noBorderRadius ? 0 : '0.5rem'};
        }
      `
    }
  }
`;

export const TableRowComponent = styled.tr<TableRowProps>`
  background-color: ${({ highlight, highlightColor, theme }) => highlight ? highlightColor || theme.colors.g400 : 'initial'};
`;

export const TableHeadComponent = styled.thead<TableHeadProps>``;

export const TableBodyComponent = styled.tbody<TableBodyProps>``;

export const TableCellComponent = styled.td<TableCellProps>`
  position: relative;
  width: ${({ width}) => width};
  cursor: ${({ hasClick }) => hasClick ? 'pointer' : 'initial'};

  &.sticky {
    position: sticky;
    left: 0;
  }
`;

export const TableSection = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
`;

export const HiddenTR = styled.tr`
  cursor: default;
  background-color: initial;
  padding: 0 !important;
  height: 0 !important;

  &:hover {
    background-color: initial !important;
  }
`;

export const HiddenTD = styled.td`
  &&& {
    min-height: 0;
    padding: 0;
    margin: 0;
    border-bottom: none;
  }
`;

export const HeaderFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

export const TablePagination = styled.div`
  margin-top: 1.25rem;
`;

export const EmptyTable = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 16.25rem;
  flex-direction: column;
`;

export const ExpandableSection = styled.div`
  padding: 1rem;
`;

export const ExpandableIcon = styled.div`
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
