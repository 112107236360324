/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../hooks/use-validation/use-validation-schema';
import { BasicForm, Block } from '../../../../styles/BasicStyles';
import { FC, useContext, useEffect, useState } from 'react';
import { Fight, Deduction } from '../../../../models/Fight';
import { showConfirm, showSuccess } from '../../../../hooks/show-notification/show-notification';
import { MillisecondsToDate } from '../../../../utils/dates';
import { InfoContext } from '../../../../Context';
import { GetFirstAndLastName } from '../../../../utils/user';
import { AnyObject } from '../../../../models/Generic';
import DeductionItem from '../../DeductionItem';
import Divider from '../../../../components/divider';
import NewDeduction from './NewDeduction';
import Modal, { ModalActions, ModalOverflowContent } from '../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../components/button';

interface Params {
  opened: boolean;
  onClose: () => void;
  initialValues?: any;
  fight?: Fight;
  reloadInfo: () => void;
}

export interface FormInitialValues {
  deductions: Deduction[];
}

const { Form } = withTypes<FormInitialValues>();

const EditDeductionsModal: FC<Params> = ({ opened, onClose, initialValues, fight, reloadInfo }) => {
  const { t, i18n: { language } } = useTranslation();
  const { info } = useContext(InfoContext);
  const { put } = useFetch('/fights');
  const [deductionsList, setDeductionsList] = useState<Deduction[]>([]);
  const [editItem, setEditItem] = useState<number | undefined>(undefined);
  const [formPristine, setFormPristine] = useState<boolean>(true);
  
  useEffect(() => {
    setDeductionsList(getInitialValues());
    setEditItem(undefined);
    setFormPristine(true);
  }, [opened, initialValues]);

  const onSubmit = async (values: FormInitialValues) => {
    const { success } = await put(`/${fight?._id}/deductions`, values);

    if(success) {
      showSuccess({
        title: t('SUCCESS'),
        message: t('FIGHT_CHANGE_SUCCESS')
      });

      onClose();
      reloadInfo();
    }
  };

  const getInitialValues = () => {
    const list: Deduction[] = [];
    fight?.record?.deductions.forEach((deduction: AnyObject) => {
      if(deduction.red > 0) {
        list.push({
          round: deduction.round,
          time: deduction.time,
          date: MillisecondsToDate(deduction.time || 0),
          points: deduction.red,
          reason: deduction.reason?._id,
          reasonName: deduction.reason?.name && deduction.reason.name[language],
          fighter: 'red',
          fighterName: fight?.red_fighter?.name
        });
      }
      if(deduction.blue > 0) {
        list.push({
          round: deduction.round,
          time: deduction.time,
          date: MillisecondsToDate(deduction.time || 0),
          points: deduction.blue,
          reason: deduction.reason?._id,
          reasonName: deduction.reason?.name && deduction.reason.name[language],
          fighter: 'blue',
          fighterName: fight?.blue_fighter?.name
        });
      }
    });

    return list;
  };

  const fightersList: AnyObject[] = [];
  if(!!fight?.red_fighter) fightersList.push({ _id: 'red', name: `Red (${GetFirstAndLastName(fight.red_fighter?.name)})` });
  if(!!fight?.blue_fighter) fightersList.push({ _id: 'blue', name: `Blue (${GetFirstAndLastName(fight.blue_fighter?.name)})` });

  const reasonsList = info?.submissions?.filter((elem: AnyObject) => elem.type === 'ACCIDENTAL' || elem.type === 'FIGHTER_FAULT' || elem.type === 'ILLEGAL_BLOW') || [];

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          deductions: yup.array().of(yup.object({
            round: yup.number().required(),
            date: yup.date().required(),
            points: yup.string().required(),
            reason: yup.string().required(),
            fighter: yup.string().required()
          }))
        })
      )}
      initialValues={{ deductions: deductionsList } || {}}
      render={({ handleSubmit, submitting, form: { reset, submit } }) => {
        const handleClose = () => {
          if(!formPristine) {
            showConfirm({
              title: t('EXIT_BEFORE_SAVE'),
              message: t('EXIT_BEFORE_SAVE_MESSAGE'),
              onConfirm: () => {
                onClose();
                reset();
              }
            });
          }
          else {
            onClose();
            reset();
          }
        };

        const handleSave = () => {
          showConfirm({
            title: t('CONFIRM_BEFORE_SAVE'),
            message: t('CONFIRM_BEFORE_SAVE_FIGHT'),
            onConfirm: () => submit()
          });
        }

        return (
          <Modal
            size={38}
            opened={opened}
            onClose={handleClose}
            title={t('VIEW_EDIT_DEDUCTIONS')}
          >
            <BasicForm onSubmit={handleSubmit}>
              <ModalOverflowContent>
                <NewDeduction 
                  fight={fight}
                  fightersList={fightersList}
                  reasonsList={reasonsList}
                  deductionsList={deductionsList}
                  setDeductionsList={setDeductionsList}
                  setFormPristine={setFormPristine}
                />
                <Divider />
                {
                  deductionsList.length > 0 &&
                  deductionsList.map((elem: Deduction, index: number) =>
                    <Block w='100%' key={index}>
                      <DeductionItem 
                        fight={fight}
                        deduction={elem} 
                        index={index} 
                        fightersList={fightersList}
                        reasonsList={reasonsList}
                        editItem={editItem}
                        setEditItem={setEditItem}
                        deductionsList={deductionsList}
                        setDeductionsList={setDeductionsList}
                        setFormPristine={setFormPristine}
                      />
                    </Block>
                  )
                }
              </ModalOverflowContent>
              <ModalActions>
                <Button
                  text={t('CANCEL')}
                  variant="secondary"
                  onClick={handleClose}
                />
                <Button
                  text={t('SAVE')}
                  loading={submitting}
                  disabled={formPristine}
                  onClick={handleSave}
                />
              </ModalActions>
            </BasicForm>
          </Modal>
        );
      }}
    />
  );
};

export default EditDeductionsModal;
