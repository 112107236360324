import { FC } from 'react';
import { StyledMenu, StyledDropdown, StyledItem, StyledTitle, ButtonWrapper, StyledDivider } from './styles';
import { Menu, UnstyledButton } from '@mantine/core';
import { Block } from '../../styles/BasicStyles';
import { Gradient } from '../../styles/Colors';
import Icon, { IconTypes } from '../icon';
import Typography from '../typography';

type FloatingPlacement = 'end' | 'start';
type FloatingSide = 'top' | 'right' | 'bottom' | 'left';
type FloatingPosition = FloatingSide | `${FloatingSide}-${FloatingPlacement}`;

export type ActionProps = {
  type: 'link' | 'title' | 'divider';
  label?: string;
  onClick?: () => void;
};

export type DropdownSize = 'xs' | 'md' | undefined;

export interface MenuProps {
  icon?: IconTypes;
  text: string;
  menuPosition?: FloatingPosition | undefined;
  actions: ActionProps[];
  size?: DropdownSize;
  bgColor?: string;
  fontColor?: string;
}

const renderAction = (action: ActionProps, index: number) => {
  if(action.type === 'link') {
    return (
      <StyledItem key={index} onClick={action.onClick}>
        <Typography variant='body'>{action.label}</Typography>
      </StyledItem>
    );
  }

  if(action.type === 'divider') {
    return (
      <StyledDivider key={index} />
    );
  }

  if(action.type === 'title') {
    return (
      <StyledTitle key={index}>
        <Typography variant='sidebar-group'>{action.label}</Typography>
      </StyledTitle>
    );
  }

  return null;
}

export const DropdownMenu: FC<MenuProps> = ({ icon, text, menuPosition, actions, size = 'md', bgColor = Gradient, fontColor = '#ffffff' }) => (
  <Block position='relative'>
    <StyledMenu position={menuPosition || 'bottom'}>
      <Menu.Target>
        <UnstyledButton>
          <ButtonWrapper fAlign='center' fJustify='space-between' bgColor={bgColor} size={size}>
            <Block display='flex' fAlign='center' fDirection='row'>
              {!!icon && <Icon icon={icon} size={1} color={fontColor} />}
              <Typography variant='dropdown-menu' lHeight={1.5} color={fontColor} pl={!!icon ? 0.75 : 0} pr={0.75}>{text}</Typography>
            </Block>
            <Icon icon='outlined_chevronDown' size={0.75} color={fontColor} />
          </ButtonWrapper> 
        </UnstyledButton>
      </Menu.Target>
      {
        actions.length > 0 &&
        <StyledDropdown>
          {
            actions.map((action: ActionProps, index: number) => renderAction(action, index))
          }
        </StyledDropdown>
      }
    </StyledMenu>
  </Block>
);

export default DropdownMenu;
