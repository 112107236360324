/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { useDidUpdate } from '@mantine/hooks';
import { PageHeader, PageHeaderProps } from './PageHeader';
import { PageFilters, FilterChildrenProps } from './PageFilters';
import { PlaceholderColor } from '../../styles/Colors';
import { Box, Block } from '../../styles/BasicStyles';
import Table, { TableColumnsProps, TableSelectionProps, TableSortProps } from '../table';
import Pagination from '../pagination';
import React from 'react';
import ConstructPaginationRequest from '../../utils/requests/ConstructPaginationRequest';
import Typography from '../typography';

interface AnyObject {
  [key: string]: any;
}

export interface PaginationResponse<T> {
  data: {
    items: T[];
    limit: number;
    page: number;
    pages: number;
    total: number;
  };
  success: boolean;
}

export interface FetchAllResponse<T> {
  data: T[];
  success: boolean;
}

export interface PaginationObject {
  page: number;
  limit: number;
  filters?: AnyObject | undefined;
  sort?: TableSortProps | undefined;
}

export interface ListPageChildren<T = unknown> {
  rows: T[];
  loading: boolean;
  pagination: PaginationObject;
  total: number;
  refreshPage: (values?: PaginationObject) => void;
  handleNewSort: (sort: TableSortProps) => void;
}

interface FetchOptions<T> {
  get: (route?: string | undefined) => Promise<PaginationResponse<T> | FetchAllResponse<T>>;
  defaultPage?: number;
  defaultLimit?: number;
  searchFields?: string[];
  reloadOnChange?: Array<string | undefined>;
  fetchAll?: boolean;
}

export interface ListPageProps<T = unknown> {
  request: FetchOptions<T>;
  header?: PageHeaderProps;
  storage?: string;
  filters?: React.FC<FilterChildrenProps>;
  tableExtras?: React.FC<ListPageChildren<T>>;
  footerExtras?: React.FC<ListPageChildren<T>>;
  refreshCallback?: (
    pagination: PaginationObject,
    rows: T[],
    total: number
  ) => void;
  table?: {
    columns: TableColumnsProps[];
    onRowClick?: (value: T, index: number) => void;
    expandable?: (value: T, index: number) => React.ReactNode;
    selection?: TableSelectionProps<T>;
    highlight?: string | ((data: T) => boolean);
    highlightColor?: string;
  };
  children?: React.FC<ListPageChildren<T>>;
  translate?: (tag: string, props?: any) => string;
  translateTags?: {
    emptyTitleTag?: string;
    emptyMessageTag?: string;
    selectPlaceholderTag?: string;
    itemsCounterTag?: string;
  };
  countLabel?: string;
  tableTitle?: string;
  maxW?: number;
}

export const DefaultListPage = <T extends AnyObject>() => {
  const TypedBaseTable = Table<T>();
  const TypedListPage: React.FC<ListPageProps<T>> = ({
    request,
    storage,
    header,
    filters,
    tableExtras,
    footerExtras,
    table,
    children,
    refreshCallback,
    translateTags,
    countLabel,
    tableTitle,
    maxW
  }) => {
    const [ready, setReady] = useState<boolean>(false);
    const [pagination, setPagination] = useState<PaginationObject>({
      page: request.defaultPage || 1,
      limit: request.defaultLimit || 20,
    });
    const [rows, setRows] = useState<T[]>([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const reloadList = request.reloadOnChange || [];

    useEffect(() => {
      const init = () => {
        const defaultPagination: PaginationObject = {
          page: request.defaultPage || 1,
          limit: request.defaultLimit || 20,
        };
        if (storage) {
          const json = localStorage.getItem(storage);
          const obj = JSON.parse(json || '{}');
          if (obj.page) defaultPagination.page = obj.page;
          if (obj.limit) defaultPagination.limit = obj.limit;
          if (obj.filters) defaultPagination.filters = obj.filters;
          if (obj.sort) defaultPagination.sort = obj.sort;
        }
        setPagination(defaultPagination);
        setReady(true);
      };
      
      init();
    }, reloadList);

    useDidUpdate(() => {
      const init = async () => {
        setLoading(true);

        if(request.fetchAll) {
          const url = ConstructPaginationRequest(
            '',
            pagination.filters,
            pagination.sort,
            request.searchFields
          );
          const result: any = await request.get(url);
          setRows(result?.data || []);
          setTotal(result?.data?.length || 0);
          setLoading(false);
        }
        else {
          const url = ConstructPaginationRequest(
            `/${pagination.page}/${pagination.limit}`,
            pagination.filters,
            pagination.sort,
            request.searchFields
          );
          const result: any = await request.get(url);
          if (pagination.page > 1 && (result?.data?.items || []).length === 0) {
            const pag = { ...pagination, page: 1 };
            handleNewPagination(pag);
          } else {
            setRows(result?.data?.items || []);
            setTotal(result?.data?.total || 0);
            setLoading(false);
            if (refreshCallback)
              refreshCallback(
                pagination,
                result?.data?.items,
                result?.data?.total
              );
          }
        }
      };
      init();
    }, [pagination]);

    const handleNewPagination = (pag: PaginationObject) => {
      if (storage) localStorage.setItem(storage, JSON.stringify(pag));
      setPagination(pag);
    };

    const handleNewPage = (page: number) => {
      const pag = { ...pagination, page };
      handleNewPagination(pag);
    };

    const handleNewSort = (sort: TableSortProps) => {
      const pag = { ...pagination, sort };
      handleNewPagination(pag);
    };

    const handleNewFilters = (filters: AnyObject) => {
      const pag = { ...pagination, page: 1, filters };
      handleNewPagination(pag);
    };

    const refreshPage = (params?: PaginationObject) => {
      const pag = params ? { ...params } : { ...pagination };
      handleNewPagination(pag);
    };

    if (!ready) return null;

    return (
      <>
        {
          header &&
          <PageHeader
            title={header.title}
            actions={header.actions}
          />
        }
        {
          <Box fAlign='center' fJustify='space-between' fDirection={{ sm: 'row', xxs: 'column' }} fWrap='wrap' maxW={maxW || '100%'} margin='auto'>
            {
              (!!tableTitle || !! countLabel) &&
              <Block display='flex' fDirection='column' w={{ sm: '40%', xxs: '100%' }} mb={{ sm: 0, xxs: 0.5 }}>
                {!!tableTitle && <Typography variant='sidebar-group' style={{ color: PlaceholderColor }} pb={!!countLabel ? 0.313 : 0}>{tableTitle}</Typography>}
                {!!countLabel && <Typography variant={!!tableTitle ? 'sidebar-group' : 'body'} color='#ffffff'>{`${total} ${countLabel}`}</Typography>}
              </Block>
            }
            {
              !!filters &&
              <Block w={{ sm: (!!tableTitle || !! countLabel) ? '60%' : '100%', xxs: '100%' }}>
                <PageFilters
                  initialFilters={pagination.filters}
                  onChange={handleNewFilters}
                >
                  {filters}
                </PageFilters>
              </Block>
            }
          </Box>
        }
        {
          tableExtras &&
          tableExtras({
            rows,
            loading,
            pagination,
            total,
            handleNewSort,
            refreshPage,
          })
        }
        {
          table &&
          <TypedBaseTable
            refreshPage={refreshPage}
            columns={table.columns}
            rows={rows}
            onRowClick={table.onRowClick}
            loading={loading}
            defaultSort={pagination.sort}
            sortCallback={handleNewSort}
            expandable={table.expandable}
            selection={table.selection}
            highlight={table.highlight}
            highlightColor={table.highlightColor}
            emptyTitleTag={translateTags?.emptyTitleTag}
            emptyMessageTag={translateTags?.emptyMessageTag}
            pagination={{
              total,
              page: pagination.page,
              setPage: handleNewPage,
              limit: pagination.limit
            }}
          />
        }
        {
          children &&
          children({ rows, loading, pagination, total, handleNewSort, refreshPage })
        }
        {
          !request.fetchAll && children && rows.length > 0 &&
          <Pagination
            total={total}
            page={pagination.page}
            setPage={handleNewPage}
            limit={pagination.limit}
            maxW={maxW}
          />
        }
        {
          footerExtras &&
          footerExtras({
            rows,
            loading,
            pagination,
            total,
            handleNewSort,
            refreshPage,
          })
        }
      </>
    );
  };
  return TypedListPage;
};

export default DefaultListPage;
