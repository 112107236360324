/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { FC, useState, useEffect } from 'react';
import { Deduction, Fight } from '../../models/Fight';
import { Box, Block } from '../../styles/BasicStyles';
import { FocusColor, MenuBgColor, BlueFighter, RedFighter, G500 } from '../../styles/Colors';
import { AnyObject } from '../../models/Generic';
import { DotIcon } from '../../components/inputs/Styles';
import { MillisecondsToTime, TimeToMilliseconds } from '../../utils/dates';
import { GetLastName } from '../../utils/user';
import { Field } from 'react-final-form';
import { Rounds } from '../../utils/rounds';
import { requiredField } from '../../hooks/use-validation/use-validation-schema';
import { showConfirm } from '../../hooks/show-notification/show-notification';
import TextInput from '../../components/inputs/TextInput';
import Typography from '../../components/typography';
import Icon from '../../components/icon';
import SelectInput from '../../components/inputs/SelectInput';
import TimeInput from '../../components/inputs/TimeInput';

interface DeductionParams {
  fight?: Fight;
  deduction: Deduction;
  index: number;
  fightersList?: AnyObject[];
  reasonsList?: AnyObject[];
  editItem?: number | undefined;
  setEditItem?: (value?: number) => void;
  deductionsList?: Deduction[];
  setDeductionsList?: (values: Deduction[]) => void;
  setFormPristine?: (value: boolean) => void;
  hideCounter?: boolean;
}

const DeductionItem: FC<DeductionParams> = ({ 
  fight, 
  deduction, 
  index, 
  fightersList, 
  reasonsList, 
  editItem, 
  setEditItem, 
  deductionsList = [], 
  setDeductionsList,
  setFormPristine,
  hideCounter = false
}) => {
  const { t, i18n: { language } } = useTranslation();
  const [round, setRound] = useState(deduction.round || '');
  const [date, setDate] = useState(deduction.date || undefined);
  const [fighter, setFighter] = useState(deduction.fighter || '');
  const [points, setPoints] = useState(deduction.points || undefined);
  const [reason, setReason] = useState(deduction.reason || '');

  useEffect(() => {
    saveEditItem(index);
  }, [round, date, fighter, points, reason]);

  const saveEditItem = (index: number) => {
    if(!!setFormPristine && !!setDeductionsList && round && date && fighter && points && reason) {
      const reasonName = reasonsList?.find(elem => elem._id === reason)?.name;

      const aux: Deduction[] = [...deductionsList];
      aux[index].round = round;
      aux[index].date = date;
      aux[index].time = TimeToMilliseconds(date);
      aux[index].fighter = fighter;
      aux[index].fighterName = fighter === 'red' ? fight?.red_fighter?.name : fighter === 'blue' ? fight?.blue_fighter?.name : '';
      aux[index].points = points;
      aux[index].reason = reason;
      aux[index].reasonName = reasonName && reasonName[language];

      setDeductionsList(aux);
      setFormPristine(false);
    }
  };

  const removeItem = (index: number) => {
    if(!!setFormPristine && !!setDeductionsList) {
      showConfirm({
        title: t('CONFIRM_BEFORE_SAVE'),
        message: t('DELETE_DEDUCTION_MESSAGE'),
        onConfirm: () => {
          const aux: Deduction[] = [...deductionsList];
          aux.splice(index, 1);
          setDeductionsList(aux);
          setFormPristine(false);
        }
      });
    }
  };

  return (
    <>
      {
        editItem === index && !!setEditItem ?
        <Box fWrap='wrap' bgColor={G500} padding='0.5 0.75'>
          <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
            <Field name={`deductions[${index}].round`} validate={requiredField}>
              {(props) => 
                <SelectInput 
                  {...props} 
                  label={`${t('ROUND')} *`}
                  placeholder={t('CHOOSE_ROUND')}
                  data={Rounds}
                  afterChange={(value: any) => setRound(value)}
                />
              }
            </Field>
          </Box>
          <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
            <Field name={`deductions[${index}].fighter`} validate={requiredField}>
              {(props) => 
                <SelectInput 
                  {...props} 
                  label={`${t('FIGHTER')} *`}
                  placeholder={t('CHOOSE_FIGHTER')}
                  data={fightersList || []}
                  afterChange={(value: any) => setFighter(value)}
                />
              }
            </Field>
          </Box>
          <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
            <Field name={`deductions[${index}].reason`} validate={requiredField}>
              {(props) => 
                <SelectInput 
                  {...props} 
                  label={`${t('REASON')} *`}
                  placeholder={t('CHOOSE_REASON')}
                  data={reasonsList || []}
                  intl
                  afterChange={(value: any) => setReason(value)}
                />
              }
            </Field>
          </Box>
          <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
            <Field name={`deductions[${index}].date`} validate={requiredField}>
              {(props) => 
                <TimeInput 
                  {...props} 
                  label={`${t('TIME')} *`} 
                  withSeconds
                  afterChange={(value: any) => setDate(value)}
                />
              }
            </Field>
          </Box>
          <Box w={{ xs: '33.333%', xxs: '100%' }} padding='0 0.5' mb={0.5}>
            <Field name={`deductions[${index}].points`} validate={requiredField}>
              {(props) =>
                <TextInput
                  {...props}
                  label={`${t('POINTS_TO_SUBTRACT')} *`}
                  placeholder={t('ENTER_POINTS')}
                  htmlType='number'
                  onlyIntegers
                  afterChange={(value: any) => setPoints(value)}
                />
              }
            </Field>
          </Box>
          <Box 
            fJustify='flex-end' 
            w={{ xs: '33.333%', xxs: '100%' }} 
            padding='0 0.5' 
            mt={{ xs: 2.375, xxs: 0.25 }} 
            mb={{ xs: 0, xxs: 1 }}
          >
            <Icon 
              icon='outlined_checkCircle' 
              hoverColor={FocusColor} 
              title={t('SAVE_CLOSE')} 
              onClick={() => setEditItem(undefined)} 
              size={1.5} 
            />
          </Box>
        </Box>
        :
        <Box 
          fAlign='center' 
          fWrap='wrap' 
          bgColor={index % 2 === 0 ? '#1F2025' : MenuBgColor} 
          padding='0.5 0.75'
        >
          <Box 
            fAlign='center' 
            w={!!setEditItem ? { xs: '90%', xxs: '100%' } : '100%'} 
            order={{ xs: 1, xxs: 2 }}
          >
            {
              !hideCounter &&
              <Box w='5%'>
                <Typography 
                  variant='body-small' 
                  fWeight={500} 
                  color='#ffffff'
                >
                  {index + 1}.
                </Typography>
              </Box>
            }
            <Box fDirection='column' w={hideCounter ? '33.333%' : '31%'} padding='0 0.5'>
              <Typography 
                variant='body-small' 
                color='#ffffff'
              >
                {`${t('ROUND')} ${deduction.round}`}
              </Typography>
              <Typography 
                variant='body-small' 
                color='#ffffff'
              >
                {MillisecondsToTime(deduction.time)}
              </Typography>
            </Box>
            <Box fDirection='column' w={hideCounter ? '33.333%' : '32%'} padding='0 0.5'>    
              <Block display='flex' fDirection='row' fAlign='center'>
                <DotIcon 
                  width={0.5} 
                  height={0.5} 
                  color={deduction.fighter === 'red' ? RedFighter : deduction.fighter === 'blue' ? BlueFighter : ''} 
                />
                <Typography 
                  variant='body-small' 
                  color='#ffffff' 
                  pl={0.5}
                >
                  {GetLastName(deduction.fighterName)}
                </Typography>
              </Block>
              <Typography 
                variant='body-small' 
                color={FocusColor}
              >
                {`-${deduction.points} ${t('POINTS').toLowerCase()}`}
                </Typography>
            </Box>
            <Block w={hideCounter ? '33.333%' : '32%'} padding='0 0.5'>
              <Typography 
                variant='body-small' 
                color='#ffffff'
                wBreak='break-word'
              >
                {deduction.reasonName}
              </Typography>
            </Block>
          </Box>
          {
            !!setEditItem &&
            <Box 
              w={{ xs: '10%', xxs: '100%' }} 
              padding='0 0.5' 
              fAlign='center' 
              order={{ xs: 2, xxs: 1 }}
              mb={{ xs: 0, xxs: 0.25 }}
              fJustify='flex-end'
            >
              <Icon 
                icon='outlined_pencil' 
                hoverColor={FocusColor} 
                title={t('EDIT')} 
                onClick={() => setEditItem(index)} 
                style={{ marginRight: '0.25rem' }} 
              />
              <Icon 
                icon='outlined_x' 
                hoverColor={FocusColor} 
                title={t('REMOVE')} 
                onClick={() => removeItem(index)} 
                size={1.25} 
              />
            </Box>
          }
        </Box>
      }
    </>
  );
};

export default DeductionItem;
