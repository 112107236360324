import { FC, ReactNode, useEffect, useState} from 'react';
import { AccordionTitleWrapper, AccordionContentWrapper } from './styles';
import { Box, Block } from '../../styles/BasicStyles';
import Icon from '../icon';

export interface AccordionProps {
  title: string | ReactNode;
  content: ReactNode;
  isOpen?: boolean;
  titleClick?: boolean;
}

export const Accordion: FC<AccordionProps> = ({ 
  title,
  content,
  isOpen = false,
  titleClick = true
}) => {
  const [open, setOpen] = useState(isOpen);
  const [height, setHeight] = useState("0px");
  const [contentEl, setElement] = useState<Element | null>(null);
  
  useEffect(() => {
    if(isOpen)
    if(contentEl) setHeight(contentEl.scrollHeight.toString()+"px");
  }, [contentEl]);

  const openAccordion = () => {
    setOpen(!open);
    if (!contentEl) throw Error("divRef is not assigned");
    if(!open) {
      setHeight(contentEl.scrollHeight.toString()+"px");
    }
    else {
      setHeight("0px");
    }
  }
     
  return (
    <Box fDirection='column' mb={0.75}>
      <AccordionTitleWrapper fAlign='center' padding={{ xs: '1 1.5', xxs: '1' }}>
        <Block w='97%' onClick={titleClick && openAccordion}>{title}</Block>
        <Block w='3%' minW={1.5} display='flex' fJustify='flex-end' onClick={openAccordion}>
          <Icon icon={open ? 'outlined_chevronUp' : 'outlined_chevronDown'} />
        </Block>
      </AccordionTitleWrapper>
      <AccordionContentWrapper ref={setElement} style={{ height: height }} open={open}>
        {content}
      </AccordionContentWrapper>
    </Box>
  );
};

export default Accordion