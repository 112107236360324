import { Box } from '../../styles/BasicStyles';
import { breakpoints } from '../../styles/Breakpoints';
import styled from 'styled-components';

export const NotificationsWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: -0.5rem;
  width: 27.5rem;
  padding: 0 0.5rem;

  @media ${breakpoints.xs} {
    width: 18.75rem;
    right: 0;
    left: -11.5rem;
  }
`;

export const NotificationsTitle = styled(Box)`
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
`;

export const NotificationsContent = styled(Box)`
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  overflow: hidden;
`;

export const NotificationsOverflowContent = styled(Box)`
  overflow-y: auto;
`;