import { Event } from '../../../../models/Event';
import { Box, Block } from '../../../../styles/BasicStyles';
import { LabelColor, BgColor, MenuBgColor, BorderColor, FocusColor, PlaceholderColor } from '../../../../styles/Colors';
import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fight } from '../../../../models/Fight';
import { UserContext } from '../../../../Context';
import { PROMOTER } from '../../../../models/User';
import Typography from '../../../../components/typography';
import dayjs from 'dayjs';
import UserCard from '../../../../components/userCard';
import Consistency from '../../../../components/consistency';

type ContentProps = {
  row: Event
};

const EventContent: FC<ContentProps> = ({ row }) => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const enterDetailPage = (fight: Fight) => navigate(`/fights/${fight._id}`);

  const renderContentDesktop = () => {
    return (
      <Box display={{ sm: 'flex', xxs: 'none' }} fDirection='column'>
        {
          row?.fights?.map((fight: Fight, index: number) => (
            <Box 
              key={index} 
              padding='0.625 1.5' 
              bgColor={index % 2 === 0 ? BgColor : MenuBgColor} 
              style={{ borderBottom: `0.063rem solid ${BorderColor}`, cursor: 'pointer' }}
              onClick={() => enterDetailPage(fight)}
            >
              <Box w='97%' fAlign='center'>
                <Box pr={0.5} w='35%' fAlign='center'>
                  <Block display='flex' fDirection='column' mr={1.875}>
                    <Typography variant='body-small' color={FocusColor}>{`${t('FIGHT')[0].toUpperCase()}${t('FIGHT').substring(1)} ${fight.fight_number}`}</Typography>
                    <Typography variant='sidebar-group' style={{ color: PlaceholderColor }}>{dayjs(fight.start_datetime).format('DD MMM, HH:mm')}</Typography>
                  </Block>
                  <Typography variant='body-small' style={{ color: LabelColor }}>{fight.weight_class?.name && fight.weight_class.name[language]}</Typography>
                </Box>
                <Box padding='0 0.5' w='20%' fAlign='center'>
                  <UserCard user={fight.red_fighter} fight={fight} size='xs' />
                </Box>
                <Box padding='0 0.5' w='20%' fAlign='center'>
                  <UserCard user={fight.blue_fighter} fight={fight} size='xs' />
                </Box>
                <Block pl={0.5} w='10%'>
                  <Typography variant='body-small' style={{ wordBreak: 'break-all', color: LabelColor }}>{t(fight.result?.decision || '')}</Typography>
                </Block>
                <Block pl={0.5} w='10%'>
                  {
                    user?.type !== PROMOTER && fight.consistency?.consistency >= 0 &&
                    <Consistency fight={fight} />
                  }
                </Block>
              </Box>
            </Box>
          ))
        }
      </Box>
    );
  };

  const renderContentMobile = () => {
    return (
      <Box display={{ sm: 'none', xxs: 'flex' }} fDirection='column'>
        {
          row?.fights?.map((fight: Fight, index: number) => (
            <Box 
              key={index} 
              padding='0.625 1.5' 
              bgColor={index % 2 === 0 ? BgColor : MenuBgColor} 
              style={{ borderBottom: `0.063rem solid ${BorderColor}`, cursor: 'pointer' }}
              onClick={() => enterDetailPage(fight)}
            >
              <Box w='97%' fAlign='center' fWrap='wrap'>
                <Box fAlign='center'>
                  <Block display='flex' fDirection='column' mr={1.875}>
                    <Typography variant='body-small' color={FocusColor}>{`${t('FIGHT')[0].toUpperCase()}${t('FIGHT').substring(1)} ${fight.fight_number}`}</Typography>
                    <Typography variant='sidebar-group' style={{ color: PlaceholderColor }}>{dayjs(fight.start_datetime).format('DD MMM, HH:mm')}</Typography>
                  </Block>
                  <Typography variant='body-small' style={{ color: LabelColor }}>{fight.weight_class?.name && fight.weight_class.name[language]}</Typography>
                </Box>
                <Box fAlign='center' padding='1 0' fWrap='wrap'>
                  <Box pr={0.5} pb={{ xs: 0, xxs: 0.75 }} w={{ xs: '35%', xxs: '100%' }} fAlign='center'>
                    <UserCard user={fight.red_fighter} fight={fight} size='xs' />
                  </Box>
                  <Box pr={0.5} pb={{ xs: 0, xxs: 0.75 }} w={{ xs: '35%', xxs: '100%' }} fAlign='center'>
                    <UserCard user={fight.blue_fighter} fight={fight} size='xs' />
                  </Box>
                  <Block pr={0.5} pb={{ xs: 0, xxs: 0.75 }} w={{ xs: '20%', xxs: '100%' }}>
                    <Typography variant='body-small' style={{ color: LabelColor }}>{t(fight.result?.decision || '')}</Typography>
                  </Block>
                  {
                    user?.type !== PROMOTER && fight.consistency?.consistency >= 0 &&
                    <Block w={{ xs: '10%', xxs: '100%' }}>
                      <Consistency fight={fight} />
                    </Block>
                  }
                </Box>
              </Box>
            </Box>
          ))
        }
      </Box>
    );
  };

  return (
    <>
      {
        row.fights && row.fights.length > 0 ?
        <Block mb={1.125}>
          {renderContentDesktop()}
          {renderContentMobile()}
        </Block>
        :
        <Block w='100%' tAlign='center' padding={1}>
          <Typography variant='body-small' color={LabelColor}>{t('NO_FIGHTS_TEXT')}</Typography>
        </Block>
      }
    </>
  );
};

export default EventContent;