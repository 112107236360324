/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode} from 'react';
import { LinkComponent } from './styles';
import { useNavigate } from 'react-router-dom';
import { AnyObject } from '../inputs/Generic';

export interface LinkProps {
  children?: ReactNode;
  underline?: boolean;
  width?: string;
  height?: string;
  href?: string;
  onClick?: () => void;
  style?: AnyObject;
  externalUrl?: boolean;
}

export const Link: FC<LinkProps & any> = ({ children, underline = false, width, height, href, onClick, externalUrl, ...props }) => {
  const navigate = useNavigate();
  
  const checkClick = () => {
    if(externalUrl && !!href) {
      window.open(href, '_blank');
    }
    else if(!!href) {
      navigate(href);
    }
    else if(!!onClick) {
      onClick();
    }
  }

  return <LinkComponent 
    width={width}
    height={height}
    underline={underline}
    onClick={checkClick}
    { ...props}
  >
    {children}
  </LinkComponent>;
};

export default Link;
