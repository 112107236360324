import { FloatingPlacement } from './index';
import { transitionsDelay } from '../../styles/BasicStyles';
import styled from 'styled-components';

export const FiltersWrapper = styled.div<{ open: boolean, menuPosition: FloatingPlacement }>` 
  position: absolute;
  bottom: ${({ menuPosition }) => menuPosition === 'top' ? '3rem' : '-25rem'};
  right: 0;
  width: 19.375rem;
  height: 24.688rem;
  background-color: ${({ theme }) => theme.colors.filtersBgColor};
  border: 0.063rem solid rgba(255, 255, 255, 0.02);
  border-radius: 0.25rem;
  display: ${({ open }) => open ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;
`;

export const FiltersHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.filtersHeaderBgColor};
  padding: 0.813rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .icon {
    cursor: pointer;

    & svg path[fill] {
      transition: fill ${transitionsDelay} linear;
    }

    & svg path[stroke] {
      transition: stroke ${transitionsDelay} linear;
    }

    &:hover {
      & svg path[fill] {
        fill: ${({ theme }) => theme.colors.focusColor};
      }

      & svg path[stroke] {
        stroke: ${({ theme }) => theme.colors.focusColor};
      }
    }
  }
`;

export const FiltersContent = styled.div` 
  max-height: 18.75rem;
  height: 100%;
  overflow-y: auto;
  padding: 0.625rem 1rem;
`;

export const FiltersActions = styled.div` 
  background-color: ${({ theme }) => theme.colors.filtersHeaderBgColor};
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClearButton = styled.div` 
  cursor: pointer;
  display: inline-block;
  width: auto;

  & span {
    color: rgba(255, 255, 255, 0.5);
    transition: color ${transitionsDelay} linear;
  }

  &:hover {
    & span {
      color: ${({ theme }) => theme.colors.labelColor};
    }
  }
`;
