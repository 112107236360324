/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TextInput as MantineInput,
  TextInputProps as MantineTextInputProps
} from '@mantine/core';
import { FieldRenderProps } from 'react-final-form';
import { GenericInput, InputSize } from './Generic';
import { InputWrapper, ErrorMessage, CounterSection } from './Styles';
import { useTranslation } from 'react-i18next';
import { DangerColor, DisabledColor } from '../../styles/Colors';
import React from 'react';
import Icon from '../icon';

export interface TextInputProps
  extends Omit<FieldRenderProps<string>, 'input'>,
    MantineTextInputProps {
  afterChange?: (value: string) => void;
  onSearch?: (value: string) => void;
  htmlType?: 'text' | 'password' | 'email' | 'number';
  input: GenericInput<string>;
  maxChar?: number;
  size?: InputSize;
  hideErrors?: boolean;
  isExternal?: boolean;
  clearable?: boolean;
  onlyIntegers?: boolean;
  showError?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
  input,
  meta = {},
  htmlType = 'text',
  label,
  placeholder,
  description,
  disabled,
  icon,
  rightSection,
  afterChange,
  variant,
  required,
  maxChar,
  size = 'md',
  hideErrors = false,
  isExternal = false,
  clearable = false,
  onSearch,
  onlyIntegers,
  showError = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { max, min } = rest;
    const value = event.currentTarget.value;

    // Extra validations to help in Score input fields
    if(value && htmlType === 'number') {
      if(min && min >= 0 && value < min) return undefined;
      if(max && max >= 0 && value > max) return undefined;

      if(onlyIntegers) {
        const regex = /^\d+$/;
        if(!regex.test(value)) return undefined;
      }
    }

    input.onChange(value);
    if (afterChange && typeof afterChange === 'function') {
      afterChange(value);
    }
  };

  const handleClear = () => {
    input.onChange('');
    if (afterChange && typeof afterChange === 'function') {
      afterChange('');
    }
  };

  const length = input.value ? input.value.length : 0;
  const missingField = showError || (meta?.invalid && meta?.submitFailed);
  const hasError = missingField || (maxChar && length > maxChar);
  const rightSectionError = hasError ? (
    <Icon icon="bold_xCircle" color={DangerColor} size={1.25} />
  ) : (
    ''
  );

  const showClear = clearable && input.value && input.value !== '' && (
    <Icon
      onClick={handleClear}
      icon="bold_xCircle"
      color={DisabledColor}
      size={1.25}
    />
  );

  const handleEnter = (e: any) => {
    if (onSearch && e.key === 'Enter' && !disabled) {
      onSearch(input.value || '');
    }
  };

  return (
    <InputWrapper
      size={size}
      hasError={hasError && !hideErrors}
      hasLeftIcon={!!icon}
      hasRightIcon={!!rightSection}
      isExternal={isExternal}
    >
      <MantineInput
        {...rest}
        type={htmlType}
        label={label}
        placeholder={placeholder}
        description={description}
        disabled={disabled}
        variant={variant}
        icon={icon}
        rightSection={rightSection || rightSectionError || showClear}
        value={input.value}
        onChange={handleChange}
        error={hasError}
        required={required}
        onKeyDown={handleEnter}
      />
      {missingField && !hideErrors ? (
        <ErrorMessage>
          {meta.error
            ? t(meta?.error?.tag, meta?.error?.values)
            : t('FIELD_REQUIRED')}
        </ErrorMessage>
      ) : maxChar ? (
        <CounterSection hasError={hasError}>
          {length}/{maxChar}
        </CounterSection>
      ) : null}
    </InputWrapper>
  );
};

export default TextInput;
