/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../../../styles/BasicStyles';
import { FC } from 'react';
import { Event } from '../../../../models/Event';
import { showConfirm, showSuccess, showError } from '../../../../hooks/show-notification/show-notification';
import { LabelColor } from '../../../../styles/Colors';
import { AnyObject } from '../../../../models/Generic';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../../components/modal';
import useFetch from 'use-http';
import Button from '../../../../components/button';
import Typography from '../../../../components/typography';
import TextAreaInput from '../../../../components/inputs/TextAreaInput';

interface Params {
  opened: boolean;
  onClose: () => void;
  initialValues?: Event;
}

const { Form } = withTypes<AnyObject>();

const RequestChangeModal: FC<Params> = ({ opened, onClose, initialValues }) => {
  const { t } = useTranslation();
  const { post } = useFetch('/events/request-change');

  const onSubmit = async (values: AnyObject) => {
    let error = false;
    
    const { data, success } = await post(`/${initialValues?._id}`, values);
    if(success) {
      if(data) {
        showSuccess({
          title: t('SUCCESS'),
          message: t('REQUEST_CHANGE_SUCCESS')
        });
      }
      else {
        showError({
          title: t('ERROR'),
          message: t('REQUEST_CHANGE_ERROR')
        });
      }

      onClose();
    }
    else error = true;

    return !error;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          description: yup.string().required()
        })
      )}
      render={({ handleSubmit, pristine, submitting, form: { reset } }) => {
        const handleClose = () => {
          if(!pristine) {
            showConfirm({
              title: t('EXIT_BEFORE_SAVE'),
              message: t('EXIT_BEFORE_SAVE_MESSAGE'),
              onConfirm: () => {
                onClose();
                reset();
              }
            });
          }
          else {
            onClose();
            reset();
          }
        };

        return (
          <Modal
            size={36.25}
            opened={opened}
            onClose={handleClose}
            title={t('REQUEST_CHANGE')}
          >
            <BasicForm onSubmit={async event => {
              const result = await handleSubmit(event);
              if(result) reset();
            }}>
              <ModalOverflowContent>
                <ModalContent>
                  <Grid gutter="xl" style={{ marginBottom: '0.5rem' }}>
                    <Grid.Col xs={12}>
                      <Typography variant='body' style={{ color: LabelColor }}>{t('EVENT')}: <span style={{ fontWeight: 500 }}>{initialValues?.name}</span></Typography>
                    </Grid.Col>
                  </Grid>
                  <Grid gutter="xl">
                    <Grid.Col xs={12}>
                      <Field name="description">
                        {(props) => 
                          <TextAreaInput 
                            {...props} 
                            label={`${t('DESCRIPTION')} *`}
                          />
                        }
                      </Field>
                    </Grid.Col>
                  </Grid>
                </ModalContent>
              </ModalOverflowContent>
              <ModalActions>
                <Button
                  text={t('CANCEL')}
                  variant="secondary"
                  onClick={handleClose}
                />
                <Button
                  text={t('SUBMIT')}
                  type="submit"
                  loading={submitting}
                  disabled={pristine}
                />
              </ModalActions>
            </BasicForm>
          </Modal>
        );
      }}
    />
  );
};

export default RequestChangeModal;
