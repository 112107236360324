import { FieldRenderProps } from 'react-final-form';
import { GenericInput, AnyObject } from '../../models/Generic';
import { useContext, useState } from 'react';
import { LanguageContext } from '../../Context';
import { useTranslation } from 'react-i18next';
import { InputWrapper, FlagIcon, InputLabel } from './Styles';
import TextInput from './TextInput';

interface IntlTitleProps extends Omit<FieldRenderProps<AnyObject>, 'input'> {
  afterChange?: (value: AnyObject) => void;
  input: GenericInput<AnyObject>;
  placeholder?: string;
  maxChar?: number;
}

const IntlTitleInput: React.FC<IntlTitleProps> = ({
  input,
  meta,
  label,
  placeholder,
  afterChange,
  maxChar
}) => {
  const [, setFocus] = useState<string>();
  const { t } = useTranslation();
  const languages = useContext(LanguageContext);

  const value = languages.reduce((result: AnyObject, lang) => {
    if (input.value && input.value[lang.code]) {
      result[lang.code] = input.value[lang.code];
    } else result[lang.code] = '';
    return result;
  }, {});

  const showError = meta.invalid && meta.submitFailed;

  const handleInputChange = (text: string | null, code: string) => {
    const newValue = { ...value };
    newValue[code] = text;
    input.onChange(newValue);
    if (afterChange && typeof afterChange === 'function') {
      afterChange(newValue);
    }
  };

  return (
    <InputWrapper hasError={showError} hasLeftIcon={true}>
      {label && <InputLabel>{label}</InputLabel>}
      {languages.map((lang) => {
        const error =
          showError &&
          meta.error &&
          (typeof meta.error === 'string' || !!meta.error[lang.code]);

        return (
          <TextInput
            input={{
              value: value[lang.code],
              onChange: (text: string | null) =>
                handleInputChange(text, lang.code)
            }}
            key={lang.code}
            placeholder={t(placeholder || lang.tag, { lang: t(lang.tag) })}
            meta={{ ...meta, invalid: error }}
            onFocus={() => setFocus(lang.code)}
            onBlur={() => setFocus(undefined)}
            icon={<FlagIcon><img src={lang.icon} /></FlagIcon>}
            styles={{
              wrapper: { marginBottom: '0.25rem' }
            }}
            maxChar={maxChar}
          />
        );
      })}
    </InputWrapper>
  );
};

export default IntlTitleInput;
