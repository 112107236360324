import { transitionsDelay } from '../../styles/BasicStyles';
import styled, { css } from 'styled-components';

const setSize = (size?: number | number[], defaultSize = 1) => {
  if (Array.isArray(size)) {
    return css`
      height: ${size?.[0]}rem;
      width: ${size?.[1]}rem;
    `;
  }

  return css`
    height: ${size || defaultSize}rem;
    width: ${size || defaultSize}rem;
  `;
};

export const IconSvg = styled.div<{ size?: number | number[], color?: string, hoverColor?: string }>`
  display: flex;

  & svg {
    ${({ size }) => setSize(size)};

    & path[stroke] {
      transition: stroke ${transitionsDelay} linear;
      stroke: ${({ color, theme }) => color || theme.colors.labelColor};
    }

    & path[fill] {
      transition: fill ${transitionsDelay} linear;
      fill: ${({ color, theme }) => color || theme.colors.labelColor};
    }
  }

  ${({ hoverColor }) => !!hoverColor &&
    css`
      cursor: pointer;
      
      &:hover {
        & svg {
          & path[stroke] {
            stroke: ${hoverColor};
          }

          & path[fill] {
            fill: ${hoverColor};
          }
        }
      }
    `
  }
`;

