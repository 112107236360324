import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react';
import { Block } from '../../styles/BasicStyles';
import { BorderColor } from '../../styles/Colors';
import { useLocation } from 'react-router-dom';
import { User, ATHLETE, AdminTypes } from '../../models/User';
import { UserContext } from '../../Context';
import { Federation } from '../../models/Federation';
import { Review } from '../../models/Review';
import EmptyState from '../../components/empty/EmptyState';
import LoadingOverlay from '../../components/loadingOverlay';
import DefaultListPage from '../../components/page/DefaultListPage';
import useFetch from 'use-http';
import Accordion from '../../components/accordion';
import Filters from './Filters';
import Title from './components/Title';
import Content from './components/Content';

const ReviewsListPage = DefaultListPage<Review>();

const ReviewsPage = () => {
  const [athletes, setAthletes] = useState<User[]>([]);
  const [federations, setFederations] = useState<Federation[]>([]);
  const { t } = useTranslation();
  const location = useLocation();
  const { get, put } = useFetch('/reviews');
  const athletesHook = useFetch(`/members/${ATHLETE}/dropdown`);
  const federationsHook = useFetch('/federations/dropdown');
  const { user } = useContext(UserContext);
  
  useEffect(() => {
    const init = async () => {
      const { data } = await athletesHook.get();
      setAthletes(data || []);

      if(user?.type && AdminTypes.includes(user.type)) {
        const federationsResult = await federationsHook.get();
        setFederations(federationsResult?.data || []);
      }
    };

    init();
  }, []);

  return (
    <ReviewsListPage
      translate={t}
      request={{
        get,
        reloadOnChange: [location.pathname]
      }}
      storage="REVIEW_FILTERS"
      header={{
        title: t('REVIEWS')
      }}
      filters={({ filters, handleChange }) => 
        <Filters 
          filters={filters} 
          handleChange={handleChange}
          athletes={athletes}
          location={location.pathname}
          federations={federations}
        />
      }
      countLabel={`${t('FIGHTS').toLowerCase()}`}
    >
      {({ rows, loading, refreshPage }) => {
        const onConfirmStatusToogle = async (id: string, status: string) => {
          const { success } = await put(`/${id}/change-status`, { status });
          if(success) refreshPage();
        };

        return (
          <Block margin="1.25 0">
            <LoadingOverlay visible={loading} />
            {
              rows.length > 0 ?
              rows.map((row, index) =>
                <div key={index}>
                  <Accordion 
                    title={<Title row={row} onConfirmStatusToogle={onConfirmStatusToogle} />}
                    content={<Content row={row} />}
                    titleClick={false}
                  />
                </div>
              )
              :
              <Block bRadius={0.5} style={{ border: `0.063rem solid ${BorderColor}` }}>
                <EmptyState />
              </Block>
            }
          </Block>
        );
      }}
    </ReviewsListPage>
  );
};

export default ReviewsPage;
