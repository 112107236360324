export const Rounds = [
  { _id: 1, name: '1' },
  { _id: 2, name: '2' },
  { _id: 3, name: '3' },
  { _id: 4, name: '4' },
  { _id: 5, name: '5' }
];

export const GetRound = (value: number | undefined) => {
  const found = Rounds.find(elem => elem._id === value);

  return found?.name || '';
};

export const RoundsDuration = [
  { _id: 1, name: '1 min' },
  { _id: 2, name: '2 min' },
  { _id: 3, name: '3 min' },
  { _id: 4, name: '4 min' },
  { _id: 5, name: '5 min' }
];

export const GetRoundDuration = (value: number | undefined) => {
  const found = RoundsDuration.find(elem => elem._id === value);

  return found?.name || '';
};

