/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import { FC, useState, useEffect } from 'react';
import { Fight } from '../../models/Fight';
import { Block, Box } from '../../styles/BasicStyles';
import { YellowJudge, BorderColor, BlueFighter, RedFighter, WhiteJudge, GreenJudge, GraphBorder, GraphRed, GraphYellow, GraphGreen, GraphWhiteSelected, GraphGreenSelected, GraphYellowSelected, PlaceholderColor } from '../../styles/Colors';
import { AnyObject } from '../../models/Generic';
import { MinuteWrapper, SvgLine, JudgeButtonWrapper, JudgeButton, GraphWrapper } from './Styles';
import { DotIcon } from '../../components/inputs/Styles';
import Typography from '../../components/typography';

type Judge = 'white' | 'green' | 'yellow';

interface Params {
  round: number;
  roundsArray: number[];
  roundDurationArray: number[];
  fight?: Fight;
}

const Graphs: FC<Params> = ({ round, roundsArray, roundDurationArray, fight }) => {
  const { t } = useTranslation();
  const [judgeButton, setJudgeButton] = useState<AnyObject[]>([]);

  useEffect(() => {
    setJudgeButton(Array.from({length: fight?.rounds || 0}, _ => ({ white: false, green: false, yellow: false })));
  }, [fight]);

  // In pixels
  const graphWidth = 270;
  const graphHeight = (106 * (fight?.duration || 0)) - 1;

  const renderGraphBlock = (bgColor: string, bColor?: string) => {
    return (
      <Block w={graphWidth / 16 / 18} h={3.25} bgColor={bgColor} style={{ borderRight: !!bColor ? `0.063rem solid ${bColor}` : 0 }} />
    );
  };

  const renderGraphLine = (minutes?: number, isLast?: boolean) => {
    return (
      <Box style={{ borderBottom: !isLast && `0.063rem solid ${BorderColor}`}} position='relative'>
        {renderGraphBlock(GraphRed, GraphBorder)}
        {renderGraphBlock(GraphRed, GraphBorder)}
        {renderGraphBlock(GraphRed, GraphBorder)}
        {renderGraphBlock(GraphYellow, GraphBorder)}
        {renderGraphBlock(GraphYellow, GraphBorder)}
        {renderGraphBlock(GraphYellow, GraphBorder)}
        {renderGraphBlock(GraphGreen, GraphBorder)}
        {renderGraphBlock(GraphGreen, GraphBorder)}
        {renderGraphBlock(GraphGreen, BorderColor)}
        {renderGraphBlock(GraphGreen, GraphBorder)}
        {renderGraphBlock(GraphGreen, GraphBorder)}
        {renderGraphBlock(GraphGreen, GraphBorder)}
        {renderGraphBlock(GraphYellow, GraphBorder)}
        {renderGraphBlock(GraphYellow, GraphBorder)}
        {renderGraphBlock(GraphYellow, GraphBorder)}
        {renderGraphBlock(GraphRed, GraphBorder)}
        {renderGraphBlock(GraphRed, GraphBorder)}
        {renderGraphBlock(GraphRed)}
        {
          minutes && minutes > 0 &&
          <MinuteWrapper>
            <Typography variant='body-small' fWeight={600} color='#000000'>{`${minutes} min`}</Typography>
          </MinuteWrapper>
        }
      </Box>
    );
  };

  const renderJudgeLine = (judge: Judge, color: string, round: number, zIndex: number) => {
    let points = '', max = 50, min = 50;
    const fightDuration = (fight?.duration || 0) * 60000;
    const pointsArray = fight?.record && fight?.record[`${judge}_stats`]?.data[round - 1]?.points || [];

    pointsArray && pointsArray?.forEach((elem: AnyObject) => {
      const x = (elem.endX * graphWidth) / 100;
      const y = ((((fightDuration - elem.time) * 100) / fightDuration) * graphHeight) / 100;

      points = `${points} ${x}, ${y}`;

      if(elem.endX > max) max = elem.endX;
      if(elem.endX < min) min = elem.endX;
    });

    max = (max * graphWidth) / 100;
    min = (min * graphWidth) / 100;

    return (
      <>
        <SvgLine zIndex={zIndex}>
          <polyline points={points} fill='none' stroke={color} strokeWidth='2' />
        </SvgLine>
        <SvgLine zIndex={zIndex}>
          <line x1={min} y1="0" x2={min} y2="100%" stroke={color} strokeWidth="3" />
        </SvgLine>
        <SvgLine zIndex={zIndex}>
          <line x1={max} y1="0" x2={max} y2="100%" stroke={color} strokeWidth="3" />
        </SvgLine>
      </>
    );
  };

  const toggleActiveButton = (judge: Judge, round: number) => {
    const oldValue = judgeButton[round - 1][judge];
    const aux = [...judgeButton];
    aux[round - 1][judge] = !oldValue;
    setJudgeButton(aux);
  };

  const renderJudgeButton = (judge: Judge, color: string, bgSelected: string, round: number) => {
    return (
      <JudgeButtonWrapper>
        <JudgeButton color={color} bgSelected={bgSelected} onClick={() => toggleActiveButton(judge, round)} isActive={judgeButton[round - 1] && judgeButton[round - 1][judge]}>
          <DotIcon color={color} />
          <Typography fSize={1} lHeight={1.188} fWeight={500} color='#ffffff' pl={0.438}>{t('JUDGE')}</Typography>
        </JudgeButton>
      </JudgeButtonWrapper>
    );
  }

  const renderRoundGraph = (round: number, showRountTitle: boolean) => {
    return (
      <Block key={round} display='flex' fDirection='column' w={{ lg: '33.333%', sm: '50%', xxs: '100%' }} padding='0 0.813' mb={0.75}>
        <Box w={graphWidth / 16} mt={1.875} fDirection='column' margin='auto'>
          {
            showRountTitle &&
            <Typography 
              as='div' 
              variant='page-title' 
              fWeight={500} 
              style={{ color: PlaceholderColor }} 
              mb={1} 
              tAlign='center' 
              tTransform='uppercase'
              w='100%'
            >
              {`${t('ROUND')} ${round}`}
            </Typography>
          }
          <Box fDirection='column'>
            <Box>
              <Box fAlign='center' fJustify='flex-end' w='50%' bgColor={RedFighter} padding='0.5 1'>
                <Typography variant='body-small' color='#ffffff'>RED</Typography>
              </Box>
              <Box fAlign='center' fJustify='flex-start' w='50%' bgColor={BlueFighter} padding='0.5 1'>
                <Typography variant='body-small' color='#ffffff'>BLUE</Typography>
              </Box>
            </Box>
            <GraphWrapper 
              fDirection='column' 
              position='relative' 
              h={graphHeight / 16} 
              hasSelectedJudge={judgeButton[round - 1]?.white || judgeButton[round - 1]?.green || judgeButton[round - 1]?.yellow ? true : false}
            >
              {
                roundDurationArray.map((_: number, durationIndex: number) =>
                  <Box key={`${round}_${durationIndex}`} fDirection='column'>
                    {renderGraphLine()}
                    {renderGraphLine(durationIndex + 1, durationIndex === (roundDurationArray.length - 1))}
                  </Box>
                )
              }
              {renderJudgeLine('white', WhiteJudge, round, judgeButton[round - 1]?.white ? 15 : 13)}
              {renderJudgeLine('green', GreenJudge, round, judgeButton[round - 1]?.green ? 15 : 12)}
              {renderJudgeLine('yellow', YellowJudge, round, judgeButton[round - 1]?.yellow ? 15 : 11)}
            </GraphWrapper>
          </Box>
          <Box mt={1.25}>
            {renderJudgeButton('white', WhiteJudge, GraphWhiteSelected, round)}
            {renderJudgeButton('green', GreenJudge, GraphGreenSelected, round)}
            {renderJudgeButton('yellow', YellowJudge, GraphYellowSelected, round)}
          </Box>
        </Box>
      </Block>
    );
  };

  return (
    <Block display='flex' margin='0 -0.813rem' style={{ flexFlow: 'row wrap' }} pb={3.25}>
      {
        round === 0 ?
        roundsArray.map((_: number, index: number) => renderRoundGraph(index + 1, true))
        :
        renderRoundGraph(round, false)
      }
    </Block>
  );
};

export default Graphs;
 