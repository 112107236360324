import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { User } from '../../../models/User';
import { BasicForm, Block } from '../../../styles/BasicStyles';
import { UserContext } from '../../../Context';
import { useContext, useEffect, useState } from 'react';
import { AnyObject } from '../../../models/Generic';
import { Languages } from '../../../utils/languages/AvailableTranslations';
import { AspectRatios } from '../../../utils/files/Conversions';
import { showSuccess, showError } from '../../../hooks/show-notification/show-notification';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../components/modal';
import useFetch from 'use-http';
import TextInput from '../../../components/inputs/TextInput';
import Button from '../../../components/button';
import SelectInput from '../../../components/inputs/SelectInput';
import ImageInput from '../../../components/inputs/ImageInput';
import toFormData from '../../../utils/formdata/ToFormData';
import PasswordInput from '../../../components/inputs/PasswordInput';
import PasswordVerificationInput from '../../../components/inputs/PasswordVerificationInput';

interface Params {
  opened: boolean;
  onClose: () => void;
  initialValues?: User;
  type?: string;
}

const { Form } = withTypes<User>();

const EditInfoModal: React.FC<Params> = ({
  opened,
  onClose,
  initialValues,
  type
}) => {
  const { t } = useTranslation();
  const { put } = useFetch('/users/my-profile');
  const passwordHook = useFetch('/users/change-password');
  const { setUser } = useContext(UserContext);
  const [info, setInfo] = useState<{ title?: string; validations?: AnyObject }>({});

  useEffect(() => {
    switch (type) {
      case 'NAME':
        setInfo({
          title: t('EDIT_NAME'),
          validations: { name: yup.string().required() }
        });
        break;
      case 'EMAIL':
        setInfo({
          title: t('EDIT_EMAIL'),
          validations: { email: yup.string().email().required() }
        });
        break;
      case 'LANGUAGE':
        setInfo({
          title: t('EDIT_LANGUAGE'),
          validations: { language: yup.string().required() }
        });
        break;
      case 'PASSWORD':
        setInfo({
          title: t('CHANGE_PASSWORD'),
          validations: { 
            current: yup.string().required().min(6),
            password: yup.string().required().min(6),
            confirm_password: yup.string().required().min(6) 
          }
        });
        break;
      case 'IMAGE':
        setInfo({
          title: t('EDIT_PHOTO'),
          validations: {}
        });
        break;
      default:
        break;
    }
  }, [type]);

  const onSubmit = async (values: User) => {
    if(type === 'PASSWORD' && values.password !== values.confirm_password) {
      return showError({
        title: t('ERROR'),
        message: t('PASSWORDS_DONT_MATCH')
      });
    }

    const payload = toFormData(values);

    if(values._id) {
      let data = null, success = false;
      if(type === 'PASSWORD') ({ data, success } = await passwordHook.put(payload));
      else ({ data, success } = await put(payload));

      if(success) {
        setUser(data);
        onClose();

        showSuccess({
          title: t('SUCCESS'),
          message: t('PROFILE_UPDATED')
        });
      }
    }
  };

  return (
    <Modal size={27.5} opened={opened} onClose={onClose} title={info.title}>
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema(yup.object(info.validations))}
        initialValues={initialValues || {}}
      >
        {({ handleSubmit, submitting }) => (
          <BasicForm onSubmit={handleSubmit}>
            <ModalOverflowContent>
              <ModalContent style={{ paddingTop: '2.5rem', paddingBottom: '2.5rem' }}>
                <Grid gutter="xl">
                  <Grid.Col span={12}>
                    {type === 'NAME' ? (
                      <Field name="name">
                        {(props) => (
                          <TextInput 
                            {...props} 
                            label={t('FULL_NAME')} 
                            placeholder={t('ENTER_NAME')} 
                          />
                        )}
                      </Field>
                    ) : type === 'EMAIL' ? (
                      <Field name="email">
                        {(props) => (
                          <TextInput
                            {...props}
                            label={t('EMAIL')}
                            placeholder={t('ENTER_EMAIL')}
                            htmlType="email"
                          />
                        )}
                      </Field>
                    ) : type === 'LANGUAGE' ? (
                      <Field name="language">
                        {(props) => (
                          <SelectInput
                            {...props}
                            label={t('LANGUAGE')}
                            placeholder={t('SELECT_LANGUAGE')}
                            data={Languages}
                            keyValue="code"
                          />
                        )}
                      </Field>
                    ) : type === 'PASSWORD' ? (
                      <>
                        <Field name="current">
                          {(props) => (
                            <PasswordInput
                              {...props}
                              label={t('CURRENT_PASSWORD')}
                              placeholder={t('ENTER_CURRENT_PASSWORD')}
                            />
                          )}
                        </Field>
                        <Block w='100%' mt={1.5}>
                          <Field name="password">
                            {(props) => (
                              <PasswordVerificationInput
                                {...props}
                                label={t('PASSWORD')}
                                placeholder={t('ENTER_PASSWORD')}
                              />
                            )}
                          </Field>
                        </Block>
                        <Block w='100%' mt={1.5}>
                          <Field name="confirm_password">
                            {(props) => (
                              <PasswordInput
                                {...props}
                                label={t('CONFIRM_PASSWORD')}
                                placeholder={t('CONFIRM_PASSWORD')}
                              />
                            )}
                          </Field>
                        </Block>
                      </>
                    ) : type === 'IMAGE' ? (
                      <Field name="photo">
                        {(props) => (
                          <ImageInput
                            {...props}
                            ratio={AspectRatios['1:1']}
                            showDropzoneArea
                          />
                        )}
                      </Field>
                    ) : null}
                  </Grid.Col>
                </Grid>
              </ModalContent>
            </ModalOverflowContent>
            <ModalActions>
              <Button
                text={t('CANCEL')}
                variant="secondary"
                onClick={onClose}
              />
              <Button text={t('SAVE')} type="submit" loading={submitting} />
            </ModalActions>
          </BasicForm>
        )}
      </Form>
    </Modal>
  );
};

export default EditInfoModal;
