import 'react-phone-number-input/style.css';
import { TextInputProps as MantineTextInputProps } from '@mantine/core';
import { FieldRenderProps } from 'react-final-form';
import { GenericInput, InputSize } from './Generic';
import {
  PhoneInputWrapper,
  ErrorMessage,
  InputLabel,
  ErrorIconWrapper
} from './Styles';
import { useTranslation } from 'react-i18next';
import { DangerColor } from '../../styles/Colors';
import PhoneInput from 'react-phone-number-input';
import React from 'react';
import Icon from '../icon';

export interface PhoneInputProps
  extends Omit<FieldRenderProps<string>, 'input'>,
    MantineTextInputProps {
  afterChange?: (value: string) => void;
  input: GenericInput<string>;
  size?: InputSize;
  isExternal?: boolean;
}

export const PhoneInputComponent: React.FC<PhoneInputProps> = ({
  input,
  meta = {},
  label,
  placeholder,
  disabled,
  afterChange,
  size = 'md',
  isExternal = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleChange = (value: string): void => {
    input.onChange(value);
    if (afterChange && typeof afterChange === 'function') {
      afterChange(value);
    }
  };

  const hasError = meta?.invalid && meta?.submitFailed;

  return (
    <PhoneInputWrapper size={size} hasError={hasError} isExternal={isExternal}>
      {label && <InputLabel size={size}>{label}</InputLabel>}
      <div style={{ position: 'relative' }}>
        <PhoneInput
          {...rest}
          placeholder={placeholder}
          value={input.value}
          onChange={handleChange}
          disabled={disabled}
          countryCallingCodeEditable={false}
          international
          defaultCountry="PT"
        />
        {hasError && (
          <ErrorIconWrapper>
            <Icon icon="bold_xCircle" color={DangerColor} size={1.25} />
          </ErrorIconWrapper>
        )}
      </div>
      {hasError && (
        <ErrorMessage>
          {meta.error
            ? t(meta?.error?.tag, meta?.error?.values)
            : t('FIELD_REQUIRED')}
        </ErrorMessage>
      )}
    </PhoneInputWrapper>
  );
};

export default PhoneInputComponent;
