import { Box, transitionsDelay } from '../../styles/BasicStyles';
import styled, { css } from 'styled-components';

export const MinuteWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 35%;
  z-index: 10;
`;

export const SvgLine = styled.svg<{ zIndex: number }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex};
`;

export const JudgeButtonWrapper = styled.div`
  padding: 0 0.188rem;
`;

export const JudgeButton = styled.div<{ color: string, isActive: boolean, bgSelected: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0.063rem solid ${({ color }) => color};
  border-radius: 0.5rem;
  padding: 0.688rem;
  color: ${({ color }) => color};
  background-color: transparent;
  cursor: pointer;
  transition: opacity ${transitionsDelay} linear;

  &:hover {
    opacity: 0.6;
  }

  ${({ isActive, bgSelected }) => isActive &&
    css`
      background-color: ${bgSelected};
    `
  }
`;

export const GraphWrapper = styled(Box)<{ hasSelectedJudge: boolean }>`
  ${({ hasSelectedJudge }) => hasSelectedJudge &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 14;
      }  
    `
  }
`;
