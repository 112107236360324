/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fight, DecisionList } from '../../models/Fight';
import { JUDGE, RECORD_KEEPER, REFEREE, COMMISSIONER, User } from '../../models/User';
import dayjs from 'dayjs';

export const GetFightDecision = (t: (tag: string, props?: any) => string, value: string | undefined, submission: string | undefined) => {
  const found = DecisionList.find(elem => elem._id === value);

  if(!found) return '';

  if((found?._id === 'OTHER' || found?._id === 'SUBMISSION') && submission) return `${t(found.name)}: ${submission}`;
  return t(found.name);
};

export const FightResults = [
  { _id: 'WIN', name: 'WIN' },
  { _id: 'DRAW', name: 'DRAW' },
  { _id: 'LOSS', name: 'LOSS' }
];

export const GetFightResult = (value: string | undefined) => {
  const found = FightResults.find(elem => elem._id === value);

  return found?.name || '';
};

export const FightTypes = [
  { _id: 'SINGLE', name: 'SINGLE_FIGHT' },
  { _id: 'TOURNAMENT', name: 'TOURNAMENT' }
];

export const GetFightType = (value: string | undefined) => {
  const found = FightTypes.find(elem => elem._id === value);

  return found?.name || '';
};

export const GetFightDate = (startDate?: Date) => {
  if(!startDate) return '';

  return dayjs(startDate).format('DD MMM, HH:mm');
}

export const GetFightRole = (fight: Fight, officialID?: string) => {
  if(fight.referee?._id?.toString() === officialID || fight.referee2?._id?.toString() === officialID) return REFEREE;
  if(fight.yellow_judge?._id?.toString() === officialID || fight.green_judge?._id?.toString() === officialID || fight.white_judge?._id?.toString() === officialID) return JUDGE;
  if(fight.record_keeper?._id?.toString() === officialID) return RECORD_KEEPER;
  if(officialID && fight.commissioner && fight.commissioner.filter((user: User) => user._id?.toString() === officialID)?.length > 0) return COMMISSIONER;

  return '';
};

export const GetNewFightNumber = (fights: Fight[]) => {
  let fight_number = 1;
  if(fights?.length > 0) {
    let max = 0;
    fights.forEach((elem: Fight) => {
      if(elem.fight_number > max) max = elem.fight_number;
    });
    fight_number = max + 1;
  }

  return fight_number;
};
