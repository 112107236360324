import EN from './flags/en.svg';
import PT from './flags/pt.svg';
// import DE from './flags/de.svg';
// import FR from './flags/fr.svg';
// import IT from './flags/it.svg';
// import ES from './flags/es.svg';

export type LanguagesProps = {
  code: string;
  name: string;
  tag: string;
  icon: string;
}

export const Languages: LanguagesProps[] = [
  { code: 'pt', name: 'Português', tag: 'PORTUGUESE', icon: PT },
  { code: 'en', name: 'English', tag: 'ENGLISH', icon: EN },
  // { code: 'es', name: 'Español', tag: 'SPANISH', icon: ES },
  // { code: 'de', name: 'Deutsch', tag: 'GERMAN', icon: DE },
  // { code: 'fr', name: 'Français', tag: 'FRENCH', icon: FR },
  // { code: 'it', name: 'Italiano', tag: 'ITALIAN', icon: IT }
];

export const AvailableLanguages = (list: string[] = []) => Languages.filter((l) => list.includes(l.code));

export const getLanguage = (code: string | undefined) => Languages.find((elem: LanguagesProps) => elem.code === code) || null; 
