import { Box } from '../../styles/BasicStyles';
import styled, { css } from 'styled-components';

export const AccordionTitleWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.menuBgColor};
  border-radius: 0.5rem;
  border: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
  cursor: pointer;
`;

export const AccordionContentWrapper = styled.div<{ open: boolean }>`
  height: 0;
  ${props => !props.open && css`overflow: hidden;`}
  opacity: ${props => props.open ? "1" : "0"};
  transition: height ease 0.2s, opacity ease 0.25s;
  width: 100%;
`;
