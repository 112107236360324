/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';

interface AnyObject {
  [key: string]: any;
}

export const unflattenObject = (data: any) => {
  const result = {};
  for (const i in data) {
    const keys = i.split('.');
    keys.reduce(function(r: AnyObject, e: string, j: number) {
      return (
        r[e] ||
        (r[e] = isNaN(Number(keys[j + 1]))
          ? keys.length - 1 == j
            ? data[i]
            : {}
          : [])
      );
    }, result);
  }
  return result;
};

export const cleanObject = (
  object: AnyObject = {},
  invalidKeys: Array<string> = [],
  unflatten = false
) => {
  const result = flattenObject(object);
  invalidKeys.map((key: string) => {
    if (invalidKeys.includes(key)) delete result[key];
  });

  if (unflatten) return unflattenObject(result);
  return result;
};

export const flattenObject = (
  obj: AnyObject,
  parent = '',
  res: AnyObject = {}
) => {
  const keys = Object.keys(obj);
  keys.forEach(key => {
    const propName = parent !== '' ? `${parent}.${key}` : key;
    if (obj[key]) {
      if (typeof obj[key] === 'object') {
        if (obj[key].blob) {
          res[propName] = obj[key].blob;
        } else if (obj[key] instanceof dayjs || obj[key] instanceof Date) {
          res[propName] = dayjs(obj[key]).format();
        } else flattenObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    if (obj[key] === '') {
      res[propName] = '';
    }
    if (obj[key] === false) {
      res[propName] = false;
    }
    if (obj[key] === undefined) {
      res[propName] = undefined;
    }
    if (obj[key] === null) {
      res[propName] = undefined;
    }
  });
  return res;
};

export const toFormData = (object: AnyObject) => {
  const flatten = flattenObject(object);
  const formData = new FormData();
  Object.keys(flatten).forEach(key => formData.append(key, flatten[key]));
  return formData;
};

export default toFormData;
