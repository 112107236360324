import { breakpoints } from '../../styles/Breakpoints';
import styled from 'styled-components';

export const LayoutWrapper = styled.div<{ showSidebar?: boolean }>`
  width: ${({ showSidebar }) => showSidebar ? 'calc(100% - 17.5rem)' : '100%'};
  margin-top: 4.5rem;
  margin-left: ${({ showSidebar }) => showSidebar ? '17.5rem' : 0};
  padding: 1rem 1.25rem;
  height: calc(100vh - 4.5rem);
  background: ${({ theme }) => theme.colors.bgColor};
  overflow-y: auto;
  overflow-x: hidden;

  @media ${breakpoints.md} {
    width: 100%;
    margin-left: 0rem;
  }
`;

export const LayoutContent = styled.div`
  max-width: 92rem;
  width: 100%;
  margin: auto;
`;
