import { User } from './User';
import { AnyObject } from './Generic';

export const AWAITING_REVIEW = 'AWAITING_REVIEW';
export const EXPIRED = 'EXPIRED';
export const VALIDATED = 'VALIDATED';
export const REJECTED = 'REJECTED';

export interface Document {
  readonly _id: string;
  user: User;
  file: AnyObject;
  category: AnyObject;
  status: typeof AWAITING_REVIEW | typeof EXPIRED | typeof VALIDATED | typeof REJECTED
  expiration_date?: Date;
}

export interface DocumentCategory {
  readonly _id: string;
  name: AnyObject;
  code: string;
}

export interface DocumentsDescriptions {
  category: string;
  description: AnyObject;
  active?: AnyObject;
}
