import { useTranslation } from 'react-i18next';
import { LabelColor, MenuBgColor, FocusColor } from '../../../styles/Colors';
import { Box, Block } from '../../../styles/BasicStyles';
import { ItemsWrapper } from '../styles';
import { ReactNode, FC } from 'react';
import { UserContext } from '../../../Context';
import { useState, useContext } from 'react';
import { getLanguage } from '../../../utils/languages/AvailableTranslations';
import { User } from '../../../models/User';
import { showDelete, showSuccess } from '../../../hooks/show-notification/show-notification';
import Typography from '../../../components/typography';
import Link from '../../../components/link';
import UserAvatar from '../../../components/userAvatar';
import EditInfoModal from './EditInfoModal';
import useFetch from 'use-http';

export const Card: FC<{ title?: string; children: ReactNode; mt: number }> = ({
  title,
  children,
  mt
}) => {
  return (
    <Box fDirection="column" maxW={50.625} margin="auto" mt={mt}>
      {
        !!title &&
        <Typography
          variant="page-title"
          style={{ color: LabelColor, fontWeight: 500 }}
        >
          {title}
        </Typography>
      }
      <ItemsWrapper
        fDirection="column"
        mt={1}
        bgColor={MenuBgColor}
        bRadius={0.5}
      >
        {children}
      </ItemsWrapper>
    </Box>
  );
};

export const Item: FC<{ title: string; children: ReactNode }> = ({
  title,
  children
}) => {
  return (
    <Block pt={1} pb={1} w="100%">
      <Box
        fDirection={{ xs: 'row', xxs: 'column' }}
        fWrap="wrap"
        fJustify="space-between"
        fAlign={{ xs: 'center', xxs: 'flex-start' }}
        pl={1.25}
        pr={1.25}
      >
        <Typography
          variant="pagination"
          style={{ color: LabelColor }}
          mb={{ xs: 0, xxs: 1 }}
          pr={0.5}
        >
          {title}
        </Typography>
        <Block display="flex" fAlign="center" fDirection="row" fWrap='wrap'>
          {children}
        </Block>
      </Box>
    </Block>
  );
};

export const renderLink = (text: string, onClick: () => void) => {
  const { t } = useTranslation();
  
  return (
    <Link onClick={onClick}>
      <Typography variant="body-small" fWeight={500} color={FocusColor}>
        {t(text)}
      </Typography>
    </Link>
  );
};

const EditProfile = () => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const [modal, setModal] = useState<{
    open: boolean;
    type?: string;
    initialValues?: User;
  }>({ open: false });
  const { put } = useFetch('/users/my-profile');

  const deleteImage = async () => {
    const { data, success } = await put({ ...user, photo: null });

    if (success) {
      setUser(data);

      showSuccess({
        title: t('SUCCESS'),
        message: t('IMAGE_DELETED')
      });
    }
  };

  return (
    <>
      <Card title={t('BASICS')} mt={3.125}>
        <Item title={t('PHOTO')}>
          <UserAvatar user={user} size="sm" />
          <Block ml={1} mr={1}>
            {renderLink('EDIT', () =>
              setModal({ open: true, type: 'IMAGE', initialValues: user })
            )}
          </Block>
          {user?.photo &&
            renderLink('DELETE', () =>
              showDelete({
                title: t('DELETE_IMAGE'),
                message: t('DELETE_IMAGE_TEXT'),
                onConfirm: deleteImage
              })
            )}
        </Item>
        <Item title={t('NAME')}>
          <Typography variant="body-small" style={{ color: LabelColor }} mr={1}>
            {user?.name}
          </Typography>
          {renderLink('EDIT', () =>
            setModal({ open: true, type: 'NAME', initialValues: user })
          )}
        </Item>
        <Item title={t('EMAIL')}>
          <Typography variant="body-small" style={{ color: LabelColor }} mr={1}>
            {user?.email}
          </Typography>
          {renderLink('EDIT', () =>
            setModal({ open: true, type: 'EMAIL', initialValues: user })
          )}
        </Item>
        <Item title={t('PASSWORD')}>
          {renderLink('EDIT', () =>
            setModal({ open: true, type: 'PASSWORD', initialValues: user })
          )}
        </Item>
      </Card>
      <Card title={t('PREFERENCES')} mt={5.188}>
        <Item title={t('LANGUAGE')}>
          <Typography variant="body-small" style={{ color: LabelColor }} mr={1}>
            {getLanguage(user?.language)?.name}
          </Typography>
          {renderLink('EDIT', () =>
            setModal({ open: true, type: 'LANGUAGE', initialValues: user })
          )}
        </Item>
      </Card>
      <EditInfoModal
        opened={modal.open}
        initialValues={modal.initialValues}
        type={modal.type}
        onClose={() => setModal({ open: false })}
      />
    </>
  );
};

export default EditProfile;
