import { useMemo, useContext } from 'react';
import * as yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import { LanguageContext } from '../../Context';
import { AnyObject } from '../../models/Generic';
import { Language } from '../../models/Language';

export interface IntlOptions {
  required?: boolean;
  html?: boolean;
  maxChar?: number;
  minChar?: number;
  length?: number;
}

export type IntlValidationFunction = (
  options?: IntlOptions
) => OptionalObjectSchema<AnyObject>;

const isHTMLEmpty = (value: string | undefined) => {
  const strippedString = value?.replace(/(<([^>]+)>)/gi, '');
  return !!(
    strippedString &&
    strippedString !== '' &&
    strippedString.length > 0
  );
};

const intlValidation = (languages: Language[]) => (options?: IntlOptions) => {
  let rules = yup.string();
  if (options?.required) rules = rules.required();
  if (options?.html)
    rules = rules.test('sanitize', 'The html content is empty', isHTMLEmpty);
  if (options?.maxChar) rules = rules.max(options?.maxChar);
  if (options?.minChar) rules = rules.max(options?.minChar);
  if (options?.length) rules = rules.length(options?.length);

  const langs: AnyObject = {};
  languages.forEach((lang) => {
    langs[lang.code] = rules;
  });
  return yup.object(langs);
};

export default function useIntlValidation() {
  const languages = useContext(LanguageContext);
  return useMemo(() => intlValidation(languages), [languages]);
}
