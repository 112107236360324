import * as yup from 'yup';
import { Grid } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../../styles/BasicStyles';
import { InfoContext, UserContext } from '../../../Context';
import { FC, useContext, useEffect, useState } from 'react';
import { Club } from '../../../models/Club';
import { AspectRatios } from '../../../utils/files/Conversions';
import { AdminTypes } from '../../../models/User';
import Modal, { ModalActions, ModalContent, ModalOverflowContent } from '../../../components/modal';
import useFetch from 'use-http';
import TextInput from '../../../components/inputs/TextInput';
import Button from '../../../components/button';
import PhoneInput from '../../../components/inputs/PhoneInput';
import SelectInput from '../../../components/inputs/SelectInput';
import ImageInput from '../../../components/inputs/ImageInput';
import toFormData from '../../../utils/formdata/ToFormData';
import DateInput from '../../../components/inputs/DateInput';
import MultiSelectInput from '../../../components/inputs/MultiSelectInput';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: Club;
}

const { Form } = withTypes<Club>();

const ManageClubModal: FC<Params> = ({ opened, onClose, initialValues }) => {
  const [federations, setFederations] = useState([]);
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const { post, put } = useFetch('/clubs');
  const { get } = useFetch('/federations/dropdown');

  useEffect(() => {
    const init = async () => {
      const { data, success } = await get();
      if (success) setFederations(data);
    };
    if (AdminTypes.includes(user?.type || '')) init();
  }, []);

  const onSubmit = async (values: Club) => {
    if (!AdminTypes.includes(user?.type || '')) values.federation = user?.federation?._id;

    let error = false;
    const payload = toFormData(values);

    if (values._id) {
      const { success } = await put(`/${values._id}`, payload);
      if (success) onClose(true);
      else error = true;
    } else {
      const { success } = await post(payload);
      if (success) onClose(true);
      else error = true;
    }

    return !error;
  };

  return (
    <Modal
      size={36.25}
      opened={opened}
      onClose={() => onClose(false)}
      title={initialValues?._id ? t('EDIT_CLUB') : t('CREATE_CLUB')}
    >
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema(
          yup.object({
            name: yup.string().required()
          })
        )}
        initialValues={initialValues || {}}
      >
        {({ handleSubmit, submitting, form: { reset } }) => (
          <BasicForm onSubmit={async event => {
            const result = await handleSubmit(event);
            if(result) reset();
          }}>
            <ModalOverflowContent>
              <ModalContent>
                <Grid gutter="xl">
                  <Grid.Col xs={12}>
                    <Field name="logo">
                      {(props) => (
                        <ImageInput {...props} ratio={AspectRatios['1:1']} />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Field name="name">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={`${t('NAME')} *`}
                          placeholder={t('ENTER_NAME')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  {AdminTypes.includes(user?.type || '') && (
                    <Grid.Col xs={12}>
                      <Field name="federation">
                        {(props) => (
                          <SelectInput
                            {...props}
                            label={`${t('FEDERATION')} *`}
                            placeholder={t('CHOOSE_FEDERATION')}
                            clearable
                            data={federations}
                          />
                        )}
                      </Field>
                    </Grid.Col>
                  )}
                  <Grid.Col xs={12} sm={6}>
                    <Field name="birthday">
                      {(props) => (
                        <DateInput
                          {...props}
                          label={`${t('BIRTH_DATE')} *`}
                          placeholder={t('CHOOSE_DATE')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="martial_arts">
                      {(props) => (
                        <MultiSelectInput
                          {...props}
                          label={t('MARTIAL_ARTS')}
                          placeholder={t('CHOOSE_MARTIALS_ARTS')}
                          clearable
                          data={info?.martialArts || []}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="email">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('EMAIL')}
                          htmlType="email"
                          placeholder={t('ENTER_EMAIL')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="contact">
                      {(props) => (
                        <PhoneInput {...props} label={t('PHONE_NUMBER')} />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Field name="website">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('WEBSITE')}
                          placeholder={t('ENTER_WEBSITE')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="address">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('ADDRESS')}
                          placeholder={t('ENTER_ADDRESS')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="postal_code">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('POSTAL_CODE')}
                          placeholder={t('ENTER_POSTAL_CODE')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                </Grid>
                <Grid gutter="xl">
                  <Grid.Col xs={12} sm={6}>
                    <Field name="city">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('CITY')}
                          placeholder={t('ENTER_CITY')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="country">
                      {(props) => (
                        <SelectInput
                          {...props}
                          label={t('COUNTRY')}
                          placeholder={t('CHOOSE_COUNTRY')}
                          clearable
                          data={info?.countries || []}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Field name="responsable.name">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('CLUB_MANAGER_NAME')}
                          placeholder={t('ENTER_NAME')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="responsable.email">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('RESPONSABLE_EMAIL')}
                          htmlType="email"
                          placeholder={t('ENTER_RESPONSABLE_EMAIL')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Field name="responsable.contact">
                      {(props) => (
                        <PhoneInput {...props} label={t('RESPONSABLE_CONTACT')} />
                      )}
                    </Field>
                  </Grid.Col>
                </Grid>
              </ModalContent>
            </ModalOverflowContent>
            <ModalActions>
              <Button
                text={t('CANCEL')}
                variant="secondary"
                onClick={() => onClose(false)}
              />
              <Button
                text={initialValues?._id ? t('SAVE') : t('CREATE')}
                type="submit"
                loading={submitting}
              />
            </ModalActions>
          </BasicForm>
        )}
      </Form>
    </Modal>
  );
};

export default ManageClubModal;
